import React from 'react'
import classNames from 'classnames'
import { ThemeProvider, useTheme } from 'react-jss'
import { useLoadingMaskStyles } from './LoadingMask.styles'

/**
 * LoadingMask - spinner when page is not loaded or fetching some requests
 * */

interface LoadingMaskContainerProps {
  light?: boolean
  dark?: boolean
  screenHeight?: boolean
  testId?: string
}

const LoadingMaskContainer: React.FC<LoadingMaskContainerProps> = ({
  light = false,
  dark = false,
  screenHeight = false,
  testId = '',
}) => {
  const theme = useTheme()
  const classes = useLoadingMaskStyles({ theme })
  const loadingMaskClasses = classNames('lmask', classes?.lmask, {
    [String(classes?.lmaskLight)]: light,
    [String(classes?.lmaskDark)]: dark,
  })
  return (
    <div className={screenHeight ? classes?.screenHeightContainer : ''}>
      <div data-testid={testId} className={loadingMaskClasses} />
    </div>
  )
}

export const LoadingMask = ({ theme = {}, ...restProps }) => (
  <ThemeProvider theme={theme}>
    <LoadingMaskContainer {...restProps} />
  </ThemeProvider>
)

LoadingMask.displayName = 'LoadingMask'

export const TableLoadingMask = ({ theme, ...restProps }) => (
  <ThemeProvider theme={{ ...theme, useDynamicPosition: true }}>
    <LoadingMaskContainer {...restProps} />
  </ThemeProvider>
)

TableLoadingMask.displayName = 'TableLoadingMask'
