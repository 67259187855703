import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloEloPublisherIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M12.3125 8.3999H15.75'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3125 11.1001H15.75'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.16479 11.0998C8.30388 11.0998 9.22729 10.1932 9.22729 9.0748C9.22729 7.95643 8.30388 7.0498 7.16479 7.0498C6.02571 7.0498 5.10229 7.95643 5.10229 9.0748C5.10229 10.1932 6.02571 11.0998 7.16479 11.0998Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.50073 13.125C4.65219 12.5442 4.99621 12.0295 5.47857 11.6619C5.96093 11.2943 6.55418 11.0947 7.16479 11.0947C7.77541 11.0947 8.36866 11.2943 8.85102 11.6619C9.33338 12.0295 9.6774 12.5442 9.82886 13.125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.8125 3H2.6875C2.3078 3 2 3.30221 2 3.675V15.825C2 16.1978 2.3078 16.5 2.6875 16.5H17.8125C18.1922 16.5 18.5 16.1978 18.5 15.825V3.675C18.5 3.30221 18.1922 3 17.8125 3Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
