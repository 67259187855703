import { stringReplaceAll } from 'utils/string.utils'

const numberFormatMoney = (
  amount: string | number,
  decimalCount: number,
  decimal: string,
  thousands: string
): string => {
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount
  decimal = decimal === void 0 ? '.' : decimal
  thousands = thousands === void 0 ? ',' : thousands
  const negativeSign = Number(amount) < 0 ? '-' : ''
  const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
  const j = i.length > 3 ? i.length % 3 : 0
  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
    (decimalCount
      ? decimal +
        Math.abs(Number(amount) - Number(i))
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}

const stringFormatMoney = (amount: string, decimalCount: number, decimal: string, thousands: string) =>
  numberFormatMoney(parseFloat(stringReplaceAll(amount, ',', '.')), decimalCount, decimal, thousands)

export const formatMoney = (amount: string | number, decimalCount: number, decimal: string, thousands: string) =>
  typeof amount === 'string'
    ? stringFormatMoney(amount, decimalCount, decimal, thousands)
    : numberFormatMoney(amount, decimalCount, decimal, thousands)

export const formatToMoney = (
  amount?: string | number | undefined,
  currencySign?: string | null,
  countNumberAfterComma?: number | null,
  separator?: string | null,
  currencySignPosition?: string | null
) => {
  if (currencySign == null) {
    currencySign = '€'
  }
  if (countNumberAfterComma == null) {
    countNumberAfterComma = 2
  }
  if (separator == null) {
    separator = ''
  }

  const isDollar = currencySign === '$'
  const isBritishPounds = currencySign === '£'

  if (currencySignPosition == null) {
    currencySignPosition = isDollar || isBritishPounds ? 'left' : 'right'
  }

  const formattedValue = formatMoney(amount, countNumberAfterComma, isDollar ? '.' : ',', isDollar ? ',' : '.')

  if (currencySignPosition === 'right') {
    return `${formattedValue}${separator}${currencySign}`
  }
  return `${currencySign}${separator}${formattedValue}`
}
