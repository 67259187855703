import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloWarningIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M9.06332 2.12669C9.3479 1.96167 9.67103 1.87476 9.99999 1.87476C10.329 1.87476 10.6521 1.96167 10.9367 2.12669C11.2207 2.29142 11.4563 2.52815 11.6197 2.81298L11.6206 2.81452L18.494 14.6868L18.4944 14.6874C18.6587 14.972 18.7453 15.2947 18.7456 15.6233C18.7459 15.9519 18.6598 16.2748 18.496 16.5596C18.3323 16.8444 18.0965 17.0812 17.8124 17.2463C17.5283 17.4113 17.2058 17.4988 16.8772 17.4999L16.875 17.5H3.12499L3.12279 17.4999C2.79422 17.4988 2.47172 17.4113 2.18761 17.2463C1.90349 17.0812 1.66773 16.8444 1.50395 16.5596C1.34016 16.2748 1.2541 15.9519 1.2544 15.6233C1.25469 15.2947 1.34132 14.972 1.5056 14.6874L1.50598 14.6868L8.37941 2.81452L8.38029 2.81298C8.54366 2.52815 8.77925 2.29142 9.06332 2.12669ZM8.92187 3.12495L9.46276 3.4381L2.58813 15.3125L2.58799 15.3127C2.53332 15.4075 2.50449 15.515 2.50439 15.6244C2.5043 15.7339 2.53298 15.8416 2.58758 15.9365C2.64217 16.0315 2.72076 16.1104 2.81546 16.1654C2.90991 16.2203 3.01708 16.2494 3.12629 16.25H16.8737C16.9829 16.2494 17.0901 16.2203 17.1845 16.1654C17.2792 16.1104 17.3578 16.0315 17.4124 15.9365C17.467 15.8416 17.4957 15.7339 17.4956 15.6244C17.4955 15.515 17.4667 15.4076 17.4121 15.3128L17.4119 15.3125L10.5372 3.4381L10.5357 3.43539L10.5357 3.43539C10.4817 3.34101 10.4037 3.26258 10.3096 3.20803C10.2155 3.15348 10.1087 3.12476 9.99999 3.12476C9.89125 3.12476 9.78445 3.15348 9.69038 3.20803C9.59631 3.26258 9.51833 3.34101 9.46432 3.43539L8.92187 3.12495ZM10 7.5C10.3452 7.5 10.625 7.77982 10.625 8.125V11.25C10.625 11.5952 10.3452 11.875 10 11.875C9.65482 11.875 9.375 11.5952 9.375 11.25V8.125C9.375 7.77982 9.65482 7.5 10 7.5ZM10.7812 14.0625C10.7812 14.494 10.4315 14.8438 10 14.8438C9.56853 14.8438 9.21875 14.494 9.21875 14.0625C9.21875 13.631 9.56853 13.2812 10 13.2812C10.4315 13.2812 10.7812 13.631 10.7812 14.0625Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
