import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCardsIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.66667 2.25C4.25245 2.25 3.91667 2.58579 3.91667 3C3.91667 3.41421 4.25245 3.75 4.66667 3.75H17.4924V14.2C17.4924 14.6142 17.8282 14.95 18.2424 14.95C18.6566 14.95 18.9924 14.6142 18.9924 14.2V3.7C18.9924 3.32276 18.8473 2.95649 18.582 2.68292C18.316 2.40856 17.9502 2.25 17.5636 2.25H4.66667ZM2.75 16.25V6.55H14.8258V16.25H2.75ZM2.67879 5.05C1.8681 5.05 1.25 5.72111 1.25 6.5V16.3C1.25 17.0789 1.8681 17.75 2.67879 17.75H14.897C15.7077 17.75 16.3258 17.0789 16.3258 16.3V6.5C16.3258 5.72111 15.7077 5.05 14.897 5.05H2.67879Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
