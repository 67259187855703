export const SELLER_LIST_TYPE_OPTIONS = [
  {
    value: 'products',
    label: I18n.t('react.shared.csv_log.list_type.products'),
  },
  {
    value: 'transfers',
    label: I18n.t('react.shared.csv_log.list_type.transfers'),
  },
  {
    value: 'payers',
    label: I18n.t('react.shared.csv_log.list_type.payers'),
  },
  {
    value: 'orders',
    label: I18n.t('react.shared.csv_log.list_type.orders'),
  },
  {
    value: 'course_sessions',
    label: I18n.t('react.shared.csv_log.list_type.course_sessions'),
  },
  {
    value: 'payment_tickets',
    label: I18n.t('react.shared.csv_log.list_type.payment_tickets'),
  },
  {
    value: 'license_keys',
    label: I18n.t('react.shared.csv_log.list_type.license_keys'),
  },
  {
    value: 'embeddable_items',
    label: I18n.t('react.shared.csv_log.list_type.embeddable_items'),
  },
  {
    value: 'affiliate_programs',
    label: I18n.t('react.shared.csv_log.list_type.affiliate_programs'),
  },
  {
    value: 'coupons',
    label: I18n.t('react.shared.csv_log.list_type.coupons'),
  },
  {
    value: 'product_groups',
    label: I18n.t('react.shared.csv_log.list_type.product_groups'),
  },
  {
    value: 'payouts',
    label: I18n.t('react.shared.csv_log.list_type.payouts'),
  },
  {
    value: 'best_sellers',
    label: I18n.t('react.shared.csv_log.list_type.best_sellers'),
  },
  {
    value: 'top_customers',
    label: I18n.t('react.shared.csv_log.list_type.top_customers'),
  },
  {
    value: 'campaigns',
    label: I18n.t('react.shared.csv_log.list_type.campaigns'),
  },
  {
    value: 'quiz_attempts',
    label: I18n.t('react.shared.csv_log.list_type.quiz_attempts'),
  },
  {
    value: 'financial_reports',
    label: I18n.t('react.shared.csv_log.list_type.financial_reports'),
  },
]

export const ADMIN_LIST_TYPE_OPTIONS = [
  {
    value: 'addon_themes',
    label: 'Order Bump Themes',
  },
  {
    value: 'affiliate_nodes',
    label: 'Affiliate Nodes',
  },
  {
    value: 'affiliate_programs',
    label: 'Affiliate Programs',
  },
  {
    value: 'best_sellers',
    label: 'Best Sellers',
  },
  {
    value: 'campaigns',
    label: 'Campaigns',
  },
  {
    value: 'claims',
    label: 'Claims',
  },
  {
    value: 'course_sessions',
    label: 'Course Sessions',
  },
  {
    value: 'coupons',
    label: 'Coupons',
  },
  {
    value: 'credit_memos',
    label: 'Credit Memos',
  },
  {
    value: 'datev_export',
    label: 'Datev Export',
  },
  {
    value: 'datev_transfers',
    label: 'Datev Transfers',
  },
  {
    value: 'elo_publisher_applications',
    label: 'Elo Publisher Applications',
  },
  {
    value: 'elo_publisher_commissions',
    label: 'Elo Publisher Commissions',
  },
  {
    value: 'elo_publisher_statistic_by_sellers',
    label: 'Elo Publisher Statistic By Sellers',
  },
  {
    value: 'elo_publisher_statistics',
    label: 'Elo Publisher Statistics',
  },
  {
    value: 'email_templates',
    label: 'Email Templates',
  },
  {
    value: 'embeddable_items',
    label: 'Embeddable Items',
  },
  {
    value: 'financial_reports',
    label: 'Financial Reports',
  },
  {
    value: 'inkasso_reports',
    label: 'Inkasso Reports',
  },
  {
    value: 'invoices',
    label: 'Invoices',
  },
  {
    value: 'leads',
    label: 'Leads',
  },
  {
    value: 'license_keys',
    label: 'License Keys',
  },
  {
    value: 'managers',
    label: 'Managers',
  },
  {
    value: 'monthly_invoices',
    label: 'Monthly Invoices',
  },
  {
    value: 'need_action_transfers',
    label: 'Need Action Transfers',
  },
  {
    value: 'open_claims',
    label: 'Open Claims',
  },
  {
    value: 'opt_in_answers',
    label: 'Custom responses',
  },
  {
    value: 'order_rates',
    label: 'Order Rates',
  },
  {
    value: 'orders',
    label: 'Orders',
  },
  {
    value: 'payers',
    label: 'Payers',
  },
  {
    value: 'payment_tickets',
    label: 'Payment Tickets',
  },
  {
    value: 'payouts',

    label: 'Payouts',
  },
  {
    value: 'product_groups',
    label: 'Product Groups',
  },
  {
    value: 'products',
    label: 'Products',
  },
  {
    value: 'quiz_attempts',
    label: 'Quiz Attempts',
  },
  {
    value: 'resolved_claims',
    label: 'Resolved Claims',
  },
  {
    value: 'sellers',
    label: 'Sellers',
  },
  {
    value: 'subs_promo_codes',
    label: 'Subs Promo Codes',
  },
  {
    value: 'sync_email_logs',
    label: 'Sync Email Logs',
  },
  {
    value: 'tax_transfers',

    label: 'Tax Transfers',
  },
  {
    value: 'tax_transfers_v2',
    label: 'Tax Transfers V2',
  },
  {
    value: 'top_customers',
    label: 'Top Customers',
  },
  {
    value: 'transfers',
    label: 'Transfers',
  },
  {
    value: 'transfers_with_additional_info',
    label: 'Transfers With Additional Info',
  },
  {
    value: 'user_profiles',
    label: 'User Profiles',
  },
  {
    value: 'users',
    label: 'Users',
  },
  {
    value: 'vat_registration/logs',
    label: 'Vat Registration Logs',
  },
]
