import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloScalesIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M10 3.125V16.875' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M8.125 16.875H11.875'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.375 6.875L15.625 4.375'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.875 13.125C1.875 14.5078 3.4375 15 4.375 15C5.3125 15 6.875 14.5078 6.875 13.125L4.375 6.875L1.875 13.125Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.125 10.625C13.125 12.0078 14.6875 12.5 15.625 12.5C16.5625 12.5 18.125 12.0078 18.125 10.625L15.625 4.375L13.125 10.625Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
