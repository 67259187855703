interface Element {
  parentElement?: { removeChild: (arg0: Element) => void }
}

export const unmountDomElement = (element: HTMLElement) => {
  if (element?.parentElement) {
    element.parentElement.removeChild(element)
  }
}

export const getHighlighterElementItem = (element: HTMLElement): string => {
  const item = element.closest<HTMLElement>('[data-highlighter-item]')

  if (!item) return ''

  return item?.dataset?.highlighterItem ? item?.dataset?.highlighterItem : ''
}

export const getHighlighterElements = (element: HTMLElement) => {
  const item = element.closest<HTMLElement>('[data-highlighter-item]')

  if (!item) return ''

  const selector = item?.dataset?.highlighterSelector ? item?.dataset?.highlighterSelector : ''

  if (selector) {
    const parentSelector = item?.dataset?.highlighterParent
    const container = parentSelector ? item.closest(parentSelector) : item
    return container?.querySelectorAll(selector)
  } else if (item) {
    return [item]
  }

  return null
}

export const getBlockIdByElement = (element: HTMLElement) =>
  element ? element?.closest<HTMLElement>('[data-block-id]')?.dataset.blockId : null
