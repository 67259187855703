export const FILE_EXTENSION_REGEX = /\.([0-9a-z]+)(?:[\?#]|$)/i
export const EXTENSION_REGEX = /\.[^/.]+$/

export const EMAIL_REGEX = /.+@.+\..+/i
export const PHONE_NUMBER_REGEX = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const BIC_REGEX = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/

export const HHMM_REGEX = /^\d{2}:\d{2}$/
export const DDMMYYYY_REGEX = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\.(0[1-9]|1[0-2])\.(1[8-9]\d{2}|20[0-1][0-9])$/
export const DDMM_REGEX = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\.(0[1-9]|1[0-2])$/
export const MMYYYY_REGEX = /^\d{2}\.\d{4}$/
export const YYYY_REGEX = /^\d{4}$/

export const COMMA_REGEX = /[ ,]+/

export const EXCLUDE_DOT_REGEX = /^[^.]*$/
export const EXCLUDE_WHITE_SPACE_REGEX = /(?!^[ ]*$)^\S+$/

export const HTML_REGEX = /(<([^>]+)>)/i

export const DATE_FIELD_VALUE_REGEX = /[^\d.]/g
