export const LW_FORBIDDEN_COUNTRIES = [
  'AF',
  'AO',
  'GY',
  'IQ',
  'LA',
  'PG',
  'VU',
  'SY',
  'YE',
  'UG',
  'MM',
  'IR',
  'KP',
  'PA',
]

export const DEFAULT_COUNTRY_CODE = 'DE'
export const SWITZERLAND_COUNTRY_CODE = 'CH'
export const GERMANY_COUNTRY_CODE = 'DE'

export const COUNTRY_CODES = {
  de: DEFAULT_COUNTRY_CODE,
  en: 'US',
  it: 'IT',
  pl: 'PL',
  cs: 'CZ',
  us: 'US',
  ca: 'CA',
  in: 'IN',
}

export const MP_REGION_REQUIRED_COUNTRIES = ['US', 'MX', 'CA']
export const BLOCKED_COUNTRIES_OPTIONS = [
  {
    label: I18n.t('react.common.russia_country'),
    value: 'RU',
  },
]
export const EVENT_COUNTRIES = ['DE', 'GB', 'AE', 'CH', 'AT']
export const GREECE_CODES = ['GR', 'EL']
export const COUNTRIES_WITH_STATES = ['US', 'CA', 'IN']

export const PAYPAL_PAYLATER_COUNTRIES = ['AU', 'FR', 'DE', 'IT', 'ES', 'UK', 'US']
