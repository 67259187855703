import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPresentationChartIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.625 1.875C10.625 1.52982 10.3452 1.25 10 1.25C9.65482 1.25 9.375 1.52982 9.375 1.875V3.125H3.125C2.43464 3.125 1.875 3.68464 1.875 4.375V13.75C1.875 14.4404 2.43464 15 3.125 15H6.19961L4.51196 17.1096C4.29633 17.3791 4.34003 17.7724 4.60957 17.988C4.8791 18.2037 5.27241 18.16 5.48804 17.8904L7.80039 15H12.1996L14.512 17.8904C14.7276 18.16 15.1209 18.2037 15.3904 17.988C15.66 17.7724 15.7037 17.3791 15.488 17.1096L13.8004 15H16.875C17.5654 15 18.125 14.4404 18.125 13.75V4.375C18.125 3.68464 17.5654 3.125 16.875 3.125H10.625V1.875ZM10 4.375H3.125V13.75H7.49501H7.50579H12.4942H12.505H16.875V4.375H10ZM7.5 8.75C7.84518 8.75 8.125 9.02982 8.125 9.375V11.25C8.125 11.5952 7.84518 11.875 7.5 11.875C7.15482 11.875 6.875 11.5952 6.875 11.25V9.375C6.875 9.02982 7.15482 8.75 7.5 8.75ZM10.625 8.125C10.625 7.77982 10.3452 7.5 10 7.5C9.65482 7.5 9.375 7.77982 9.375 8.125V11.25C9.375 11.5952 9.65482 11.875 10 11.875C10.3452 11.875 10.625 11.5952 10.625 11.25V8.125ZM12.5 6.25C12.8452 6.25 13.125 6.52982 13.125 6.875V11.25C13.125 11.5952 12.8452 11.875 12.5 11.875C12.1548 11.875 11.875 11.5952 11.875 11.25V6.875C11.875 6.52982 12.1548 6.25 12.5 6.25Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
