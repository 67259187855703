import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloStriketroughIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M3.125 10H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M5.96083 7.5C5.89626 7.29804 5.8646 7.08701 5.86708 6.875C5.86708 5.14844 7.58583 3.75 9.99989 3.75C11.8593 3.75 13.3046 4.57813 13.8671 5.74219'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M5.625 13.125C5.625 14.8516 7.58594 16.25 10 16.25C12.4141 16.25 14.375 14.8516 14.375 13.125C14.375 11.2656 12.6875 10.5469 10.8125 10'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </EloBaseIcon>
)
