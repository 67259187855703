import I18n from 'i18n-js'

import { PAYMENT_SYSTEMS } from 'constants/paymentSettingShared.constants'

export const TRANSFER_STATES = {
  successful: 'successful',
  denied: 'denied',
  error: 'error',
  pending: 'pending',
  canceled: 'canceled',
  expired: 'expired',
  waiting: 'waiting',
}

export const PAYMENT_FORM_TYPE_COMMON = [
  {
    label: I18n.t('react.shared.payment_type.cashin'),
    value: 'Cashin',
  },
  {
    label: I18n.t('react.shared.payment_type.payment'),
    value: 'Payment',
  },
  {
    label: I18n.t('react.shared.payment_type.refund'),
    value: 'Refund',
  },
  {
    label: I18n.t('react.shared.payment_type.chargeback'),
    value: 'Chargeback',
  },
]

export const PAYMENT_FORM_TYPE_OPTIONS = [
  ...PAYMENT_FORM_TYPE_COMMON,
  {
    label: I18n.t('react.shared.payout'),
    value: 'Payout',
  },
]

export const TRANSFER_PROVIDERS_BASE = {
  noProvider: 'no_provider',
  lemonWay: 'lemonway',
  mangoPay: 'mango_pay',
  stripe: 'stripe',
  elopageConnect: 'elopage_connect',
  paypalRest: 'paypal_rest',
  paypalNvp: 'paypal_nvp',
  inAppPurchase: 'in_app_purchase',
}

export const TRANSFER_PROVIDERS = {
  ...TRANSFER_PROVIDERS_BASE,
  creditReformInkasso: 'credit_reform_inkasso',
}

export const TRANSFER_PROVIDERS_LIST = Object.values(TRANSFER_PROVIDERS)
export const TRANSFER_PROVIDERS_LABELS = {
  no_provider: I18n.t('react.cabinet.payment_methods.payment_providers.no_provider'),
  lemonway: I18n.t('react.cabinet.payment_methods.payment_providers.lemonway'),
  mango_pay: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
  stripe: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
  elopage_connect: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
  paypal_rest: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_rest'),
  paypal_nvp: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_nvp'),
  in_app_purchase: I18n.t('react.cabinet.payment_methods.payment_providers.in_app_purchase'),
  credit_reform_inkasso: I18n.t('react.cabinet.payment_methods.payment_providers.credit_reform_inkasso'),
}
export const TRANSFER_PROVIDERS_OPTIONS = TRANSFER_PROVIDERS_LIST.map((value) => ({
  label: TRANSFER_PROVIDERS_LABELS[value],
  value,
}))
const TRANSFER_PROVIDERS_BASE_LABELS = {
  no_provider: I18n.t('react.cabinet.payment_methods.payment_providers.no_provider'),
  lemonway: I18n.t('react.cabinet.payment_methods.payment_providers.lemonway'),
  mango_pay: I18n.t('react.cabinet.payment_methods.payment_providers.mango_pay'),
  stripe: I18n.t('react.cabinet.payment_methods.payment_providers.stripe'),
  elopage_connect: I18n.t('react.cabinet.payment_methods.payment_providers.elopage_connect'),
  paypal_rest: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_rest'),
  paypal_nvp: I18n.t('react.cabinet.payment_methods.payment_providers.paypal_nvp'),
  in_app_purchase: I18n.t('react.cabinet.payment_methods.payment_providers.in_app_purchase'),
}
export const TRANSFER_PROVIDERS_BASE_LIST = Object.values(TRANSFER_PROVIDERS_BASE)

export const TRANSFER_PROVIDERS_BASE_OPTIONS = TRANSFER_PROVIDERS_BASE_LIST.map((value) => ({
  label: TRANSFER_PROVIDERS_BASE_LABELS[value],
  value,
}))

export const TRANSFER_STATE_OPTIONS = [
  {
    label: I18n.t('react.shared.state.successful'),
    value: TRANSFER_STATES.successful,
  },
  {
    label: I18n.t('react.shared.state.denied'),
    value: TRANSFER_STATES.denied,
  },
  {
    label: I18n.t('react.shared.state.error'),
    value: TRANSFER_STATES.error,
  },
  {
    label: I18n.t('react.shared.state.pending'),
    value: TRANSFER_STATES.pending,
  },
  {
    label: I18n.t('react.shared.state.canceled'),
    value: TRANSFER_STATES.canceled,
  },
  {
    label: I18n.t('react.shared.state.expired'),
    value: TRANSFER_STATES.expired,
  },
  {
    label: I18n.t('react.shared.state.waiting'),
    value: TRANSFER_STATES.waiting,
  },
]

export const TRANSFER_STATE_ADMIN_OPTIONS = [
  ...TRANSFER_STATE_OPTIONS,
  {
    value: 'partially_paid',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.partially_paid'),
  },
]

export const TRANSFERABLE_TYPE = [
  ...PAYMENT_FORM_TYPE_OPTIONS,
  {
    label: I18n.t('react.shared.payment_type.cashout'),
    value: 'Cashout',
  },
  {
    label: 'Monthly Invoice Transfers',
    value: 'SubsPayment',
  },
  {
    label: 'StampPayment',
    value: 'StampPayment',
  },
]

export const PAYMENT_FORM_TYPE_OPTIONS_FOR_NORMAL_SELLER = [
  ...PAYMENT_FORM_TYPE_COMMON,
  {
    label: I18n.t('react.shared.payment_type.cashout'),
    value: 'Payout',
  },
]

export const TRANSFER_PROVIDERS_ADMIN = {
  ...TRANSFER_PROVIDERS,
  directBank: PAYMENT_SYSTEMS.directBank,
}

export const TRANSFER_PROVIDERS_ADMIN_OPTIONS = [
  ...TRANSFER_PROVIDERS_OPTIONS,
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.direct_bank'),
    value: TRANSFER_PROVIDERS_ADMIN.directBank,
  },
]

export const TRANSFER_PROVIDERS_BASE_ADMIN_OPTIONS = [
  ...TRANSFER_PROVIDERS_BASE_OPTIONS,
  {
    label: I18n.t('react.cabinet.payment_methods.payment_providers.direct_bank'),
    value: TRANSFER_PROVIDERS_ADMIN.directBank,
  },
]

export const VIBAN_TRANSFER_STATE_OPTIONS = [
  {
    label: I18n.t('react.shared.state.successful'),
    value: TRANSFER_STATES.successful,
  },
  {
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.partially_paid'),
    value: 'partially_paid',
  },
  {
    label: I18n.t('react.shared.state.canceled'),
    value: TRANSFER_STATES.canceled,
  },
  {
    label: I18n.t('react.shared.state.waiting'),
    value: TRANSFER_STATES.waiting,
  },
]

export const CASHOUT_TRANSFER_STATE_OPTIONS = [
  {
    label: I18n.t('react.shared.state.successful'),
    value: TRANSFER_STATES.successful,
  },
  {
    label: I18n.t('react.shared.deals.status_pending'),
    value: TRANSFER_STATES.pending,
  },
  {
    label: I18n.t('react.shared.state.canceled'),
    value: TRANSFER_STATES.canceled,
  },
]

export const CANCELLED_TRANSFER_TYPES = {
  refund: 'Refund',
  chargeback: 'Chargeback',
}

export const CANCELLED_TRANSFER_TYPES_VALUES = Object.values(CANCELLED_TRANSFER_TYPES)

export const TRANSFER_TYPES = {
  payment: 'Payment',
  payout: 'Payout',
  ...CANCELLED_TRANSFER_TYPES,
}
