import { SelectOption } from 'types/helpers'

import { Country, State } from 'shared/api/countries.api'

import { SWITZERLAND_COUNTRY_CODE, COUNTRY_CODES } from '../constants/countries.constants'

type StateOption = SelectOption<State>

interface CountryOption {
  label: string
  value: string
  code?: string
}

interface CountrySelectOption {
  label: string
  value: Country
  code?: string
}

export const isEqualCH = (value: string): boolean => value === SWITZERLAND_COUNTRY_CODE

export const getCountryName = (countries: Country[], countryCode: string): string => {
  const country = countries && countries.find(({ alpha2 }) => alpha2 === countryCode)
  return country ? country.name : ''
}

export const getCountryStatePlaceholderLocale = (countryCode: string) => {
  const COUNTRY_STATE_PLACEHOLDER_LOCALES = {
    [COUNTRY_CODES.us]: I18n.t('react.shared.country_state'),
    [COUNTRY_CODES.ca]: I18n.t('react.shared.province'),
  }
  return COUNTRY_STATE_PLACEHOLDER_LOCALES[countryCode] || I18n.t('react.shared.country_state')
}

export const findCountryByKey = (countrySelectOptions: CountrySelectOption[], alpha2: string): CountrySelectOption =>
  countrySelectOptions.find(({ value }) => value.alpha2 === alpha2)

export const formatCountryListOptions = (list: Country[] | State[]): CountryOption[] =>
  list.map(({ alpha2, name }) => ({
    label: name,
    value: alpha2,
  }))

export const formatStateListOptions = (list: State[]): StateOption[] =>
  list.map((item) => ({
    ...item,
    label: item.name,
    value: item.alpha2,
  }))

export const formatCountryListOptionsForSignUp = (list: Country[]): CountryOption[] =>
  list.map(({ alpha2, name }) => ({
    value: name,
    label: name,
    code: alpha2,
  }))

export const filterBySublist = (list: Country[], filterList: string[]) =>
  list.filter(({ alpha2 }) => filterList.includes(alpha2))
