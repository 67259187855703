import { apiClient } from 'utils/requests.utils'

const BASE_URL = (username) => `/shop/${username}/product_groups`

export const createProductGroupsApi = ({ GET }) => ({
  fetchList: (data) => GET(BASE_URL(data.username), data),
})

const api = createProductGroupsApi(apiClient)
const fetchList = api.fetchList
export default fetchList
