import { computed, makeObservable, override, action, observable } from 'mobx'

import { formatCountryListOptions, formatStateListOptions } from 'utils/countries.utils'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import SharedStore from 'shared/stores/shared.store'
import { CountriesApi, createCountriesApi, Country, State } from 'shop/api/countries.api'
import { Nullable, SelectOption } from 'types/helpers'
import { isEmpty } from 'utils/validatorsShared.utils'

const defaultCountry: Country = {
  id: 'DE',
  name: 'Germany',
  alpha2: 'DE',
  euMember: true,
  region: '',
}

const defaultState: State = {
  id: '',
  name: '',
  alpha2: '',
}

type StateOption = SelectOption<State>

export class CountriesStore extends SharedStore<Country> {
  storeName = 'CountriesStore'
  declare childApi: CountriesApi
  private memoCountry: Nullable<Country> = null

  @observable states = {}
  // <string, State[]>()

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createCountriesApi(root.apiClientV2)
    makeObservable(this)
  }

  @computed get countrySelectOptions() {
    return formatCountryListOptions(this.list)
  }

  @override
  async fetchList(): Promise<void> {
    if (this.list.length) {
      return
    }
    const { data } = await this.childApi.fetchList()
    this.list = data
  }

  @action
  async fetchStates(id: string): Promise<void> {
    if (!this.states[id]) {
      const { data } = await this.childApi.fetchStates(id)
      this.states = {
        ...this.states,
        [id]: data,
      }
    }
  }

  getStates = (countryKey: string): State[] => this.states[countryKey] ?? ([] as State[])

  getStatesSelectOptions = (countryKey: string): StateOption[] => {
    const states = this.getStates(countryKey)

    return formatStateListOptions(states)
  }

  getState = (countryKey: string, stateKey: string): State => {
    const states = this.getStates(countryKey)
    const state = states.find((state) => state.id === stateKey)

    return state ?? defaultState
  }

  getStateSelectOptions = (countryKey: string, stateKey: string): StateOption => {
    const state = this.getState(countryKey, stateKey)

    return {
      label: state.name,
      value: state.id,
      ...state,
    }
  }

  getCountry = (id: string): Country => {
    if (this.memoCountry?.id === id) {
      return this.memoCountry
    }

    const country = this.list.find((country) => country.id === id)
    this.memoCountry = country

    if (!country) {
      console.error(`Country with id ${id} not found`)
    }

    return country ?? defaultCountry
  }

  @override
  hydrate(key, data) {
    if (key === 'item') {
      // TODO: rm after EVERY response will be covered by types
      // @ts-ignore
      this.setItem({ data: data, success: !isEmpty(data) }) // TODO: SSR - can be data === {} and success === true
    } else if (key === 'list') {
      this.setList(data)
    } else if (key === 'loading') {
      this.loading = data
    } else if (key === 'expands') {
      this.expands = data
    } else if (key === 'scopes') {
      this.scopes = new Map()
      for (const key in data) {
        this.scopes.set(key, data[key])
      }
    } else if (key === 'states') {
      this.states = data
    }
  }
}
