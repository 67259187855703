export const isEmpty = (obj = {}) =>
  // additional check, because Object.keys(new Date()).length === 0;
  Object.keys(obj).length === 0 && obj.constructor === Object
export const isInteger = (value: unknown) => Number.isInteger(value)

export const isString = (value: unknown) => typeof value === 'string' || value instanceof String

export const isNumber = (value: unknown) => typeof value === 'number'

export const isUrlValid = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}
