import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCalendarIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.8412 1.75C14.8412 1.33579 14.5054 1 14.0912 1C13.677 1 13.3412 1.33579 13.3412 1.75V2.375H6.65884V1.75C6.65884 1.33579 6.32305 1 5.90884 1C5.49462 1 5.15884 1.33579 5.15884 1.75V2.375H3.18138C2.38477 2.375 1.74951 3.02442 1.74951 3.8125V7.25V17.5625C1.74951 18.3506 2.38477 19 3.18138 19H16.8187C17.6153 19 18.2505 18.3506 18.2505 17.5625V7.25V3.8125C18.2505 3.02442 17.6153 2.375 16.8187 2.375H14.8412V1.75ZM16.7505 6.5V3.875H14.8412V4.5C14.8412 4.91421 14.5054 5.25 14.0912 5.25C13.677 5.25 13.3412 4.91421 13.3412 4.5V3.875H6.65884V4.5C6.65884 4.91421 6.32305 5.25 5.90884 5.25C5.49462 5.25 5.15884 4.91421 5.15884 4.5V3.875H3.24951V6.5H16.7505ZM3.24951 8H16.7505V17.5H3.24951V8Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
