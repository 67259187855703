export const INTERNAL_STATUS_OPTIONS = [
  {
    value: 'succeed',
    label: 'Succeed',
  },
  {
    value: 'failed',
    label: 'Failed',
  },
  {
    value: 'waiting',
    label: 'Waiting',
  },
]

export const EXTERNAL_TRANSFERS_KIND_OPTIONS = [
  {
    value: 'regular',
    label: 'Regular',
  },
  {
    value: 'p2p',
    label: 'P2p',
  },
]
