import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMoneyIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.4254 4.25H6.50322H6.49626H2.57144C1.77979 4.25 1.25001 4.93028 1.25001 5.625V8.99679V9.00375V10.9962C1.25 10.9986 1.25 11.0009 1.25001 11.0032V14.375C1.25001 15.0697 1.77979 15.75 2.57144 15.75H6.49619C6.49855 15.75 6.50092 15.75 6.50329 15.75H13.4253H13.4324H17.4286C18.2202 15.75 18.75 15.0697 18.75 14.375V5.625C18.75 4.93028 18.2202 4.25 17.4286 4.25H13.4323H13.4254ZM6.78516 14.25H13.1434L17.25 10.5997V9.40029L13.1434 5.75H6.78516L2.75001 9.3368V10.6632L6.78516 14.25ZM2.75001 12.6701V14.25H4.52736L2.75001 12.6701ZM2.75001 7.32987L4.52736 5.75H2.75001V7.32987ZM15.4012 14.25L17.25 12.6067V14.25H15.4012ZM17.25 5.75V7.39336L15.4012 5.75H17.25ZM8.50001 10C8.50001 9.17157 9.17158 8.5 10 8.5C10.8284 8.5 11.5 9.17157 11.5 10C11.5 10.8284 10.8284 11.5 10 11.5C9.17158 11.5 8.50001 10.8284 8.50001 10ZM10 7C8.34315 7 7.00001 8.34315 7.00001 10C7.00001 11.6569 8.34315 13 10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
