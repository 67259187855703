import { COLORS } from '@elo-kit/constants/general.constants'
import { BumpOneColumnOne } from '@elo-kit/components/icons/payment-page-view/bump-one-column-one/BumpOneColumnOne'
import { BumpOneColumnSec } from '@elo-kit/components/icons/payment-page-view/bump-one-column-sec/BumpOneColumnSec'
import { BumpOneColumnThird } from '@elo-kit/components/icons/payment-page-view/bump-one-column-third/BumpOneColumnThird'
import { BumpTwoColumnOne } from '@elo-kit/components/icons/payment-page-view/bump-two-column-one/BumpTwoColumnOne'
import { BumpTwoColumnSec } from '@elo-kit/components/icons/payment-page-view/bump-two-column-sec/BumpTwoColumnSec'
import { BumpTwoColumnThird } from '@elo-kit/components/icons/payment-page-view/bump-two-column-third/BumpTwoColumnThird'
import { BumpTwoStepOne } from '@elo-kit/components/icons/payment-page-view/bump-two-step-one'
import { BumpTwoStepSec } from '@elo-kit/components/icons/payment-page-view/bump-two-step-sec/BumpTwoStepSec'
import { BumpTwoStepThird } from '@elo-kit/components/icons/payment-page-view/bump-two-step-third/BumpTwoStepThird'

export const PAYMENT_PAGE_BUTTON_OPTIONS = [
  {
    value: 'dynamic_cta',
    label: I18n.t('react.shared.button.dynamic_to_payment_method'),
  },
  {
    value: 'buy_now',
    label: I18n.t('react.shared.button.buy_now'),
  },
  {
    value: 'agree_on_terms_and_pay',
    label: I18n.t('react.shared.button.agree_on_terms_and_pay'),
  },
  {
    value: 'complete_order',
    label: I18n.t('react.shared.button.complete_order'),
  },
  {
    value: 'accept_and_pay_now',
    label: I18n.t('react.shared.button.accept_and_pay_now'),
  },
]

export const PAYMENT_PAGE_LAYOUT = {
  default: 'default',
  simplified: 'simplified',
  twoSteps: 'two_steps',
  twoStepsAdvanced: 'two_steps_advanced',
}
export const PAYMENT_PAGE_LAYOUT_LABELS = {
  default: I18n.t('react.cabinet.checkout_settings.default'),
  simplified: I18n.t('react.cabinet.checkout_settings.simplified'),
  two_steps: I18n.t('react.cabinet.checkout_settings.two_steps'),
  two_steps_advanced: I18n.t('react.cabinet.checkout_settings.two_steps_advanced'),
}

export const ORDER_BUMPS_POSITIONS_TYPES = {
  belowProductDetails: 'belowProductDetails',
  belowPayerDetails: 'belowPayerDetails',
  belowPaymentMethods: 'belowPaymentMethods',
}

export const ORDER_BUMPS_POSITIONS_DEFAULT = ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails

export const PAYMENT_PAGE_BUTTON_POSITION_KEYS = {
  belowPaymentOptions: 'below_payment_options',
  belowLegalText: 'below_legal_text',
  belowCustomFields: 'below_custom_fields',
}

export const PAYMENT_PAGE_BUTTON_POSITION_OPTIONS = [
  {
    value: PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowPaymentOptions,
    label: I18n.t('react.shared.button.below_payment_options'),
  },
  {
    value: PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowLegalText,
    label: I18n.t('react.shared.button.below_legal_text'),
  },
  {
    value: PAYMENT_PAGE_BUTTON_POSITION_KEYS.belowCustomFields,
    label: I18n.t('react.shared.button.below_custom_field'),
  },
]

export const EXPANDABLE_PRODUCT_DEFAULT_BEHAVIOUR = {
  expanded: 'expanded',
  collapsed: 'collapsed',
}

export const defaultTemplateData = {
  name: '',
  layout: PAYMENT_PAGE_LAYOUT.default,
  theme: {
    upsellPosition: ORDER_BUMPS_POSITIONS_DEFAULT,
    paymentApplyCouponBtnColor: COLORS.eloBlue,
    paymentApplyCouponBtnFontColor: '#ffffff',
    paymentApplyCouponBorderRadius: 22,
    paymentBuyBtnColor: '#ffc107',
    paymentBuyBtnFontColor: '#ffffff',
    paymentBgColor: '#f0f0f0',
    paymentPageColor: '#2196F3',
    paymentBuyBtnBorderRadius: 22,
    paymentBuyBtnText: PAYMENT_PAGE_BUTTON_OPTIONS[0].value,
    paymentGetForFreeBtnText: I18n.t('react.shared.button.take_for_free'),
    paymentRecommendedBadgeColor: COLORS.eloBlue,
    paymentRecommendedBadgeTextColor: COLORS.eloWhite,
    paymentCountdownBgColor: '#6ce369',
    paymentCountdownBorderColor: '#6ce369',
    paymentCountdownTextColor: '#21282e',
    paymentCountdownTimeColor: '#21282e',
    countdownText: '',
    expandableDescriptionOnMobileEnabled: false,
    expandableDescriptionDefaultBehavior: EXPANDABLE_PRODUCT_DEFAULT_BEHAVIOUR.expanded,
  },
  trustSealsSettings: {
    visa: true,
    sslSecure: true,
    mastercard: true,
    elopage: false,
  },
  buyerInformation: {
    includeType: {},
  },
}

export const ORDER_BUMPS_POSITIONS = [
  {
    id: ORDER_BUMPS_POSITIONS_TYPES.belowProductDetails,
    label: I18n.t('react.cabinet.checkout_settings.order_bump_positions.belowProductDetails'),
    icons: {
      [PAYMENT_PAGE_LAYOUT.default]: BumpTwoColumnOne,
      [PAYMENT_PAGE_LAYOUT.simplified]: BumpOneColumnOne,
      [PAYMENT_PAGE_LAYOUT.twoSteps]: BumpTwoStepOne,
      [PAYMENT_PAGE_LAYOUT.twoStepsAdvanced]: BumpTwoStepOne,
    },
  },
  {
    id: ORDER_BUMPS_POSITIONS_TYPES.belowPayerDetails,
    label: I18n.t('react.cabinet.checkout_settings.order_bump_positions.belowPayerDetails'),
    icons: {
      [PAYMENT_PAGE_LAYOUT.default]: BumpTwoColumnSec,
      [PAYMENT_PAGE_LAYOUT.simplified]: BumpOneColumnSec,
      [PAYMENT_PAGE_LAYOUT.twoSteps]: BumpTwoStepSec,
      [PAYMENT_PAGE_LAYOUT.twoStepsAdvanced]: BumpTwoStepSec,
    },
  },
  {
    id: ORDER_BUMPS_POSITIONS_TYPES.belowPaymentMethods,
    label: I18n.t('react.cabinet.checkout_settings.order_bump_positions.belowPaymentMethods'),
    icons: {
      [PAYMENT_PAGE_LAYOUT.default]: BumpTwoColumnThird,
      [PAYMENT_PAGE_LAYOUT.simplified]: BumpOneColumnThird,
      [PAYMENT_PAGE_LAYOUT.twoSteps]: BumpTwoStepThird,
      [PAYMENT_PAGE_LAYOUT.twoStepsAdvanced]: BumpTwoStepThird,
    },
  },
]
