import React from 'react'
import { toJS } from 'mobx'

import { LANGUAGES, ROOT_URL, THEME_TYPE } from 'constants/general.constants'
import { CUSTOMIZATION_OPTION_KEY } from 'constants/options.constants'
import { THEME_PAGE_TYPES } from 'shop/stores/shopTheme.store'

import { getCookies, setCookies } from 'libs/common/cookies'
import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import { setSearchParams } from 'utils/queryString.utils'
import { Header } from '@elo-kit/components/page-builder/blocks/header/Header'
import { GoodsItem } from 'shared/types/content-page'

interface Props {
  block: GoodsItem
  previewMode?: boolean
  baseURL?: string
  username?: string
  shopLink?: string
  domainName?: string
  pageBuilderPreview?: boolean
  shopThemeStore?: any
  contentPageStore
  userStore
  sellerStore
}

export const HeaderPreview: React.FC<Props> = (props) => {
  const { block, previewMode, baseURL, username, shopLink, domainName, pageBuilderPreview, shopThemeStore } = props
  const {
    contentPageStore: { view, themeType, seller, themePages, availableLanguages },
    userStore: { item: user = {} },
    sellerStore,
  } = props

  const isShopTheme =
    themeType === THEME_TYPE.shop || Object.values(THEME_PAGE_TYPES).includes(shopThemeStore?.pageType)
  const hideHeaderLinks = isShopTheme && !sellerStore.isAppActive(CUSTOMIZATION_OPTION_KEY)

  const getCurrentLocale = () => I18n.currentLocale()
  const setToKey = (params) => setSearchParams(params)

  return (
    <Header
      block={block}
      toJS={toJS}
      view={view}
      themeType={themeType}
      seller={seller}
      themePages={themePages}
      username={username}
      shopLink={shopLink}
      domainName={domainName}
      baseURL={baseURL}
      previewMode={previewMode}
      rootUrl={ROOT_URL}
      LANGUAGES={LANGUAGES}
      currentLocale={getCurrentLocale}
      setToKey={setToKey}
      snakeCaseToCamelCase={snakeCaseToCamelCase}
      getCookies={getCookies}
      setCookies={setCookies}
      availableLanguages={availableLanguages}
      pageBuilderPreview={pageBuilderPreview}
      isLoggedIn={!!user.id}
      hideHeaderLinks={hideHeaderLinks}
    />
  )
}
