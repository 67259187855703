export const arrayUnique = <T>(arr: T[]): T[] => {
  let i
  let j
  const a = [...arr]
  i = 0
  while (i < a.length) {
    j = i + 1
    while (j < a.length) {
      if (a[i] === a[j]) {
        a.splice(j--, 1)
      }
      ++j
    }
    ++i
  }
  return a
}

export function arrayLast<T>(arr: T[]): T {
  return arr[arr.length - 1]
}
