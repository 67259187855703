import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBuyIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M7.5 19C8.32843 19 9 18.3284 9 17.5C9 16.6716 8.32843 16 7.5 16C6.67157 16 6 16.6716 6 17.5C6 18.3284 6.67157 19 7.5 19Z'
      fill='currentColor'
    />
    <path
      d='M14.5 19C15.3284 19 16 18.3284 16 17.5C16 16.6716 15.3284 16 14.5 16C13.6716 16 13 16.6716 13 17.5C13 18.3284 13.6716 19 14.5 19Z'
      fill='currentColor'
    />
    <path
      d='M4.25559 5.80555H17.6875L15.7109 12.8639C15.6404 13.12 15.4894 13.3455 15.281 13.5056C15.0727 13.6657 14.8187 13.7515 14.5579 13.75H7.38521C7.12442 13.7515 6.87035 13.6657 6.66204 13.5056C6.45373 13.3455 6.30271 13.12 6.23219 12.8639L3.52185 3.19305C3.48603 3.0653 3.41038 2.95293 3.30635 2.87298C3.20232 2.79303 3.07558 2.74986 2.94534 2.75H1.6875'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
