import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloWarningCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
    <path d='M10 5.875V10.6875' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M10.5312 13.7812C10.5312 14.0747 10.2934 14.3125 10 14.3125C9.7066 14.3125 9.46875 14.0747 9.46875 13.7812C9.46875 13.4878 9.7066 13.25 10 13.25C10.2934 13.25 10.5312 13.4878 10.5312 13.7812Z'
      stroke='currentColor'
    />
  </EloBaseIcon>
)
