import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTextAlignCenterIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M3.125 5.3125H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M5 8.4375H15' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M3.125 11.5625H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M5 14.6875H15' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
  </EloBaseIcon>
)
