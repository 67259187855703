import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloStarUnselectedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10.3384 15.3356L14.2151 17.8945C14.715 18.2224 15.3304 17.7346 15.1842 17.1348L14.0612 12.5447C14.0309 12.4175 14.0357 12.2839 14.0752 12.1594C14.1146 12.0349 14.1871 11.9245 14.2843 11.841L17.761 8.82624C18.2148 8.4344 17.984 7.64272 17.3918 7.60274L12.8536 7.29886C12.7298 7.29137 12.6108 7.24654 12.5111 7.16985C12.4113 7.09317 12.3353 6.98794 12.2921 6.86704L10.6 2.43684C10.5552 2.30882 10.4736 2.19822 10.3662 2.12007C10.2589 2.04192 10.131 2 10 2C9.86896 2 9.7411 2.04192 9.63377 2.12007C9.52644 2.19822 9.44484 2.30882 9.40004 2.43684L7.70786 6.86704C7.66474 6.98794 7.58867 7.09317 7.48895 7.16985C7.38923 7.24654 7.27019 7.29137 7.14637 7.29886L2.60824 7.60274C2.01598 7.64272 1.78523 8.4344 2.23904 8.82624L5.71571 11.841C5.81288 11.9245 5.88537 12.0349 5.92484 12.1594C5.9643 12.2839 5.96913 12.4175 5.93877 12.5447L4.90038 16.799C4.72347 17.5187 5.46188 18.1025 6.05414 17.7106L9.66156 15.3356C9.76272 15.2687 9.88012 15.2332 10 15.2332C10.1199 15.2332 10.2373 15.2687 10.3384 15.3356V15.3356Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
