import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloLicenseCodesIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.575 2.22127C11.5562 1.81483 12.636 1.70848 13.6776 1.91569C14.7193 2.12289 15.6762 2.63433 16.4272 3.38534C17.1782 4.13635 17.6896 5.09319 17.8968 6.13487C18.104 7.17655 17.9977 8.25627 17.5912 9.23751C17.1848 10.2187 16.4965 11.0574 15.6134 11.6475C14.7303 12.2376 13.6921 12.5525 12.63 12.5525L12.6272 12.5525C11.9443 12.5551 11.2675 12.4238 10.6351 12.166C10.3556 12.0521 10.0351 12.1168 9.82167 12.3302L8.91934 13.2325H7.19C6.77578 13.2325 6.44 13.5683 6.44 13.9825V15.2725H5.15C4.73579 15.2725 4.4 15.6083 4.4 16.0225V17.3125H2.5V14.9732L7.48233 9.99083C7.69574 9.77742 7.76044 9.45688 7.64652 9.1774C7.38874 8.54501 7.25743 7.86823 7.25999 7.18532H7.26V7.1825C7.26 6.12042 7.57494 5.08218 8.16501 4.19909C8.75507 3.316 9.59375 2.62771 10.575 2.22127ZM13.9703 0.444508C12.6376 0.179428 11.2563 0.315477 10.001 0.835451C8.74563 1.35542 7.67269 2.23597 6.9178 3.36574C6.16318 4.49511 5.76028 5.82283 5.76 7.18109C5.75748 7.89042 5.86719 8.5946 6.0841 9.26774L1.21967 14.1322C1.07902 14.2728 1 14.4636 1 14.6625V18.0625C1 18.4767 1.33579 18.8125 1.75 18.8125H5.15C5.56421 18.8125 5.9 18.4767 5.9 18.0625V16.7725H7.19C7.60421 16.7725 7.94 16.4367 7.94 16.0225V14.7325H9.23C9.42891 14.7325 9.61968 14.6535 9.76033 14.5128L10.5448 13.7284C11.2179 13.9453 11.922 14.055 12.6313 14.0525C13.9896 14.0522 15.3174 13.6493 16.4468 12.8947C17.5765 12.1398 18.4571 11.0669 18.977 9.81153C19.497 8.55621 19.6331 7.17488 19.368 5.84223C19.1029 4.50958 18.4486 3.28547 17.4878 2.32468C16.527 1.36389 15.3029 0.709589 13.9703 0.444508ZM15.5 5.53125C15.5 6.10079 15.0383 6.5625 14.4688 6.5625C13.8992 6.5625 13.4375 6.10079 13.4375 5.53125C13.4375 4.96171 13.8992 4.5 14.4688 4.5C15.0383 4.5 15.5 4.96171 15.5 5.53125Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
