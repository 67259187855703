import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDownloadIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M6.39062 8.45312L10 12.0625L13.6094 8.45312'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10 2.4375V11.4375' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M18 12.0625V17.3125C18 17.5114 17.9234 17.7022 17.787 17.8428C17.6506 17.9835 17.4656 18.0625 17.2727 18.0625H2.72727C2.53439 18.0625 2.3494 17.9835 2.21301 17.8428C2.07662 17.7022 2 17.5114 2 17.3125V12.0625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
