import React from 'react'

import { COLORS } from '@elo-kit/constants/general.constants'

/**
 * SVG Burger Menu
 */

interface Props {
  className?: string
  height?: number
  viewBox?: string
  width?: number
  color?: string
}

export const BurgerMenuIcon = (props: Props) => {
  const { height = 19, viewBox = '0 0 25 19', width = 25, className = 'menu__icon', color = COLORS.eloOrange } = props

  return (
    <svg width={width} height={height} viewBox={viewBox} className={className}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-18.000000, -19.000000)'>
        <g transform='translate(18.000000, 19.000000)' fill={color}>
          <rect x='0' y='0' width={width} height='3' rx='1.5' />
          <rect x='0' y='8' width={width} height='3' rx='1.5' />
          <rect x='0' y='16' width={width} height='3' rx='1.5' />
        </g>
      </g>
    </svg>
  )
}

BurgerMenuIcon.displayName = 'BurgerMenuIcon'
