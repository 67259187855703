import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDuplicateIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5625 1.6875C6.14829 1.6875 5.8125 2.02329 5.8125 2.4375V5.8125H2.4375C2.02329 5.8125 1.6875 6.14829 1.6875 6.5625V17.5625C1.6875 17.9767 2.02329 18.3125 2.4375 18.3125H13.4375C13.8517 18.3125 14.1875 17.9767 14.1875 17.5625V14.1875H17.5625C17.9767 14.1875 18.3125 13.8517 18.3125 13.4375V2.4375C18.3125 2.02329 17.9767 1.6875 17.5625 1.6875H6.5625ZM14.1875 12.6875H16.8125V3.1875H7.3125V5.8125H13.4375C13.8517 5.8125 14.1875 6.14829 14.1875 6.5625V12.6875ZM6.5625 7.3125H3.1875V16.8125H12.6875V13.4375V7.3125H6.5625Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
