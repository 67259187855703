export const ORDER_RATES_FILTER_LIST = [
  'chargedAtTime',
  'createdAtTime',
  'currencyId',
  'multiOrderRatesState',
  'multiPeriodType',
  'orderId',
]

export const ORDER_RATES_UNPAID_STATES = {
  skipped: 'skipped',
  chargebacked: 'chargebacked',
  debt: 'debt',
  refunded: 'refunded',
  pending: 'pending',
  partlyPaid: 'partly_paid',
}
export const ORDER_RATES_UNPAID_STATES_LIST = Object.values(ORDER_RATES_UNPAID_STATES)

export const ORDER_RATES_STATES = {
  paid: 'paid',
  unpaid: 'unpaid',
  future: 'future',
  ...ORDER_RATES_UNPAID_STATES,
}
export const ORDER_RATES_ACTIVE_INPUTS = {
  price: 'price',
  date: 'date',
}

export const ORDERS_RATES_ACTION_KEYS = {
  publishers: 'publishers',
  paymentForm: 'paymentForm',
  rates: 'rates',
  cancel: 'cancel',
  refund: 'refund',
  unavailable: 'unavailable',
  mergeOrders: 'mergeOrders',
  subscription: 'subscription',
  giveAccess: 'giveAccess',
  assign: 'assign',
}
