import React from 'react'
import classNames from 'classnames'

import { SIZES } from '@elo-kit/constants/general.constants'

import './loading-spinner.scss'

type sizeKeys = keyof typeof SIZES

interface Props {
  loading?: boolean
  className?: string
  loaderClassName?: string
  size?: typeof SIZES[sizeKeys]
}
/**
 * Loading Spinner component
 */
export const LoadingSpinner: React.FC<Props> = ({
  size = SIZES.medium,
  loading = true,
  className,
  loaderClassName,
}) => {
  const componentClassNames = classNames('loading-spinner', className)
  const loaderClassNames = classNames('loading-spinner__loader', `loading-spinner__loader--${size}`, loaderClassName)

  return loading ? (
    <div className={componentClassNames}>
      <div className={loaderClassNames} />
    </div>
  ) : null
}
