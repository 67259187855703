import { action, makeObservable } from 'mobx'

import { apiClient } from 'utils/requests.utils'

import SharedStore from './shared.store'

import { createAffiliateProgramsApi } from '../api/affiliatePrograms.api'

export class AffiliateProgramsStore extends SharedStore {
  storeName = 'AffiliateProgramsStore'
  childApi

  constructor(root) {
    super()
    this.childApi = createAffiliateProgramsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }

  @action accept = async (token, data) => {
    const resp = await this.childApi.accept(token, data)
    const { success, redirectLink = '' } = resp || {}
    const windowSearch = window.location.search.slice(1)

    if (success) {
      const [link, search = ''] = redirectLink.split('?')
      const mergedSearch = search || windowSearch ? `?${search}&${windowSearch}` : ''
      window.location.assign(`${link}${mergedSearch}`)
    }
  }
}

export default new AffiliateProgramsStore()
