import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCustomInReview: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.625 10C2.625 5.9269 5.9269 2.625 10 2.625C10 2.625 10 2.625 10 2.625V10L15.2149 15.2149C13.8803 16.5495 12.0366 17.375 10 17.375C5.9269 17.375 2.625 14.0731 2.625 10ZM10 1.375C5.23654 1.375 1.375 5.23654 1.375 10C1.375 14.7635 5.23654 18.625 10 18.625C14.7635 18.625 18.625 14.7635 18.625 10C18.625 5.23654 14.7635 1.375 10 1.375Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
