import React from 'react'

export interface EloBaseIconProps extends React.SVGAttributes<SVGElement> {
  size?: number // TODO: replace with enum
  color?: string
}

export const EloBaseIcon: React.FC<EloBaseIconProps> = ({
  size = 20,
  color = 'currentColor',
  children,
  ...restProps
}) => (
  <svg
    width={size}
    height={size}
    style={{ color }}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restProps}
  >
    {children}
  </svg>
)
