import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPowerIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M10 2V8' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M14.3636 4C15.8091 4.89557 16.9118 6.21185 17.5075 7.75266C18.1033 9.29346 18.1601 10.9763 17.6695 12.5503C17.1788 14.1244 16.1671 15.5053 14.785 16.4874C13.403 17.4694 11.7246 18 10 18C8.27543 18 6.59703 17.4694 5.21496 16.4874C3.83289 15.5053 2.82116 14.1244 2.33055 12.5503C1.83994 10.9763 1.89673 9.29346 2.49245 7.75266C3.08817 6.21185 4.19093 4.89557 5.63636 4'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
