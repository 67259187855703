import { useEffect } from 'react'
import { COOKIES_CONSENTS_TYPE, COOKIES_CONSENTS_EXTERNAL_TYPE } from '@elo-kit/constants/cookiesConsents.constants'
import { CUSTOM_CSS_FEATURE_FLAG } from 'constants/themes.constants'
import { DOMAINS_OPTION_KEY } from 'constants/options.constants'

export const useCookiebot = ({
  trackingCodesStore,
  sellerStore,
  cookiesConsentsStore,
  cookiesConsentCategoriesStore,
}) => {
  const seller = sellerStore.item
  const showCookieConsent =
    !cookiesConsentsStore.loading &&
    !cookiesConsentCategoriesStore.loading &&
    seller?.consentForm !== COOKIES_CONSENTS_TYPE.none &&
    seller?.consentForm !== COOKIES_CONSENTS_TYPE.external

  const withCookiebot =
    seller.activeExternalConsent === COOKIES_CONSENTS_EXTERNAL_TYPE.cookiebot &&
    seller.cookiebotId &&
    seller.cookiebotMode &&
    sellerStore.isAppActive(DOMAINS_OPTION_KEY, seller)

  const handleCookiebotOnLoad = () => {
    trackingCodesStore.triggerCookiebotStatus(true)
    trackingCodesStore.trackEvent()
  }

  const handleCookiebotOnDialogShown = () => {
    trackingCodesStore.triggerCookiebotDialogStatus(true)
  }

  const handleCookiebotDecided = () => {
    trackingCodesStore.triggerCookiebotDialogStatus(false)
  }

  const handleCookiebotRenew = () => {
    if (trackingCodesStore.cookiebotInitialized) {
      window.Cookiebot.renew()
    }
  }

  const handleCookiesSubmit = () => {
    trackingCodesStore.trackEvent()
  }

  useEffect(() => {
    window.addEventListener('CookiebotOnLoad', handleCookiebotOnLoad)
    window.addEventListener('CookiebotOnAccept', handleCookiebotDecided)
    window.addEventListener('CookiebotOnDecline', handleCookiebotDecided)
    window.addEventListener('CookiebotOnDialogDisplay', handleCookiebotOnDialogShown)

    return () => {
      window.removeEventListener('CookiebotOnLoad', handleCookiebotOnLoad)
      window.removeEventListener('CookiebotOnAccept', handleCookiebotDecided)
      window.removeEventListener('CookiebotOnDecline', handleCookiebotDecided)
      window.removeEventListener('CookiebotOnDialogDisplay', handleCookiebotOnDialogShown)
    }
  }, [])

  const showFloatingCookiebotConsent =
    seller && seller?.consentForm === COOKIES_CONSENTS_TYPE.external && !trackingCodesStore.cookiebotDialogShown

  const isCustomCssAppActive = sellerStore.isAppActive(CUSTOM_CSS_FEATURE_FLAG)

  return {
    handleCookiebotRenew,
    handleCookiesSubmit,
    showCookieConsent,
    withCookiebot,
    showFloatingCookiebotConsent,
    isCustomCssAppActive,
  }
}
