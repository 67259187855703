export const NOTIFICATIONS_FILTER_LIST = ['read', 'createdAt']

export const NOTIFIC_TYPES_KEYS = {
  quizAttempt: 'quiz_attempt',
  comment: 'comment',
  freeTransaction: 'free_transaction',
  paidTransaction: 'paid_transaction',
  dayIncome: 'day_income',
  weekIncome: 'week_income',
  monthIncome: 'month_income',
  orderCreated: 'order_created',
  orderCanceled: 'order_canceled',
  customNotification: 'custom_notification',
  newPostForReview: 'community_new_post_for_review',
}

export const NOTIFIC_TYPES_COURSE_KEYS = [NOTIFIC_TYPES_KEYS.quizAttempt, NOTIFIC_TYPES_KEYS.comment]
export const NOTIFIC_TYPES_TRANSACTIONS_KEYS = [NOTIFIC_TYPES_KEYS.freeTransaction, NOTIFIC_TYPES_KEYS.paidTransaction]
export const NOTIFIC_TYPES_INCOME_KEYS = [
  NOTIFIC_TYPES_KEYS.dayIncome,
  NOTIFIC_TYPES_KEYS.weekIncome,
  NOTIFIC_TYPES_KEYS.monthIncome,
]
export const NOTIFIC_TYPES_ORDER_KEYS = [NOTIFIC_TYPES_KEYS.orderCreated, NOTIFIC_TYPES_KEYS.orderCanceled]
export const NOTIFIC_TYPES_COMMUNITY_POST_KEYS = [NOTIFIC_TYPES_KEYS.newPostForReview]

export const NOTIFIC_TYPES = {
  course: NOTIFIC_TYPES_COURSE_KEYS,
  transactions: NOTIFIC_TYPES_TRANSACTIONS_KEYS,
  income: NOTIFIC_TYPES_INCOME_KEYS,
  order: NOTIFIC_TYPES_ORDER_KEYS,
  community: NOTIFIC_TYPES_COMMUNITY_POST_KEYS,
}

export const NOTIFIC_SETTINGS_PERMISSION_KEY = 'notifications_settings'
