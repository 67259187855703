import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloArrowLeftIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      fill='currentColor'
      d='M8.69194 4.81694C8.93602 4.57286 8.93602 4.17714 8.69194 3.93306C8.44786 3.68898 8.05214 3.68898 7.80806 3.93306L2.18306 9.55806C1.93898 9.80214 1.93898 10.1979 2.18306 10.4419L7.80806 16.0669C8.05214 16.311 8.44786 16.311 8.69194 16.0669C8.93602 15.8229 8.93602 15.4271 8.69194 15.1831L4.13388 10.625H16.875C17.2202 10.625 17.5 10.3452 17.5 10C17.5 9.65482 17.2202 9.375 16.875 9.375H4.13388L8.69194 4.81694Z'
    />
  </EloBaseIcon>
)
