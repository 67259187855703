import { COLORS } from '@elo-kit/constants/general.constants'
import { HEADER } from '@elo-kit/constants/contentPage.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const headerSharingOptionsStyles = (theme) => {
  const { eloBlue, eloWhite } = COLORS
  const languageFont = cleanupFonts(theme[HEADER.languageFont]) || 'Montserrat Medium'
  return {
    menuWithSubmenu: {
      '& .submenus': {
        display: 'none',
      },
      '&:hover .submenus': {
        display: 'block',
      },
      '&:active .submenus': {
        display: 'block',
      },
      position: 'relative',
    },
    menuItem: {
      fontSize: theme[HEADER.menuSize] ? `${theme[HEADER.menuSize]}px` : '15px',
      fontFamily: cleanupFonts(theme[HEADER.menuFont]) || 'Montserrat Medium',
      color: theme[HEADER.menuColor] || eloBlue,
      textDecoration: 'none',
      border: 'none',
      background: 'none',
      padding: 0,
      ...getFontStyles(theme[HEADER.menuStyle]),
      '&:hover': {
        opacity: 0.8,
        color: theme[HEADER.menuColor] || eloBlue,
      },
      '@media (max-width: 576px)': {
        fontSize: theme[HEADER.menuSize] > 26 ? 26 : theme[HEADER.menuSize],
      },
    },
    langSwitcher: {
      display: 'flex',
      alignItems: 'baseline',
      marginLeft: '10px',
      fontSize: theme[HEADER.languageSize] ? `${theme[HEADER.languageSize]}px` : '15px',
      fontFamily: languageFont,
      color: theme[HEADER.languageColor] || eloBlue,
      ...getFontStyles(theme[HEADER.languageStyle]),
      '&:hover': {
        color: theme[HEADER.languageColor] || eloBlue,
        '& .fa-sort-down': {
          transform: 'rotate(180deg)',
          marginTop: 2,
          marginBottom: 0,
        },
      },
      '&:focus': {
        outline: 'none',
      },
      '& .fa-sort-down': {
        alignSelf: 'center',
        marginBottom: 8,
        marginLeft: 2,
        marginRight: 0,
      },
      '@media (max-width: 576px)': {
        fontSize: theme[HEADER.menuSize] > 26 ? 26 : theme[HEADER.languageSize],
      },
    },
    submenuContainer: {
      position: 'absolute',
      minWidth: '150px',
      borderRadius: '6px',
      zIndex: 1,
      background: theme[HEADER.background] || eloWhite,
    },
    langSwitcherSubmenusContainer: {
      minWidth: '70px',
      zIndex: 2,
      boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.15)',
    },
    langSwitcherSubitem: {
      display: 'block',
      margin: '15px 20px',
      color: theme[HEADER.languageColor] || eloBlue,
      fontSize: theme[HEADER.languageSize] ? `${theme[HEADER.languageSize]}px` : '15px',
      fontFamily: languageFont,
      textDecoration: 'none',
      ...getFontStyles(theme[HEADER.languageStyle]),
      '&:hover': {
        opacity: 0.8,
        color: theme[HEADER.languageColor] || eloBlue,
        textDecoration: 'underline',
      },
    },
  }
}

export default headerSharingOptionsStyles
