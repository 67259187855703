import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDeviceMobileIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.77844 2.79932C5.48542 2.79932 5.21582 3.04828 5.21582 3.39543V4.26587L14.2168 4.27792V3.39543C14.2168 3.04828 13.9472 2.79932 13.6541 2.79932H5.77844ZM5.21582 16.1069V5.76587L14.2168 5.77792V16.1069H5.21582ZM3.71582 16.8569V18.2027C3.71582 19.3425 4.62158 20.2989 5.77844 20.2989H13.6541C14.811 20.2989 15.7168 19.3425 15.7168 18.2027V3.39543C15.7168 2.25571 14.811 1.29932 13.6541 1.29932H5.77844C4.62158 1.29932 3.71582 2.25571 3.71582 3.39543V16.8569ZM5.21582 17.6069H14.2168V18.2027C14.2168 18.5499 13.9472 18.7989 13.6541 18.7989H5.77844C5.48542 18.7989 5.21582 18.5499 5.21582 18.2027V17.6069Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
