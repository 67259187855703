import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface ProductSettingsReq {
  username: string
  id: number
}

interface ProductSetting {
  id: number
  createdAt: string
  prefs: {
    emailForEachItem: boolean
    mainProductRequired: boolean
    mainProductSettings: boolean
    upsell: boolean
    upsellType: string
    upsellTitle: string
  }
  productId: number
  updatedAt: string
}

const BASE_URL = (username, id) => `/shop/${username}/products/${id}/product_setting`

export const createProductSettingsApi = ({ GET }: ApiClient) => ({
  fetchItem: (data: ProductSettingsReq): Promise<Response<ProductSetting>> =>
    GET(BASE_URL(data.username, data.id), data),
})

export type ProductSettingsApi = ReturnType<typeof createProductSettingsApi>
