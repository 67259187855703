import isEmail from 'validator/es/lib/isEmail'
import isURL from 'validator/es/lib/isURL'
import isCreditCard from 'validator/es/lib/isCreditCard'
import isLength from 'validator/es/lib/isLength'
import isIP from 'validator/es/lib/isIP'
import isInt from 'validator/es/lib/isInt'

export const isEmpty = (obj = {}) =>
  // additional check, because Object.keys(new Date()).length === 0;
  Object.keys(obj).length === 0 && obj.constructor === Object

export const isNumber = (value: unknown) => typeof value === 'number'

export const isValidVatNo = (campaignId) => {
  const pattern = new RegExp(/^[A-Za-z]{2,4}(?=.{2,12}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/gm)
  return pattern.test(campaignId)
}

export const isValidCampaignId = (campaignId) => {
  const pattern = new RegExp('^[_a-zA-Z0-9-]*$')
  return pattern.test(campaignId)
}

export const validators = {
  isEmail,
  isURL,
  isCreditCard,
  isLength,
  isIP,
  isInt,
}
