import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCardIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.75004 6.25V4.75H17.25V6.25749C17.2153 6.25255 17.1798 6.25 17.1436 6.25H2.75004ZM1.25004 7.0074V15.3333C1.25004 16.0581 1.80595 16.75 2.61542 16.75H17.3847C18.1941 16.75 18.75 16.0581 18.75 15.3333V4.66667C18.75 3.94192 18.1941 3.25 17.3847 3.25H2.61542C1.80595 3.25 1.25004 3.94192 1.25004 4.66667V6.9926C1.25001 6.99506 1.25 6.99753 1.25 7C1.25 7.00247 1.25001 7.00494 1.25004 7.0074ZM2.75004 7.75H17.1436C17.1798 7.75 17.2153 7.74745 17.25 7.74251V15.25H2.75004V7.75ZM13.0625 12.6538C12.6483 12.6538 12.3125 12.9896 12.3125 13.4038C12.3125 13.818 12.6483 14.1538 13.0625 14.1538H15.8125C16.2267 14.1538 16.5625 13.818 16.5625 13.4038C16.5625 12.9896 16.2267 12.6538 15.8125 12.6538H13.0625ZM8.18754 13.4038C8.18754 12.9896 8.52332 12.6538 8.93754 12.6538H10.3125C10.7267 12.6538 11.0625 12.9896 11.0625 13.4038C11.0625 13.818 10.7267 14.1538 10.3125 14.1538H8.93754C8.52332 14.1538 8.18754 13.818 8.18754 13.4038Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
