import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDownloadableIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.2727 18H2.72727C2.53439 18 2.3494 17.9234 2.21301 17.787C2.07662 17.6506 2 17.4656 2 17.2727V4.90909L3.45455 2H16.5455L18 4.90909V17.2727C18 17.4656 17.9234 17.6506 17.787 17.787C17.6506 17.9234 17.4656 18 17.2727 18Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.91833 12.0093L10.0002 15.0911L13.082 12.0093'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 7.81836V15.0911'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M2 4.90918H18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </EloBaseIcon>
)
