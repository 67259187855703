interface DataLayerObject {
  event?: string
  [key: string]: any
}

export const isDataLayerAvailable = () => window && window.dataLayer !== undefined

export const pushToDataLayer = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer.push(obj)
}

// TODO: replace function above with this one
export const addToDataLayer = (obj: DataLayerObject) => {
  if (isDataLayerAvailable()) {
    window.dataLayer = window.dataLayer || []
    return window.dataLayer.push(obj)
  }
}
