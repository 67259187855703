import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloExpandIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M13.5 3H17V6.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.5 17H3V13.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M17 13.5V17H13.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3 6.5V3H6.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </EloBaseIcon>
)
