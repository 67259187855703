import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPreviewIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.2727 3H2.72727C2.32561 3 2 3.28491 2 3.63636V16.3636C2 16.7151 2.32561 17 2.72727 17H17.2727C17.6744 17 18 16.7151 18 16.3636V3.63636C18 3.28491 17.6744 3 17.2727 3Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.6875 12.4028L14.0523 8.7676C13.9884 8.70264 13.9121 8.65106 13.828 8.61584C13.7439 8.58063 13.6537 8.5625 13.5625 8.5625C13.4713 8.5625 13.3811 8.58063 13.297 8.61584C13.2129 8.65106 13.1366 8.70264 13.0727 8.7676L9.23984 12.6004C9.17587 12.6654 9.09962 12.717 9.01552 12.7522C8.93143 12.7874 8.84117 12.8055 8.75 12.8055C8.65883 12.8055 8.56857 12.7874 8.48448 12.7522C8.40038 12.717 8.32413 12.6654 8.26016 12.6004L6.48984 10.8301C6.42587 10.7651 6.34962 10.7136 6.26552 10.6783C6.18143 10.6431 6.09117 10.625 6 10.625C5.90883 10.625 5.81857 10.6431 5.73448 10.6783C5.65038 10.7136 5.57413 10.7651 5.51016 10.8301L2.5625 13.7778'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.15625 8.5C7.72579 8.5 8.1875 8.03829 8.1875 7.46875C8.1875 6.89921 7.72579 6.4375 7.15625 6.4375C6.58671 6.4375 6.125 6.89921 6.125 7.46875C6.125 8.03829 6.58671 8.5 7.15625 8.5Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
