import moment from 'moment'
import { PAYMENT_STATES, PRICING_PLAN_FORMS_PRIO } from 'constants/ordersShared.constants'
import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { SPLITTED_PAYMENT_PLANS } from 'constants/pricingPlans.constants'
import { arrayUnique } from 'utils/array.utils'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import { isWindowEnv } from './env.utils'
import { getSearchParams } from './queryString.utils'

/**
 * Check whether the redirect should take a place
 * @return {boolean} True - with redirect False - without redirect
 */
export const processOrderRedirection = (redirectLink) => {
  const { success_url: successUrl } = getSearchParams()
  const redirectionOn = successUrl !== 'skip'
  if (redirectionOn && redirectLink) {
    window.location.href = redirectLink
    return true
  }
  return false
}

export const processFunnelRedirection = (redirectionUrl, token, username) => {
  const url = new URL(redirectionUrl)
  url.searchParams.set('order_token', token)
  url.searchParams.set('account_id', username)

  if (isWindowEnv()) {
    window.location.href = url.href
  } else {
    return url.href
  }
}

export const getNodeById = (node, id) => {
  if (node.id == id) {
    return node
  } else if (node.childs.length > 0) {
    let res = null
    for (let i = 0; res == null && i < node.childs.length; i++) {
      res = this.getNodeById(node.childs[i], id)
    }
    return res
  }
}

/**
 * Check if need to show cancellation checkbox in modal/manage page
 * @param order
 * @returns {boolean}
 */
export const showCancellationButton = (order) => {
  const { paymentState, periodType, cancelAt, paymentForm, testPeriodPassed } = order || {}

  const isInstallmentPlan = periodType === SPLITTED_PAYMENT_PLANS.installment
  const paymentInProgress = [PAYMENT_STATES.paying, PAYMENT_STATES.paymentPaused].includes(paymentState)
  const paymentRefunded =
    !isInstallmentPlan && [PAYMENT_STATES.paymentRefunded, PAYMENT_STATES.paymentChargebacked].includes(paymentState)

  return (
    (paymentInProgress || paymentRefunded) &&
    (!isInstallmentPlan || !testPeriodPassed) &&
    !cancelAt &&
    paymentForm !== PAYMENT_FORMS.payLater
  )
}

/**
 * Check if need to show continue button on modal/manage page
 * @param order
 * @returns {boolean}
 */
export const showContinueButton = (order) => {
  const { paymentState, canContinue, cancelAt } = order || {}

  return (PAYMENT_STATES.paymentCanceled === paymentState && canContinue) || cancelAt
}

export const transposeArray = (array) => array[0].map((_, colIndex) => array.map((row) => row[colIndex]))

/*
  function isPossibleToGroupPlans should receive array with plans
  and will return true or false, false in case if installment should be
  together with subscription or limited subscription
*/
export const isPossibleToGroupPlans = (plans = []) => {
  const forms = plans.map((i) => i.form)
  const formsConflict =
    forms.indexOf('installment') >= 0 &&
    (forms.indexOf('subscription') >= 0 || forms.indexOf('limited_subscription') >= 0)
  return !formsConflict
}

/*
  function isPossibleToGroupPlanLists should receive array of arrays with plans
  and will return true or false
*/
export const isPossibleToGroupPlanLists = (lists = []) => {
  // should be more then one list
  if (lists?.length <= 1) {
    return false
  }

  // should be the same amount of plans inside each list
  if (arrayUnique(lists.map((i) => i?.length))?.length !== 1) {
    return false
  }

  const transposedLists = transposeArray(lists)

  const formsMatching = transposedLists.map((plans) => {
    if (!isPossibleToGroupPlans(plans)) {
      return false
    }
  })

  if (formsMatching.indexOf(false) >= 0) {
    return false
  }

  return true
}

/*
  function groupPlans should receive two plans
  and will return single plan item
*/
export const mergePlans = (plan1, plan2) => {
  const form = PRICING_PLAN_FORMS_PRIO[plan1.form] > PRICING_PLAN_FORMS_PRIO[plan2.form] ? plan1.form : plan2.form
  const priceP1 = parseFloat(plan1.prefs.price || plan1.prefs.firstAmount || plan1.prefs.rate1Amount || 0)
  const priceP2 = parseFloat(plan2.prefs.price || plan2.prefs.firstAmount || plan2.prefs.rate1Amount || 0)
  const price = priceP1 + priceP2
  const oldPriceP1 = parseFloat(plan1.prefs.oldPrice || plan1.prefs.oldFirstAmount || 0)
  const oldPriceP2 = parseFloat(plan2.prefs.oldPrice || plan2.prefs.oldFirstAmount || 0)
  const oldPrice = oldPriceP1 || oldPriceP2 ? (oldPriceP1 || priceP1) + (oldPriceP2 || priceP2) : 0
  const firstAmount = parseFloat(plan1.prefs.firstAmount || 0) + parseFloat(plan2.prefs.firstAmount || 0)
  const nextAmount = parseFloat(plan1.prefs.nextAmount || 0) + parseFloat(plan2.prefs.nextAmount || 0)
  const rate1Amount = parseFloat(plan1.prefs.rate1Amount || 0) + parseFloat(plan2.prefs.rate1Amount || 0)
  const rate2Amount = parseFloat(plan1.prefs.rate2Amount || 0) + parseFloat(plan2.prefs.rate2Amount || 0)

  return {
    ...plan1,
    ...plan2,
    form,
    prefs: {
      ...plan1.prefs,
      ...plan2.prefs,
      price,
      oldPrice,
      firstAmount,
      nextAmount,
      rate1Amount,
      rate2Amount,
    },
  }
}

/*
  function groupPlans should receive array with plans
  and will return single plan item
*/
export const groupPlans = (plans) => {
  let resPlan = {
    form: 'one_time',
    prefs: { price: 0 },
  }

  plans.forEach((plan) => {
    resPlan = mergePlans(resPlan, plan)
  })

  return resPlan
}

/*
  function groupPlans should receive array of arrays with plans
  and will return array of groupped plans
*/

export const groupPlanLists = (lists = []) => {
  if (!isPossibleToGroupPlanLists(lists)) {
    return false
  }

  const transposedLists = transposeArray(lists)
  return transposedLists.map((plans) => groupPlans(plans))
}

export const getSellableFromOrderRates = (rates) =>
  Object.values(
    rates.reduce((acc, item) => {
      item.orderRatePrices.forEach((orderRate) => {
        const sellableId = orderRate.sellableId
        const entry = acc[sellableId] || { id: sellableId, orderRates: [] }

        entry.orderRates.push({
          id: item.id,
          chargeDate: item.chargeDate,
          amount: orderRate.data.toPaySum,
        })

        acc[sellableId] = entry
      })

      return acc
    }, {})
  )

export const getSellableForUpdate = (existingSellables, newSellables) => {
  // Create a Map for faster lookup of existingSellables items and rates
  const existingSellablesMap = new Map(existingSellables.map((item) => [item.id, item]))

  if (newSellables.length === 0) {
    // If newSellables is empty, assume all items from existingSellables are deleted
    return existingSellables.map((existingSellable) => ({
      id: existingSellable.id,
      orderRates: { delete: existingSellable.orderRates.map((rate) => ({ id: rate.id })) },
    }))
  }

  return newSellables.reduce((acc, newSellable) => {
    const existingSellable = existingSellablesMap.get(newSellable.id)

    if (!existingSellable) {
      // If the item is not present in the existing sellables, it was created
      acc.push({
        id: newSellable.id,
        orderRates: {
          create: newSellable.orderRates.map((rate) => ({
            chargeDate: moment(rate.chargeDate).format(DATE_FORMATS.DDMMYYYY),
            amount: rate.amount.toString(),
          })),
        },
      })
    } else {
      const existingRatesMap = new Map(existingSellable.orderRates.map((rate) => [rate.id, rate]))

      const createEntry = []
      const deleteEntry = []
      const updateEntry = []

      newSellable.orderRates.forEach((newRate) => {
        const existingRate = existingRatesMap.get(newRate.id)

        if (!existingRate) {
          // If the rate is not present in the existing sellables, it was created
          createEntry.push({
            chargeDate: moment(newRate.chargeDate).format(DATE_FORMATS.DDMMYYYY),
            amount: newRate.amount.toString(),
          })
        } else if (
          newRate.chargeDate !== existingRate.chargeDate ||
          newRate.amount.toString() !== existingRate.amount.toString()
        ) {
          // If chargeDate or amount is different, it was updated
          updateEntry.push({
            id: existingRate.id,
            chargeDate: moment(newRate.chargeDate).format(DATE_FORMATS.DDMMYYYY),
            amount: newRate.amount.toString(),
          })
        }
      })

      existingSellable.orderRates.forEach((existingRate) => {
        if (!newSellable.orderRates.some((rate) => rate.id === existingRate.id)) {
          // If the rate is not present in the new sellables, it was deleted
          deleteEntry.push({ id: existingRate.id })
        }
      })

      const entry = { id: newSellable.id, orderRates: {} }
      if (createEntry.length > 0) entry.orderRates.create = createEntry
      if (deleteEntry.length > 0) entry.orderRates.delete = deleteEntry
      if (updateEntry.length > 0) entry.orderRates.update = updateEntry

      if (Object.keys(entry.orderRates).length > 0) {
        acc.push(entry)
      }
    }

    return acc
  }, [])
}
