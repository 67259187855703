export const COOKIES_CONSENTS_FEATURE_FLAG = 'custom_cookies'

export const COOKIES_CONSENTS = {
  shop: 'consent_accepted_shop_s',
  app: 'consent_accepted_app',
}

export const COOKIES_CONSENTS_TYPE = {
  none: 'consent_none',
  simple: 'consent_simple',
  rich: 'consent_rich',
  external: 'consent_external',
}

export const COOKIES_CONSENTS_EXTERNAL_TYPE = {
  none: 'no_external',
  cookiebot: 'cookiebot',
}

export const COOKIEBOT_MODES = {
  auto: 'auto',
  manual: 'manual',
}

export const COOKIES_CONSENTS_LOCATION = {
  shop: 'shop',
  app: 'app',
}

export const COOKIES_CONSENTS_TYPES_LIST = Object.values(COOKIES_CONSENTS_TYPE)
export const COOKIES_CONSENTS_ALL_ACCEPTED = 'all'
