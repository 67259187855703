import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

export class TicketDatesStore extends SharedStore {
  storeName = 'TicketDatesStore'
  constructor() {
    super()
    makeObservable(this)
  }
}
