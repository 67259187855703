import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { MobileAppDocumentsApi, createMobileAppDocumentsApi } from 'shop/api/mobileAppDocuments.api'

export class MobileAppDocumentsStore extends SharedStore<any> {
  storeName = 'MobileAppDocumentsStore'
  declare childApi: MobileAppDocumentsApi

  constructor(root?: ShopRootStore) {
    super()
    this.childApi = createMobileAppDocumentsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }
}

export default new MobileAppDocumentsStore()
