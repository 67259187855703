import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCustomSortDescending: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10.4836 13.1458C10.3649 13.2909 10.1873 13.375 9.99988 13.375C9.81243 13.375 9.63487 13.2909 9.51616 13.1458L5.01616 7.64577C4.86324 7.45887 4.83168 7.20059 4.93509 6.98236C5.03851 6.76413 5.25839 6.625 5.49988 6.625H14.4999C14.7414 6.625 14.9613 6.76413 15.0647 6.98236C15.1681 7.20059 15.1365 7.45887 14.9836 7.64577L10.4836 13.1458Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
