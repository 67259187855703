import { action, observable, makeObservable } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { PaymentCountriesApi, createPaymentCountriesApi, PaymentCountry } from 'shop/api/paymentCountries.api'

export class PaymentCountriesStore {
  storeName = 'PaymentCountriesStore'
  declare childApi: PaymentCountriesApi
  root

  constructor(root: ShopRootStore) {
    this.root = root
    this.childApi = createPaymentCountriesApi(root.apiClientV2)
    makeObservable(this)
  }

  @observable paymentCountries = [] as PaymentCountry[]
  @observable loading = true

  @action toggleLoading = (value: boolean) => (this.loading = value)

  fetchPaymentCountries = async (username: string) => {
    const { data } = await this.childApi.fetchPaymentCountries(username)

    if (data) {
      this.paymentCountries = data
    }

    this.toggleLoading(false)
  }

  @action hydrate(...args: HydrationData) {
    const [key, data] = args

    if (key === 'paymentCountries') {
      this.paymentCountries = data || []
    }
  }
}

type HydrationData = ['paymentCountries', PaymentCountry[]]
