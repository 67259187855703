import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { TrackingCodeAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type TrackingCode = FromSwagger<TrackingCodeAttributes, number>

const BASE_URL = (username: string) => `/shop/${username}/tracking_codes`

export const createTrackingCodeApi = ({ GET }: ApiClientV2) => ({
  fetchCodes: (productId: number | string, username: string) =>
    GET<TrackingCode[]>(BASE_URL(username), {
      productId,
      username,
    }),
})

export type TrackingCodeApi = ReturnType<typeof createTrackingCodeApi>
