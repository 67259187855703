import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { EmbeddableItemsApi, createEmbeddableItemsApi } from 'shop/api/embeddableItems.api'

export class EmbeddableItemsStore extends SharedStore<any> {
  storeName = 'EmbeddableItemsStore'
  declare childApi: EmbeddableItemsApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createEmbeddableItemsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }
}
