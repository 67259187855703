import { ENVIRONMENT } from 'constants/general.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'

// TODO: Move env outside of function
export const isProduction = () => {
  const env = getElopageConfig('env')
  return env === ENVIRONMENT.production
}

export const isStaging = () => {
  const env = getElopageConfig('env')
  return env === ENVIRONMENT.staging
}

export const isDevelopment = () => {
  const env = getElopageConfig('env')
  return env === ENVIRONMENT.development
}

export const isUat = () => {
  const env = getElopageConfig('env')
  return env === ENVIRONMENT.uat
}

export const isWindowEnv = () => typeof window !== 'undefined'
