import { normalizeShopLink } from 'utils/link.utils'
import { formatToMoney } from 'utils/money.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { isPureObject } from 'utils/nameStyle.utils'
import { omit } from 'utils/lodash.utils'

import { WHITE_SPACES_REGEX } from 'constants/regex.constants'

type Seller = {
  protocol?: string
  host?: string
  username?: string
}

export const getSellerLink = (seller: Seller, slug?: string) =>
  normalizeShopLink(`${seller.protocol}://${seller.host}/s/${seller.username}${slug ? `/${slug}` : ''}`)

export const debounceEvent = (fn, time: number) => {
  let timeout
  return function (...args) {
    // @ts-ignore
    const functionCall = () => fn.apply(this, args)
    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export const removeSpaces = (str: string) => str.replace(WHITE_SPACES_REGEX, '')

export const convertToPercents = (value = 0) => formatToMoney(Number(value), '%')

export const getCancellationTermsShopLink = (username: string, termId: number, productType: string) =>
  `<a href='/s/${username}/document/cancellation_terms?ct_id=${termId}&product_type=${productType}' target='_blank'>${
    // prettier-ignore
    I18n.t('react.shared.payment.cancellation_term_link')
  }</a>`

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x)

// TODO: SSR - looks like getParamsFromUrl sometime is needed on SSR and should be isomorphic
export const getParamsFromUrl = (params) => {
  const paramsList = {}
  const searchParams = getSearchParams()
  params.forEach((paramName) => {
    if (searchParams[paramName]) paramsList[paramName] = searchParams[paramName]
  })
  return paramsList
}

// this function is inaccurate: it returns false if orders of properties of compared objects are different
// example: JSON.stringify({a: 1, b: 2}) === JSON.stringify({b: 2, a:1})
export const areObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)

export const removeEmptyValuesFromObject = (obj) => {
  const filteredObj = { ...obj }
  if (!isEmpty(filteredObj)) {
    Object.keys(filteredObj).forEach((key) => {
      if (isPureObject(filteredObj[key]) && !Array.isArray(filteredObj[key])) {
        filteredObj[key] = removeEmptyValuesFromObject(filteredObj[key])
      } else {
        ;(filteredObj[key] === undefined || filteredObj[key] === null || filteredObj[key] === '') &&
          delete filteredObj[key]
      }
    })
  }
  return filteredObj
}

export const getObjectWithoutProperties = (obj = {}, list = []) =>
  list.reduce((accumulator, currentValue) => omit(accumulator, currentValue), obj)

export const moveToTheEndOfArray = (list, value) => {
  if (list.includes(value)) {
    const filteredList = list.filter((item) => item !== value)
    return [...filteredList, value]
  }
  return list
}

export const moveToTheBeginningOfArray = (value, array) => {
  array.forEach((item, i) => {
    if (item === value) {
      array.splice(i, 1)
      array.unshift(item)
    }
  })
}
