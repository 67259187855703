import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPlayCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM20.555 15.1675L14.555 11.1675C14.4044 11.067 14.2293 11.0093 14.0484 11.0005C13.8675 10.9918 13.6877 11.0323 13.528 11.1177C13.3684 11.2032 13.2349 11.3304 13.1419 11.4858C13.0489 11.6412 12.9999 11.8189 13 12V20C12.9999 20.1811 13.0489 20.3588 13.1419 20.5142C13.2349 20.6696 13.3684 20.7968 13.528 20.8823C13.6877 20.9677 13.8675 21.0082 14.0484 20.9995C14.2293 20.9907 14.4044 20.933 14.555 20.8325L20.555 16.8325C20.6922 16.7412 20.8047 16.6175 20.8825 16.4722C20.9603 16.327 21.001 16.1648 21.001 16C21.001 15.8352 20.9603 15.673 20.8825 15.5278C20.8047 15.3825 20.6922 15.2588 20.555 15.1675ZM15 18.1313V13.875L18.1975 16L15 18.1313Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
