import { observable, action, computed, makeObservable } from 'mobx'
import queryString from 'query-string'

import { EMBED_TYPES } from 'constants/embeddableItemsShared.constants'
import { TRACKING_TYPES } from 'constants/affiliateTracker.constants'

import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { removeEmptyValuesFromObject } from 'utils/helpersShared.utils'
import { apiClient } from 'utils/requests.utils'
import { LocalStorageService } from 'utils/local-storage.utils'

import { AffiliateCookiesApi, createAffiliateCookiesApi } from 'shop/api/affiliateCookies.api'
import { AffiliateClicksApi, createAffiliateClicksApi } from 'shop/api/affiliateClicks.api'
import { RedirectionsApi, createRedirectionsApi } from 'shop/api/redirections.api'

type TrackingTypes = 'affiliateCookie' | 'affiliateClick' | 'affiliateRedirection'

interface AffiliateTrackingParams {
  username: string
  slug: string
  product_slug: string
}

export class AffiliatesStore {
  storeName = 'AffiliatesStore'
  root: ShopRootStore

  affiliateCookiesApi: AffiliateCookiesApi
  affiliateClicksApi: AffiliateClicksApi
  redirectionsApi: RedirectionsApi

  constructor(root: ShopRootStore) {
    this.root = root
    makeObservable(this)

    this.affiliateCookiesApi = createAffiliateCookiesApi(root?.apiClient ?? apiClient)
    this.affiliateClicksApi = createAffiliateClicksApi(root?.apiClient ?? apiClient)
    this.redirectionsApi = createRedirectionsApi(root?.apiClient ?? apiClient)
  }

  @observable loadings = {
    affiliateCookie: false,
    affiliateClick: false,
    affiliateRedirection: false,
  }

  @computed get loading() {
    return this.loadings.affiliateClick || this.loadings.affiliateCookie || this.loadings.affiliateRedirection
  }

  @action setLoading = (type: TrackingTypes, val: boolean) => (this.loadings[type] = val)

  checkAffiliateTracking = (params: AffiliateTrackingParams) =>
    this.setAffiliateCookie(params).then(() =>
      this.trackAffiliateClicks(params).then(() => this.checkAffiliateRedirection(params))
    )

  setAffiliateCookie = async ({ username }: { username: string }) => {
    const { contentId, pid, prid, prids } = queryString.parse(document.location.search)
    const affiliateToken = LocalStorageService.getItem('affiliateToken') || undefined

    if ((prid || prids) && pid) {
      this.setLoading(TRACKING_TYPES.affiliateCookie as TrackingTypes, true)
      const data = {
        affiliateToken,
        contentId,
        pid,
        prid,
        prids,
      }
      await this.affiliateCookiesApi
        .createCookie(username, removeEmptyValuesFromObject(data))
        .then(({ data, success }) => {
          if (success) {
            if (LocalStorageService.isLocalStorageSupported()) {
              LocalStorageService.setItem('affiliateToken', data?.affiliateToken)
            } else {
              this.root.paymentStore.setAffiliateToken(data?.affiliateToken)
            }
          } else {
            if (LocalStorageService.isLocalStorageSupported() && LocalStorageService.getItem('affiliateToken')) {
              LocalStorageService.setItem('affiliateToken', data?.affiliateToken)
            }
          }
          this.setLoading(TRACKING_TYPES.affiliateCookie as TrackingTypes, false)
        })
    }
  }

  setCampaignID = () => {
    const campaign_id = queryString.parse(document.location.search).campaign_id as string

    if (campaign_id) {
      sessionStorage.setItem('campaignId', campaign_id)
    }
  }

  trackAffiliateClicks = async ({ slug, product_slug: productSlug, username }: AffiliateTrackingParams) => {
    const { pid } = queryString.parse(document.location.search)
    const affiliateToken = LocalStorageService.getItem('affiliateToken') || this.root.paymentStore.store.affiliateToken

    if (affiliateToken) {
      this.setLoading(TRACKING_TYPES.affiliateClick as TrackingTypes, true)
      const data = {
        pid,
        token: affiliateToken,
        productSlug: slug || productSlug,
        clickerRef: LocalStorageService.getItem('clickerRef') || undefined,
      }
      await this.affiliateClicksApi
        .createClick(username, removeEmptyValuesFromObject(data))
        .then(({ data, success }) => {
          if (success) {
            if (LocalStorageService.isLocalStorageSupported()) {
              LocalStorageService.setItem('clickerRef', data.clickerRef)
            }
          }
          this.setLoading(TRACKING_TYPES.affiliateClick as TrackingTypes, false)
        })
    }
  }

  checkAffiliateRedirection = async ({ slug, product_slug: productSlug, username }: AffiliateTrackingParams) => {
    const { content_id: contentId, pid, embed_type: embedType } = queryString.parse(document.location.search)
    if (pid || contentId) {
      this.setLoading(TRACKING_TYPES.affiliateClick as TrackingTypes, true)
      const affiliateToken =
        LocalStorageService.getItem('affiliateToken') || this.root.paymentStore.store.affiliateToken
      const requestData = removeEmptyValuesFromObject({
        contentId,
        pid,
        productSlug: slug || productSlug,
        affiliateToken,
      })
      const { data, success } = await this.redirectionsApi.getRedirection(username, requestData)
      if (success) {
        const { forwardUrlParams, redirectionUrl } = data
        const search = redirectionUrl.includes('?') ? window.location.search.replace('?', '&') : window.location.search
        const currentUrl = window.location.href
        const formattedRedirectionUrl = `${redirectionUrl}${forwardUrlParams ? search : ''}`
        const currentUrlWithoutParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

        if (
          currentUrl === formattedRedirectionUrl ||
          embedType === EMBED_TYPES.onPage ||
          currentUrlWithoutParams === redirectionUrl
        ) {
          this.setLoading(TRACKING_TYPES.affiliateClick as TrackingTypes, false)
        } else {
          window.location.href = formattedRedirectionUrl
        }
      } else {
        this.setLoading(TRACKING_TYPES.affiliateClick as TrackingTypes, false)
      }
    }
  }
}
