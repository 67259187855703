import { observable, action, makeObservable } from 'mobx'

import { setCookies, getCookies } from 'libs/common/cookies'
import { notify } from 'libs/common/notify'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { PaymentTicket, TicketsApi, createTicketsApi } from 'shop/api/tickets.api'

export class TicketStore {
  storeName = 'TicketStore'
  childApi: TicketsApi

  constructor(root: ShopRootStore) {
    this.childApi = createTicketsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }

  @observable loading = true
  @observable item: PaymentTicket
  @observable cookiesPasswordValid = false
  @observable password = ''

  @action fetchItem = async (
    username: string,
    data: {
      token: string
      code: string
    }
  ) => {
    this.loading = true
    const resp = await this.childApi.fetchItem(username, data)
    this.item = resp.data
    this.cookiesPasswordValid = resp.data.ticketPassword === this.cookiesPassword()
    this.password = this.cookiesPasswordValid ? this.cookiesPassword() : ''
    this.loading = false
    return resp
  }

  @action updateItem = async (
    username: string,
    data: {
      token: string
      code: string
      password: string
    }
  ) => {
    this.loading = true
    const resp = await this.childApi.updateItem(username, data)
    this.item = resp.data
    this.loading = false

    if (resp.success) {
      this.cookiesPassword(this.password)
      notify('success', I18n.t('react.shop.notific.success'))
    }
  }

  @action setPassword = (value: string) => {
    this.password = value
  }

  cookiesPassword = (password?: string) => {
    const key = `ticket_${this.item.ticketId}`
    const date = new Date()

    date.setTime(date.getTime() + 100 * 24 * 60 * 60 * 1000)
    password && setCookies(key, password, { expires: date })

    return getCookies(key) as string
  }

  @action hydrate(key, data) {
    this.item = data
  }
}
