import { getElopageConfig } from 'utils/elopageConfig.utils'

export const normalizeShopLink = (link: string): string => {
  if (!link || typeof link !== 'string') return link
  if (!link.startsWith('http') && !link.startsWith('/')) return link

  if (getElopageConfig('env') === 'development') {
    const webProtocol = getElopageConfig('webProtocol')
    const webHost = getElopageConfig('webHost')
    const isAbsoluteURL = link.startsWith('http')
    const absoluteURL = isAbsoluteURL ? link : `${webProtocol}://${webHost}${link}`
    const normalizedLink = new URL(absoluteURL)
    const isLinkToNextJSApp = normalizedLink.pathname.startsWith('/s/') || normalizedLink.pathname.startsWith('/epl')

    if (isLinkToNextJSApp) {
      normalizedLink.protocol = 'http'
      normalizedLink.hostname = 'localhost'
      normalizedLink.port = '8081'
    } else {
      normalizedLink.protocol = 'http'
      normalizedLink.hostname = 'localhost'
      normalizedLink.port = '8080'
    }

    return normalizedLink.href
  }

  return link
}
