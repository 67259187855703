import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { AffiliateLandingAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type AffiliateLanding = FromSwagger<AffiliateLandingAttributes>

const BASE_URL = (username) => `shop/${username}/affiliate_landings`

export const createAffiliateLandingsApi = ({ GET }: ApiClientV2) => ({
  fetchItem: (id: string, data: { username: string }) =>
    GET<AffiliateLanding>(`${BASE_URL(data.username)}/${id}`, data),
})

export type AffiliateLandingsApi = ReturnType<typeof createAffiliateLandingsApi>
