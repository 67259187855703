import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCertificateIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M4.34615 14.6538C3.63846 13.9462 4.10769 12.4615 3.74615 11.5923C3.38462 10.7231 2 9.96154 2 9C2 8.03846 3.36923 7.30769 3.74615 6.40769C4.12308 5.50769 3.63846 4.05385 4.34615 3.34615C5.05385 2.63846 6.53846 3.10769 7.40769 2.74615C8.27692 2.38462 9.03846 1 10 1C10.9615 1 11.6923 2.36923 12.5923 2.74615C13.4923 3.12308 14.9462 2.63846 15.6538 3.34615C16.3615 4.05385 15.8923 5.53846 16.2538 6.40769C16.6154 7.27692 18 8.03846 18 9C18 9.96154 16.6308 10.6923 16.2538 11.5923C15.8769 12.4923 16.3615 13.9462 15.6538 14.6538C14.9462 15.3615 13.4615 14.8923 12.5923 15.2538C11.7231 15.6154 10.9615 17 10 17C9.03846 17 8.30769 15.6308 7.40769 15.2538C6.50769 14.8769 5.05385 15.3615 4.34615 14.6538Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
