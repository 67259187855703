import { computed, makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { SellerFonts, createSellerFontsApi, SellerFontsApi } from 'shop/api/sellerFonts.api'
import { getFontFamilyOptions } from 'utils/fonts.utils'
import type { ShopRootStore } from 'shop/stores/shopRoot.store'

export class FontsStore extends SharedStore<SellerFonts> {
  storeName = 'FontsStore'
  declare childApi: SellerFontsApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createSellerFontsApi(root.apiClientV2)
    makeObservable(this)
  }

  /**
   * Returns Font Family options for select picker.
   */
  @computed get fontsOptions() {
    return getFontFamilyOptions(this.list || [], false)
  }

  /**
   * Returns Font Family options for Froala Editor.
   */
  @computed get froalaFonts() {
    return getFontFamilyOptions(this.list || [], true)
  }

  @override
  async fetchList(reqData: { username: string }): Promise<void> {
    if (this.list.length) {
      return
    }

    const { data } = await this.childApi.fetchList(reqData)
    this.list = data
  }
}
