import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBundleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.2381 2H2.7619C2.34112 2 2 2.34112 2 2.7619V17.2381C2 17.6589 2.34112 18 2.7619 18H17.2381C17.6589 18 18 17.6589 18 17.2381V2.7619C18 2.34112 17.6589 2 17.2381 2Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10 2V18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 5.42871H18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 8.47607H18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 11.5239H18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 14.5713H18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </EloBaseIcon>
)
