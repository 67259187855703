export const APP_ROOT_PATH = '/'
export const APP_PLANS_PATH = '/plans'
export const APP_TERMS_PATH = '/terms'
export const APP_PRIVACY_PATH = '/privacy'
export const APP_IMPRINT_PATH = '/imprint'
export const APP_EMAIL_UNSUBSCRIBE_PATH = '/emails/unsubscribe/:token'
export const APP_USERS_CONFIRMATION_PATH = '/users/confirmation'
export const APP_USERS_CONFIRMATION_NEW_PATH = '/users/confirmation/new'
export const APP_USERS_SIGN_IN_PATH = '/users/sign_in'
export const APP_USERS_SIGN_UP_PATH = '/users/sign_up'
export const APP_USERS_PASSWORD_NEW_PATH = '/users/password/new'
export const APP_USERS_PASSWORD_EDIT_PATH = '/users/password/edit'
export const APP_USERS_ELO_PUBLISHER_EDIT_PATH = '/users/elo_publisher'
export const APP_EMAIL_BASE_PATH = 'email_logs'
export const APP_EMAIL_LOGS_PATH = `/${APP_EMAIL_BASE_PATH}/:token`
export const APP_ELOPAGE_PROMO_PATH = `/elopage_promos/:token`
export const APP_SLUG_PATH = '/:slug'
export const APP_USERS_SIGN_UP_QUESTIONS_PATH = '/users/sign_up/about_you'
