import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCurrencyCircleDollarIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.125 3.25C6.32804 3.25 3.25 6.32804 3.25 10.125C3.25 13.922 6.32804 17 10.125 17C13.922 17 17 13.922 17 10.125C17 6.32804 13.922 3.25 10.125 3.25ZM2 10.125C2 5.63769 5.63769 2 10.125 2C14.6123 2 18.25 5.63769 18.25 10.125C18.25 14.6123 14.6123 18.25 10.125 18.25C5.63769 18.25 2 14.6123 2 10.125ZM10.75 5.75C10.75 5.40482 10.4702 5.125 10.125 5.125C9.77982 5.125 9.5 5.40482 9.5 5.75V6.375H9.1875C8.60734 6.375 8.05094 6.60547 7.6407 7.0157C7.23047 7.42594 7 7.98234 7 8.5625C7 9.14266 7.23047 9.69906 7.6407 10.1093C8.05094 10.5195 8.60734 10.75 9.1875 10.75H11.0625C11.3111 10.75 11.5496 10.8488 11.7254 11.0246C11.9012 11.2004 12 11.4389 12 11.6875C12 11.9361 11.9012 12.1746 11.7254 12.3504C11.5496 12.5262 11.3111 12.625 11.0625 12.625H8.25C7.90482 12.625 7.625 12.9048 7.625 13.25C7.625 13.5952 7.90482 13.875 8.25 13.875H9.5V14.5C9.5 14.8452 9.77982 15.125 10.125 15.125C10.4702 15.125 10.75 14.8452 10.75 14.5V13.875H11.0625C11.6427 13.875 12.1991 13.6445 12.6093 13.2343C13.0195 12.8241 13.25 12.2677 13.25 11.6875C13.25 11.1073 13.0195 10.5509 12.6093 10.1407C12.1991 9.73047 11.6427 9.5 11.0625 9.5H9.1875C8.93886 9.5 8.7004 9.40123 8.52459 9.22541C8.34877 9.0496 8.25 8.81114 8.25 8.5625C8.25 8.31386 8.34877 8.0754 8.52459 7.89959C8.7004 7.72377 8.93886 7.625 9.1875 7.625H10.125H12C12.3452 7.625 12.625 7.34518 12.625 7C12.625 6.65482 12.3452 6.375 12 6.375H10.75V5.75Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
