import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloSearchIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M8.96875 16.1875C12.9556 16.1875 16.1875 12.9556 16.1875 8.96875C16.1875 4.98194 12.9556 1.75 8.96875 1.75C4.98194 1.75 1.75 4.98194 1.75 8.96875C1.75 12.9556 4.98194 16.1875 8.96875 16.1875Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0732 14.0732L18.2498 18.2498'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
