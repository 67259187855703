import { parse } from 'qs'

import { isWindowEnv } from 'utils/env.utils'

const colours = {
  warn: 'display: inline-block; background-color: gold; color: black; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;',
  error:
    'display: inline-block; background-color: #e0005a; color: #ffffff; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;',
  info: 'display: inline-block; background-color:#007acc; color: #ffffff; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;',
  success:
    'display: inline-block; background-color:#26bfa5; color: #000000; padding: 3px 7px 3px 7px; border-radius: 3px 3px 3px 3px;',
  infoText: 'display: inline-block; color: #0098f7; padding: 3px 7px 3px 7px; font-weight: bold;',
}

export const useLogger = (serverSideLogs = []) => {
  if (isWindowEnv() && serverSideLogs && serverSideLogs.length > 0) {
    const logs = serverSideLogs.map((item) => JSON.parse(item))
    // eslint-disable-next-line
    console.log('===== SSR API requests - START =====')
    logs.map((item, idx) => {
      const [path, query] = (item.url || `${item.baseURL}/${item.path}`).split('?')
      // eslint-disable-next-line
      console.groupCollapsed(
        `%c${idx} - ${item.method.toUpperCase()} ${(item.path || item.url).split('?')[0]} ${item.respStatus} ${
          item.baseURL ? ' (v2)' : ' (v1)'
        } in ${item.reqDuration} ms`,
        item.respStatus === 200 ? colours.success : colours.error
      )
      // eslint-disable-next-line
      console.info('%curl 👇', colours.infoText)
      // eslint-disable-next-line
      console.log(path)
      // eslint-disable-next-line
      // eslint-disable-next-line
      console.info('%cquery params 👇', colours.infoText)
      // eslint-disable-next-line
      console.table(parse(query))
      // eslint-disable-next-line
      console.info('%crequest headers 👇', colours.infoText)
      // eslint-disable-next-line
      console.table(item.reqHeaders)
      // eslint-disable-next-line
      console.info('%cresponse headers 👇', colours.infoText)
      // eslint-disable-next-line
      console.table(item.resHeaders)
      // eslint-disable-next-line
      console.info('%cresponse 👇', colours.infoText + 'font-size: 1rem;')
      // eslint-disable-next-line
      console.dir(item.response)
      // eslint-disable-next-line
      console.groupEnd()
    })
    // eslint-disable-next-line
    console.log('===== SSR API requests - END =====')
  }
}
