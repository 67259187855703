import React, { useEffect, useRef } from 'react'

function InnerHtml(props) {
  const { html } = props
  const divRef = useRef(null)

  useEffect(() => {
    const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
    divRef.current.appendChild(slotHtml) // Append it so it can be executed
    return () => window.location.reload()
  }, [])

  return <div ref={divRef} />
}

export default InnerHtml
