import { EMPTY_TEXT_EDITOR } from 'constants/courses.constants'
import { SCRIPT_TAGS_WITH_SRC_ATTRIBUTE_REGEX } from 'constants/regex.constants'

export const replaceEscapeSymbols = (value) =>
  (value || '').replace(/font-size: \n/, 'font-size: ').replace(/font-family: \n/, 'font-family: ')

export const trimEditorText = (initialValue) => {
  const trimmedValue = initialValue.replace(/&nbsp;/g, '').trim()
  const isTextEditorEmpty = trimmedValue === EMPTY_TEXT_EDITOR
  return isTextEditorEmpty ? '' : trimmedValue
}

export const stripHTMLFromText = (text) =>
  // Rule to remove inline CSS.
  text &&
  text.length > 0 &&
  text
    .replace(/<style[^>]*>.*<\/style>/gm, '')
    // Rule to remove all opening, closing and orphan HTML tags.
    .replace(/<[^>]+>/gm, '')
    // Rule to remove leading spaces and repeated CR/LF.
    .replace(/( +[\r\n]+ +)+/gm, '')
    .replace(/([\r\n]+ +)+/gm, ' ')

export const removeScriptTagsWithSrcAttibute = (htmlString) =>
  htmlString.replace(/<\/script>/gi, '</script>\n').replace(SCRIPT_TAGS_WITH_SRC_ATTRIBUTE_REGEX, '')
