import { apiClient } from 'utils/requests.utils'

const BASE_URL = '/common/affiliate_programs'

export const createAffiliateProgramsApi = ({ GET, POST }) => ({
  fetchItem: (id, data) => GET(`${BASE_URL}/${id}`, data),

  accept: (token, data) => POST(`${BASE_URL}/${token}/accept`, data),
})

const api = createAffiliateProgramsApi(apiClient)
export const fetchItem = api.fetchItem
export const accept = api.accept
