import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCustomSortAscendingIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10.4836 6.85423C10.3649 6.70914 10.1873 6.625 9.99988 6.625C9.81243 6.625 9.63487 6.70914 9.51616 6.85423L5.01616 12.3542C4.86324 12.5411 4.83168 12.7994 4.93509 13.0176C5.03851 13.2359 5.25839 13.375 5.49988 13.375H14.4999C14.7414 13.375 14.9613 13.2359 15.0647 13.0176C15.1681 12.7994 15.1365 12.5411 14.9836 12.3542L10.4836 6.85423Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
