import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloFunnelIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M2.68138 3.125H17.3186C17.4508 3.12504 17.5802 3.16332 17.6909 3.23519C17.8016 3.30705 17.8889 3.40938 17.9422 3.52972C17.9955 3.65007 18.0124 3.78322 17.991 3.91297C17.9695 4.04272 17.9105 4.16345 17.8213 4.26047L12.2237 10.3869C12.1072 10.511 12.0431 10.6748 12.0448 10.8445V15.6406C12.0458 15.7533 12.0183 15.8644 11.9646 15.9636C11.911 16.0628 11.8331 16.147 11.7381 16.2083L9.01169 18.0132C8.90942 18.0802 8.79089 18.1186 8.66856 18.1243C8.54624 18.1299 8.42463 18.1027 8.31653 18.0455C8.20843 17.9883 8.11782 17.9032 8.05424 17.7991C7.99066 17.695 7.95646 17.5758 7.95522 17.454V10.8445C7.95686 10.6748 7.8928 10.511 7.7763 10.3869L2.1787 4.26047C2.08946 4.16345 2.03051 4.04272 2.00905 3.91297C1.98758 3.78322 2.00451 3.65007 2.05779 3.52972C2.11107 3.40938 2.19838 3.30705 2.3091 3.23519C2.41982 3.16332 2.54917 3.12504 2.68138 3.125V3.125Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
