import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { apiClient } from 'utils/requests.utils'

import { createProductGroupsApi } from 'shop/api/productGroups.api'

export class ProductGroupsStore extends SharedStore {
  storeName = 'ProductGroupsStore'
  childApi

  constructor(root) {
    super()
    this.childApi = createProductGroupsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }
}
