export const COUPON_CREATION_TYPE = {
  single: 'single',
  bulk: 'bulk',
}

export const COUPON_CODE_FORMAT_OPTIONS = [
  {
    value: 'alphabetic',
    label: I18n.t('react.cabinet.coupons.form.alphabetic'),
  },
  {
    value: 'numeric',
    label: I18n.t('react.cabinet.coupons.form.numeric'),
  },
  {
    value: 'alphanumeric',
    label: I18n.t('react.cabinet.coupons.form.alphanumeric'),
  },
]

export const COUPON_UNITS_OPTIONS = [
  {
    value: 'currency',
    label: I18n.t('react.common.currency'),
  },
  {
    value: 'percents',
    label: I18n.t('react.common.percents'),
  },
]

export const COUPON_CALCS_OPTIONS = [
  {
    value: 'end_amount',
    label: I18n.t('react.common.end_amount'),
  },
  {
    value: 'net_amount',
    label: I18n.t('react.common.net_amount'),
  },
]

export const COUPON_PRODUCTS = {
  all: 'all_products',
  single: 'single_product',
  multiple: 'multiple_products',
}

export const COUPON_VALUE_FORM = {
  currency: 'currency',
  percents: 'percents',
}

export const COUPON_EXPANDS = ['coupons_products']

export const COUPON_MIN_VALUE = 1
export const COUPON_MIN_COUNT = 2

export const COUPON_APPLY_FORM = {
  all_products: I18n.t('react.cabinet.coupons.form.all_products'),
  single_product: I18n.t('react.cabinet.coupons.form.single_product'),
  multiple_products: I18n.t('react.cabinet.coupons.form.multiple_products'),
}
