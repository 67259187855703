import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloNumbersIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M8.125 10H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M8.125 5H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M8.125 15H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M3.125 4.6875L4.375 4.0625V8.4375' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M3.21094 11.9225C3.26853 11.7799 3.35555 11.651 3.46632 11.5443C3.57708 11.4376 3.70911 11.3555 3.85377 11.3032C3.99843 11.251 4.15248 11.2299 4.30586 11.2413C4.45923 11.2526 4.60849 11.2962 4.74389 11.3691C4.87928 11.4421 4.99777 11.5428 5.09162 11.6646C5.18547 11.7865 5.25256 11.9267 5.28853 12.0763C5.32449 12.2258 5.32852 12.3812 5.30034 12.5324C5.27216 12.6836 5.21242 12.8272 5.125 12.9537L3.125 15.6256H5.3125'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </EloBaseIcon>
)
