import { COLORS } from './general.constants'
import { PRODUCT_TYPE_IDS } from './products.constants'

export const HEADER = {
  background: 'backgroundColor',
  logo: 'logo',
  menuFont: 'menuFont',
  menuStyle: 'menuStyle',
  menuColor: 'menuColor',
  menuSize: 'menuSize',
  website: 'website',
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  youtube: 'youtube',
  tumblr: 'tumblr',
  pinterest: 'pinterest',
  linkedIn: 'linkedin',
  xing: 'xing',
  tiktok: 'tiktok',
  socialsColor: 'socialsColor',
  menuList: 'menuList',
  accountFont: 'accountFont',
  accountStyle: 'accountStyle',
  accountColor: 'accountColor',
  accountSize: 'accountSize',
  iconColor: 'iconColor',
  languageSwitcher: 'languageSwitcher',
  languageFont: 'languageFont',
  languageStyle: 'languageStyle',
  languageColor: 'languageColor',
  languageSize: 'languageSize',
  sharingOptions: 'sharingOptions',
  sharingOptionsColor: 'sharingOptionsColor',
  myAccountSwitcher: 'myAccountSwitcher',
}

export const FOOTER = {
  background: 'backgroundColor',
  menuFont: 'menuFont',
  menuStyle: 'menuStyle',
  menuColor: 'menuColor',
  menuSize: 'menuSize',
  submenuFont: 'submenuFont',
  submenuStyle: 'submenuStyle',
  submenuColor: 'submenuColor',
  submenuSize: 'submenuSize',
  website: 'website',
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  youtube: 'youtube',
  tumblr: 'tumblr',
  pinterest: 'pinterest',
  socialsColor: 'socialsColor',
  menuList: 'menuList',
  socialsTitle: 'socialsTitle',
  socialsIsShown: 'socialsIsShown',
}

export const SOCIALS = [
  'website',
  'facebook',
  'instagram',
  'twitter',
  'youtube',
  'tumblr',
  'pinterest',
  'linkedin',
  'xing',
  'tiktok',
]

export const SOCIAL_ICONS = {
  website: 'fas fa-globe',
  facebook: 'fa fa-facebook', // use from 4.7.0 version
  instagram: 'fab fa-instagram',
  twitter: 'fab fa-twitter',
  youtube: 'fab fa-youtube',
  tumblr: 'fab fa-tumblr',
  pinterest: 'fab fa-pinterest',
  linkedin: 'fab fa-linkedin-in',
  xing: 'fab fa-xing',
  tiktok: 'fab fa-tiktok',
}

export const SHOP_SOCIAL_ICONS = {
  ...SOCIAL_ICONS,
  facebook: 'fab fa-facebook-f', // use from 5.15 version
}

export const BLOCK_VIEW = {
  grid: 'grid',
  list: 'list',
  slider: 'slider',
  button: 'button',
  text: 'text',
}

export const TEXT = {
  text: 'text',
  background: 'backgroundColor',
  paddingTop: 'paddingTop',
  paddingBottom: 'paddingBottom',
  paddingRight: 'paddingRight',
  paddingLeft: 'paddingLeft',
}

export const BUTTON_STYLE = {
  filled: 'filled',
  outlined: 'outlined',
}

export const VIDEO_TYPE = {
  elopage: 'elopage',
  vimeo: 'vimeo',
}

export const DEFAULT_TEXT_PADDING = 15
export const DEFAULT_FILES_PADDING = 15
export const DEFAULT_PICTURE_PADDING = 0
export const DEFAULT_BANNER_PADDING = 15
export const DEFAULT_CONTACT_FORM_PADDING = 40

export const DEFAULT_VIDEO_PADDING = 0
export const DEFAULT_VIDEO_WIDTH = 100
export const DEFAULT_VIDEO_MAX_WIDTH = 5000

export const TESTIMONIALS_TEXT_LENGTH = 250

export const DEFAULT_SORTING_DIR = {
  position: 'asc',
  created_at: 'desc',
}

export const PDF_FILE = {
  backgroundColor: 'backgroundColor',
  backgroundImage: 'backgroundImage',
  transparency: 'transparency',
  buttonColor: 'buttonColor',
  toolbarButtonSize: 'toolbarButtonSize',
  toolbarBackgroundColor: 'toolbarBackgroundColor',
  toolbarFontSize: 'toolbarFontSize',
  toolbarFont: 'toolbarFont',
  toolbarFontStyle: 'toolbarFontStyle',
  toolbarColor: 'toolbarColor',
  progressBackgroundColor: 'progressBackgroundColor',
  downloadFile: 'downloadFile',
}

export const DISPLAY_OPTION = {
  display: 'display',
  hide: 'hide',
}

export const PRODUCTS_LIST = {
  productFont: 'productFont',
  productStyle: 'productStyle',
  productColor: 'productColor',
  productSize: 'productSize',
  productBackground: 'productBackground',
  progress: 'progress',
  progressColor: 'progressColor',
  progressBackground: 'progressBackground',
  background: 'backgroundColor',
  priceFont: 'priceFont',
  priceStyle: 'priceStyle',
  priceColor: 'priceColor',
  priceBackground: 'priceBackground',
  priceSize: 'priceSize',
  priceRadius: 'priceRadius',
  view: 'view',
  showButton: 'showButton',
  buttonText: 'buttonText',
  buttonFont: 'buttonFont',
  buttonStyle: 'buttonStyle',
  buttonSize: 'buttonSize',
  btnTextColor: 'buttonTextColor',
  btnColor: 'buttonColor',
  descriptionFont: 'descriptionFont',
  descriptionStyle: 'descriptionStyle',
  descriptionColor: 'descriptionColor',
  descriptionSize: 'descriptionSize',
  currencyFont: 'currencyFont',
  currencyStyle: 'currencyStyle',
  currencyColor: 'currencyColor',
  currencySize: 'currencySize',
  oldPriceFont: 'oldPriceFont',
  oldPriceStyle: 'oldPriceStyle',
  oldPriceColor: 'oldPriceColor',
  oldPriceSize: 'oldPriceSize',
  showCategories: 'showCategories',
  categoryFont: 'categoryFont',
  categoryStyle: 'categoryStyle',
  categoryColor: 'categoryColor',
  categorySize: 'categorySize',
  categoryActiveColor: 'categoryActiveColor',
  categoryBorderColor: 'categoryBorderColor',
  categoryDescriptionFont: 'categoryDescriptionFont',
  categoryDescriptionStyle: 'categoryDescriptionStyle',
  categoryDescriptionColor: 'categoryDescriptionColor',
  categoryDescriptionSize: 'categoryDescriptionSize',
}

export const BANNER = {
  backgroundImage: 'backgroundImage',
  backgroundColor: 'backgroundColor',
  colorOverlay: 'colorOverlay',
  transparency: 'transparency',
  mainColor: 'mainColor',
  mainSize: 'mainSize',
  mainFont: 'mainFont',
  mainStyle: 'mainStyle',
  secondColor: 'additionalColor',
  secondSize: 'additionalSize',
  secondFont: 'additionalFont',
  secondStyle: 'additionalStyle',
  showButton: 'showButton',
  buttonText: 'buttonText',
  buttonLink: 'buttonLink',
  btnTextColor: 'buttonTextColor',
  btnColor: 'buttonColor',
  title: 'titleText',
  additionalText: 'additionalText',
  horizontalAlignment: 'horizontalAlignment',
  textAlignment: 'textAlignment',
  width: 'width',
  maxWidth: 'maxWidth',
}

export const TESTIMONIALS = {
  textFont: 'textFont',
  textStyle: 'textStyle',
  textColor: 'textColor',
  textSize: 'textSize',

  usernamesFont: 'usernamesFont',
  usernamesStyle: 'usernamesStyle',
  usernamesColor: 'usernamesColor',
  usernamesSize: 'usernamesSize',

  backgroundImage: 'backgroundImage',
  backgroundColor: 'backgroundColor',
  transparency: 'transparency',

  arrowsColor: 'arrowsColor',
  testimonials: 'testimonials',

  testimonialId: 'id',
  testimonialAvatar: 'avatar',
  testimonialFullName: 'fullName',
  testimonialText: 'text',
}

export const POPULAR_PRODUCTS = {
  titleFont: 'textFont',
  titleStyle: 'textStyle',
  titleColor: 'textColor',
  titleSize: 'textSize',

  backgroundImage: 'backgroundImage',
  backgroundColor: 'backgroundColor',
  transparency: 'transparency',

  buttonColor: 'buttonColor',
  buttonFont: 'buttonFont',
  buttonStyle: 'buttonStyle',
  buttonSize: 'buttonSize',
  buttonTextColor: 'buttonTextColor',
  buttonText: 'buttonText',
  freeButtonText: 'freeButtonText',
  productBackgroundColor: 'productBackground',
  arrowsColor: 'arrowsColor',
  itemsPerSlide: 'itemsPerSlide',

  products: 'productIds',

  view: 'view',

  autobuyConfirmButtonColor: 'autobuyConfirmButtonColor',
  autobuyCancelButtonColor: 'autobuyCancelButtonColor',
  autobuyConfirmButtonTextColor: 'autobuyConfirmButtonTextColor',
  autobuyCancelButtonTextColor: 'autobuyCancelButtonTextColor',
  autobuyModalTitleColor: 'autobuyModalTitleColor',

  buttonType: 'buttonType',
  paymentOption: 'paymentOption',

  descriptionFont: 'descriptionFont',
  descriptionStyle: 'descriptionStyle',
  descriptionColor: 'descriptionColor',
  descriptionSize: 'descriptionSize',
  currencyFont: 'currencyFont',
  currencyStyle: 'currencyStyle',
  currencyColor: 'currencyColor',
  currencySize: 'currencySize',
  oldPriceFont: 'oldPriceFont',
  oldPriceStyle: 'oldPriceStyle',
  oldPriceColor: 'oldPriceColor',
  oldPriceSize: 'oldPriceSize',
  priceFont: 'priceFont',
  priceStyle: 'priceStyle',
  priceColor: 'priceColor',
  priceSize: 'priceSize',
  priceBackground: 'priceBackground',
  showPrice: 'showPrice',
  priceRadius: 'priceRadius',
}

export const ALL_PRODUCTS_CATEGORY = {
  color: COLORS.eloBlue,
  description: 'category description',
  hidden: false,
  id: 0,
  position: 0,
  productIds: [],
  title: 'All products',
}

export const DEFAULT_CATEGORIES = [
  {
    color: '#FFB000',
    description: 'Description 1',
    hidden: false,
    id: 1,
    position: 1,
    productIds: [],
    title: 'Category 1',
  },
  {
    color: COLORS.eloOrange,
    description: 'Description 2',
    hidden: false,
    id: 2,
    position: 2,
    productIds: [],
    title: 'Category 2',
  },
]

export const DEFAULT_LESSONS_LIST = [
  {
    isCategory: false,
    preview: true,
    name: 'parent lesson',
  },
  {
    isCategory: true,
    preview: false,
    name: 'category',
    children: [
      {
        isCategory: true,
        preview: false,
        name: 'nested category',
        children: [],
      },
      {
        isCategory: false,
        preview: false,
        name: 'nested lesson',
      },
    ],
  },
  {
    isCategory: true,
    preview: false,
    name: 'category 2',
    children: [
      {
        isCategory: true,
        preview: false,
        name: 'nested category',
        children: [],
      },
    ],
  },
]

export const DEFAULT_PRODUCTS_LIST = [
  {
    name: 'Product 1',
    form: 'digital',
    id: 'product-list-item-1',
    description: 'Default description',
    free: true,
  },
  {
    name: 'Product 2',
    form: 'digital',
    id: 'product-list-item-2',
    free: false,
    displayPrice: 50,
    displayOldPrice: 100,
    displayCurrencyId: 2,
    description: 'Default description',
  },
  {
    name: 'Product 3',
    form: 'digital',
    slug: 'product3',
    id: 'product-list-item-3',
    free: false,
    displayPrice: 100,
    displayOldPrice: 250,
    displayCurrencyId: 1,
    pricingPlans: [
      {
        currencyId: 1,
        useNetPrice: true,
      },
    ],
    description: 'Default description',
  },
]

export const DEFAULT_POPULAR_PRODUCTS_LIST = [
  ...DEFAULT_PRODUCTS_LIST,
  {
    name: 'Product 4',
    form: 'digital',
    id: 'product-list-item-4',
    free: false,
    displayPrice: 300,
    displayCurrencyId: 1,
    pricingPlans: [
      {
        currencyId: 1,
        useNetPrice: true,
      },
    ],
    description: 'Default description',
  },
]

export const ALL_PRODUCTS_CATEGORY_OPTION = {
  value: 0,
  label: 'All products', // I18n.t('react.shared.all_products')
}

export const DEFAULT_CATEGORY_OPTIONS = [
  {
    value: 1,
    label: 'Category 1', // `${I18n.t('react.shared.category')} 1`
  },
  {
    value: 2,
    label: 'Category 2', // `${I18n.t('react.shared.category')} 2`
  },
]

export const DEFAULT_PRODUCT = {
  id: 'product-1',
  name: I18n.t('react.shared.product'),
  form: PRODUCT_TYPE_IDS.digital,
  free: false,
  displayPrice: 50,
  displayOldPrice: 100,
  displayCurrencyId: 2,
  pricingPlans: [
    {
      currencyId: 2,
    },
  ],
  slug: 'product',
  sellerUsername: 'some_fake_username',
  description: I18n.t('react.shared.product_description_default'),
  menuUnfold: true,
  covers: [],
}

export const COURSE_DEFAULT_PRODUCT = {
  ...DEFAULT_PRODUCT,
  form: PRODUCT_TYPE_IDS.course,
}

export const TICKET_DEFAULT_PRODUCT = {
  ...DEFAULT_PRODUCT,
  form: PRODUCT_TYPE_IDS.eventTickets,
  tickets: [
    {
      id: 22,
      name: 'Ticket without dates ',
      free: false,
      location: 'Some street name Str. 100, Berlin, Germany',
      locationShortName: 'Some street name Str. 100, Berlin, Germany',
      price: 20.55,
      ticketDates: [
        {
          startDate: '23.08.2019, 00:00',
          endDate: '24.08.2019, 00:00',
          id: 1,
        },
        {
          startDate: '25.08.2019, 00:00',
          endDate: '26.08.2019, 00:00',
          id: 2,
        },
        {
          startDate: '26.08.2019, 00:00',
          endDate: '27.08.2019, 00:00',
          id: 2,
        },
        {
          startDate: '28.08.2019, 00:00',
          endDate: '29.08.2019, 00:00',
          id: 2,
        },
        {
          startDate: '29.08.2019, 00:00',
          endDate: '30.08.2019, 00:00',
          id: 2,
        },
        {
          startDate: '30.08.2019, 00:00',
          endDate: '31.08.2019, 00:00',
          id: 2,
        },
      ],
    },
  ],
}

export const DOWNLOAD_DEFAULT_PRODUCT = {
  ...DEFAULT_PRODUCT,
  form: PRODUCT_TYPE_IDS.download,
  goods: [
    {
      data: {
        id: 1,
        style: 'demo',
        duration: {
          full: 300,
          demo: 60,
        },
      },
      digital: {
        audio: true,
        digitalIcon: '<i class="fas fa-file-sound" />',
        fileName: 'Audio_file.mp3',
        fileUrl: '/',
        image: false,
      },
      name: 'Audio_file.mp3',
    },
    {
      data: {
        id: 2,
        style: 'demo',
        duration: null,
      },
      digital: {
        audio: false,
        digitalIcon: '<i class="fas fa-file-image" />',
        fileName: 'image_file.jpg',
        fileUrl: null,
        image: true,
      },
      name: 'image_file.jpg',
    },
    {
      data: {
        id: 2,
        style: 'demo',
        duration: null,
      },
      digital: {
        audio: false,
        digitalIcon: '<i class="fas fa-file-pdf" />',
        fileName: 'pdf_file.pdf',
        fileUrl: '/',
        image: false,
      },
      name: 'pdf_file.mp3',
    },
  ],
}

export const BUTTON_TYPE = {
  buy: 'buy',
  details: 'details',
  customLink: 'customLink',
  individualLink: 'individualLink',
  downloadFile: 'downloadFile',
  lessonLink: 'lessonLink',
}

export const PAYMENT_OPTION = {
  autopay: 'autopay',
  checkout: 'checkout',
}
