import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloArrowsClockWiseIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.60911 4.22676C8.36704 3.91249 9.1795 3.75073 10 3.75073C10.8205 3.75073 11.633 3.91249 12.3909 4.22676C13.1488 4.54103 13.8374 5.00164 14.4171 5.58225L14.4181 5.58322L16.5051 7.66406H14.2656C13.9204 7.66406 13.6406 7.94388 13.6406 8.28906C13.6406 8.63424 13.9204 8.91406 14.2656 8.91406H18.0156C18.3608 8.91406 18.6406 8.63424 18.6406 8.28906V4.53906C18.6406 4.19388 18.3608 3.91406 18.0156 3.91406C17.6704 3.91406 17.3906 4.19388 17.3906 4.53906V6.78185L15.3016 4.69901L15.3012 4.69854C14.6054 4.00189 13.7792 3.4492 12.8697 3.07209C11.96 2.69489 10.9848 2.50073 10 2.50073C9.01519 2.50073 8.04005 2.69489 7.13034 3.07209C6.22063 3.44929 5.39422 4.00214 4.69836 4.69901C4.45446 4.94326 4.45475 5.33899 4.69901 5.58289C4.94326 5.82679 5.33899 5.8265 5.58289 5.58225C6.16265 5.00164 6.85118 4.54103 7.60911 4.22676ZM1.35938 11.7109C1.35938 11.3658 1.6392 11.0859 1.98438 11.0859H5.73438C6.07955 11.0859 6.35938 11.3658 6.35938 11.7109C6.35938 12.0561 6.07955 12.3359 5.73438 12.3359H3.49493L5.58192 14.4168L5.58289 14.4178C6.16265 14.9984 6.85119 15.459 7.60912 15.7732C8.36705 16.0875 9.1795 16.2493 10 16.2493C10.8205 16.2493 11.633 16.0875 12.3909 15.7732C13.1488 15.459 13.8374 14.9984 14.4171 14.4178C14.661 14.1735 15.0567 14.1732 15.301 14.4171C15.5452 14.661 15.5455 15.0567 15.3016 15.301C14.6058 15.9979 13.7794 16.5507 12.8697 16.9279C11.96 17.3051 10.9848 17.4993 10 17.4993C9.01519 17.4993 8.04005 17.3051 7.13034 16.9279C6.22085 16.5508 5.39461 15.9981 4.69886 15.3015L4.69836 15.301L2.60938 13.2182V15.4609C2.60938 15.8061 2.32955 16.0859 1.98438 16.0859C1.6392 16.0859 1.35938 15.8061 1.35938 15.4609V11.7109Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
