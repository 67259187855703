import type { Nullable } from 'types/helpers'
import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface OrderBumpTheme {
  copyFromId: Nullable<number>
  createdAt: string
  id: number
  name: string
  prefs: {
    additionalText: {
      additionalTextBackground_color: string
      additionalTextRadius: string
      additionalTextTransparency: string
    }
    alignmentType: string
    animation: {
      animationArrows: string
      animationBorder: string
      animationButton: string
      animationHeader: string
      animationPrice: string
    }
    arrows: {
      arrowsColor: string
      arrowsStyle: string
      arrowsTransparency: string
    }
    background: {
      backgroundBorder: string
      backgroundBorderColor: string
      backgroundColor: string
      backgroundShadow: string
      backgroundTransparency: string
    }
    bundleView: {
      buttonText: string
      buyButtonColor: string
      buyButtonEnabled: boolean
      buyButtonFontColor: string
      buyButtonFontFamily: string
      buyButtonFontSize: string
      buyButtonFontStyle: string
      label: string
      labelBgColor: string
      labelColor: string
      labelEnabled: boolean
      labelFontFamily: string
      labelFontStyle: string
      labelSize: string
      showProductNames: boolean
    }
    button: {
      buttonButtonColor: string
      buttonCheckboxBorder: string
      buttonCheckboxColor: string
      buttonCheckboxStyle: string
      buttonColor: string
      buttonFontFamily: string
      buttonFontSize: string
      buttonFontStyle: string
      buttonRadius: string
      buttonText: string
      buttonViewStyle: string
    }
    header: {
      headerColor: string
      headerFontFamily: string
      headerFontSize: string
      headerFontStyle: string
      headerText: string
    }
    product: {
      descriptionActive: boolean
      descriptionColor: string
      nameActive: boolean
      nameColor: string
      oldPriceActive: boolean
      oldPriceColor: string
      priceActive: boolean
      priceColor: string
      productImageState: string
    }
    viewType: string
  }
  sellerId: number
  sharingId: Nullable<number>
  sharingItemId: Nullable<number>
  updatedAt: string
}

const BASE_URL = (username) => `/shop/${username}/addon_themes`

export const createOrderBumpsThemesApi = ({ GET }: ApiClient) => ({
  fetchItem: (username: string, id: string | number) => GET<Response<OrderBumpTheme>>(`${BASE_URL(username)}/${id}`),
})

export type OrderBumpsThemesApi = ReturnType<typeof createOrderBumpsThemesApi>
