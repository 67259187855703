import React from 'react'
import Popper from '@popperjs/core'
import { UncontrolledTooltip } from 'reactstrap'
import classNames from 'classnames'

/**
 * Show error tooltip/message
 */

interface Props {
  errorText?: React.ReactNode
  id?: string | number
  outlined?: boolean
  placement?: Popper.Placement
  cssClasses?: string
}

export const FieldError = (props: Props) => {
  const { errorText, placement = 'top', id, outlined, cssClasses = '' } = props
  const fieldClasses = classNames('field__error', cssClasses)

  return (
    <div className={fieldClasses}>
      {id ? (
        <UncontrolledTooltip target={String(id)} placement={placement}>
          <div className='field__error'>{errorText}</div>
        </UncontrolledTooltip>
      ) : (
        errorText
      )}

      {!outlined && <i className='fas fa-exclamation-circle' id={String(id)} />}
    </div>
  )
}

FieldError.displayName = 'FieldError'
