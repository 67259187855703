import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpTwoStepOne = (props) => {
  const { height, viewBox, width } = props
  /* eslint-disable */
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <defs>
        <polygon points='5.536 11.28 5.536 -1.13686838e-13 3.28 -1.13686838e-13 5.68434189e-14 0.928 0.592 3.328 2.48 2.896 2.48 11.28' />
        <path d='M8.864,11.392 L8.864,8.864 L4.24,8.864 L6.16,7.52 C7.07733333,6.86933333 7.73866667,6.264 8.144,5.704 C8.54933333,5.144 8.752,4.44266667 8.752,3.6 C8.752,2.53333333 8.37066667,1.66666667 7.608,1 C6.84533333,0.333333333 5.85066667,2.66453526e-14 4.624,2.66453526e-14 C3.58933333,2.66453526e-14 2.73066667,0.192 2.048,0.576 C1.36533333,0.96 0.704,1.568 0.064,2.4 L0.064,2.4 L2.16,4.144 C2.608,3.62133333 3,3.25066667 3.336,3.032 C3.672,2.81333333 4.02133333,2.704 4.384,2.704 C4.75733333,2.704 5.05866667,2.80533333 5.288,3.008 C5.51733333,3.21066667 5.632,3.488 5.632,3.84 C5.632,4.18133333 5.52266667,4.504 5.304,4.808 C5.08533333,5.112 4.71466667,5.46133333 4.192,5.856 L4.192,5.856 L7.03437308e-13,9.056 L7.03437308e-13,11.392 L8.864,11.392 Z' />
        <polygon points='5.536 11.28 5.536 -1.13686838e-13 3.28 -1.13686838e-13 5.68434189e-14 0.928 0.592 3.328 2.48 2.896 2.48 11.28' />
        <path d='M8.864,11.392 L8.864,8.864 L4.24,8.864 L6.16,7.52 C7.07733333,6.86933333 7.73866667,6.264 8.144,5.704 C8.54933333,5.144 8.752,4.44266667 8.752,3.6 C8.752,2.53333333 8.37066667,1.66666667 7.608,1 C6.84533333,0.333333333 5.85066667,2.66453526e-14 4.624,2.66453526e-14 C3.58933333,2.66453526e-14 2.73066667,0.192 2.048,0.576 C1.36533333,0.96 0.704,1.568 0.064,2.4 L0.064,2.4 L2.16,4.144 C2.608,3.62133333 3,3.25066667 3.336,3.032 C3.672,2.81333333 4.02133333,2.704 4.384,2.704 C4.75733333,2.704 5.05866667,2.80533333 5.288,3.008 C5.51733333,3.21066667 5.632,3.488 5.632,3.84 C5.632,4.18133333 5.52266667,4.504 5.304,4.808 C5.08533333,5.112 4.71466667,5.46133333 4.192,5.856 L4.192,5.856 L7.03437308e-13,9.056 L7.03437308e-13,11.392 L8.864,11.392 Z' />
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='249' rx='8' />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -47.000000) '
            x='53'
            y='23'
            width='115'
            height='48'
            rx='4'
          />
          <path
            d='M136.940549,55 C138.052088,55 138.938534,55.8954305 138.938534,57 C138.938534,58.1122704 138.044006,59 136.940549,59 L136.940549,59 L101.997985,59 C100.886447,59 100,58.1045695 100,57 L100,57 L100.00548,56.8497808 C100.081752,55.8088611 100.944685,55 101.997985,55 L101.997985,55 Z M154.00693,45 C155.097692,45 156,45.8954305 156,47 C156,48.1122704 155.107672,49 154.00693,49 L154.00693,49 L101.99307,49 C100.902308,49 100,48.1045695 100,47 L100,47 L100.005467,46.8497808 C100.081551,45.8088611 100.942361,45 101.99307,45 L101.99307,45 Z M154.00693,35 C155.097692,35 156,35.8954305 156,37 C156,38.1122704 155.107672,39 154.00693,39 L154.00693,39 L101.99307,39 C100.902308,39 100,38.1045695 100,37 L100,37 L100.005467,36.8497808 C100.081551,35.8088611 100.942361,35 101.99307,35 L101.99307,35 Z'
            fill='#E5E3E5'
          />
          <rect id='Rectangle-Copy-11' fill='#E5E3E5' x='65' y='35' width='24' height='23.9835004' rx='4' />
          <path
            d='M81.875,52 C82.4963226,52 83,51.4403585 83,50.75 L83,43.25 C83,42.5596415 82.4963226,42 81.875,42 L72.125,42 C71.5036774,42 71,42.5596415 71,43.25 L71,50.75 C71,51.4403585 71.5036774,52 72.125,52 L81.875,52 Z M73.5,46 C72.6715785,46 72,45.3284215 72,44.5 C72,43.6715785 72.6715785,43 73.5,43 C74.3284215,43 75,43.6715785 75,44.5 C75,45.3284215 74.3284215,46 73.5,46 Z M82,50 L73,50 L73,48.817906 L74.6761017,47.0567178 C74.785965,46.9413029 74.964035,46.9413029 75.0738754,47.0567178 L76.375,48.4238747 L79.5511017,45.0865611 C79.660965,44.9711463 79.839035,44.9711463 79.9488754,45.0865611 L82,47.2417807 L82,50 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 183.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -183.000000) '
            x='53'
            y='125'
            width='115'
            height='116'
            rx='4'
          />
          <path
            d='M153.99328,202 C155.099341,202 156,202.895431 156,204 C156,205.11227 155.101561,206 153.99328,206 L153.99328,206 L67.00672,206 C65.900659,206 65,205.104569 65,204 L65,204 L65.0055042,203.849781 C65.0821098,202.808861 65.9488155,202 67.00672,202 L67.00672,202 Z M153.99328,193 C155.099341,193 156,193.895431 156,195 C156,196.11227 155.101561,197 153.99328,197 L153.99328,197 L67.00672,197 C65.900659,197 65,196.104569 65,195 L65,195 L65.0055042,194.849781 C65.0821098,193.808861 65.9488155,193 67.00672,193 L67.00672,193 Z M153.99328,184 C155.099341,184 156,184.895431 156,186 C156,187.11227 155.101561,188 153.99328,188 L153.99328,188 L67.00672,188 C65.900659,188 65,187.104569 65,186 L65,186 L65.0055042,185.849781 C65.0821098,184.808861 65.9488155,184 67.00672,184 L67.00672,184 Z M153.99328,175 C155.099341,175 156,175.895431 156,177 C156,178.11227 155.101561,179 153.99328,179 L153.99328,179 L67.00672,179 C65.900659,179 65,178.104569 65,177 L65,177 L65.0055042,176.849781 C65.0821098,175.808861 65.9488155,175 67.00672,175 L67.00672,175 Z M153.99328,166 C155.099341,166 156,166.895431 156,168 C156,169.11227 155.101561,170 153.99328,170 L153.99328,170 L67.00672,170 C65.900659,170 65,169.104569 65,168 L65,168 L65.0055042,167.849781 C65.0821098,166.808861 65.9488155,166 67.00672,166 L67.00672,166 Z'
            fill='#E5E3E5'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='65' y='213' width='90' height='15.821267' rx='7.91063348' />
          <g transform='translate(64.000000, 88.000000)'>
            <rect
              fill='#E5E3E5'
              transform='translate(21.500000, 5.703620) scale(1, -1) rotate(-180.000000) translate(-21.500000, -5.703620) '
              x='10'
              y='1'
              width='23'
              height='9.40723982'
              rx='3'
            />
          </g>
          <path
            d='M109,83.0018785 C109,80.791702 110.797968,79 113.008925,79 L168,79 L168,108 L109,108 L109,83.0018785 Z'
            fill='#E5E3E5'
            transform='translate(138.500000, 93.500000) scale(1, -1) rotate(-180.000000) translate(-138.500000, -93.500000) '
          />
          <g transform='translate(118.000000, 88.000000)' fill='#FFFFFF'>
            <rect
              transform='translate(24.500000, 5.703620) scale(1, -1) rotate(-180.000000) translate(-24.500000, -5.703620) '
              x='13'
              y='1'
              width='23'
              height='9.40723982'
              rx='3'
            />
          </g>
          <rect
            fill='#B8B9BC'
            fillRule='nonzero'
            transform='translate(110.500000, 98.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -98.000000) '
            x='53'
            y='79'
            width='115'
            height='38'
            rx='4'
          />
          <path
            d='M120.963284,107.831683 C122.332381,107.831683 123.442245,106.72182 123.442245,105.352723 L123.442245,90.4789604 C123.442245,89.1098633 122.332381,88 120.963284,88 L99.4789604,88 C98.1098633,88 97,89.1098633 97,90.4789604 L97,105.352723 C97,106.72182 98.1098633,107.831683 99.4789604,107.831683 L120.963284,107.831683 Z M102.784241,96.6763615 C101.186978,96.6763615 99.8921205,95.3815041 99.8921205,93.784241 C99.8921205,92.1869779 101.186978,90.8921205 102.784241,90.8921205 C104.381504,90.8921205 105.676362,92.1869779 105.676362,93.784241 C105.676362,95.3815041 104.381504,96.6763615 102.784241,96.6763615 Z M120.136964,104.526403 L100.305281,104.526403 L100.305281,102.047442 L103.998605,98.3540678 C104.240691,98.1120323 104.633072,98.1120323 104.875107,98.3540678 L107.742162,101.221122 L114.740767,94.2224671 C114.982853,93.9804315 115.375234,93.9804315 115.617269,94.2224671 L120.136964,98.7421619 L120.136964,104.526403 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
            opacity='0.5'
          />
          <g id='Group-45' transform='translate(64.000000, 134.000000)' fill='#E5E3E5'>
            <rect
              transform='translate(21.500000, 5.703620) scale(1, -1) rotate(-180.000000) translate(-21.500000, -5.703620) '
              x='10'
              y='1'
              width='23'
              height='9.40723982'
              rx='3'
            />
          </g>
          <path
            d='M109,129.001879 C109,126.791702 110.797968,125 113.008925,125 L168,125 L168,154 L109,154 L109,129.001879 Z'
            fill='#E5E3E5'
            transform='translate(138.500000, 139.500000) scale(1, -1) rotate(-180.000000) translate(-138.500000, -139.500000) '
          />
          <g transform='translate(118.000000, 134.000000)' fill='#FFFFFF'>
            <rect
              transform='translate(24.500000, 5.703620) scale(1, -1) rotate(-180.000000) translate(-24.500000, -5.703620) '
              x='13'
              y='1'
              width='23'
              height='9.40723982'
              rx='3'
            />
          </g>
          <g>
            <path
              d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
              fill='#D7DADF'
            />
            <path
              d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
              fill='#FFFFFF'
            />
            <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
          </g>
        </g>
      </g>
    </svg>
  )
  /* eslint-enable */
}

BumpTwoStepOne.displayName = 'BumpTwoStepOne'
BumpTwoStepOne.propTypes = propTypes
BumpTwoStepOne.defaultProps = defaultProps
