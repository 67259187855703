import { get } from 'utils/lodash.utils'

import { COOKIES_CONSENTS_TYPE, COOKIES_CONSENTS_FEATURE_FLAG } from '@elo-kit/constants/cookiesConsents.constants'
import { areCookiesAllowed } from 'utils/cookiesConsents.utils'

import { isWindowEnv } from 'utils/env.utils'

export const useChat = (stores) => {
  const getChatBody = () => {
    if (!isWindowEnv()) return false
    const {
      sellerStore: { isAppActive, item: seller },
    } = stores
    const chatBody = get(seller, 'sellerSetting.apiSettings.chatBody', '')
    const chatCookiesConsentIds = get(seller, 'sellerSetting.apiSettings.chatCookiesConsentIds', [])
    const chatCookiesConsentIdsProtected = Array.isArray(chatCookiesConsentIds)
      ? chatCookiesConsentIds
      : [chatCookiesConsentIds]
    const isCookiesConsentsAppActive = isAppActive(COOKIES_CONSENTS_FEATURE_FLAG)
    const shouldCheckCookies =
      seller?.consentForm !== COOKIES_CONSENTS_TYPE.none && seller?.consentForm !== COOKIES_CONSENTS_TYPE.external
    const cookiesVerified =
      isCookiesConsentsAppActive && shouldCheckCookies
        ? areCookiesAllowed(
            chatCookiesConsentIdsProtected.filter((item) => item !== '' && item !== null),
            undefined,
            seller.username
          )
        : true
    const isChatAllowed = seller?.optionKeys?.includes('chat')

    return cookiesVerified && isChatAllowed && chatBody
  }

  const chatBody = getChatBody()

  return {
    chatBody: chatBody ? chatBody : null,
  }
}
