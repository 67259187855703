import { cleanupFonts } from 'utils/fonts.utils'

import { COLORS } from '../../../constants/general.constants'

const { eloWhite, eloBlue } = COLORS

// TODO: update with using createUseStyles
const cookiesConsentStyles = (theme = {}) => {
  const font = cleanupFonts(theme.font)
  return {
    preview: {
      '&.elo-snackbar': {
        position: 'initial',
        marginBottom: 30,
        pointerEvents: 'none',
      },
    },
    сonsentFloatBtnPreview: {
      '&.elo-btn--floating': {
        position: 'relative',
        marginBottom: 30,
        pointerEvents: 'none',
      },
    },
    simpleConsent: {
      backgroundColor: `${theme.background || '#000000'}b3`,
      color: theme.color || eloWhite,
      fontFamily: font || 'Montserrat Reg',
      zIndex: 9999999,
      '& .elo-snackbar__content a': {
        color: theme.linkColor || eloWhite,
        fontFamily: font || 'Montserrat Reg',
        textDecoration: 'underline',
      },
      '& .elo-btn': {
        color: theme.color || eloWhite,
        fontFamily: font || 'Montserrat Reg',
        backgroundColor: `${theme.linkColor || eloBlue} !important`,
        '&:hover': {
          opacity: 0.9,
        },
        '&:active': {
          opacity: 0.8,
        },
      },
      '& .elo-snackbar__additional-btn': {
        color: `${theme.linkColor || eloBlue} !important`,
        fontFamily: font || 'Montserrat Reg',
        borderColor: `${theme.linkColor || eloBlue} !important`,
        backgroundColor: 'transparent !important',
        '&:hover': {
          opacity: 0.9,
        },
        '&:active': {
          opacity: 0.8,
        },
      },
      '& .elo-snackbar__settings-btn': {
        color: `${theme.linkColor || eloBlue} !important`,
        borderColor: `${theme.linkColor || eloBlue} !important`,
      },
    },
    simpleCategoriesList: {
      display: 'flex',
      marginTop: 10,
      marginBottom: 24,
      flexWrap: 'wrap',
      gap: 24,
      '& a': {
        display: 'inline-block',
        textDecoration: 'underline',
      },
    },
    categoriesListPreview: {
      '@media (max-width: 1500px)': {
        flexWrap: 'wrap',
        marginTop: 25,
        '& .field': {
          minWidth: 240,
          marginBottom: 17,
        },
        '& .checkbox-field__label': {
          marginRight: 20,
        },
      },
    },
    consentFloatBtn: {
      '&.elo-btn': {
        color: theme.linkColor || eloBlue,
        fontFamily: font || 'Montserrat Reg',
        backgroundColor: `${theme.background || '#000000'}b3`,
        lineHeight: 1,
        padding: '8px 15px 6px',
        '&:hover': {
          opacity: 0.9,
        },
        '&:active': {
          opacity: 0.8,
        },
      },
    },
    richConsent: {
      zIndex: 10000,
      fontFamily: font || 'Montserrat Reg',
      '& .elo-modal__title': {
        fontFamily: font || 'Montserrat Reg',
      },
      '& a': {
        color: `${theme.linkColor || eloBlue}`,
        textDecoration: 'none',
        display: 'inline-block',
        marginRight: 24,
        textDecoration: 'underline',
        '&:hover': {
          opacity: 0.8,
          textDecoration: 'none',
        },
        '&:active': {
          opacity: 0.7,
          textDecoration: 'none',
        },
      },
      '& .compact-table a:not(.elo-btn)': {
        color: `${theme.linkColor || eloBlue}`,
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.7,
        },
      },
      '& .checkbox-field__control input:checked ~ .custom-check-mark': {
        borderColor: `${theme.linkColor || eloBlue}`,
        backgroundColor: `${theme.linkColor || eloBlue}`,
      },
      '& .elo-btn.blue': {
        color: theme.color || eloWhite,
        fontFamily: font || 'Montserrat Reg',
        backgroundColor: `${theme.linkColor || eloBlue} !important`,
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.7,
        },
      },
      '& .elo-btn.outline-blue': {
        color: `${theme.linkColor || eloBlue} !important`,
        fontFamily: font || 'Montserrat Reg',
        borderColor: `${theme.linkColor || eloBlue} !important`,
        '&:hover': {
          opacity: 0.8,
          color: `${theme.linkColor || eloBlue} !important`,
          borderColor: `${theme.linkColor || eloBlue} !important`,
        },
        '&:active': {
          opacity: 0.7,
          color: `${theme.linkColor || eloBlue} !important`,
          borderColor: `${theme.linkColor || eloBlue} !important`,
        },
      },
      '& .additional-info-toggle:not(.additional-info-toggle--active)': {
        color: theme.linkColor || eloBlue,
      },
    },
    tooltip: {
      fontFamily: font || 'Montserrat Reg',
      '& a': {
        color: `${theme.linkColor || eloBlue}`,
        textDecoration: 'none',
        '&:active': {
          opacity: 0.8,
          textDecoration: 'none',
        },
      },
    },
  }
}

export default cookiesConsentStyles
