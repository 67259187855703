import { capitalize } from 'utils/nameStyle.utils'

export enum POSITIONS {
  bottom = 'bottom',
  center = 'center',
  left = 'left',
  right = 'right',
  top = 'top',
}

export const COLORS = {
  eloBlack: '#4A4A4A',
  eloBlue: '#1E84D7',
  eloBlueDark: '#2B90D9',
  eloGreen: '#39AA75',
  eloGreenMain: '#17df87',
  eloGrey: '#71747a',
  eloGrey10: '#F2F1F1',
  eloGrey20: '#EFEDEF',
  eloGrey30: '#ECECEC',
  eloGrey40: '#CDCDCD',
  eloGrey50: '#B7B7B7',
  eloGrey60: '#9e9e9e',
  eloGrey70: '#787878',
  eloGrey80: '#717171',
  eloGrey90: '#4a4a4a',
  eloOrange: '#FFB000',
  eloDarkOrange: '#ff9604',
  eloLightGreen: '#0fae63',
  eloRed: '#FF2800',
  eloRed40: '#f43555',
  eloWhite: '#FFFFFF',
  eloAccent: '#2BFF99',
  eloPrimary: '#21282E',
  eloOceanBlue: '#daebef',
  eloDeepBlack: '#000000',
  eloNeutralBlack: '#000004',
  eloGrey100: '#666666',
}

export const SCREEN_SIZE = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
}

export const PADDING_UNITS = {
  px: 'px',
  percentage: '%',
}

export const ELOPAGE_CABINETS = {
  cabinet: 'cabinet',
  admin: 'admin',
  shop: 'shop',
  payer: 'payer',
}

export const YES = 'yes'
export const NO = 'no'
export const COMPANY_NAME = 'ablefy'
export const SLASH_SEPARATOR = '/'
export const NO_DATA = '-'
export const ZERO_DATA = 0
export const ALL = 'all'
export const INFINITY = '∞'
export const DEFAULT_DURATION = '00:00'

export const B_BASE = 1024
export const KB_BASE = B_BASE ** 2
export const MB_BASE = B_BASE ** 3
export const GB_BASE = B_BASE ** 4
export const TB_BASE = B_BASE ** 5

export const SIZES = {
  extraSmall: 'x-small',
  small: 'small',
  medium: 'medium',
  large: 'large',
  responsive: 'responsive',
  extraLarge: 'x-large',
}

export const THEME_TYPE = {
  shop: 'ShopTheme',
  membership: 'MembershipTheme',
  course: 'course',
  funnel: 'funnel',
}

export const getPeriodOptions = () => [
  {
    value: 'd',
    label: capitalize(I18n.t('react.shared.common.day')),
  },
  {
    value: 'm',
    label: capitalize(I18n.t('react.shared.common.month')),
  },
  {
    value: 'y',
    label: capitalize(I18n.t('react.shared.common.year')),
  },
]

export const getPeriodOptionsPlural = () => [
  {
    value: 'd',
    label: capitalize(I18n.t('react.shared.common.days')),
  },
  {
    value: 'm',
    label: capitalize(I18n.t('react.shared.common.months')),
  },
  {
    value: 'y',
    label: capitalize(I18n.t('react.shared.common.years')),
  },
]

export const getSmallPeriodOptions = () => [
  {
    value: 'd',
    label: capitalize(I18n.t('react.shared.common.day')),
  },
  {
    value: 'w',
    label: capitalize(I18n.t('react.shared.common.week')),
  },
  {
    value: 'm',
    label: capitalize(I18n.t('react.shared.common.month')),
  },
]

export const getSmallPeriodOptionsPlural = () => [
  {
    value: 'd',
    label: capitalize(I18n.t('react.shared.common.days')),
  },
  {
    value: 'w',
    label: capitalize(I18n.t('react.shared.common.weeks')),
  },
  {
    value: 'm',
    label: capitalize(I18n.t('react.shared.common.months')),
  },
]
