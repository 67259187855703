import React from 'react'
import classNames from 'classnames'

import { AnyCallback } from 'types/helpers'

import { EloButton } from '../../elo-button'

import './elo-snackbar.scss'

/**
 * EloSnackbar component
 */

interface Props {
  children?: any
  action?: AnyCallback
  btnText?: any
  className?: string
  handleSettings?: AnyCallback
  additionalAction?: AnyCallback
  additionalBtnText?: any
  settingsBtnText?: string
}

export const EloSnackbar = (props: Props) => {
  const { children, action, btnText, settingsBtnText, className, handleSettings, additionalAction, additionalBtnText } =
    props

  const containerClasses = classNames(className, 'elo-snackbar')

  return (
    <div className={containerClasses}>
      <div className='elo-snackbar__content'>{children}</div>
      <div className='elo-snackbar__buttons'>
        {handleSettings && (
          <EloButton
            dataTestId='consent-accept-customize'
            onClick={handleSettings}
            className='elo-snackbar__main-btn'
            orange
          >
            {settingsBtnText}
          </EloButton>
        )}
        {additionalAction && (
          <EloButton
            dataTestId='consent-accept-deny'
            onClick={additionalAction}
            className='elo-snackbar__main-btn'
            orange
          >
            {additionalBtnText}
          </EloButton>
        )}
        <EloButton dataTestId='consent-accept-all' onClick={action} className='elo-snackbar__main-btn' orange>
          {btnText}
        </EloButton>
      </div>
    </div>
  )
}

EloSnackbar.displayName = 'EloSnackbar'
