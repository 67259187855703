import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface Props {
  to: string
  children: React.ReactNode
  onClick?: () => void
  className?: string
}

export const LinkComponent: React.FC<Props> = ({ to, onClick, children, className }) => (
  <Link href={to} onClick={onClick} className={className ? className : ''}>
    {children}
  </Link>
)

interface NextRouter<Params> {
  historyPush: (url: string) => void
  historyReplace: (url: string) => void
  historyGoBack: () => void
  pathname: string
  LinkComponent: React.FC<{ to: string; children: React.ReactNode }>
  params: Params
}

export function useNextRouter<Params>(): NextRouter<Params> {
  const router = useRouter()
  return {
    historyPush: router.push,
    historyGoBack: router.back,
    historyReplace: router.replace,
    LinkComponent: LinkComponent,
    pathname: router.pathname,
    // @ts-ignore
    params: router.query,
  }
}
