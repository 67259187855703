import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPublisherIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M6.03566 10.4531C7.66293 10.4531 8.98209 9.10648 8.98209 7.44531C8.98209 5.78414 7.66293 4.4375 6.03566 4.4375C4.40839 4.4375 3.08923 5.78414 3.08923 7.44531C3.08923 9.10648 4.40839 10.4531 6.03566 10.4531Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.3392 5.64062H18.4107'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.3392 9.25H18.4107'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.1072 12.8594H18.4107'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.46875 14.0627C1.72839 13.0275 2.31815 12.1099 3.14504 11.4547C3.97194 10.7995 4.98894 10.4438 6.03571 10.4438C7.08249 10.4438 8.09949 10.7995 8.92638 11.4547C9.75328 12.1099 10.343 13.0275 10.6027 14.0627'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
