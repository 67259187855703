import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPlusIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 1.625C4.9269 1.625 1.625 4.9269 1.625 9C1.625 13.0731 4.9269 16.375 9 16.375C13.0731 16.375 16.375 13.0731 16.375 9C16.375 4.9269 13.0731 1.625 9 1.625ZM0.375 9C0.375 4.23654 4.23654 0.375 9 0.375C13.7635 0.375 17.625 4.23654 17.625 9C17.625 13.7635 13.7635 17.625 9 17.625C4.23654 17.625 0.375 13.7635 0.375 9ZM5.38583 8.91782C5.38583 8.57264 5.66565 8.29282 6.01083 8.29282H8.43069V6.01162C8.43069 5.66644 8.71052 5.38662 9.05569 5.38662C9.40087 5.38662 9.68069 5.66644 9.68069 6.01162V8.29282H12.1012C12.4464 8.29282 12.7262 8.57264 12.7262 8.91782C12.7262 9.26299 12.4464 9.54282 12.1012 9.54282H9.68069V12.102C9.68069 12.4472 9.40087 12.727 9.05569 12.727C8.71052 12.727 8.43069 12.4472 8.43069 12.102V9.54282H6.01083C5.66565 9.54282 5.38583 9.26299 5.38583 8.91782Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
