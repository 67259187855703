export const ORDER_BUMPS_PREVIEW_TYPES = {
  desktop: 'desktop',
  mobile: 'mobile',
}

export const ORDER_BUMPS_VIEW_FIELD_KEY = 'viewType'

export const ORDER_BUMPS_VIEW_TYPES_KEYS = {
  first: 'first',
  sec: 'sec',
  third: 'third',
  fourth: 'fourth',
  fifth: 'fifth',
  six: 'six',
  seven: 'seven',
}

export const ORDER_BUMPS_OLD_VIEW_KEY = 'bundleView'
