import { notify } from 'libs/common/notify'

import {
  StripeResponse,
  StripeClient,
  StripeElement,
  StripeElementsParams,
  StripeElementsIndentParams,
  KlarnaFormSettings,
  StripeMessageParams,
} from 'shop/types/stripe'
import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { hasWindow } from './browser.utils'

type HandleStripe = Promise<StripeResponse> | undefined

export const getStripeStyles = (paymentForm = '') => {
  switch (paymentForm) {
    case PAYMENT_FORMS.p24:
    case PAYMENT_FORMS.ideal:
    case PAYMENT_FORMS.klarna:
      return {
        base: {
          backgroundColor: '#fff',
          padding: '15px 13px',
          color: '#4a4a4a',
          lineHeight: '16px',
          fontFamily: 'Montserrat Reg, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '13px',
          '::placeholder': {
            color: '#4a4a4a',
          },
          ':hover': {
            backgroundColor: '#E4F0FA',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }

    case PAYMENT_FORMS.card:
    default:
      return {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
  }
}

export const getNewStripeStyles = (paymentForm = '') => {
  switch (paymentForm) {
    case PAYMENT_FORMS.p24:
    case PAYMENT_FORMS.ideal:
    case PAYMENT_FORMS.klarna:
      return {
        base: {
          backgroundColor: '#fff',
          padding: '16px',
          fontFamily: 'Inter, sans-serif',
          fontSize: '14px',
          fontWeight: 400,

          ':hover': {
            backgroundColor: '#f3f5f8',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }

    case PAYMENT_FORMS.card:
    default:
      return {
        base: {
          color: '#000004',
          fontFamily: 'Inter, sans-serif',
          fontSize: '14px',
          iconColor: '#485056',

          '::placeholder': {
            color: '#8f9295',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
  }
}

export const isStripeInjected = () => hasWindow && window.Stripe

export const injectStripeScript = (onLoadHandler = () => {}): void => {
  /* Do nothing in case it's already injected */
  if (isStripeInjected()) {
    return
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://js.stripe.com/v3/'
  document.body.appendChild(script)

  script.onload = () => {
    onLoadHandler()
  }
}

export const createStripeClient = (stripePubKey = '') => {
  if (hasWindow && (window as any).Stripe) {
    return (window as any).Stripe(stripePubKey, { betas: ['klarna_pm_beta_1'] })
  }
}

export const createStripeElements = (
  stripeClient?: {
    elements: (localeObj: { locale: string }) => object
  },
  params?: StripeElementsParams | StripeElementsIndentParams | StripeMessageParams
): object | void => {
  if (stripeClient) {
    return stripeClient.elements({
      locale: I18n.locale,
      ...(params ? params : {}),
    })
  }
}

export const createStripeP24 = (
  stripeElements?: {
    create: (provider: string, style: object) => object
  },
  isNewPaymentMethodUI?: boolean
): object | void => {
  if (stripeElements) {
    return stripeElements.create('p24Bank', {
      style: isNewPaymentMethodUI ? getNewStripeStyles(PAYMENT_FORMS.p24) : getStripeStyles(PAYMENT_FORMS.p24),
    })
  }
}

export const createStripeCard = (
  stripeElements?: {
    create: (card: string, style: object) => object
  },
  isNewPaymentMethodUI?: boolean
): object | void => {
  if (stripeElements) {
    return stripeElements.create('card', {
      style: isNewPaymentMethodUI ? getNewStripeStyles(PAYMENT_FORMS.card) : getStripeStyles(PAYMENT_FORMS.card),
    })
  }
}

export const createStripeKlarna = (
  stripeElements: {
    create: (provider: string, options: KlarnaFormSettings) => object
  },
  options: KlarnaFormSettings
): object | void => {
  if (stripeElements) {
    return stripeElements.create('payment', {
      ...options,
    })
  }
}

export const createStripeIdeal = (
  stripeElements?: {
    create: (provider: string, style: object) => object
  },
  isNewPaymentMethodUI?: boolean
): object | void => {
  if (stripeElements) {
    return stripeElements.create('idealBank', {
      style: isNewPaymentMethodUI ? getNewStripeStyles(PAYMENT_FORMS.ideal) : getStripeStyles(PAYMENT_FORMS.ideal),
    })
  }
}

export const createStripeSepa = (
  stripeElements?: {
    create: (card: string, style: object) => object
  },
  options?
) => {
  if (stripeElements) {
    return stripeElements.create('payment', options)
  }
}

export const handleSofortTransaction = (
  stripeClient?: {
    confirmSofortPayment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  },
  redirectLink?: string,
  clientSecret?: string,
  payer = {
    countryCode: '',
    billingDetails: {},
  }
): HandleStripe => {
  if (stripeClient && clientSecret) {
    return stripeClient.confirmSofortPayment(clientSecret, {
      payment_method: {
        sofort: {
          country: (payer.countryCode || '').toUpperCase(),
        },
        ...payer.billingDetails,
      },
      return_url: redirectLink,
    })
  }
}

export const handleSepaError = (error) => {
  const messageContainer = document.querySelector('#sepa-error-message')
  messageContainer.textContent = error.message
}

export const handleP24Transaction = (
  stripeClient?: {
    confirmP24Payment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  },
  stripeP24?: StripeElement | string,
  redirectLink?: string,
  clientSecret?: string,
  payer = {
    fullName: '',
    email: '',
    tosShowAndAccepted: false,
  }
): HandleStripe => {
  if (stripeClient && clientSecret && stripeP24) {
    return stripeClient.confirmP24Payment(clientSecret, {
      payment_method: {
        p24: stripeP24,
        billing_details: {
          name: payer.fullName,
          email: payer.email,
        },
      },
      payment_method_options: {
        p24: {
          // In order to be able to pass the `tos_shown_and_accepted` parameter, you must
          // ensure that the P24 regulations and information obligation consent
          // text is clearly visible to the customer. See
          // https://stripe.com/docs/payments/p24/accept-a-payment#requirements
          // for directions.
          tos_shown_and_accepted: payer.tosShowAndAccepted,
        },
      },
      return_url: redirectLink,
    })
  }
}

export const handleIdealTransaction = (
  stripeClient?: {
    confirmIdealPayment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  },
  idealBank?: StripeElement | string,
  redirectLink?: string,
  clientSecret?: string,
  payer = {
    fullName: '',
    email: '',
  }
): HandleStripe => {
  if (stripeClient && clientSecret && idealBank) {
    return stripeClient.confirmIdealPayment(clientSecret, {
      payment_method: {
        ideal: idealBank,
        billing_details: {
          name: payer.fullName,
          email: payer.email,
        },
      },
      return_url: redirectLink,
    })
  }
}
export const getKlarnaFormSettings = (): KlarnaFormSettings => ({
  fields: {
    billingDetails: 'never',
  },
})

export const handleStripeCardTransaction = (
  stripeClient: {
    confirmCardPayment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  },
  paymentMethodUserData: { id?: string | number; stripeCard?: object },
  clientSecret: string,
  userData?: {
    fullName?: string
    email?: string
    tosShowAndAccepted?: boolean
  },
  redirectLink?: string
): HandleStripe => {
  if (stripeClient && clientSecret) {
    const billingDetails: { name?: string; email?: string } = {}
    if (userData?.fullName) {
      billingDetails.name = userData?.fullName
    }
    if (userData?.email) {
      billingDetails.email = userData?.email
    }

    const paymentMethodData = paymentMethodUserData.id || {
      card: paymentMethodUserData.stripeCard,
      billing_details: billingDetails,
    }
    return stripeClient.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodData,
      return_url: redirectLink,
    })
  }
}

export const handleTransactionResponse = async (
  result: StripeResponse,
  handleSuccess: () => void,
  handleError: () => void,
  stripeClient?: StripeClient,
  clientSecret?: string
) => {
  const { error: confirmError, paymentIntent } = result

  if (confirmError) {
    notify('error', confirmError.message)
    handleError()
  } else if (paymentIntent?.status === 'requires_action' || paymentIntent?.status === 'requires_source_action') {
    // Stripe.js handles the rest of the payment flow.
    const { error } = await stripeClient.confirmCardPayment(clientSecret)

    if (error) {
      notify('error', error.message)
      handleError()
    } else {
      handleSuccess()
    }
  } else {
    handleSuccess()
  }
}

export const handleStripeCardRecuringPayment = (
  clientSecret: string,
  paymentMethod: number,
  stripePubKey: string,
  handleSuccess: () => void,
  handleError: () => void
): void => {
  const handleTransaction = (): void => {
    const stripeClient = createStripeClient(stripePubKey)
    handleStripeCardTransaction(stripeClient, { id: paymentMethod }, clientSecret).then((result: StripeResponse) =>
      handleTransactionResponse(result, handleSuccess, handleError, stripeClient, clientSecret)
    )
  }

  if (!isStripeInjected()) {
    injectStripeScript(handleTransaction)
  } else {
    handleTransaction()
  }
}

export const handleStripeSofortRecuringPayment = (
  clientSecret: string,
  stripePubKey: string,
  redirectLink: string,
  payerInfo: { countryCode: string; billingDetails: object } | undefined,
  handleSuccess: () => void,
  handleError: () => void
): void => {
  const handleTransaction = (): void => {
    const stripeClient = createStripeClient(stripePubKey)
    handleSofortTransaction(stripeClient, redirectLink, clientSecret, payerInfo).then((result: StripeResponse) =>
      handleTransactionResponse(result, handleSuccess, handleError)
    )
  }

  if (!isStripeInjected()) {
    injectStripeScript(handleTransaction)
  } else {
    handleTransaction()
  }
}

export const handleStripeDigitalTransaction = (
  stripeClient: {
    confirmCardPayment: (
      clientSecretString: string,
      paymentMethodData?: { payment_method: string },
      extData?: { handleActions: boolean }
    ) => Promise<StripeResponse>
  },
  paymentRequest: StripeElement,
  clientSecret: string,
  handleRedirect: () => void,
  handleClose: () => void
): void => {
  if (stripeClient && clientSecret) {
    paymentRequest.on('paymentmethod', async (ev) => {
      const { paymentIntent, error: confirmError } = await stripeClient.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      )

      if (confirmError) {
        notify('error', confirmError.message)
      } else if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_source_action') {
        // Stripe.js handles the rest of the payment flow.
        const { error } = await stripeClient.confirmCardPayment(clientSecret)
        if (error) {
          notify('error', error.message)
        } else {
          handleRedirect()
        }
      } else {
        handleRedirect()
      }
    })
    if (handleClose) {
      paymentRequest.on('cancel', handleClose)
    }
  }
}

export const convertPriceForStripe = (price: number): number => Math.round((Number(price) || 0) * 100)
