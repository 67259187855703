import React from 'react'
import withStyles from 'react-jss'
import { inject, observer } from 'mobx-react'
import { findIndex } from 'utils/lodash.utils'
import moment from 'moment'

import { POSITIONS } from '@elo-kit/constants/general.constants'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { DEBOUNCE_ACTIONS } from 'constants/general.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { WHITE_SPACES_REGEX } from 'constants/regex.constants'
import { COUNTRIES_LIST_IBAN } from 'constants/paymentSettingShared.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { normalizeShopLink } from 'utils/link.utils'
import type { CabinetRootStore } from '../containers/cabinet/stores/cabinetRoot.store'
import type { AdminRootStore } from '../containers/admin/stores/adminRoot.store'
import type { EloPublisherRootStore } from '../containers/elo-publisher/stores/eloPublisherRoot.store'
import type { PayerRootStore } from '../containers/payer/stores/payerRoot.store'
import type { PublisherRootStore } from '../containers/publisher/stores/publisherRoot.store'
import type { SalesTeamMemberRootStore } from '../containers/sales-team-member/stores/salesTeamMemberRoot.store'
import type { TeamMemberRootStore } from '../containers/team-member/stores/teamMemberRoot.store'
// eslint-disable-next-line import/no-cycle
import { isString, isNumber, isBoolean } from './validators.utils'
import { capitalize } from './nameStyle.utils'
import { isWindows } from './browsers.utils'

export * from 'utils//helpersShared.utils'

type Seller = {
  protocol?: string
  host?: string
  username?: string
}

// For test purpouse only will be removed before merge
export const getShopProductLink = (seller: Seller, productSlug: string, slug?: string) =>
  normalizeShopLink(`${seller.protocol}://${seller.host}/s/${seller.username}/${productSlug}${slug ? `/${slug}` : ''}`)

export const getManagePageLink = (seller: Seller, token: string) =>
  normalizeShopLink(`${seller.protocol}://${seller.host}/s/${seller.username}/payment/${token}/manage`)

export const getDomainPayerLink = (domainName, username) => {
  const webProtocol = getElopageConfig('webProtocol')
  const webHost = getElopageConfig('webHost')

  return `${webProtocol}://${domainName || webHost}/payer/s/${username}`
}

export const getLiveShoppingEventLink = (seller, slug) =>
  `${seller.protocol}://${seller.host}/v/${seller.username}/${slug ? `${slug}` : ''}`

export const debounceEventAsync = (fn, time) => {
  let timeout
  return function (...args) {
    // @ts-ignore
    const functionCall = () => fn.apply(this, args)

    return new Promise((resolve) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => resolve(functionCall()), time)
    })
  }
}

export const debounce = (fn, time) => {
  let timeout
  return function (action, ...rest) {
    // @ts-ignore
    const functionCall = () => fn.call(this, action, ...rest)
    clearTimeout(timeout)
    if (action !== DEBOUNCE_ACTIONS.clear) {
      timeout = setTimeout(functionCall, time)
    }
  }
}

const arrayMoveMutate = (array, from, to, index = 0) => {
  array.splice(to < 0 ? array.length + to : to - index, 0, array.splice(from - index, 1)[0])
}

export const arrayMove = (array: any[], from: number, to: number, index?: number) => {
  array = array.slice()
  arrayMoveMutate(array, from, to, index)
  return array
}

export const getPosition = (position, length, bottomOffset) => {
  let resultPosition = 0
  switch (position) {
    case POSITIONS.bottom:
    case POSITIONS.right:
      resultPosition = 100 - length
      break
    case POSITIONS.center:
      resultPosition = 50 - length / 2
      break
    default:
      null
  }

  if (bottomOffset && position === POSITIONS.bottom) {
    resultPosition -= bottomOffset
  }

  return `${resultPosition < 0 ? 0 : resultPosition}%`
}

export const getObservableMapKeys = (mapList) => {
  const keysArray = []
  mapList.forEach((value, key) => keysArray.push(key))

  return keysArray
}

export const convertMapToObject = (map = new Map()) =>
  Array.from(map).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value,
    }),
    {}
  )

export const parseStringToBool = (string) => {
  switch (string) {
    case 'true':
    case '1':
      return true
    case 'false':
    case '0':
      return false
    default:
      return !!string
  }
}

export const trimStart = (string) => {
  if (string === null) return string
  return (string || '').replace(/^\s+/g, '')
}

const compose =
  (...callbacks) =>
  (value) =>
    callbacks.reduceRight((result, callback) => callback(result), value)

const removeDot = (string) => string.replace(/\./g, ' ')

const capitalizeLettersAfterDot = (string) => string.replace(/\.([a-z])/g, (letter) => letter.toUpperCase())

export const transformFromSnakeCaseToPlainText = compose(removeDot, capitalizeLettersAfterDot, capitalize)

export const mapToIdsArray = (array = []) => array.map((item) => item?.id || item)

export const getLastWord = (string = '') => string.split(/\b/).pop()

export const replaceVariables = (string = '', values = {}) =>
  (string || '').replace(/\%{(\w+)}/g, (_, valueName) => (values[valueName] ? values[valueName] : ''))

export const removeEmptyProps = (obj) => JSON.parse(JSON.stringify(obj))

export const isSingleValue = (item = {}) => Object.keys(item).length === 1

const createSorter =
  (condition: boolean, additionalKey?: string, additionalCondition?: boolean) =>
  (list = [], key = 'position') => {
    const accumulator = {
      listToSort: [],
      listToKeep: [],
    }
    const { listToSort, listToKeep } = list.reduce((result, item) => {
      const value = item[key]

      const isValueSortable = isNumber(value) || isString(value) || isBoolean(value)

      const arrayToPush = isValueSortable ? 'listToSort' : 'listToKeep'
      return {
        ...result,
        [arrayToPush]: [...result[arrayToPush], item],
      }
    }, accumulator)

    return [
      ...listToSort.sort((a, b) => {
        const getValueForSorting = (value) => {
          const prepareDate = moment(value, DATE_FORMATS.DDMMYYYYHHmm).format(DATE_FORMATS.MMDDYYYY)

          if (isBoolean(value) || !isNaN(value)) {
            return Number(value)
          }
          if (moment(prepareDate).isValid()) {
            return Date.parse(prepareDate)
          }
          return value
        }

        const getSortingPredicate = (sortCondition, sortKey) => {
          const aValue = getValueForSorting(a[sortKey])
          const bValue = getValueForSorting(b[sortKey])

          if (isString(aValue)) {
            if (!isString(aValue) || !isString(bValue)) {
              return -1
            }
            const toLowerCaseAValue = aValue.toLowerCase()
            const toLowerCaseBValue = bValue.toLowerCase()

            if (toLowerCaseAValue > toLowerCaseBValue) {
              return sortCondition ? -1 : 1
            }

            if (toLowerCaseBValue > toLowerCaseAValue) {
              return sortCondition ? 1 : -1
            }
            return 0
          }

          return sortCondition ? aValue - bValue : bValue - aValue
        }

        const sort = getSortingPredicate(condition, key)

        if (additionalKey) {
          const additionalSort = getSortingPredicate(additionalCondition, additionalKey)

          return sort || additionalSort
        }

        return sort
      }),
      ...listToKeep,
    ]
  }

export const ascendingSort = createSorter(true)
export const descendingSort = createSorter(false)
export const ascendingSortWithSecondDesc = createSorter(true, 'id', false)

export const truncate = (source, size) => (source.length > size ? `${source.slice(0, size - 1)}…` : source)

export const booleanLabel = (value, yesComponent = I18n.t('react.common.yes')) => (value ? yesComponent : '-')

export const sortItemsByPositions = (keys, list) =>
  keys.map((key) => list.find(({ id }) => String(id) === String(key))).filter(Boolean)

export const areUsernamesEqual = (username1, username2) =>
  (username1 || '').toLowerCase() === (username2 || '').toLowerCase()

export const getArrayItemById = (array, id) => array.find((item) => item.id === id)

export const randomWithMinMax = (min, max) => Math.floor(Math.random() * (max - min)) + min

export const createRange = ({ start = 0, end = 10, step = 1, length = 0 } = {}) =>
  Array.from({ length: length || (end - start) / step + 1 })
    .fill(start) // @ts-ignore
    .map((item, index) => item + index * step)

export const getArrayMaxByKey = (array = [], key = 'value') =>
  Math.max(0, ...array.map(({ [key]: value }) => Math.abs(Number(value))))

export const getItemById = (array, id) => (array ? array.find((item) => String(item.id) === String(id)) || {} : {})

export const useCustomStyles = (Component) => (props) => {
  const { styles, ...componentProps } = props
  const StyledComponent = withStyles(styles)(Component)
  return <StyledComponent {...componentProps} />
}

export const amountCrossed = ({ amount, isCrossed }) =>
  `<span class='${
    isCrossed && (isWindows ? 'price-crossed price-crossed--windows' : 'price-crossed')
  }'>${amount}</span>`

export const cropFloatNumberWithoutRounding = (number, decimal = 2) => {
  const [integer, fractional] = String(number).split('.')
  return fractional ? Number([integer, fractional.slice(0, decimal)].join('.')) : number
}

export const filterObjectByArrayOfValues = (object, arrayValues) =>
  Object.keys(object).filter((key) => !arrayValues.some((item) => item === key))

type MapStores =
  | Array<keyof CabinetRootStore>
  | Array<keyof AdminRootStore>
  | Array<keyof EloPublisherRootStore>
  | Array<keyof PayerRootStore>
  | Array<keyof PublisherRootStore>
  | Array<keyof SalesTeamMemberRootStore>
  | Array<keyof TeamMemberRootStore>

export const withStores = (
  stores: MapStores,
  Component: any // @ts-ignore
): any => inject(...stores)(observer(Component))

/**
 * Runs multiple methods passing the argument and result along to each of the methods composed
 * @param {Array<Function>} hocs
 * @returns {Component} Component
 */
export const composeHocs =
  (...hocs) =>
  (Component) =>
    hocs.reduce((component, HOC) => HOC(component), Component)
// @ts-ignore
export const getArrayFromString = (array = []) => (Array.isArray(array) ? array : array.split(',').filter(Boolean))

export const areMapsEqual = (map1, map2) => {
  if (map1.size !== map2.size) {
    return false
  }

  for (const [key, val] of map1) {
    const testVal = map2.get(key)
    const keysDoesntEqual = JSON.stringify(testVal) !== JSON.stringify(val)
    if (keysDoesntEqual || (testVal === undefined && !map2.has(key))) {
      return false
    }
  }

  return true
}

export const flatArray = (arr = [], depth = 1) => {
  // Recursively reduce sub-arrays to the specified depth
  // If depth is 0, return the array as-is
  if (depth < 1) {
    return arr.slice()
  }

  // Otherwise, concatenate into the parent array
  return arr.reduce(function (acc, val) {
    return acc.concat(Array.isArray(val) ? flatArray(val, depth - 1) : val)
  }, [])
}

/**
 * Sort array of object by key in alphabetic order
 * @param a
 * @param b
 * @param sortKey
 * @returns {number}
 */
export const sortFunction = (a, b, sortKey) => {
  const firstElement = (a[sortKey] || '').toLowerCase()
  const secondElement = (b[sortKey] || '').toLowerCase()

  if (firstElement < secondElement) {
    return -1
  }
  if (firstElement > secondElement) {
    return 1
  }

  // firstElement must be equal to secondElement
  return 0
}

export const getHeaderKeys = (tableHead = []) =>
  tableHead.reduce((result, item) => {
    const { csvKey, label, sortingKey } = item || {}

    if (sortingKey === undefined && csvKey === undefined) {
      return result
    }

    return [...result, label]
  }, [])

export const markOrUnMarkDestroy = (list = [], id, _destroy, key = 'id') =>
  list.map((item) => {
    if (String(item[key]) === String(id)) {
      return {
        ...item,
        _destroy,
      }
    }
    return item
  })

export const getActiveTabs = (list, hideData = {}) => (list || []).filter((item) => !hideData[item.key])

export const arrayUniqueByKey = (array: Array<any>, key = 'id') => [
  // @ts-ignore
  ...new Map(array.map((item) => [item[key], item])).values(),
]

export const findAndReplace = (arr, id, obj, elmIndex) => {
  const index = elmIndex === 0 || elmIndex ? elmIndex : findIndex(arr, { id })
  arr.splice(index, 1, obj)
}

export const hasWhiteSpace = (str) => WHITE_SPACES_REGEX.test(str)

export const checkCountryName = (country) => COUNTRIES_LIST_IBAN.find((item) => item.value === country).label

export const getValueFromComplexName = (initialValue, complexName) =>
  complexName.reduce((value, path, index) => {
    if (isEmpty(initialValue) || !value) {
      return false
    }
    if (!index) {
      return initialValue[path]
    } else {
      return value[path]
    }
  }, initialValue)

export const lazyTitle = (text, value) => `${text} ${value ? `(${value})` : ''}`
