export const EMBED_TYPES = {
  modal: 'modal',
  onPage: 'on_page',
}

export const FORM_KEYS = {
  plainLink: 'plain_link',
  textLink: 'text_link',
  button: 'button',
  banner: 'banner',
  badge: 'badge',
  slider: 'slider',
  embedForm: 'embed_form',
  embedPaymentPage: 'embed_payment_page',
}

export const FORM_TRANSLATIONS = {
  plainLink: I18n.t('react.shared.plain_link'),
  textLink: I18n.t('react.shared.text_link'),
  button: I18n.t('react.shared.button.button'),
  banner: I18n.t('react.shared.banner'),
  badge: I18n.t('react.shared.badge'),
  slider: I18n.t('react.shared.slider'),
  embedForm: I18n.t('react.shared.embed_form_for_publishers'),
  embedPaymentPage: I18n.t('react.shared.embed_payment_page'),
}

export const PURPOSES_KEYS = {
  shop: 'shop',
  product: 'product',
  courseLesson: 'course_lesson',
  loginPage: 'login_page',
  customLink: 'custom_link',
}

export const HIDE_VALUE = 'none'

export const REDIRECTION_KEYS = {
  productPage: 'product_page',
  paymentPage: 'payment_page',
}
