type AnyObject = { [key: string]: any }

export const flattenObject = (obj: AnyObject, prefix = ''): AnyObject =>
  Object.entries(obj).reduce((acc: AnyObject, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, newKey))
    } else {
      acc[newKey] = value
    }
    return acc
  }, {})
