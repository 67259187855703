import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDeleteIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M18 4.8125H2' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 8V14' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.125 8V14' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M16 4.8125V17.1125C16 17.2982 15.9298 17.4762 15.8047 17.6075C15.6797 17.7388 15.5101 17.8125 15.3333 17.8125H4.66667C4.48986 17.8125 4.32029 17.7388 4.19526 17.6075C4.07024 17.4762 4 17.2982 4 17.1125V4.8125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14 4.5V3.5C14 3.10218 13.8314 2.72064 13.5314 2.43934C13.2313 2.15804 12.8243 2 12.4 2H7.6C7.17565 2 6.76869 2.15804 6.46863 2.43934C6.16857 2.72064 6 3.10218 6 3.5V4.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
