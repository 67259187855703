import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '@elo-ui/components/icons/regular/_base'

export const EloArrowElbowDownRight: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M16.6922 14.1922L12.9422 17.9422C12.8249 18.0595 12.6659 18.1253 12.5 18.1253C12.3341 18.1253 12.1751 18.0595 12.0578 17.9422C11.9405 17.8249 11.8747 17.6659 11.8747 17.5C11.8747 17.3341 11.9405 17.1751 12.0578 17.0578L14.7414 14.375H5C4.83424 14.375 4.67527 14.3092 4.55806 14.1919C4.44085 14.0747 4.375 13.9158 4.375 13.75V2.5C4.375 2.33424 4.44085 2.17527 4.55806 2.05806C4.67527 1.94085 4.83424 1.875 5 1.875C5.16576 1.875 5.32473 1.94085 5.44194 2.05806C5.55915 2.17527 5.625 2.33424 5.625 2.5V13.125H14.7414L12.0578 10.4422C11.9405 10.3249 11.8747 10.1659 11.8747 10C11.8747 9.83415 11.9405 9.67509 12.0578 9.55781C12.1751 9.44054 12.3341 9.37465 12.5 9.37465C12.6659 9.37465 12.8249 9.44054 12.9422 9.55781L16.6922 13.3078C16.7503 13.3659 16.7964 13.4348 16.8279 13.5107C16.8593 13.5865 16.8755 13.6679 16.8755 13.75C16.8755 13.8321 16.8593 13.9135 16.8279 13.9893C16.7964 14.0652 16.7503 14.1341 16.6922 14.1922Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
