import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const cookiesConsentRichStyles = (theme = {}) => {
  const { eloBlue, eloWhite } = COLORS
  const font = cleanupFonts(theme.font)
  return {
    richConsent: {
      zIndex: 10000,
      fontFamily: font || 'Montserrat Reg',
      '& .elo-modal__title': {
        fontFamily: font || 'Montserrat Reg',
      },
      '& a': {
        color: `${theme.linkColor || eloBlue}`,
        textDecoration: 'none',
        '&:hover': {
          opacity: 0.8,
          textDecoration: 'none',
        },
        '&:active': {
          opacity: 0.7,
          textDecoration: 'none',
        },
      },
      '& .compact-table a:not(.elo-btn)': {
        color: `${theme.linkColor || eloBlue}`,
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.7,
        },
      },
      '& .checkbox-field__control input:checked ~ .custom-check-mark': {
        borderColor: `${theme.linkColor || eloBlue}`,
        backgroundColor: `${theme.linkColor || eloBlue}`,
      },
      '& .elo-btn.blue': {
        color: theme.color || eloWhite,
        fontFamily: font || 'Montserrat Reg',
        backgroundColor: `${theme.linkColor || eloBlue} !important`,
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          opacity: 0.7,
        },
      },
      '& .elo-btn.outline-blue': {
        color: `${theme.linkColor || eloBlue} !important`,
        fontFamily: font || 'Montserrat Reg',
        borderColor: `${theme.linkColor || eloBlue} !important`,
        '&:hover': {
          opacity: 0.8,
          color: `${theme.linkColor || eloBlue} !important`,
          borderColor: `${theme.linkColor || eloBlue} !important`,
        },
        '&:active': {
          opacity: 0.7,
          color: `${theme.linkColor || eloBlue} !important`,
          borderColor: `${theme.linkColor || eloBlue} !important`,
        },
      },
      '& .additional-info-toggle:not(.additional-info-toggle--active)': {
        color: theme.linkColor || eloBlue,
      },
    },
    tooltip: {
      fontFamily: font || 'Montserrat Reg',
      '& a': {
        color: `${theme.linkColor || eloBlue}`,
        textDecoration: 'none',
        '&:active': {
          opacity: 0.8,
          textDecoration: 'none',
        },
      },
    },
  }
}

export default cookiesConsentRichStyles
