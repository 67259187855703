export const hex2rgb = (hex = '#000', opacity?: number): string => {
  let h: RegExpMatchArray | null | number | string = hex.replace('#', '')
  h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'))

  if (h) {
    for (let i = 0; i < h.length; i++) {
      h[i] = String(parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16))
    }

    if (typeof opacity !== 'undefined' && parseFloat(String(opacity))) {
      h.push(String(opacity))
    }

    return `rgba(${h.join(',')})`
  }

  return 'rgba(0, 0, 0)'
}

export const rgb2hex = (rgb?: string): string => {
  const fallbackColor = '#000000'
  if (!rgb) return fallbackColor

  const data = rgb.replace(/[^\d,]/g, '').split(',')
  const rgbData = data.slice(0, 3).map((x) => parseInt(x))

  if (rgbData.some((x) => x > 255 || x < 0)) return fallbackColor

  const hexValues = rgbData.map((x) => {
    const val = x.toString(16)
    return val.length == 1 ? '0' + val : val
  })

  return '#' + hexValues.join('')
}

export const invertColor = (color = ''): string =>
  '#' + ('000000' + (0xffffff ^ parseInt(color.substring(1), 16)).toString(16)).slice(-6)
