import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTextOutdendIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M8.75 10H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M8.75 5H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M3.125 15H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M5.625 4.375L2.5 7.5L5.625 10.625' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
  </EloBaseIcon>
)
