import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { TrackingLogAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type TrackingLog = FromSwagger<TrackingLogAttributes>

const BASE_URL = (username: string) => `/shop/${username}/tracking_logs`

export interface ReqBody {
  form: string
  username: string
  trackingCodeId: number
  trackingEventId?: Record<string, never> | number
  sellerId?: number
  trackingCodeForm?: string
  code?: {
    name?: string
    form?: string
    code: string
    customerToken: string | boolean
    orderId?: number
  }
  orderId?: number
}

export const createTrackingLogApi = ({ POST }: ApiClientV2) => ({
  trackingLog: ({ username, ...restData }: ReqBody) => POST(BASE_URL(username), { type: 'tracking_logs', ...restData }),
})

export type TrackingLogApi = ReturnType<typeof createTrackingLogApi>
