import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloUnreadableIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M3.8125 3.39404L16.1875 17.0065'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0806 12.4894C11.5128 13.0095 10.77 13.2967 10 13.2938C9.37561 13.2937 8.76582 13.1047 8.25083 12.7516C7.73584 12.3985 7.33976 11.8978 7.11465 11.3153C6.88954 10.7329 6.84595 10.096 6.9896 9.4883C7.13325 8.88062 7.45742 8.33064 7.91949 7.91064'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.82346 5.60645C2.66784 7.19973 1.33752 10.2007 1.33752 10.2007C1.33752 10.2007 3.81252 15.7694 10 15.7694C11.4499 15.7812 12.8817 15.4471 14.1766 14.7949'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.234 13.3794C17.9201 11.8712 18.6626 10.2006 18.6626 10.2006C18.6626 10.2006 16.1876 4.63185 10.0001 4.63185C9.46365 4.6308 8.9281 4.67478 8.39905 4.76334'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5801 7.16064C11.238 7.2853 11.8375 7.62058 12.2882 8.11588C12.7388 8.61117 13.0161 9.23961 13.0783 9.90635'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
