import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMoreVerticalIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12C11.1046 12 12 11.1046 12 10Z'
      fill='currentColor'
    />
    <path
      d='M12 16C12 14.8954 11.1046 14 10 14C8.89543 14 8 14.8954 8 16C8 17.1046 8.89543 18 10 18C11.1046 18 12 17.1046 12 16Z'
      fill='currentColor'
    />
    <path
      d='M12 4C12 2.89543 11.1046 2 10 2C8.89543 2 8 2.89543 8 4C8 5.10457 8.89543 6 10 6C11.1046 6 12 5.10457 12 4Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
