import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from 'react-jss'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { getDocument } from '@elo-kit/utils/helpers.utils'

import headerSharingOptionsStyles from './HeaderLangSwitcher.styles'

/**
 * Page Builder's Header Language Switcher component
 */
export class HeaderLangSwitcherContainer extends Component {
  componentDidMount() {
    // We're taking locale from html tag for emvbedable widget
    // which are being used in browser which dont accept third party cookies
    const { availableLanguages = [], getCookies, previewMode, I18n } = this.props

    // TODO: check conditions which used here for setting locale
    const protectedDocument = getDocument()
    const browserLanguages = [...new Set(window.navigator.language.split(/,|-|;/).map((item) => item.toLowerCase()))]
    const getBrowserLocale = browserLanguages.filter((language) => I18n?.availableLanguages?.includes(language))[0]
    const selectedLang =
      getCookies('locale') ||
      protectedDocument?.getElementsByTagName('html')?.[0]?.getAttribute('lang') ||
      getBrowserLocale
    const isSelectedLangAvailable = availableLanguages.includes(selectedLang)

    if (!isSelectedLangAvailable && !previewMode) {
      const availableLang = availableLanguages[0]
      if (selectedLang !== availableLang) {
        this.changeLocale(availableLang)
      }
    }
  }

  getLanguageList = () => {
    const { availableLanguages, currentLocale } = this.props

    const notSelectedLanguages = availableLanguages.filter((lang) => lang !== currentLocale())

    return notSelectedLanguages || []
  }

  changeLocale = (locale) => {
    const { setCookies, setToKey } = this.props

    setCookies('locale', locale).then(() => {
      /* There are some text translated on the back end
       * We need to reload the page to notify back about language changed,
       * or just send ajax request with locale query parameter, but there are
       * some parts that we need update manually to obtain new translations.
       */
      setToKey({ locale })
    })
  }

  render() {
    const { classes, currentLocale, snakeCaseToCamelCase, LANGUAGES } = this.props
    const languageList = this.getLanguageList()
    const currentLocaleText = snakeCaseToCamelCase(currentLocale())?.toUpperCase()

    return languageList.length ? (
      <div
        className={classNames(classes.menuWithSubmenu)}
        data-highlighter-item={BLOCK_MENU_HIGHLIGHT_IDS.header.languageSwitcher}
        data-highlighter-selector=''
      >
        <button type='button' className={classNames(classes.menuItem, classes.langSwitcher)}>
          {currentLocaleText}
          <i className='fas fa-sort-down' />
        </button>

        <div className={classNames(classes.submenuContainer, classes.langSwitcherSubmenusContainer, 'submenus')}>
          {languageList.map((item) => {
            const { value } = LANGUAGES[snakeCaseToCamelCase(item)] || {}

            return (
              <div className={classes.langSwitcherSubitem} onClick={() => this.changeLocale(value)} key={value}>
                {value?.toUpperCase()}
              </div>
            )
          })}
        </div>
      </div>
    ) : null
  }
}

HeaderLangSwitcherContainer.propTypes = {
  /** Languages map */
  LANGUAGES: PropTypes.object,
  /** currentLocale from I18n Object */
  currentLocale: PropTypes.func,
  /** setToKey from Hashovka Object */
  setToKey: PropTypes.func,
  /** Snake case to camel case helper */
  snakeCaseToCamelCase: PropTypes.func,
  /** Function to get cookies */
  getCookies: PropTypes.func,
  /** Function to set cookies */
  setCookies: PropTypes.func,
  /** Available Languages from contentPageStore */
  availableLanguages: PropTypes.array,
  /** React JSS styles */
  classes: PropTypes.object,
}

HeaderLangSwitcherContainer.defaultProps = {
  availableLanguages: [],
  getCookies: () => {},
  setCookies: () => {},
  currentLocale: () => {},
  setToKey: () => {},
  snakeCaseToCamelCase: (val) => val,
  classes: {},
  LANGUAGES: {},
}

const HeaderLangSwitcherWithStyles = withStyles(headerSharingOptionsStyles)(HeaderLangSwitcherContainer)

const HeaderLangSwitcher = (props) => {
  const I18n = useI18n()

  return <HeaderLangSwitcherWithStyles I18n={I18n} {...props} />
}

export default HeaderLangSwitcher
