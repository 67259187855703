import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'
import { FromSwagger } from 'types/helpers'
import { ProductAttributes } from 'types/__generated__/api'

import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

type ProductParams =
  | {
      username?: string
      expand?: string[]
    }
  | {
      username?: string
      expand?: string[]
      forShop: boolean
    }

export interface Product {
  id?: number
  accessPassword?: string
  pricingPlans?: any
  slug?: string
  courseThemeId?: number
  private?: boolean
  shortDescription?: string
  description?: string
  metaTitle?: string
  name?: string
  metaTags?: string
  metaDescription?: string
  covers?: {
    type?: string
    cover?: string
    url?: string
  }[]
  displayPrice?: number
  displayCurrencyId?: number
  form?: string
  paymentPageTemplateId?: number
  position?: number
  tickets?: any
  redirectType?: string
}

const BASE_URL = (username: string): string => `/shop/${username}/products`
const BASE_URL_V2 = (username: string): string => `shop/${username}/products`

export const createProductsApi = ({ GET }: ApiClient) => ({
  fetchProduct: (username: string, slug: string, data: ProductParams) =>
    GET<Response<Product>>(`${BASE_URL(username)}/${slug}`, data),
})

export type ProductsApi = ReturnType<typeof createProductsApi>
export interface ProductsApiParams {
  filter?: {
    name?: string
    form?: string
    productGroupId?: string[]
    ids?: number[]
  }
  page?: {
    size?: number
    number?: number
  }
  sortKey?: string
  sortDir?: string
}
export type Products = FromSwagger<ProductAttributes>

// todo: remove v2 from naming after integrate fetchProduct to v2
export const createProductsV2Api = ({ GET }: ApiClientV2) => ({
  fetchProducts: (username: string, data: ProductsApiParams) => GET<ProductAttributes[]>(BASE_URL_V2(username), data),
})

export type ProductsV2Api = ReturnType<typeof createProductsV2Api>
