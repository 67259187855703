import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloQuestionCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 15.5C10.5695 15.5 11.0312 15.0383 11.0312 14.4688C11.0312 13.8992 10.5695 13.4375 10 13.4375C9.43046 13.4375 8.96875 13.8992 8.96875 14.4688C8.96875 15.0383 9.43046 15.5 10 15.5Z'
      fill='currentColor'
    />
    <path
      d='M10 11.375V10.6875C10.4759 10.6875 10.9411 10.5464 11.3368 10.282C11.7325 10.0176 12.041 9.64177 12.2231 9.20208C12.4052 8.7624 12.4529 8.27858 12.36 7.81181C12.2672 7.34505 12.038 6.9163 11.7015 6.57978C11.365 6.24326 10.9362 6.01408 10.4694 5.92124C10.0027 5.82839 9.51885 5.87604 9.07917 6.05817C8.63948 6.24029 8.26368 6.5487 7.99928 6.94441C7.73487 7.34012 7.59375 7.80534 7.59375 8.28125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
