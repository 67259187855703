export const EMAIL_PROVIDERS = {
  activeCampaign: 'active_campaign',
  mailChimp: 'mail_chimp',
  getResponse: 'get_response',
  mailingWork: 'mailing_work',
}

export const INTEGRATIONS = {
  klickTipp: 'klick_tipp',
  zapier: 'zapier',
  ...EMAIL_PROVIDERS,
  chat: 'chat',
  api: 'api',
  vimeoVideos: 'vimeo_videos',
  hubspot: 'hubspot',
  tracify: 'tracify',
}

const { chat, api, mailingWork, ...integrations } = INTEGRATIONS
export const CUSTOM_FIELDS_INTEGRATIONS_LIST = Object.values(integrations)
