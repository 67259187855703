import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface EmbeddableItem {
  purpose: string
  embedPrefs: {
    purpose: string
    redirection: string
    transparent: string
    lessonId: string
  }
  product: {
    slug: string
  }
  created: string
  views: number
  link: string
  shortLink: string
  embed_code: string
  form: string
  slug: string
  params: Record<string, unknown> // TODO: add object
  affiliateNode: {
    publisherId: number
    affiliateProgramId: number
  }
  seller: {
    protocol: string
    host: string
    username: string
  }
}

const BASE_URL = '/shop/embeddable_items'

export const createEmbeddableItemsApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: number, data: { expand: string[] }) => GET<Response<EmbeddableItem>>(`${BASE_URL}/${id}`, data),
})

export type EmbeddableItemsApi = ReturnType<typeof createEmbeddableItemsApi>
