import React, { Component } from 'react'
import classNames from 'classnames'
import { uuid } from 'utils/uuid'

import { AUTOCOMPLETE_OFF } from '@elo-kit/constants/forms.constants'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { I18nType, useI18n } from '@elo-kit/components/i18n/i18n'

import { InfoTooltip } from '../../info-tooltip'
import { FieldError } from '../field-error'

interface Props {
  className?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  value?: string
  onChange?: (trimmedValue: string) => void
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onBlur?: () => void
  onFocus?: () => void
  autocomplete?: string
  type?: string
  validationOnUpdate?: boolean
  isInvalid?: boolean
  withHidePassword?: boolean
  skipValidationOnUpdate?: boolean
  required?: boolean
  id?: string
  prefix?: React.ReactNode
  /** Outlined Flag */
  outlined?: boolean
  focus?: any
  labelClassName?: string
  errorMessage?: string
  tooltipId?: string
  tooltipTitle?: boolean
  tooltipPms?: object
  transparent?: boolean
  /** Tooltip ID */
  errorId?: string | number
  darkValue?: boolean
  prefixNormalField?: boolean
  prefixInsideField?: boolean
  saveOnBlur?: () => void
  validateOnBlur?: boolean
  maxlength?: number
  editInput?: () => void
  I18n?: I18nType
  /** CheckboxField */
  checkboxFieldLabel?: string
  checkboxFieldChecked?: boolean
  checkboxFieldOnChange?: (event: React.FormEvent<HTMLInputElement>, checked: boolean) => void
  tooltipContent?: string
  checkboxDisabled?: boolean
  inputId?: string
}
interface State {
  inValid?: boolean
  hidePassword: boolean
  textName: string
}

const defaultProps = {
  autocomplete: AUTOCOMPLETE_OFF,
  disabled: false,
  type: 'text',
  validationOnUpdate: true,
  withHidePassword: false,
  skipValidationOnUpdate: false,
  validateOnBlur: true,
  I18n: {
    t: () => {},
  },
}

export class TextFieldContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { validationOnUpdate, isInvalid, inputId } = this.props

    this.state = {
      inValid: validationOnUpdate ? false : isInvalid,
      hidePassword: true,
      textName: inputId || uuid(),
    }
  }

  static defaultProps = defaultProps
  static displayName = 'TextField'

  componentDidUpdate() {
    const { isInvalid, skipValidationOnUpdate } = this.props
    const { inValid } = this.state

    if (!skipValidationOnUpdate && isInvalid !== inValid) {
      this.setInValid(isInvalid)
    }
  }

  textFieldChange = (e: { target: { value: string } }) => {
    const { onChange, required, isInvalid, saveOnBlur, onBlur } = this.props

    const trimmedValue = e?.target?.value?.trim()

    if (onChange) onChange(trimmedValue)
    if (onBlur) onBlur()

    const inValid = isInvalid || (required && !trimmedValue)
    this.setInValid(inValid)

    if (saveOnBlur) saveOnBlur()
  }

  setInValid = (inValid: boolean | undefined) => this.setState({ inValid })

  toggleHidePassword = () => {
    const { hidePassword } = this.state
    this.setState({ hidePassword: !hidePassword })
  }

  getLabel = () => {
    const { label, labelClassName, tooltipId, id, tooltipTitle, tooltipPms, I18n } = this.props
    const { textName } = this.state
    const labelClasses = classNames(
      {
        [labelClassName as keyof typeof labelClassName]: labelClassName,
      },
      'field__label'
    )

    const tooltipTitles = {
      api_key: I18n.t('react.cabinet.help_icon.api_key.title'),
      api_endpoint: I18n.t('react.cabinet.help_icon.api_endpoint.title'),
      mailing_work_username: I18n.t('react.cabinet.help_icon.mailing_work_username.title'),
      klick_tipp_username: I18n.t('react.cabinet.help_icon.klick_tipp_username.title'),
      mailing_work_password: I18n.t('react.cabinet.help_icon.mailing_work_password.title'),
      klick_tipp_password: I18n.t('react.cabinet.help_icon.klick_tipp_password.title'),
      keys_format: I18n.t('react.cabinet.help_icon.keys_format.title'),
    }

    const tooltipContent = {
      api_key: I18n.t('react.cabinet.help_icon.api_key.content'),
      api_endpoint: I18n.t('react.cabinet.help_icon.api_endpoint.content'),
      mailing_work_username: I18n.t('react.cabinet.help_icon.mailing_work_username.content'),
      klick_tipp_username: I18n.t('react.cabinet.help_icon.klick_tipp_username.content'),
      mailing_work_password: I18n.t('react.cabinet.help_icon.mailing_work_password.content'),
      klick_tipp_password: I18n.t('react.cabinet.help_icon.klick_tipp_password.content'),
      hubspot_access_token: I18n.t('react.cabinet.help_icon.hubspot_access_token.content'),
      hubspot_pipeline_id: I18n.t('react.cabinet.help_icon.hubspot_pipeline_id.content'),
      hubspot_match_order_id_on: I18n.t('react.cabinet.help_icon.hubspot_match_order_id_on.content'),
      feedback_email: I18n.t('react.cabinet.help_icon.feedback_email.content'),
      redirection_url: I18n.t('react.cabinet.help_icon.redirection_url.content'),
      cookie_consents_keys: I18n.t('react.cabinet.help_icon.cookie_consents_keys.content'),
      cookiebot_id: I18n.t('react.cabinet.help_icon.cookiebot_id.content'),
      dns_record_recordType: I18n.t('react.cabinet.help_icon.dns_record_recordType.content'),
      dns_record_name: I18n.t('react.cabinet.help_icon.dns_record_name.content'),
      dns_record_ttl: I18n.t('react.cabinet.help_icon.dns_record_ttl.content'),
      dns_record_value: I18n.t('react.cabinet.help_icon.dns_record_value.content'),
      fontName: I18n.t('react.cabinet.help_icon.fontName.content'),
      offer_name: I18n.t('react.cabinet.help_icon.offer_name.content'),
      campaign_id: I18n.t('react.cabinet.help_icon.campaign_id.content'),
      offer_free_button_text: I18n.t('react.cabinet.help_icon.offer_free_button_text.content'),
      offer_paid_button_text: I18n.t('react.cabinet.help_icon.offer_paid_button_text.content'),
      keys_format: I18n.t('react.cabinet.help_icon.keys_format.content', tooltipPms),
      meta_title: I18n.t('react.cabinet.help_icon.meta_title.content'),
      // prettier-ignore
      mobile_app_custom_push_notification_title: I18n.t('react.cabinet.help_icon.mobile_app_custom_push_notification_title.content'),
      respective_vat: I18n.t('react.cabinet.help_icon.respective_vat.content'),
      csv_name: I18n.t('react.cabinet.help_icon.csv_name.content'),
      coupon_affiliate: I18n.t('react.cabinet.help_icon.coupon_affiliate.content'),
      pricing_plan_currency: I18n.t('react.cabinet.help_icon.pricing_plan_currency.content'),
      live_shopping_content_id: I18n.t('react.cabinet.help_icon.live_shopping_content_id.content'),
      live_shopping_coupon: I18n.t('react.cabinet.help_icon.live_shopping_coupon.content'),
      app_shop_theme: I18n.t('react.cabinet.help_icon.app_shop_theme.content'),
      app_membership_theme: I18n.t('react.cabinet.help_icon.app_membership_theme.content'),
      mobile_app_name: I18n.t('react.cabinet.help_icon.mobile_app_name.content'),
      multiple_items_sharing_name: I18n.t('react.cabinet.help_icon.multiple_items_sharing_name.content'),
    }

    return (
      <label className={labelClasses} htmlFor={textName}>
        <span>{label}</span>
        {tooltipId && (
          <InfoTooltip
            id={`${tooltipId}${id ? `_${id}` : ''}_popover`}
            title={tooltipTitle && tooltipTitles[tooltipId]}
            body={tooltipContent[tooltipId]}
          />
        )}
      </label>
    )
  }

  render() {
    const {
      className,
      label,
      required,
      placeholder,
      autocomplete,
      onChange,
      id,
      prefix,
      outlined,
      focus,
      labelClassName,
      type,
      errorMessage,
      value,
      disabled,
      tooltipId,
      tooltipTitle,
      tooltipPms,
      transparent,
      errorId,
      withHidePassword,
      darkValue,
      prefixNormalField,
      prefixInsideField,
      onClick,
      validateOnBlur,
      validationOnUpdate, //to exclude in ...props
      skipValidationOnUpdate, //to exclude in ...props
      editInput, //to exclude in ...props
      isInvalid, //to exclude in ...props
      maxlength,
      I18n,
      checkboxFieldLabel,
      checkboxFieldChecked,
      checkboxFieldOnChange,
      tooltipContent,
      checkboxDisabled,
      inputId,
      ...props
    } = this.props
    const { hidePassword, inValid, textName } = this.state

    const errorText = errorMessage || (required && I18n.t('react.shared.validations.required'))

    let fieldType = type

    if (withHidePassword) {
      fieldType = hidePassword ? type : 'text'
    }

    const fieldClassNames = classNames('field text-field', className, {
      'text-field--disabled': disabled,
      'text-field--required': required,
    })

    const fieldControlClassNames = classNames('field__control text-field__control', {
      'text-field__control--error': inValid,
      'text-field__control--with-prefix': prefix,
      'text-field__control--with-prefix-normal-field': prefixNormalField,
      'text-field__control--transparent': transparent,
      'text-field__control--dark-value': darkValue,
    })

    const eyeClasses = classNames('password-icon', {
      'fas fa-eye': hidePassword,
      'fas fa-eye-slash': !hidePassword,
    })

    const prefixClasses = classNames('text-field__prefix input-group-prepend', {
      'text-field__prefix--inside-field': prefixInsideField,
    })

    return (
      <div className={fieldClassNames}>
        {label && this.getLabel()}

        {inValid && <FieldError id={errorId} errorText={errorText} outlined={outlined} />}
        {checkboxFieldLabel && !inValid && (
          <CheckboxField
            checked={checkboxFieldChecked}
            onChange={checkboxFieldOnChange}
            tooltipContent={tooltipContent}
            className='text-field__checkbox'
            noMargin
            disabled={checkboxDisabled}
            tooltipId={id}
            topLabel={checkboxFieldLabel}
          />
        )}
        <div className='text-field__control-wrapper'>
          {prefix && <div className={prefixClasses}>{prefix}</div>}

          <input
            id={textName}
            className={fieldControlClassNames}
            autoComplete={autocomplete}
            onBlur={(e) => validateOnBlur && this.textFieldChange(e)}
            onChange={(e) => onChange && onChange(e.target.value)}
            onClick={onClick}
            maxLength={maxlength}
            {...{
              ...props,
              placeholder,
              type: fieldType,
              value: value || '',
            }}
            ref={focus}
          />

          {withHidePassword && <i className={eyeClasses} onClick={this.toggleHidePassword} />}
        </div>
      </div>
    )
  }
}

export const TextField = (props) => {
  const I18n = useI18n()

  return <TextFieldContainer I18n={I18n} {...props} />
}
