import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloEditIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M6.96641 17.5629H3.125C2.94267 17.5629 2.7678 17.4905 2.63887 17.3615C2.50994 17.2326 2.4375 17.0577 2.4375 16.8754V13.034C2.43719 12.9447 2.45449 12.8563 2.4884 12.7737C2.52232 12.6911 2.57219 12.616 2.63516 12.5528L12.9477 2.24026C13.0116 2.1753 13.0879 2.12371 13.172 2.0885C13.2561 2.05329 13.3463 2.03516 13.4375 2.03516C13.5287 2.03516 13.6189 2.05329 13.703 2.0885C13.7871 2.12371 13.8634 2.1753 13.9273 2.24026L17.7602 6.07307C17.8251 6.13704 17.8767 6.21329 17.9119 6.29739C17.9471 6.38148 17.9653 6.47174 17.9653 6.56291C17.9653 6.65408 17.9471 6.74434 17.9119 6.82844C17.8767 6.91253 17.8251 6.98879 17.7602 7.05276L7.44766 17.3653C7.38438 17.4282 7.3093 17.4781 7.22672 17.512C7.14414 17.5459 7.05568 17.5632 6.96641 17.5629V17.5629Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6875 4.5L15.5 9.3125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
