import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'

import { CheckboxField } from '@elo-kit/components/form/checkbox-field'
import { EloTooltipMore } from '@elo-kit/components/elo-ui/elo-tooltip-more'
import { AdditionalInfoToggle } from '@elo-kit/components/elo-ui/additional-info-toggle'

import { SIZES, SCREEN_SIZE } from '@elo-kit/constants/general.constants'
import { createId } from '@elo-kit/utils/general.utils'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

import './collapsible-subitems-list.scss'

/**
 * Collapsible items list
 */

interface ListItem {
  id?: number | string
  key?: string
  description?: string
  purpose?: string
  required?: boolean
  name?: string
}
interface Props {
  list?: Array<ListItem | undefined>
  subitemsList?: Record<string, any>
  checkedItems?: Record<string, any>
  checkedSubitems?: Record<string, any>
  itemAction?: (item?: ListItem) => void
  subitemAction?: (item?: ListItem) => void
  tooltipClasses?: any
  expandDetails?: boolean
}

export const CollapsibleSubitemsList = (props: Props) => {
  const {
    list = [],
    subitemsList = {},
    checkedItems = {},
    checkedSubitems = {},
    itemAction,
    subitemAction,
    tooltipClasses = '',
    expandDetails = false,
  } = props

  const [detailsOpened, setDetailsOpened] = useState<Record<string, any>>({})
  const [mobileView, setMobileView] = useState(window.innerWidth < SCREEN_SIZE.mobile)

  const handleResize = () => setMobileView(window.innerWidth < SCREEN_SIZE.mobile)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    const initDetailsOpened: Record<string, any> = {}

    list.forEach((item) => {
      const subitemIsEnabled = (subitem: Record<string, any>) => checkedSubitems[String(subitem?.id)]
      const containEnabled = subitemsList[String(item?.id)] && subitemsList[String(item?.id)].some(subitemIsEnabled)
      initDetailsOpened[String(item?.id)] = containEnabled
    })

    if (expandDetails) {
      setDetailsOpened(initDetailsOpened)
    }

    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, [])

  const toggleDetails = (id: string | number) =>
    setDetailsOpened({
      ...detailsOpened,
      [id]: !detailsOpened[id],
    })

  const DescriptionWrapper = mobileView ? EloTooltipMore : Fragment

  const tooltipClassName = classNames(tooltipClasses, 'collapsible-description')

  const getDescription = (item?: ListItem) => {
    const descrWrapperProps = mobileView
      ? {
          id: createId(String(item?.id), item?.key),
          size: SIZES.responsive,
        }
      : {}

    return (
      <DescriptionWrapper {...descrWrapperProps}>
        <div
          dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(String(item?.description || item?.purpose)) }}
          className={tooltipClassName}
        />
      </DescriptionWrapper>
    )
  }

  const getSubitem = (subitem: ListItem) => (
    <div className='collapsible-subitem-container' key={subitem.id}>
      <CheckboxField
        checked={checkedSubitems[String(subitem?.id)]}
        onChange={() => subitemAction && subitemAction(subitem)}
        className='checkbox-field--no-margin'
        disabled={subitem.required}
      />
      <div className='collapsible-subitem'>
        <div className='collapsible-subitem-name'>{subitem.name}</div>
        {getDescription(subitem)}
      </div>
    </div>
  )

  return (
    <div className='collapsible-list-container'>
      {list.map((item) => {
        const subItems = subitemsList[String(item?.id)] || []
        const areAllSubItemsRequired = subItems.every((subitem: { required: boolean }) => subitem.required)
        const isEmpty = subItems && subItems.length === 0

        return (
          <div className='collapsible-item-container' key={item?.id}>
            <div className='collapsible-item'>
              <CheckboxField
                id={item?.id}
                checked={checkedItems[String(item?.id)]}
                disabled={areAllSubItemsRequired && subItems.length !== 0}
                onChange={() => itemAction && itemAction(item)}
                className='checkbox-field--no-margin'
              />
              <div className='collapsible-item-controls'>
                <div className='collapsible-item-details'>
                  <div className='collapsible-item-name'>{item?.name}</div>
                  {getDescription(item)}
                </div>
                {!isEmpty && (
                  <AdditionalInfoToggle
                    open={detailsOpened[String(item?.id)]}
                    toggle={() => item?.id && toggleDetails(item?.id)}
                  />
                )}
              </div>
            </div>
            {detailsOpened[String(item?.id)] && (
              <div className='collapsible-subitems-list-container'>
                {(subitemsList[String(item?.id)] || []).map((subitem: ListItem) => getSubitem(subitem))}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

CollapsibleSubitemsList.displayName = 'CollapsibleSubitemsList'
