import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloChatsCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M2.86352 11.231C2.15235 10.0521 1.90308 8.65832 2.16253 7.31138C2.42197 5.96443 3.17227 4.75702 4.27253 3.91591C5.37278 3.0748 6.74728 2.65786 8.13787 2.74339C9.52847 2.82893 10.8395 3.41105 11.8247 4.38044C12.8099 5.34983 13.4016 6.63978 13.4885 8.00803C13.5754 9.37628 13.1517 10.7287 12.2968 11.8113C11.442 12.8938 10.2149 13.6321 8.84591 13.8874C7.47696 14.1426 6.06039 13.8974 4.86224 13.1976L2.88731 13.7517C2.80594 13.7742 2.71995 13.775 2.63817 13.754C2.55639 13.7329 2.48178 13.6908 2.42201 13.632C2.36224 13.5732 2.31947 13.4998 2.29809 13.4194C2.27672 13.3389 2.27751 13.2543 2.30039 13.1742L2.86352 11.231Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.46399 13.9786C7.74353 14.7628 8.19661 15.4761 8.79049 16.067C9.38438 16.6579 10.1042 17.1116 10.8981 17.3954C11.6919 17.6791 12.5399 17.7858 13.3807 17.7078C14.2216 17.6298 15.0344 17.3689 15.7603 16.9442L17.7352 17.4982C17.8166 17.5208 17.9026 17.5215 17.9843 17.5005C18.0661 17.4795 18.1407 17.4374 18.2005 17.3786C18.2603 17.3198 18.303 17.2464 18.3244 17.1659C18.3458 17.0854 18.345 17.0008 18.3221 16.9207L17.759 14.9776C18.2617 14.1472 18.538 13.2032 18.5609 12.2369C18.5839 11.2707 18.3528 10.315 17.8901 9.46245C17.4273 8.6099 16.7486 7.88942 15.9196 7.37083C15.0907 6.85224 14.1397 6.55311 13.1588 6.50244'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
