import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloHouseIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M12.1818 17.7715V13.4006C12.1818 13.2074 12.1052 13.0221 11.9688 12.8855C11.8324 12.7489 11.6474 12.6721 11.4545 12.6721H8.54545C8.35257 12.6721 8.16759 12.7489 8.0312 12.8855C7.89481 13.0221 7.81818 13.2074 7.81818 13.4006V17.7715C7.81818 17.9647 7.74156 18.15 7.60517 18.2866C7.46878 18.4232 7.28379 18.5 7.09091 18.5H2.72727C2.53439 18.5 2.3494 18.4232 2.21301 18.2866C2.07662 18.15 2 17.9647 2 17.7715V9.34838C2.00163 9.24756 2.02338 9.14809 2.06397 9.05582C2.10457 8.96355 2.16318 8.88035 2.23636 8.81112L9.50909 2.191C9.64316 2.06814 9.81829 2 10 2C10.1817 2 10.3568 2.06814 10.4909 2.191L17.7636 8.81112C17.8368 8.88035 17.8954 8.96355 17.936 9.05582C17.9766 9.14809 17.9984 9.24756 18 9.34838V17.7715C18 17.9647 17.9234 18.15 17.787 18.2866C17.6506 18.4232 17.4656 18.5 17.2727 18.5H12.9091C12.7162 18.5 12.5312 18.4232 12.3948 18.2866C12.2584 18.15 12.1818 17.9647 12.1818 17.7715Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
