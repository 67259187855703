import { toJS } from 'mobx'
import moment from 'moment'

import {
  DATE_RANGE_TYPE,
  BLACK_LIST_FILTER_PARAMS,
  FILTERS_KEYS_LIST,
  CREATED_RELATIVE_PERIOD_KEYS,
} from 'constants/filter.constants'
import { NUMBER_REGEX } from 'constants/regex.constants'
import { PERIODS } from '@elo-kit/constants/dateTime.constants'

import { areMapsEqual } from './helpers.utils'

export const formFilterRequestParams = (filterList, filterValues, appliedFilters) => {
  let params = {}

  filterList &&
    filterList.forEach((filter) => {
      const filterNotApplied = !appliedFilters.find((appliedFilter) => appliedFilter === filter.key)

      if (filterNotApplied) {
        return null
      }

      const filterValue = filterValues.get(filter.key)
      if (filter.type === DATE_RANGE_TYPE) {
        params[filter.serverKey.from] = filterValue && filterValue.from
        params[filter.serverKey.untill] = filterValue && filterValue.untill
      } else {
        params[filter.serverKey] = filterValue
      }
    })

  return params
}

const getFiltersMap = (filters) => {
  const convertedFilters = toJS(filters) instanceof Map ? Object.fromEntries(toJS(filters)) : toJS(filters)
  const filtersArray = Object.entries(convertedFilters).filter(
    ([key, _value]) => !BLACK_LIST_FILTER_PARAMS.includes(key) && FILTERS_KEYS_LIST.includes(key) && _value !== null
  )
  return new Map(filtersArray)
}

export const isPrevAndNextFiltersEqual = (previousFilters, filters) =>
  areMapsEqual(getFiltersMap(filters), getFiltersMap(previousFilters))

const [startOfDate, endOfDate, subtractDate] = ['startOf', 'endOf', 'subtract'].map(
  (operation) => (number, period, extraOperation) =>
    extraOperation
      ? moment()[operation](number, period)[extraOperation](period).toISOString()
      : moment()[operation](number, period).toISOString()
)

export const getRelativeDataFilter = ({ period, time, fromKey = 'from', tillKey = 'till' }) => {
  if (period && time) {
    switch (period) {
      case CREATED_RELATIVE_PERIOD_KEYS.last: {
        const day = time.match(NUMBER_REGEX).join('')
        const fromDate = subtractDate(day, PERIODS.day, 'startOf')
        const tillDate = moment().toISOString()

        return {
          [fromKey]: fromDate,
          [tillKey]: tillDate,
        }
      }
      case CREATED_RELATIVE_PERIOD_KEYS.current: {
        const fromDate = startOfDate(time)
        const tillDate = endOfDate(time)

        return {
          [fromKey]: fromDate,
          [tillKey]: tillDate,
        }
      }
      case CREATED_RELATIVE_PERIOD_KEYS.previous: {
        const fromDate = subtractDate(1, time, 'startOf')
        const tillDate = subtractDate(1, time, 'endOf')

        return {
          [fromKey]: fromDate,
          [tillKey]: tillDate,
        }
      }
    }
  }

  return {}
}
