import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTicketIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M7.25 3.8125V15.8125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.06251 13.0792C1.06166 12.9256 1.1145 12.7766 1.21189 12.6579C1.30928 12.5392 1.4451 12.4583 1.59584 12.4292C2.19544 12.3019 2.73313 11.9725 3.11893 11.4962C3.50474 11.0198 3.71525 10.4255 3.71525 9.8125C3.71525 9.19953 3.50474 8.60516 3.11893 8.12884C2.73313 7.65252 2.19544 7.32314 1.59584 7.19583C1.4451 7.16668 1.30928 7.08576 1.21189 6.96707C1.1145 6.84837 1.06166 6.69937 1.06251 6.54583V4.47917C1.06251 4.30236 1.13275 4.13279 1.25777 4.00776C1.3828 3.88274 1.55237 3.8125 1.72918 3.8125H17.7292C17.906 3.8125 18.0756 3.88274 18.2006 4.00776C18.3256 4.13279 18.3958 4.30236 18.3958 4.47917V6.54583C18.3967 6.69937 18.3439 6.84837 18.2465 6.96707C18.1491 7.08576 18.0133 7.16668 17.8625 7.19583C17.2629 7.32314 16.7252 7.65252 16.3394 8.12884C15.9536 8.60516 15.7431 9.19953 15.7431 9.8125C15.7431 10.4255 15.9536 11.0198 16.3394 11.4962C16.7252 11.9725 17.2629 12.3019 17.8625 12.4292C18.0133 12.4583 18.1491 12.5392 18.2465 12.6579C18.3439 12.7766 18.3967 12.9256 18.3958 13.0792V15.1458C18.3958 15.3226 18.3256 15.4922 18.2006 15.6172C18.0756 15.7423 17.906 15.8125 17.7292 15.8125H1.72918C1.55237 15.8125 1.3828 15.7423 1.25777 15.6172C1.13275 15.4922 1.06251 15.3226 1.06251 15.1458V13.0792Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
