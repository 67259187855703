import { observable, action, override, makeObservable } from 'mobx'

import { isEmpty } from 'utils/validatorsShared.utils'
import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { ProductUpsellsApi, createProductUpsellsApi } from 'shop/api/productUpsells.api'

interface UpsellDetails {
  id?: number
  pricingPlans?: { id: number }[]
  form?: string
  position?: number
  productAddons?: Addon[]
}
interface Addon {
  addon: {
    cover: string
    form: string
    active: boolean
  }
  ticket: {
    online: boolean
  }
}

export class ProductUpsellsStore extends SharedStore<any> {
  storeName = 'ProductUpsellsStore'
  declare childApi: ProductUpsellsApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createProductUpsellsApi(root?.apiClient ?? apiClient)
    makeObservable(this)

    this.loading = true
  }

  @observable upsellProducts: UpsellDetails[] = []

  @action setUpsellProducts = (data) => {
    this.upsellProducts = [...this.upsellProducts, data]
  }

  @override
  hydrate(key, data) {
    if (key === 'item') {
      // TODO: rm after EVERY response will be covered by types
      // @ts-ignore
      this.setItem({ data: data, success: !isEmpty(data) })
    } else if (key === 'list') {
      this.setList(data)
    } else if (key === 'upsellProducts') {
      this.upsellProducts = data
    } else if (key === 'loading') {
      this.loading = data
    }
  }
}
