import { getSortedFroalaFonts, sortFontByLabel } from 'utils/fonts.utils'

import { FONT_FAMILY_GOOGLE_FONTS, FONT_FAMILY_SELECT_OPTIONS, FROALA_FONTS_UNSORTED } from 'constants/fonts.constants'

export const getFontFamilyOptions = (customFontsList = [], forFroala = false) => {
  const fonts = customFontsList || []
  const customFonts = fonts.map((fontItem) => ({
    label: fontItem.prefs.fontName,
    value: `${fontItem.prefs.fontName},${fontItem.prefs.fallbackFont}`,
  }))

  if (forFroala) {
    return getSortedFroalaFonts(FROALA_FONTS_UNSORTED, FONT_FAMILY_GOOGLE_FONTS, customFonts)
  }

  return FONT_FAMILY_SELECT_OPTIONS.concat(customFonts).sort(sortFontByLabel)
}
