import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface CookieReq {
  affiliateToken: string
  contentId: string
  pid: string
  prid: string
  prids: string
}

interface Cookie {
  affiliateToken: string
}

const BASE_URL = (username) => `/shop/${username}/affiliate_cookies`

export const createAffiliateCookiesApi = ({ POST }: ApiClient) => ({
  createCookie: (username: string, data: CookieReq) => POST<Response<Cookie>>(BASE_URL(username), data),
})

export type AffiliateCookiesApi = ReturnType<typeof createAffiliateCookiesApi>
