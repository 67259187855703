import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCalendarBlockedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.75 1C14.75 0.585786 14.4142 0.25 14 0.25C13.5858 0.25 13.25 0.585786 13.25 1V1.625H6.75V1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1V1.625H2.72727C1.9114 1.625 1.25 2.2864 1.25 3.10227V6V17.6477C1.25 18.4636 1.9114 19.125 2.72727 19.125H17.2727C18.0886 19.125 18.75 18.4636 18.75 17.6477V3.10227C18.75 2.2864 18.0886 1.625 17.2727 1.625H14.75V1ZM2.75 6.75V17.625H17.25V6.73963C17.2093 6.74645 17.1676 6.75 17.125 6.75H2.75ZM17.25 5.26037C17.2093 5.25355 17.1676 5.25 17.125 5.25H2.75V3.125H5.25V3.75C5.25 4.16421 5.58579 4.5 6 4.5C6.41421 4.5 6.75 4.16421 6.75 3.75V3.125H13.25V3.75C13.25 4.16421 13.5858 4.5 14 4.5C14.4142 4.5 14.75 4.16421 14.75 3.75V3.125H17.25V5.26037ZM12.6732 9.32681C12.9661 9.61971 12.9661 10.0946 12.6732 10.3875L11.0607 12L12.6732 13.6125C12.9661 13.9054 12.9661 14.3803 12.6732 14.6732C12.3803 14.9661 11.9054 14.9661 11.6125 14.6732L10 13.0607L8.38747 14.6732C8.09458 14.9661 7.61971 14.9661 7.32681 14.6732C7.03392 14.3803 7.03392 13.9054 7.32681 13.6125L8.93934 12L7.32681 10.3875C7.03392 10.0946 7.03392 9.61971 7.32681 9.32681C7.61971 9.03392 8.09458 9.03392 8.38747 9.32681L10 10.9393L11.6125 9.32681C11.9054 9.03392 12.3803 9.03392 12.6732 9.32681Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
