import type { Response, ShallowResponse } from 'types/responses'
import type { Nullable } from 'types/helpers'

import { ApiClient, POST } from 'utils/requests.utils'

interface SalesTeamMember {
  id: number
  avatar: string
  email: string
  fullName: string
  termsAccepted: number
  userProfile: any
}

interface Deal {
  id: number
  commissionRate: string
  couponId: Nullable<number>
  incentivePlanId: number
  orderId: Nullable<number>
  payerData: any
  payerEmail: string
  payerFullName: string
  payerId: Nullable<string>
  price: string
  pricingPlanId: number
  pricingPlan: any
  productId: number
  product: any
  productIncentiveId: number
  productName: string
  salesTeamId: number
  salesTeamMember: SalesTeamMember
  salesTeamMemberId: number
  selectedPayMethod: string
  sellerId: number
  state: string
  token: Nullable<string>
}

const BASE_URL = (username) => `/shop/${username}/deals`

export const createDealsApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: string, data: { username: string }): Promise<Response<Deal>> =>
    GET(`${BASE_URL(data.username)}/${id}`, data),
  createOrder: (username, data) =>
    POST<ShallowResponse<{ redirectLink: string; clientSecret: string }>>(`${BASE_URL(username)}`, data),
})

export type DealsApi = ReturnType<typeof createDealsApi>
