import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { CheckTicketsAvailabilityApi, createCheckTicketsAvailabilityApi } from 'shop/api/ticketsAvailability.api'

export class TicketsAvailabilityStore extends SharedStore<any> {
  storeName = 'TicketsAvailabilityStore'
  root: ShopRootStore
  declare childApi: CheckTicketsAvailabilityApi

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    makeObservable(this)

    this.childApi = createCheckTicketsAvailabilityApi(root?.apiClient ?? apiClient)
  }

  @action check = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.check(this.root.sellerStore.item.username, data)
    this.toggleLoading(false)
    return resp
  }
}
