export const PAYMENT_TICKETS_TABS = [
  {
    key: 'overview',
    link: '/cabinet/payment_tickets/overview',
    label: I18n.t('react.cabinet.payment_tickets.tabs.overview'),
  },
  {
    key: 'details',
    link: '/cabinet/payment_tickets/details',
    label: I18n.t('react.cabinet.payment_tickets.tabs.details'),
  },
]

export const TICKETS_OVERVIEW_FILTER_LIST = ['isFree', 'createdAt']

export const PAYMENT_TICKETS_STATES = {
  expired: 'expired',
  refunded: 'refunded',
  used: 'used',
  unused: 'unused',
  invalidated: 'invalidated',
}
export const PAYMENT_TICKETS_STATES_LABELS = {
  expired: I18n.t('react.cabinet.payment_tickets.label.expired'),
  refunded: I18n.t('react.cabinet.payment_tickets.label.refunded'),
  used: I18n.t('react.cabinet.payment_tickets.label.used'),
  unused: I18n.t('react.cabinet.payment_tickets.label.unused'),
  invalidated: I18n.t('react.cabinet.payment_tickets.label.invalidated'),
}

export const PAYMENT_TICKETS_STATES_VALUES = Object.values(PAYMENT_TICKETS_STATES)

export const PAYMENT_TICKET_ITEM_EXPANDS = [
  'sellable_item.product',
  'sellable_item.sellable.order.payer.user',
  'sellable_item.sellable.order.payer.user_profile',
  'sellable_item.ticket',
  'sellable_item.ticket_date',
  'ticket_attendee',
]

export const SELLER_PAYMENT_TICKETS_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.common.event_date'),
    csvKey: 'event_date',
  },
  {
    label: I18n.t('react.cabinet.common.qr_code_url'),
    csvKey: 'qr_code_url',
  },
  {
    label: I18n.t('react.cabinet.common.code'),
    csvKey: 'code',
  },
  {
    label: I18n.t('react.cabinet.common.state'),
    csvKey: 'state',
  },
  {
    label: I18n.t('react.cabinet.common.date.label'),
    csvKey: 'date',
  },
  {
    label: I18n.t('react.cabinet.common.product_name'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.cabinet.common.ticket_name'),
    csvKey: 'ticket_name',
  },
  {
    label: I18n.t('react.cabinet.common.location'),
    csvKey: 'location',
  },
  {
    label: I18n.t('react.cabinet.common.transfer_id'),
    csvKey: 'transfer_id',
  },
  {
    label: I18n.t('react.cabinet.common.price'),
    csvKey: 'price',
  },
  {
    label: I18n.t('shared.common.phone_number'),
    csvKey: 'phone_number',
  },
  {
    label: I18n.t('react.cabinet.common.payer_company'),
    csvKey: 'payer_company',
  },
  {
    label: I18n.t('react.cabinet.common.payer_first_name'),
    csvKey: 'payer_first_name',
  },
  {
    label: I18n.t('react.cabinet.common.payer_last_name'),
    csvKey: 'payer_last_name',
  },
  {
    label: I18n.t('react.cabinet.common.payer_email'),
    csvKey: 'payer_email',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_number'),
    csvKey: 'payer_phone_number',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_street'),
    csvKey: 'payer_street',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_street_number'),
    csvKey: 'payer_street_number',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_zip'),
    csvKey: 'payer_zip',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_city'),
    csvKey: 'payer_city',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_country'),
    csvKey: 'payer_country',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.payer_vat'),
    csvKey: 'payer_vat',
  },
  {
    label: I18n.t('react.cabinet.common.attendee_email'),
    csvKey: 'attendee_email',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_first_name'),
    csvKey: 'attendee_first_name',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_last_name'),
    csvKey: 'attendee_last_name',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_street'),
    csvKey: 'attendee_street',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_street_number'),
    csvKey: 'attendee_street_number',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_zip'),
    csvKey: 'attendee_zip',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_city'),
    csvKey: 'attendee_city',
  },
  {
    label: I18n.t('react.cabinet.ticket.index.attendee_country'),
    csvKey: 'attendee_country',
  },
  {
    label: I18n.t('react.cabinet.common.order_id'),
    csvKey: 'order_id',
  },
]
