import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpOneColumnOne = (props) => {
  const { height, viewBox, width } = props
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='249' rx='8' />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -47.000000) '
            x='53'
            y='23'
            width='115'
            height='48'
            rx='4'
          />
          <path
            d='M136.940549,55 C138.052088,55 138.938534,55.8954305 138.938534,57 C138.938534,58.1122704 138.044006,59 136.940549,59 L136.940549,59 L101.997985,59 C100.886447,59 100,58.1045695 100,57 L100,57 L100.00548,56.8497808 C100.081752,55.8088611 100.944685,55 101.997985,55 L101.997985,55 Z M154.00693,45 C155.097692,45 156,45.8954305 156,47 C156,48.1122704 155.107672,49 154.00693,49 L154.00693,49 L101.99307,49 C100.902308,49 100,48.1045695 100,47 L100,47 L100.005467,46.8497808 C100.081551,45.8088611 100.942361,45 101.99307,45 L101.99307,45 Z M154.00693,35 C155.097692,35 156,35.8954305 156,37 C156,38.1122704 155.107672,39 154.00693,39 L154.00693,39 L101.99307,39 C100.902308,39 100,38.1045695 100,37 L100,37 L100.005467,36.8497808 C100.081551,35.8088611 100.942361,35 101.99307,35 L101.99307,35 Z'
            fill='#E5E3E5'
          />
          <rect fill='#E5E3E5' x='65' y='35' width='24' height='23.9835004' rx='4' />
          <path
            d='M81.875,52 C82.4963226,52 83,51.4403585 83,50.75 L83,43.25 C83,42.5596415 82.4963226,42 81.875,42 L72.125,42 C71.5036774,42 71,42.5596415 71,43.25 L71,50.75 C71,51.4403585 71.5036774,52 72.125,52 L81.875,52 Z M73.5,46 C72.6715785,46 72,45.3284215 72,44.5 C72,43.6715785 72.6715785,43 73.5,43 C74.3284215,43 75,43.6715785 75,44.5 C75,45.3284215 74.3284215,46 73.5,46 Z M82,50 L73,50 L73,48.817906 L74.6761017,47.0567178 C74.785965,46.9413029 74.964035,46.9413029 75.0738754,47.0567178 L76.375,48.4238747 L79.5511017,45.0865611 C79.660965,44.9711463 79.839035,44.9711463 79.9488754,45.0865611 L82,47.2417807 L82,50 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 144.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -144.000000) '
            x='53'
            y='125'
            width='115'
            height='38'
            rx='4'
          />
          <path
            d='M153.99328,147 C155.099341,147 156,147.895431 156,149 C156,150.11227 155.101561,151 153.99328,151 L153.99328,151 L67.00672,151 C65.900659,151 65,150.104569 65,149 L65,149 L65.0055042,148.849781 C65.0821098,147.808861 65.9488155,147 67.00672,147 L67.00672,147 Z M105.998,137 C107.101884,137 108,137.895431 108,139 C108,140.11227 107.103674,141 105.998,141 L105.998,141 L67.0019996,141 C65.8981161,141 65,140.104569 65,139 L65,139 L65.0054912,138.849781 C65.0819167,137.808861 65.9465836,137 67.0019996,137 L67.0019996,137 Z'
            fill='#E5E3E5'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 206.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -206.000000) '
            x='53'
            y='171'
            width='115'
            height='70'
            rx='4'
          />
          <path
            d='M153.99328,202 C155.099341,202 156,202.895431 156,204 C156,205.11227 155.101561,206 153.99328,206 L153.99328,206 L67.00672,206 C65.900659,206 65,205.104569 65,204 L65,204 L65.0055042,203.849781 C65.0821098,202.808861 65.9488155,202 67.00672,202 L67.00672,202 Z M153.99328,193 C155.099341,193 156,193.895431 156,195 C156,196.11227 155.101561,197 153.99328,197 L153.99328,197 L67.00672,197 C65.900659,197 65,196.104569 65,195 L65,195 L65.0055042,194.849781 C65.0821098,193.808861 65.9488155,193 67.00672,193 L67.00672,193 Z M105.998,183 C107.101884,183 108,183.895431 108,185 C108,186.11227 107.103674,187 105.998,187 L105.998,187 L67.0019996,187 C65.8981161,187 65,186.104569 65,185 L65,185 L65.0054912,184.849781 C65.0819167,183.808861 65.9465836,183 67.0019996,183 L67.0019996,183 Z'
            fill='#E5E3E5'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='65' y='213' width='90' height='15.821267' rx='7.91063348' />
          <rect
            fill='#B8B9BC'
            fillRule='nonzero'
            transform='translate(110.500000, 98.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -98.000000) '
            x='53'
            y='79'
            width='115'
            height='38'
            rx='4'
          />
          <path
            d='M120.963284,107.831683 C122.332381,107.831683 123.442245,106.72182 123.442245,105.352723 L123.442245,90.4789604 C123.442245,89.1098633 122.332381,88 120.963284,88 L99.4789604,88 C98.1098633,88 97,89.1098633 97,90.4789604 L97,105.352723 C97,106.72182 98.1098633,107.831683 99.4789604,107.831683 L120.963284,107.831683 Z M102.784241,96.6763615 C101.186978,96.6763615 99.8921205,95.3815041 99.8921205,93.784241 C99.8921205,92.1869779 101.186978,90.8921205 102.784241,90.8921205 C104.381504,90.8921205 105.676362,92.1869779 105.676362,93.784241 C105.676362,95.3815041 104.381504,96.6763615 102.784241,96.6763615 Z M120.136964,104.526403 L100.305281,104.526403 L100.305281,102.047442 L103.998605,98.3540678 C104.240691,98.1120323 104.633072,98.1120323 104.875107,98.3540678 L107.742162,101.221122 L114.740767,94.2224671 C114.982853,93.9804315 115.375234,93.9804315 115.617269,94.2224671 L120.136964,98.7421619 L120.136964,104.526403 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
            opacity='0.5'
          />
          <g>
            <path
              d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
              fill='#D7DADF'
            />
            <path
              d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
              fill='#FFFFFF'
            />
            <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
          </g>
        </g>
      </g>
    </svg>
  )
}

BumpOneColumnOne.displayName = 'BumpOneColumnOne'
BumpOneColumnOne.propTypes = propTypes
BumpOneColumnOne.defaultProps = defaultProps
