import type { Nullable } from 'types/helpers'
import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

type OnOff = 'on' | 'off'

type Field = 'hidden' | 'visible' | 'required' | 'protected'

interface BuyerInformation {
  attendee: {
    city: Field
    country: Field
    email: Field
    fullName: Field
    street: Field
    streetNumber: Field
    zip: Field
    company: Field
    phone: Field
    vatNo: Field
  }
  attendeesForm?: {
    opened?: OnOff
  }
  business: {
    city?: Field
    company?: Field
    country?: Field
    email?: Field
    fullName?: Field
    street?: Field
    streetNumber?: Field
    vatNo?: Field
    zip?: Field
  }
  couponField?: {
    visible?: OnOff
  }
  emailConfirmation?: {
    visible?: OnOff
  }
  emailNewsletter?: {
    text?: string
    visible?: OnOff
  }
  free?: {
    city?: Field
    email?: Field
    fullName?: Field
    street?: Field
    streetNumber?: Field
    zip?: Field
  }
  gift: {
    city?: Field
    company?: Field
    country?: Field
    email?: Field
    fullName?: Field
    street?: Field
    streetNumber?: Field
    vatNo?: Field
    zip?: Field
  }
  includeType: {
    attendee?: OnOff
    business?: OnOff
    gift?: OnOff
    private?: OnOff
  }
  private: {
    city?: Field
    country?: Field
    email?: Field
    fullName?: Field
    street?: Field
    streetNumber?: Field
    zip?: Field
    additionalAddress?: Field
    phone?: Field
  }
}
interface MenuList {
  default?: boolean
  id: number
  url: string
  name: string
  linkType?: string
  submenu?: MenuList[]
}

interface PageTheme {
  active?: boolean
  header: {
    menuList: MenuList[]
  }
  footer: {
    menuList: MenuList[]
  }
}

export interface DisplayData {
  header: {
    backgroundImage?: string
    logo?: string
    languageSwitcher?: boolean
    myAccountSwitcher?: boolean
    showSharingOption?: boolean
    customClassName?: string
    menuColor?: string
    menuList?: MenuList[]
  }
  footer: {
    id?: number
    menuList?: {
      id: number
      name: string
      submenu?: MenuList[]
    }[]
  }
  thankYouPageTheme?: PageTheme
  funnelPageTheme?: PageTheme
  managePageTheme?: PageTheme
  paymentPageTheme?: PageTheme
  faviconUrl: string
  products?: string[]
  headerHidden?: boolean
  footerHidden?: boolean
  headerGeneral?: boolean
  noFollow?: boolean
  languages?: string[]
  showCategories: boolean
  showFavicon: boolean
  showFooter: boolean
  showHeader: boolean
  metaTagsEnabled: boolean
  footerFunnelPageHidden: boolean
  footerGeneral: boolean
  showDescription: boolean
  descriptionValue: string
  cookies: {
    font: string
    color: string
    linkColor: string
  }
}

interface ThemePage {
  slug: string
  form: string
  prefs?: DisplayData
  contentPageId: number
  default: boolean
}

interface MembershipTheme {
  id: number
  themePages: ThemePage[]
}
interface ShopTheme {
  id: number
  prefs: DisplayData
  buyerInformation: BuyerInformation
}

export interface PaymentPageTemplate {
  id: number
  layout: string
  name: string
  theme: {
    paymentPageColor: Nullable<string>
    paymentBuyBtnText?: Nullable<string>
    paymentGetForFreeBtnText?: Nullable<string>
    paymentBuyBtnColor: Nullable<string>
    paymentBuyBtnFontColor: Nullable<string>
    paymentCustomFreeBtn?: Nullable<string>
    paymentBuyBtnPosition?: Nullable<string>
    upsellPosition: string
    paymentApplyCouponBtnColor: string
    paymentApplyCouponBtnFontColor: string
    paymentApplyCouponBorderRadius: number
    paymentBgColor: Nullable<string>
    paymentBuyBtnBorderRadius: number
    customCss?: Nullable<string>
    paymentRecommendedBadgeColor?: string
    paymentRecommendedBadgeTextColor?: string
    paymentCountdownBgColor?: string
    paymentCountdownBorderColor?: string
    paymentCountdownTextColor?: string
    paymentCountdownTimeColor?: string
    countdownText?: string
    expandableDescriptionOnMobileEnabled?: boolean
    expandableDescriptionDefaultBehavior?: string
  }
  buyerInformation: BuyerInformation
  paymentBuyBtnText: string
  paymentCustomFreeBtn: boolean
  paymentGetForFreeBtnText: string
  paymentBuyBtnPosition: string
  sealCustomizationEnabled: boolean
  trustSealsSettings: {
    visa: boolean
    elopage: boolean
    mastercard: boolean
    moneyBack: boolean
    monochromaticColorEnabled: boolean
    quality: boolean
    sslSecure: boolean
  }
}

interface PaymentPageTemplateParams {
  username: string
  token: string | number
}

interface PaymentPageTemplatePreview {
  layout: string
  theme: {
    upsellPosition: string
    paymentApplyCouponBtnColor: string
    paymentApplyCouponBtnFontColor: string
    paymentApplyCouponBorderRadius: number
    paymentBgColor: Nullable<string>
    paymentBuyBtnColor: Nullable<string>
    paymentBuyBtnFontColor: Nullable<string>
    paymentBuyBtnBorderRadius: number
    paymentPageColor: Nullable<string>
  }
}

interface CreateSellerLeadsParams {
  contactFormId?: number
  shopThemeId?: number
}

const BASE_URL = (username) => `/shop/${username}`

export const createThemeApi = ({ GET, POST }: ApiClient) => ({
  fetchPaymentPageTemplate: (username: string, id: number): Promise<Response<PaymentPageTemplate>> =>
    GET(`${BASE_URL(username)}/payment_page_templates/${id}`),

  fetchShopTheme: (username: string, id: string | number): Promise<Response<ShopTheme>> =>
    GET(`${BASE_URL(username)}/shop_themes/${id}`, { 'expand[]': 'prefs.footer' }),

  fetchMembershipTheme: (username: string, id: string | number): Promise<Response<MembershipTheme>> =>
    GET(`${BASE_URL(username)}/membership_themes/${id}`, { 'expand[]': 'prefs.footer' }),

  createSellerLeads: (username: string, data: CreateSellerLeadsParams) =>
    POST(`${BASE_URL(username)}/seller_leads`, data),

  fetchPaymentPageTemplatePreview: (data: PaymentPageTemplateParams): Promise<Response<PaymentPageTemplatePreview>> =>
    GET(`${BASE_URL(data.username)}/payment_page_templates/${data.token}`, data),
})

export type ThemeApi = ReturnType<typeof createThemeApi>
