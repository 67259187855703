export const TRACKING_EVENT_FORMS = {
  shop: 'shop_page',
  product: 'product_page',
  payment: 'payment_page',
  funnel: 'funnel_page',
  success: 'success_page',
  shopSubPage: 'shop_sub_page',
}

export const TRACKING_CODE_TYPES = {
  googleAnalytics: 'google_analytics',
  googleAnalytics4: 'google_analytics_4',
  googleTagManager: 'google_tag_manager',
  facebookPixel: 'facebook_pixel',
  custom: 'custom_tracking_codes',
  tracify: 'tracify',
}

export const TRACKING_CODE_TYPES_LIST = Object.values(TRACKING_CODE_TYPES)

export const TRACKING_CODE_HELP_LINKS = {
  [TRACKING_CODE_TYPES.googleAnalytics]: I18n.t('react.cabinet.tracking_codes.form.help_links.google_analytics'),
  [TRACKING_CODE_TYPES.googleAnalytics4]: I18n.t('react.cabinet.tracking_codes.form.help_links.google_analytics_4'),
  [TRACKING_CODE_TYPES.googleTagManager]: I18n.t('react.cabinet.tracking_codes.form.help_links.google_tag_manager'),
  [TRACKING_CODE_TYPES.facebookPixel]: I18n.t('react.cabinet.tracking_codes.form.help_links.facebook_pixel'),
  [TRACKING_CODE_TYPES.custom]: I18n.t('react.cabinet.tracking_codes.form.help_links.custom_tracking_codes'),
  [TRACKING_CODE_TYPES.tracify]: I18n.t('react.cabinet.tracking_codes.form.help_links.tracify'),
}

export const TRACKING_CODE_TYPES_OPTIONS = [
  {
    value: TRACKING_CODE_TYPES.googleAnalytics4,
    label: I18n.t('react.cabinet.tracking_codes.list.google_analytics_4'),
  },
  {
    value: TRACKING_CODE_TYPES.googleTagManager,
    label: I18n.t('react.cabinet.tracking_codes.list.google_tag_manager'),
  },
  {
    value: TRACKING_CODE_TYPES.facebookPixel,
    label: I18n.t('react.cabinet.tracking_codes.list.facebook_pixel'),
  },
  {
    value: TRACKING_CODE_TYPES.custom,
    label: I18n.t('react.cabinet.tracking_codes.form.custom'),
  },
]

export const TRACKING_EVENT_FORMS_OPTIONS = [
  {
    value: TRACKING_EVENT_FORMS.shop,
    label: I18n.t('react.cabinet.tracking_codes.form.event_shop_page'),
  },
  {
    value: TRACKING_EVENT_FORMS.product,
    label: I18n.t('react.cabinet.tracking_codes.form.event_product_page'),
  },
  {
    value: TRACKING_EVENT_FORMS.payment,
    label: I18n.t('react.cabinet.tracking_codes.form.event_show_payment'),
  },
  {
    value: TRACKING_EVENT_FORMS.funnel,
    label: I18n.t('react.cabinet.tracking_codes.form.event_show_funnel'),
  },
  {
    value: TRACKING_EVENT_FORMS.success,
    label: I18n.t('react.cabinet.tracking_codes.form.event_payment_success'),
  },
  {
    value: TRACKING_EVENT_FORMS.shopSubPage,
    label: I18n.t('react.cabinet.tracking_codes.form.event_shop_sub_page'),
  },
]

export const TRACKING_CODES_VALIDATIONS = {
  [TRACKING_CODE_TYPES.googleAnalytics]: 'UA-',
  [TRACKING_CODE_TYPES.googleAnalytics4]: 'G-',
}

export const TRACKING_CODES_VALIDATIONS_ERRORS = {
  [TRACKING_CODE_TYPES.googleAnalytics]: I18n.t('react.cabinet.tracking_codes.form.validations.google_analytics_3'),
  [TRACKING_CODE_TYPES.googleAnalytics4]: I18n.t('react.cabinet.tracking_codes.form.validations.google_analytics_4'),
}

export const TRACKING_CODES_PLACEHOLDERS = {
  [TRACKING_CODE_TYPES.googleAnalytics]: 'UA-XXXXXXX',
  [TRACKING_CODE_TYPES.googleAnalytics4]: 'G-XXXXXXX',
  [TRACKING_CODE_TYPES.googleTagManager]: 'GTM-XXXXXXX',
  [TRACKING_CODE_TYPES.facebookPixel]: 'XXXXXXXXXXXXXXXX',
}

export const TRACKING_EVENT_PARAMS = {
  [TRACKING_EVENT_FORMS.shop]: [],
  [TRACKING_EVENT_FORMS.product]: ['product_id', 'product_name', 'product_price', 'currency'],
  [TRACKING_EVENT_FORMS.payment]: [
    'product_id',
    'product_name',
    'product_price',
    'upsell_ids',
    'upsell_names',
    'currency',
  ],
  [TRACKING_EVENT_FORMS.funnel]: [
    'funnel_id',
    'funnel_name',
    'funnel_page_id',
    'funnel_page_name',
    'product_ids',
    'product_names',
  ],
  [TRACKING_EVENT_FORMS.success]: [
    'transaction_id',
    'order_id',
    'order_form',
    'period_type',
    'revenue',
    'product_ids',
    'product_names',
    'funnel_id',
    'funnel_name',
    'funnel_page_id',
    'funnel_page_name',
    'upsell_id',
    'upsell_name',
    'currency',
  ],
  [TRACKING_EVENT_FORMS.shopSubPage]: [],
}
