import { useContext, useMemo } from 'react'
import { getCookie, setCookie, deleteCookie } from 'cookies-next'
import { MobXProviderContext, enableStaticRendering } from 'mobx-react'

import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { isWindowEnv } from 'utils/env.utils'
import { ApiClient } from 'utils/requests.utils'
import { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { cookiesKey } from 'libs/common/cookies'
import { FINGER_PRINT_KEY } from 'utils/fingerprint.utils'

enableStaticRendering(typeof window === 'undefined')

export const getShopStores = (apiClient: ApiClient, apiClientV2: ApiClientV2) =>
  new ShopRootStore(apiClient, apiClientV2)

// Singleton - to avoid recreating shopRootStore on client side on client-side page transitions through Link/router.push
// https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props#when-does-getserversideprops-run
let shopRootStore

function initializeStore({ initialData, locale }) {
  // reinitialise stores per each request on SSR and only once on CSR
  if (!isWindowEnv()) {
    const store = getShopStores(
      new ApiClient({ userLocale: locale }),
      new ApiClientV2({
        userLocale: locale,
        debugMode: false,
        accessToken: '',
        refreshToken: '',
        fingerprint: '',
        setAuthorizationCookies: () => {},
        removeAuthorizationCookies: () => {},
      })
    )

    store.hydrate(initialData)

    return store
  } else {
    // CSR
    const shouldCreateStores = typeof shopRootStore === 'undefined'

    if (shouldCreateStores) {
      const accessToken = getCookie(cookiesKey('access_token')) as string
      const refreshToken = getCookie(cookiesKey('refresh_token')) as string
      const fingerprint = getCookie(cookiesKey(FINGER_PRINT_KEY)) as string

      shopRootStore = getShopStores(
        new ApiClient({ userLocale: locale }),
        new ApiClientV2({
          userLocale: locale,
          debugMode: false,
          accessToken,
          refreshToken,
          fingerprint,
          setAuthorizationCookies: ({ accessToken, refreshToken, expiresAt, refreshExpiresAt }) => {
            const expires = new Date(expiresAt)
            const refreshExpires = new Date(refreshExpiresAt)
            setCookie(cookiesKey('access_token'), accessToken, {
              expires,
            })
            setCookie(cookiesKey('refresh_token'), refreshToken, {
              expires: refreshExpires,
            })
          },
          removeAuthorizationCookies: () => {
            deleteCookie(cookiesKey('access_token'))
            deleteCookie(cookiesKey('refresh_token'))
            deleteCookie(cookiesKey(FINGER_PRINT_KEY))
          },
        })
      )
    }

    shopRootStore.hydrate(initialData)

    return shopRootStore
  }
}

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState])
}

export const useShopStores = () => useContext(MobXProviderContext) as ShopRootStore
