import React from 'react'

import { useI18n } from '@elo-kit/components/i18n/i18n'
import { EloModal } from '@elo-kit/components/elo-ui/elo-modal'

const EloModalBlock = (props) => {
  const I18n = useI18n()

  return (
    <EloModal
      {...props}
      submitButtonChild={props.submitButtonChild || I18n.t('react.shared.button.save')}
      cancelButtonChild={
        props.cancelButtonChild ||
        (props.submit ? I18n.t('react.shared.button.cancel') : I18n.t('react.shared.button.close'))
      }
    />
  )
}

EloModalBlock.Message = EloModal.Message
export default EloModalBlock
