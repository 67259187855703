import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface Params {
  username: string
}

export interface CourseTheme {
  id: number
  form: string
}

const BASE_URL = (username: string): string => `/shop/${username}/course_themes`

export const createCourseThemesApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: number, data: Params) => GET<Response<CourseTheme>>(`${BASE_URL(data.username)}/${id}`, data),
})

export type CourseThemesApi = ReturnType<typeof createCourseThemesApi>
