import { COLORS } from '@elo-kit/constants/general.constants'
import { HEADER } from '@elo-kit/constants/contentPage.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const headerSharingOptionsStyles = (theme) => {
  const { eloBlue, eloWhite } = COLORS

  return {
    socials: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      background: theme[HEADER.socialsColor] || eloBlue,
      border: theme[HEADER.socialsColor] ? 'none' : 'dashed 1px #00000021',
      borderRadius: '50%',
      color: eloWhite,
      margin: '0 5px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        opacity: 0.8,
        color: eloWhite,
      },
    },
    sharingOptions: {
      background: theme[HEADER.sharingOptionsColor] || eloBlue,
      border: theme[HEADER.sharingOptionsColor] ? 'none' : 'dashed 1px #00000021',
      position: 'relative',
      '&:hover .submenus': {
        display: 'flex',
      },
      '& .fa-share-alt': {
        fontSize: 14,
      },
      '@media (max-width: 576px)': {
        '&:hover .submenus': {
          display: 'block',
        },
      },
    },
    sharingOptionsContainer: {
      position: 'absolute',
      minWidth: 'max-content',
      borderRadius: '6px',
      background: theme[HEADER.background] || eloWhite,
      display: 'none',
      top: '100%',
      right: 0,
    },
    submenuItem: {
      display: 'block',
      margin: '15px 20px',
      color: theme[HEADER.menuColor] || eloBlue,
      fontSize: theme[HEADER.menuSize] ? `${theme[HEADER.menuSize]}px` : '15px',
      fontFamily: cleanupFonts(theme[HEADER.menuFont]) || 'Montserrat Medium',
      textDecoration: 'none',
      ...getFontStyles(theme[HEADER.menuStyle]),
      '&:hover': {
        opacity: 0.8,
        color: theme[HEADER.menuColor] || eloBlue,
      },
    },
    sharingOptionBtn: {
      border: 'none',
      margin: '10px 5px',
      backgroundColor: 'transparent',
      '&:focus': {
        outline: 'none',
      },
      '& i': {
        fontSize: 20,
      },
      '& .fa-facebook-square': {
        color: '#2A5DBD',
      },
      '& .fa-google-plus-square': {
        color: '#E81C00',
      },
      '& .fa-twitter-square': {
        color: '#41CFEE',
      },
      '& .fa-linkedin': {
        color: '#007BB6',
      },
      '& .fa-tumblr-square': {
        color: '#B11500',
      },
      '& .fa-reddit-square': {
        color: '#FF4006',
      },
      '& .fa-pinterest-square': {
        color: '#B11500',
      },
    },
  }
}

export default headerSharingOptionsStyles
