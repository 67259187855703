import classNames from 'classnames'
import {
  SHARABLE_FORM,
  SHARING_PRIVACY_STATUS_ATTRIBUTES,
  SHARABLE_TYPE_ITEMS_PREVIEW_PATH,
  SHARABLE_TYPE,
  MULTI_SHARING_TABLES_HEADERS,
} from 'constants/sharing.constants'
import { isWindowEnv } from 'utils/env.utils'

export const isSharingPreviewRoute = (routerPathname) => {
  const { pathname } = isWindowEnv() ? window.location : { pathname: routerPathname }
  return (
    !!pathname.includes('shop_theme_preview') ||
    !!pathname.includes('membership_theme_preview') ||
    !!pathname.includes('course_theme_preview')
  )
}

export const getSharingStatusProps = (form, active) => {
  const isPublic = form === SHARABLE_FORM.public
  const privacyStatus = isPublic ? 'active' : 'notActive'
  const statusText = active ? I18n.t('shared.common.active') : I18n.t('shared.common.not_active')
  const statusClasses = classNames({
    'status-text--active': active,
    'status-text--canceled': !active,
  })

  return {
    statusText,
    statusClasses,
    privacyStatusProps: SHARING_PRIVACY_STATUS_ATTRIBUTES[privacyStatus],
  }
}

export const getMultiSharingInfo = (stores = {}, activeTab) => {
  const {
    productsStore,
    affiliateLandingsStore,
    paymentPageTemplatesStore,
    funnelsStore,
    addonThemesStore,
    shopThemesStore,
    membershipThemesStore,
    sharingsStore,
  } = stores

  const storesByType = {
    [SHARABLE_TYPE.product]: productsStore,
    [SHARABLE_TYPE.affiliateLanding]: affiliateLandingsStore,
    [SHARABLE_TYPE.paymentPage]: paymentPageTemplatesStore,
    [SHARABLE_TYPE.funnel]: funnelsStore,
    [SHARABLE_TYPE.addon]: addonThemesStore,
    [SHARABLE_TYPE.shop]: shopThemesStore,
    [SHARABLE_TYPE.membership]: membershipThemesStore,
    [SHARABLE_TYPE.multiItemsSharing]: sharingsStore,
  }

  return {
    store: storesByType[activeTab],
    tableHead: MULTI_SHARING_TABLES_HEADERS[activeTab],
  }
}

export const formatSharingItemsArray = (list) =>
  (list || []).reduce((prevValue, currentValue) => {
    const { id, sharableId, sharableType, sharable = {} } = currentValue
    return {
      ...prevValue,
      [sharableType]: [
        ...(prevValue[sharableType] || []),
        {
          id,
          sharableId,
          name: sharable?.name,
          form: sharable?.form,
        },
      ],
    }
  }, {})

export const detectSharingTypeByRoute = (path) =>
  (Object.entries(SHARABLE_TYPE_ITEMS_PREVIEW_PATH).find(([, value]) => path?.includes(value)) || [])[0]
