import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMapPinIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.25 4.75C8.59315 4.75 7.25 6.09315 7.25 7.75C7.25 9.40686 8.59315 10.75 10.25 10.75C11.9069 10.75 13.25 9.40686 13.25 7.75C13.25 6.09315 11.9069 4.75 10.25 4.75ZM8.25 7.75C8.25 6.64543 9.14543 5.75 10.25 5.75C11.3546 5.75 12.25 6.64543 12.25 7.75C12.25 8.85457 11.3546 9.75 10.25 9.75C9.14543 9.75 8.25 8.85457 8.25 7.75Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.96296 18.1594C9.96312 18.1595 9.96327 18.1596 10.25 17.75L9.96296 18.1594C10.1351 18.2799 10.3646 18.2801 10.5367 18.1596L10.25 17.75C10.5367 18.1596 10.5366 18.1597 10.5367 18.1596L10.5375 18.1591L10.5388 18.1581L10.5432 18.1551L10.5586 18.1441C10.5718 18.1347 10.5907 18.1211 10.615 18.1033C10.6636 18.0679 10.7337 18.0159 10.8222 17.9483C10.9992 17.813 11.2501 17.6147 11.5504 17.3595C12.1503 16.8496 12.9506 16.1094 13.7523 15.1875C15.3434 13.3577 17 10.7425 17 7.75C17 5.95979 16.2888 4.2429 15.023 2.97703C13.7571 1.71116 12.0402 1 10.25 1C8.45979 1 6.7429 1.71116 5.47703 2.97703C4.21116 4.2429 3.5 5.95979 3.5 7.75C3.5 10.7425 5.15658 13.3577 6.7477 15.1875C7.54937 16.1094 8.34975 16.8496 8.94961 17.3595C9.24989 17.6147 9.50085 17.813 9.67783 17.9483C9.76634 18.0159 9.83642 18.0679 9.885 18.1033C9.9093 18.1211 9.92822 18.1347 9.94139 18.1441L9.95679 18.1551L9.96116 18.1581L9.96296 18.1594ZM6.18414 3.68414C7.26247 2.6058 8.72501 2 10.25 2C11.775 2 13.2375 2.6058 14.3159 3.68414C15.3942 4.76247 16 6.22501 16 7.75C16 10.3825 14.5316 12.7673 12.9977 14.5313C12.2369 15.4062 11.4748 16.1113 10.9027 16.5975C10.6367 16.8236 10.4127 17.0017 10.25 17.1269C10.0873 17.0017 9.86325 16.8236 9.59726 16.5975C9.02525 16.1113 8.26314 15.4062 7.5023 14.5313C5.96842 12.7673 4.5 10.3825 4.5 7.75C4.5 6.22501 5.1058 4.76247 6.18414 3.68414Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
