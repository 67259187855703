import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloUserIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.30558 7.07339C6.30558 5.05626 7.95441 3.41162 10 3.41162C12.0456 3.41162 13.6945 5.05626 13.6945 7.07339C13.6945 9.09051 12.0456 10.7352 10 10.7352C7.95441 10.7352 6.30558 9.09051 6.30558 7.07339ZM10 1.91162C7.13644 1.91162 4.80558 4.21741 4.80558 7.07339C4.80558 8.96398 5.82701 10.6135 7.34823 11.5126C6.71542 11.7053 6.10357 11.9671 5.52462 12.2951C4.16296 13.0665 3.0305 14.1771 2.24248 15.5166C2.03245 15.8736 2.1516 16.3333 2.50861 16.5433C2.86563 16.7533 3.32531 16.6342 3.53534 16.2772C4.18852 15.1669 5.12913 14.2432 6.26401 13.6002C7.39898 12.9572 8.68755 12.6182 10 12.6182C11.3125 12.6182 12.6011 12.9572 13.736 13.6002C14.8709 14.2432 15.8115 15.1669 16.4647 16.2772C16.6747 16.6342 17.1344 16.7533 17.4914 16.5433C17.8484 16.3333 17.9676 15.8736 17.7576 15.5166C16.9696 14.1771 15.8371 13.0665 14.4754 12.2951C13.8965 11.9671 13.2846 11.7053 12.6518 11.5126C14.173 10.6135 15.1945 8.96398 15.1945 7.07339C15.1945 4.21741 12.8636 1.91162 10 1.91162Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
