import { FILE_EXTENSION_REGEX } from '../constants/regex.constants'
import { FILE_UNITS } from '../constants/files.constants'
import { B_BASE, KB_BASE, MB_BASE, GB_BASE, TB_BASE } from '../constants/general.constants'

import { formatBytes } from './formatters.utils'

export const getFileExtension = (fileName: string) => (fileName || '').split('.').pop() || ''

export const splitFileName = (fileName: string): string[] =>
  fileName
    .split('.')
    .reduce((acc, val, i, arr) => (i == arr.length - 1 ? [acc[0].substring(1), val] : [[acc[0], val].join('.')]), [])

export const getExtension = (fileName = '') => fileName && fileName.match(FILE_EXTENSION_REGEX)

export const convertToBytes = (value: number, units = '') => {
  const base =
    {
      pb: TB_BASE,
      tb: GB_BASE,
      gb: MB_BASE,
      mb: KB_BASE,
      kb: B_BASE,
    }[units.toLowerCase()] || 1

  return value * base
}

export const convertFromBytes = (bytes = 0) => {
  let unit = ''
  let value = bytes

  if (bytes < B_BASE) {
    unit = FILE_UNITS.bytes
  } else if (bytes < KB_BASE) {
    unit = FILE_UNITS.kb
    value /= B_BASE
  } else if (bytes < MB_BASE) {
    unit = FILE_UNITS.mb
    value /= KB_BASE
  } else if (bytes < GB_BASE) {
    unit = FILE_UNITS.gb
    value /= MB_BASE
  } else if (bytes < TB_BASE) {
    unit = FILE_UNITS.tb
    value /= GB_BASE
  } else {
    unit = FILE_UNITS.pb
    value /= TB_BASE
  }

  return `${formatBytes(value)} ${unit}`
}
