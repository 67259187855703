import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMainIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M18 4L10 11.3333L2 4'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 4H18V15.3333C18 15.5101 17.9298 15.6797 17.8047 15.8047C17.6797 15.9298 17.5101 16 17.3333 16H2.66667C2.48986 16 2.32029 15.9298 2.19526 15.8047C2.07024 15.6797 2 15.5101 2 15.3333V4Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.54171 10L2.20837 15.8083'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.7917 15.8083L11.4584 10'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
