import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpOneColumnThird = (props) => {
  const { height, viewBox, width } = props
  /* eslint-disable */
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='249' rx='8' />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -47.000000) '
            x='53'
            y='23'
            width='115'
            height='48'
            rx='4'
          />
          <path
            d='M136.940549,55 C138.052088,55 138.938534,55.8954305 138.938534,57 C138.938534,58.1122704 138.044006,59 136.940549,59 L136.940549,59 L101.997985,59 C100.886447,59 100,58.1045695 100,57 L100,57 L100.00548,56.8497808 C100.081752,55.8088611 100.944685,55 101.997985,55 L101.997985,55 Z M154.00693,45 C155.097692,45 156,45.8954305 156,47 C156,48.1122704 155.107672,49 154.00693,49 L154.00693,49 L101.99307,49 C100.902308,49 100,48.1045695 100,47 L100,47 L100.005467,46.8497808 C100.081551,45.8088611 100.942361,45 101.99307,45 L101.99307,45 Z M154.00693,35 C155.097692,35 156,35.8954305 156,37 C156,38.1122704 155.107672,39 154.00693,39 L154.00693,39 L101.99307,39 C100.902308,39 100,38.1045695 100,37 L100,37 L100.005467,36.8497808 C100.081551,35.8088611 100.942361,35 101.99307,35 L101.99307,35 Z'
            fill='#E5E3E5'
          />
          <rect fill='#E5E3E5' x='65' y='35' width='24' height='23.9835004' rx='4' />
          <path
            d='M81.875,52 C82.4963226,52 83,51.4403585 83,50.75 L83,43.25 C83,42.5596415 82.4963226,42 81.875,42 L72.125,42 C71.5036774,42 71,42.5596415 71,43.25 L71,50.75 C71,51.4403585 71.5036774,52 72.125,52 L81.875,52 Z M73.5,46 C72.6715785,46 72,45.3284215 72,44.5 C72,43.6715785 72.6715785,43 73.5,43 C74.3284215,43 75,43.6715785 75,44.5 C75,45.3284215 74.3284215,46 73.5,46 Z M82,50 L73,50 L73,48.817906 L74.6761017,47.0567178 C74.785965,46.9413029 74.964035,46.9413029 75.0738754,47.0567178 L76.375,48.4238747 L79.5511017,45.0865611 C79.660965,44.9711463 79.839035,44.9711463 79.9488754,45.0865611 L82,47.2417807 L82,50 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 98.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -98.000000) '
            x='53'
            y='79'
            width='115'
            height='38'
            rx='4'
          />
          <path
            d='M153.99328,101 C155.099341,101 156,101.895431 156,103 C156,104.11227 155.101561,105 153.99328,105 L153.99328,105 L67.00672,105 C65.900659,105 65,104.104569 65,103 L65,103 L65.0055042,102.849781 C65.0821098,101.808861 65.9488155,101 67.00672,101 L67.00672,101 Z M105.998,91 C107.101884,91 108,91.8954305 108,93 C108,94.1122704 107.103674,95 105.998,95 L105.998,95 L67.0019996,95 C65.8981161,95 65,94.1045695 65,93 L65,93 L65.0054912,92.8497808 C65.0819167,91.8088611 65.9465836,91 67.0019996,91 L67.0019996,91 Z'
            fill='#E5E3E5'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 183.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -183.000000) '
            x='53'
            y='125'
            width='115'
            height='116'
            rx='4'
          />
          <path
            d='M153.99328,156 C155.099341,156 156,156.895431 156,158 C156,159.11227 155.101561,160 153.99328,160 L153.99328,160 L67.00672,160 C65.900659,160 65,159.104569 65,158 L65,158 L65.0055042,157.849781 C65.0821098,156.808861 65.9488155,156 67.00672,156 L67.00672,156 Z M153.99328,147 C155.099341,147 156,147.895431 156,149 C156,150.11227 155.101561,151 153.99328,151 L153.99328,151 L67.00672,151 C65.900659,151 65,150.104569 65,149 L65,149 L65.0055042,148.849781 C65.0821098,147.808861 65.9488155,147 67.00672,147 L67.00672,147 Z M105.998,137 C107.101884,137 108,137.895431 108,139 C108,140.11227 107.103674,141 105.998,141 L105.998,141 L67.0019996,141 C65.8981161,141 65,140.104569 65,139 L65,139 L65.0054912,138.849781 C65.0819167,137.808861 65.9465836,137 67.0019996,137 L67.0019996,137 Z'
            fill='#E5E3E5'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='65' y='213' width='90' height='15.821267' rx='7.91063348' />
          <rect
            fill='#B8B9BC'
            fillRule='nonzero'
            transform='translate(110.000000, 186.000000) scale(1, -1) rotate(-180.000000) translate(-110.000000, -186.000000) '
            x='65'
            y='167'
            width='90'
            height='38'
            rx='4'
          />
          <path
            d='M120.963284,195.831683 C122.332381,195.831683 123.442245,194.72182 123.442245,193.352723 L123.442245,178.47896 C123.442245,177.109863 122.332381,176 120.963284,176 L99.4789604,176 C98.1098633,176 97,177.109863 97,178.47896 L97,193.352723 C97,194.72182 98.1098633,195.831683 99.4789604,195.831683 L120.963284,195.831683 Z M102.784241,184.676362 C101.186978,184.676362 99.8921205,183.381504 99.8921205,181.784241 C99.8921205,180.186978 101.186978,178.892121 102.784241,178.892121 C104.381504,178.892121 105.676362,180.186978 105.676362,181.784241 C105.676362,183.381504 104.381504,184.676362 102.784241,184.676362 Z M120.136964,192.526403 L100.305281,192.526403 L100.305281,190.047442 L103.998605,186.354068 C104.240691,186.112032 104.633072,186.112032 104.875107,186.354068 L107.742162,189.221122 L114.740767,182.222467 C114.982853,181.980432 115.375234,181.980432 115.617269,182.222467 L120.136964,186.742162 L120.136964,192.526403 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
            opacity='0.5'
          />
          <path
            d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
            fill='#D7DADF'
          />
          <path
            d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
            fill='#FFFFFF'
          />
          <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
        </g>
      </g>
    </svg>
  )
  /* eslint-enable */
}

BumpOneColumnThird.displayName = 'BumpOneColumnThird'
BumpOneColumnThird.propTypes = propTypes
BumpOneColumnThird.defaultProps = defaultProps
