export const FILE_UPLOAD_DEFAULT_ACCEPTED_FILES = 'audio/*, video/*, image/*, application/*, text/*'
export const FILE_UPLOAD_ACCEPT_VIDEO_FILES = 'video/mp4, video/x-m4v, video/*'
export const FILE_UPLOAD_ACCEPT_IMAGE_FILES = 'image/*'
export const FILE_UPLOAD_ACCEPT_AUDIO_FILES = 'audio/mp3, audio/flac, audio/aac, audio/wav'
export const FILE_UPLOAD_ACCEPT_TEXT_FILES = 'text/*'
export const FILE_UPLOAD_DOCUMENT_FILES = 'image/*, application/pdf'
export const FILE_UPLOAD_PDF_FILES = 'application/pdf'
export const FILE_UPLOAD_ALL_FILES = 'all/'
export const FILE_UPLOAD_CER_FILES = '.cer'
export const FILE_UPLOAD_P12_FILES = '.p12'
export const FILE_UPLOAD_MOBILE_PROVISION_FILES = '.mobileprovision'
export const FILE_UPLOAD_JSON_FILES = '.json'
export const FILE_UPLOAD_ACCEPT_IMAGE_PNG = '.png'
export const FILE_ACCEPT_VERIFICATION_FILES = [FILE_UPLOAD_ACCEPT_IMAGE_FILES, FILE_UPLOAD_PDF_FILES].join(', ')
export const FILE_UPLOAD_IOS_PUSH_NOTIFICATION_FILES = '.plist'
export const FILE_UPLOAD_P8_FILES = '.p8'

export const FILE_ICONS = {
  pdf: 'fas fa-file-pdf',
  doc: 'fas fa-file-word',
  docx: 'fas fa-file-word',
  mp4: 'fas fa-video',
  m4v: 'fas fa-video',
  avi: 'fas fa-video',
  mkv: 'fas fa-video',
  jpeg: 'fas fa-images',
  jpg: 'fas fa-images',
  png: 'fas fa-images',
  raw: 'fas fa-images',
  mp3: 'fas fa-file-audio',
  flac: 'fas fa-file-audio',
  wav: 'fas fa-file-audio',
}

export const FILE_TYPES = {
  picture: 'picture',
  video: 'video',
  audio: 'audio',
}

export const CONTENT_TYPES = {
  picture: 'image',
  audio: 'audio',
}

export const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'raw']

export const VIDEO_EXTENSIONS = ['avi', 'flv', 'mov', 'm4v', 'mp4', 'mkv', 'wmv', 'rm', 'qt']

export const FILE_UNITS = {
  bytes: 'B',
  kb: 'KB',
  mb: 'MB',
  gb: 'GB',
  tb: 'TB',
  pb: 'PB',
  eb: 'EB',
  zb: 'ZB',
  yb: 'YB',
}

export const FILE_UNITS_LIST = Object.values(FILE_UNITS)
