export const AFFILIATE_NODE_STATE_OPTIONS = [
  {
    label: I18n.t('shared.common.requested'),
    value: 'requested',
  },
  {
    label: I18n.t('shared.common.active'),
    value: 'accepted',
  },
  {
    label: I18n.t('shared.common.rejected'),
    value: 'rejected',
  },
  {
    label: I18n.t('shared.common.canceled'),
    value: 'canceled',
  },
]

export const AFFILIATE_NODES_FILTERS_LIST = ['affiliateProgram', 'affiliteNodeState']

export const AFFILIATE_NODE_STATE_KEYS = {
  requested: 'requested',
  accepted: 'accepted',
  rejected: 'rejected',
  canceled: 'canceled',
}

export const SELLER_AFFILIATE_NODES_CSV_PARAMS = [
  {
    label: I18n.t('shared.common.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('shared.common.prid'),
    sortingKey: 'affiliate_program_id',
  },
  {
    label: I18n.t('shared.common.invited_at'),
    sortingKey: 'created_at',
  },
  {
    label: I18n.t('shared.common.status'),
    sortingKey: 'state',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.fromLandingPage'),
    sortingKey: 'from_landing_page',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.bonus'),
    sortingKey: 'bonus',
  },
  {
    label: I18n.t('shared.common.pid'),
    sortingKey: 'publisher_id',
  },
  {
    label: I18n.t('shared.common.name'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('shared.common.email'),
    sortingKey: 'email',
  },
  {
    label: I18n.t('shared.common.program'),
    sortingKey: 'affiliate_program',
  },
]

export const PUBLISHER_AFFILIATE_NODES_CSV_PARAMS = [
  {
    label: I18n.t('shared.common.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('shared.common.emails'),
    sortingKey: 'email',
  },
  {
    label: I18n.t('shared.common.prid'),
    sortingKey: 'affiliate_program_id',
  },
  {
    label: I18n.t('shared.common.invited_at'),
    sortingKey: 'created_at',
  },
  {
    label: I18n.t('shared.common.status'),
    sortingKey: 'state',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.fromLandingPage'),
    sortingKey: 'from_landing_page',
  },
]
