export const SHARABLE_TYPE = {
  shop: 'ShopTheme',
  membership: 'MembershipTheme',
  product: 'Product',
  addon: 'AddonTheme',
  paymentPage: 'PaymentPageTemplate',
  affiliateLanding: 'AffiliateLanding',
  funnel: 'Funnel',
  multiItemsSharing: 'MultiItemsSharing',
}

export const SHARABLE_FORM = {
  public: 'public',
  private: 'private',
}

export const COMMON_TABLE_HEADERS = [
  {
    label: '',
  },
  {
    label: I18n.t('shared.common.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('shared.common.name'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('shared.common.created'),
    sortingKey: 'created_at',
  },
]

export const MULTI_SHARING_TABS = [
  {
    key: SHARABLE_TYPE.product,
    label: I18n.t('react.shared.products'),
  },
  {
    key: SHARABLE_TYPE.affiliateLanding,
    label: I18n.t('react.shared.affiliate_landings'),
  },
  {
    key: SHARABLE_TYPE.funnel,
    label: I18n.t('react.cabinet.funnels.title'),
  },
  {
    key: SHARABLE_TYPE.paymentPage,
    label: I18n.t('react.shared.nav.checkout_pages'),
  },
  {
    key: SHARABLE_TYPE.addon,
    label: I18n.t('react.cabinet.order_bumps.title'),
  },
  {
    key: SHARABLE_TYPE.shop,
    label: I18n.t('react.shared.shop_themes'),
  },
  {
    key: SHARABLE_TYPE.membership,
    label: I18n.t('react.shared.membership_themes'),
  },
]

export const PRODUCTS_TABLE_HEADERS = [
  ...COMMON_TABLE_HEADERS,
  {
    label: I18n.t('react.cabinet.common.price'),
  },
]

export const AFFILIATE_LANDINGS_TABLE_HEADERS = [
  ...COMMON_TABLE_HEADERS,
  {
    label: I18n.t('react.shared.affiliateProgram.affiliate_program_ids'),
  },
  {
    label: I18n.t('shared.common.link'),
  },
]

export const FUNNELS_TABLE_HEADERS = COMMON_TABLE_HEADERS

export const CHECKOUT_PAGES_TABLE_HEADERS = [
  ...COMMON_TABLE_HEADERS,
  {
    label: I18n.t('react.common.layout_type'),
    sortingKey: 'layout',
  },
]

export const ORDER_BUMPS_TABLE_HEADERS = [
  ...COMMON_TABLE_HEADERS,
  {
    label: I18n.t('shared.common.applied'),
  },
  {
    label: I18n.t('shared.common.type'),
  },
]

export const SHOP_THEMES_TABLE_HEADERS = [
  ...COMMON_TABLE_HEADERS,
  {
    label: I18n.t('react.cabinet.common.preview'),
  },
]

export const MEMBERSHIP_THEMES_TABLE_HEADERS = [
  ...COMMON_TABLE_HEADERS,
  {
    label: I18n.t('react.cabinet.common.applied'),
  },
]

export const MULTI_SHARING_TABLES_HEADERS = {
  [SHARABLE_TYPE.product]: PRODUCTS_TABLE_HEADERS,
  [SHARABLE_TYPE.affiliateLanding]: AFFILIATE_LANDINGS_TABLE_HEADERS,
  [SHARABLE_TYPE.funnel]: FUNNELS_TABLE_HEADERS,
  [SHARABLE_TYPE.paymentPage]: CHECKOUT_PAGES_TABLE_HEADERS,
  [SHARABLE_TYPE.addon]: ORDER_BUMPS_TABLE_HEADERS,
  [SHARABLE_TYPE.shop]: SHOP_THEMES_TABLE_HEADERS,
  [SHARABLE_TYPE.membership]: MEMBERSHIP_THEMES_TABLE_HEADERS,
}

export const SHARABLE_TYPE_ITEMS_PREVIEW_PATH = {
  [SHARABLE_TYPE.product]: 'product_preview',
  [SHARABLE_TYPE.affiliateLanding]: 'affiliate_landing_preview',
  [SHARABLE_TYPE.funnel]: 'funnel_preview',
  [SHARABLE_TYPE.paymentPage]: 'payment_page_template_preview',
  [SHARABLE_TYPE.addon]: 'addon_theme_preview',
  [SHARABLE_TYPE.multiItemsSharing]: 'multiple_items_sharing_preview',
  [SHARABLE_TYPE.shop]: 'shop_theme_preview',
  [SHARABLE_TYPE.membership]: 'membership_theme_preview',
}

export const SHARABLE_TYPE_ITEMS_PREVIEW_PATH_LIST = Object.values(SHARABLE_TYPE_ITEMS_PREVIEW_PATH)

export const MULTI_SHARING_STEPS_KEYS = {
  select: 'select',
  edit: 'edit',
  generate: 'generate',
  link: 'link',
}

export const MULTI_SHARING_ITEMS_MODAL_TEXTS = {
  [MULTI_SHARING_STEPS_KEYS.select]: {
    title: I18n.t('react.cabinet.multiple_items_sharing.share_modal.select_step.title'),
    message: I18n.t('react.cabinet.multiple_items_sharing.share_modal.select_step.message'),
  },
  [MULTI_SHARING_STEPS_KEYS.edit]: {
    title: I18n.t('react.cabinet.multiple_items_sharing.share_modal.edit_step.create_title'),
    message: I18n.t('react.cabinet.multiple_items_sharing.share_modal.edit_step.create_message'),
  },
  [MULTI_SHARING_STEPS_KEYS.generate]: {
    title: I18n.t('react.cabinet.multiple_items_sharing.share_modal.generate_step.title'),
    message: I18n.t('react.cabinet.multiple_items_sharing.share_modal.generate_step.message'),
  },
  [MULTI_SHARING_STEPS_KEYS.link]: {
    title: I18n.t('react.cabinet.multiple_items_sharing.share_modal.link_step.title'),
    message: I18n.t('react.cabinet.multiple_items_sharing.share_modal.link_step.message'),
  },
}

export const SHARING_PRIVACY_STATUS_ATTRIBUTES = {
  active: {
    iconClasses: 'fas fa-users',
    text: I18n.t('react.shared.public'),
    statusClasses: 'elo-table__title status-text--active',
  },
  notActive: {
    iconClasses: 'fas fa-key',
    text: I18n.t('react.shared.private'),
    statusClasses: 'elo-table__title elo-table__text-gray',
  },
}

export const getTemplateTypeOptions = () => [
  {
    value: 'download',
    label: I18n.t('shared.product_form.download'),
  },
  {
    value: 'event',
    label: I18n.t('shared.product_form.event'),
  },
  {
    value: 'course',
    label: I18n.t('shared.product_form.course'),
  },
  {
    value: 'service',
    label: I18n.t('shared.product_form.service'),
  },
  {
    value: 'membership',
    label: I18n.t('shared.product_form.membership'),
  },
  {
    value: 'license_key',
    label: I18n.t('shared.product_form.license_key'),
  },
  {
    value: 'certificate',
    label: I18n.t('shared.product_form.certificate'),
  },
  {
    value: 'affiliate_page',
    label: I18n.t('react.shared.affiliate_landings'),
  },
  {
    value: 'funnel',
    label: I18n.t('react.shared.funnels.title'),
  },
  {
    value: 'checkout_page',
    label: I18n.t('react.shared.nav.checkout_pages'),
  },
  {
    value: 'order_bump',
    label: I18n.t('react.shared.order_bumps.title'),
  },
  {
    value: 'shop_theme',
    label: I18n.t('react.shared.shop_themes'),
  },
  {
    value: 'membership_theme',
    label: I18n.t('react.shared.membership_themes'),
  },
]
