export const PRODUCTS_LIST = {
  productFont: 'productFont',
  productStyle: 'productStyle',
  productColor: 'productColor',
  productSize: 'productSize',
  productBackground: 'productBackground',
  progress: 'progress',
  progressColor: 'progressColor',
  progressBackground: 'progressBackground',
  background: 'backgroundColor',
  priceFont: 'priceFont',
  priceStyle: 'priceStyle',
  priceColor: 'priceColor',
  priceBackground: 'priceBackground',
  priceSize: 'priceSize',
  view: 'view',
  showButton: 'showButton',
  buttonText: 'buttonText',
  buttonFont: 'buttonFont',
  buttonStyle: 'buttonStyle',
  buttonSize: 'buttonSize',
  btnTextColor: 'buttonTextColor',
  btnColor: 'buttonColor',
  descriptionFont: 'descriptionFont',
  descriptionStyle: 'descriptionStyle',
  descriptionColor: 'descriptionColor',
  descriptionSize: 'descriptionSize',
  currencyFont: 'currencyFont',
  currencyStyle: 'currencyStyle',
  currencyColor: 'currencyColor',
  currencySize: 'currencySize',
  oldPriceFont: 'oldPriceFont',
  oldPriceStyle: 'oldPriceStyle',
  oldPriceColor: 'oldPriceColor',
  oldPriceSize: 'oldPriceSize',
  showCategories: 'showCategories',
  categoryFont: 'categoryFont',
  categoryStyle: 'categoryStyle',
  categoryColor: 'categoryColor',
  categorySize: 'categorySize',
  categoryActiveColor: 'categoryActiveColor',
  categoryBorderColor: 'categoryBorderColor',
  categoryDescriptionFont: 'categoryDescriptionFont',
  categoryDescriptionStyle: 'categoryDescriptionStyle',
  categoryDescriptionColor: 'categoryDescriptionColor',
  categoryDescriptionSize: 'categoryDescriptionSize',
}

export const getDefaultProductsList = () => [
  {
    name: `${I18n.t('react.shared.product')} 1`,
    form: 'digital',
    id: 'product-list-item-1',
    description: I18n.t('react.shared.product_description_default'),
    free: true,
  },
  {
    name: `${I18n.t('react.shared.product')} 2`,
    form: 'digital',
    id: 'product-list-item-2',
    free: false,
    displayPrice: 50,
    displayOldPrice: 100,
    displayCurrencyId: 2,
    description: I18n.t('react.shared.product_description_default'),
  },
  {
    name: `${I18n.t('react.shared.product')} 3`,
    form: 'digital',
    id: 'product-list-item-3',
    free: false,
    displayPrice: 100,
    displayOldPrice: 250,
    displayCurrencyId: 1,
    pricingPlans: [
      {
        currencyKey: 1,
        useNetPrice: true,
      },
    ],
    description: I18n.t('react.shared.product_description_default'),
  },
]

export const getAllProductsCategories = () => ({
  color: '#1E84D7',
  description: I18n.t('react.shared.category_description'),
  hidden: false,
  id: 0,
  position: 0,
  productIds: [],
  title: `${I18n.t('react.shared.all_products')}`,
})

export const DEFAULT_SORTING_DIR = {
  position: 'asc',
  created_at: 'desc',
}

export const ALL_PRODUCTS_CATEGORY_OPTION = {
  value: 0,
  label: I18n.t('react.shared.all_products'),
}

export const DEFAULT_CATEGORIES = [
  {
    color: '#FFB000',
    description: `${I18n.t('react.shared.category_description')} 1`,
    hidden: false,
    id: 1,
    position: 1,
    productIds: [],
    title: `${I18n.t('react.shared.category')} 1`,
  },
  {
    color: '#FFB000',
    description: `${I18n.t('react.shared.category_description')} 2`,
    hidden: false,
    id: 2,
    position: 2,
    productIds: [],
    title: `${I18n.t('react.shared.category')} 2`,
  },
]

export const DEFAULT_CATEGORY_OPTIONS = [
  {
    value: 1,
    label: `${I18n.t('react.shared.category')} 1`,
  },
  {
    value: 2,
    label: `${I18n.t('react.shared.category')} 2`,
  },
]

export const BLOCK_VIEW = {
  grid: 'grid',
  list: 'list',
  slider: 'slider',
  button: 'button',
  text: 'text',
  countdown: 'countdown',
  modal: 'modal',
  progressBar: 'progressBar',
}

export const BUTTON_TYPE = {
  buy: 'buy',
  details: 'details',
  customLink: 'customLink',
  individualLink: 'individualLink',
  downloadFile: 'downloadFile',
  lessonLink: 'lessonLink',
}

export const TEXT = {
  text: 'text',
  background: 'backgroundColor',
  paddingTop: 'paddingTop',
  paddingBottom: 'paddingBottom',
  paddingRight: 'paddingRight',
  paddingLeft: 'paddingLeft',
}

export const DEFAULT_TEXT_PADDING = 15

export const MARKETING_TOOLS_PROGRESS_BAR_SECTION_WIDTH = 19

export const PDF_FILE = {
  backgroundColor: 'backgroundColor',
  backgroundImage: 'backgroundImage',
  transparency: 'transparency',
  buttonColor: 'buttonColor',
  toolbarButtonSize: 'toolbarButtonSize',
  toolbarBackgroundColor: 'toolbarBackgroundColor',
  toolbarFontSize: 'toolbarFontSize',
  toolbarFont: 'toolbarFont',
  toolbarFontStyle: 'toolbarFontStyle',
  toolbarColor: 'toolbarColor',
  progressBackgroundColor: 'progressBackgroundColor',
  downloadFile: 'downloadFile',
}

const DEFAULT_MENU_ITEMS = [
  {
    name: 'react.shared.menu_item',
    url: '/',
    id: 'menu-item-1',
  },
  {
    name: 'react.shared.menu_item',
    url: '/',
    id: 'menu-item-2',
  },
  {
    name: 'react.shared.menu_item',
    url: '/',
    id: 'menu-item-3',
  },
]

export const DEFAULT_SECTION_MENU_ITEMS = [
  {
    name: 'react.shared.section',
    id: 'section-item-1',
    submenu: DEFAULT_MENU_ITEMS,
  },
  {
    name: 'react.shared.section',
    id: 'section-item-2',
    submenu: DEFAULT_MENU_ITEMS,
  },
]

export const VIDEO_TYPE = {
  elopage: 'elopage',
  vimeo: 'vimeo',
}

export const HEADER = {
  background: 'backgroundColor',
  logo: 'logo',
  menuFont: 'menuFont',
  menuStyle: 'menuStyle',
  menuColor: 'menuColor',
  menuSize: 'menuSize',
  website: 'website',
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  youtube: 'youtube',
  tumblr: 'tumblr',
  pinterest: 'pinterest',
  linkedIn: 'linkedin',
  xing: 'xing',
  tiktok: 'tiktok',
  socialsColor: 'socialsColor',
  menuList: 'menuList',
  accountFont: 'accountFont',
  accountStyle: 'accountStyle',
  accountColor: 'accountColor',
  accountSize: 'accountSize',
  iconColor: 'iconColor',
  languageSwitcher: 'languageSwitcher',
  languageFont: 'languageFont',
  languageStyle: 'languageStyle',
  languageColor: 'languageColor',
  languageSize: 'languageSize',
  sharingOptions: 'sharingOptions',
  sharingOptionsColor: 'sharingOptionsColor',
  myAccountSwitcher: 'myAccountSwitcher',
  stickyHeader: 'stickyHeader',
  showCancelSubscription: 'showCancelSubscription',
}

export const DEFAULT_TEXT_PERCENT_PADDING = {
  paddingTop: 5,
  paddingRight: 5,
  paddingBottom: 5,
  paddingLeft: 5,
}

export const PAYMENT_OPTION = {
  autopay: 'autopay',
  checkout: 'checkout',
}

export const DISPLAY_OPTION = {
  display: 'display',
  hide: 'hide',
}

export const DEFAULT_PICTURE_PADDING = 0

export const DEFAULT_VIDEO_WIDTH = 100

export const DEFAULT_VIDEO_PADDING = 0

export const DEFAULT_VIDEO_MAX_WIDTH = 5000

export const DEFAULT_FILES_PADDING = 15

export const DEFAULT_FILE_MAX_WIDTH = 5000

export const DEFAULT_SORTING_DIR_KEYS = {
  position: 'position',
  createdAt: 'created_at',
}

export const DEFAULT_BANNER_PADDING = 15

export const BLOCK_LAYOUT_OPTIONS = {
  left: 'left',
  center: 'center',
}

export const MENU_TYPE = {
  link: 'link',
  dropdown: 'dropdown',
}

export const MENU_ITEM_DEFAULT = {
  id: 'DEFAULT',
  name: '',
  url: '',
  linkType: 'custom',
  submenu: new Map(),
}

export const MENU_ITEM_PROPS = {
  name: 'name',
  url: 'url',
  submenu: 'submenu',
  linkType: 'linkType',
}

export const SOCIALS = [
  'website',
  'facebook',
  'instagram',
  'twitter',
  'youtube',
  'tumblr',
  'pinterest',
  'linkedin',
  'xing',
  'tiktok',
]

export const FOOTER = {
  background: 'backgroundColor',
  menuFont: 'menuFont',
  menuStyle: 'menuStyle',
  menuColor: 'menuColor',
  menuSize: 'menuSize',
  submenuFont: 'submenuFont',
  submenuStyle: 'submenuStyle',
  submenuColor: 'submenuColor',
  submenuSize: 'submenuSize',
  website: 'website',
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  youtube: 'youtube',
  tumblr: 'tumblr',
  pinterest: 'pinterest',
  linkedIn: 'linkedIn',
  xing: 'xing',
  tiktok: 'tiktok',
  socialsColor: 'socialsColor',
  menuList: 'menuList',
  socialsTitle: 'socialsTitle',
  socialsIsShown: 'socialsIsShown',
}

export const SECTION_MENU_ITEM_DEFAULT = {
  id: 'DEFAULT',
  name: '',
  submenu: new Map(),
}

export const FOOTER_SECTION_MENU_ITEM_DEFAULT = {
  ...SECTION_MENU_ITEM_DEFAULT,
  default: false,
}

export const TESTIMONIALS = {
  textFont: 'textFont',
  textStyle: 'textStyle',
  textColor: 'textColor',
  textSize: 'textSize',

  usernamesFont: 'usernamesFont',
  usernamesStyle: 'usernamesStyle',
  usernamesColor: 'usernamesColor',
  usernamesSize: 'usernamesSize',

  backgroundImage: 'backgroundImage',
  backgroundColor: 'backgroundColor',
  transparency: 'transparency',

  arrowsColor: 'arrowsColor',
  testimonials: 'testimonials',

  testimonialId: 'id',
  testimonialAvatar: 'avatar',
  testimonialFullName: 'fullName',
  testimonialText: 'text',
}
