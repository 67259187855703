import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const cookiesConsentSimpleStyles = (theme = {}) => {
  const { eloBlue, eloWhite } = COLORS
  const font = cleanupFonts(theme.font)
  return {
    preview: {
      '&.elo-snackbar': {
        position: 'initial',
        marginBottom: 30,
        pointerEvents: 'none',
      },
    },
    categoriesListPreview: {
      '@media (max-width: 1500px)': {
        flexWrap: 'wrap',
        marginTop: 25,
        '& .field': {
          minWidth: 240,
          marginBottom: 17,
        },
        '& .checkbox-field__label': {
          marginRight: 20,
        },
      },
    },
    simpleConsent: {
      backgroundColor: `${theme.background || '#000000'}b3`,
      color: theme.color || eloWhite,
      fontFamily: font || 'Montserrat Reg',
      zIndex: 9999999,
      '& .elo-snackbar__content a': {
        color: theme.linkColor || eloBlue,
        fontFamily: font || 'Montserrat Reg',
      },
      '& .elo-btn:last-child': {
        color: theme.color || eloWhite,
        fontFamily: font || 'Montserrat Reg',
        backgroundColor: `${theme.linkColor || eloBlue} !important`,
        '&:hover': {
          opacity: 0.9,
        },
        '&:active': {
          opacity: 0.8,
        },
      },
      '& .elo-snackbar__additional-btn': {
        color: `${theme.linkColor || eloBlue} !important`,
        fontFamily: font || 'Montserrat Reg',
        borderColor: `${theme.linkColor || eloBlue} !important`,
        backgroundColor: 'transparent !important',
        '&:hover': {
          opacity: 0.9,
        },
        '&:active': {
          opacity: 0.8,
        },
      },
      '& .elo-snackbar__settings-btn': {
        color: `${theme.linkColor || eloBlue} !important`,
        borderColor: `${theme.linkColor || eloBlue} !important`,
      },
    },
    simpleCategoriesList: {
      display: 'flex',
      marginTop: 10,
      '& .field': {
        marginBottom: 0,
      },
      '& .checkbox-field__label': {
        fontSize: '12px !important',
        textTransform: 'uppercase',
        fontFamily: `${font || 'Montserrat Medium'} !important`,
        fontWeight: 700,
        margin: '1px 40px 0 0',
        color: `${theme.color || eloWhite} !important`,

        '@media (max-width: 1100px)': {
          marginRight: 20,
        },
      },
      '& .custom-check-mark': {
        backgroundColor: 'transparent !important',
      },
      '& .checkbox-field__control input:checked ~ .custom-check-mark': {
        backgroundColor: `${theme.linkColor || eloBlue} !important`,
        borderColor: `${theme.linkColor || eloBlue} !important`,
      },
      '@media (max-width: 991px)': {
        flexWrap: 'wrap',
        marginTop: 25,
        '& .field': {
          minWidth: 240,
          marginBottom: 17,
        },
      },
      '@media (max-width: 700px)': {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        '& .field': {
          minWidth: 240,
          marginBottom: 20,
        },
      },
    },
  }
}

export default cookiesConsentSimpleStyles
