import { Fragment } from 'react'
import { get } from 'utils/lodash.utils'

export const fetchCustomSellerFonts = (customFonts) => {
  if (!customFonts.length) return

  const links = customFonts.map((fontItem, idx) => {
    const fontURL = get(fontItem, 'file.original', '')
    const fontFormat = get(fontItem, 'prefs.fontFormat', '')
    return <link key={idx} rel='preload' type={fontFormat} href={fontURL} as='font' crossOrigin='anonymous' />
  })
  const styles = customFonts.map((fontItem, idx) => {
    const fontURL = get(fontItem, 'file.original', '')
    const fontName = get(fontItem, 'prefs.fontName', '')
    const fontWeight = get(fontItem, 'prefs.fontWeight', '')
    const fontStyle = get(fontItem, 'prefs.fontStyle', '')
    const fontDisplay = get(fontItem, 'prefs.fontDisplay', '')
    return (
      <style key={idx}>{`
        @font-face {
          font-family: '${fontName}';
          font-style: ${fontStyle};
          font-weight: ${Number(fontWeight)};
          font-display: ${fontDisplay};
          src: url('${fontURL}');
        }
      `}</style>
    )
  })

  return (
    <Fragment>
      {links}
      {styles}
    </Fragment>
  )
}
