import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { SharingsApi, createSharingsApi } from 'shop/api/sharings.api'

export class SharingsStore extends SharedStore<any> {
  storeName = 'SharingsStore'
  declare childApi: SharingsApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createSharingsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }
}
