import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloArrowRightIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.92 3.21283C10.6271 2.91994 10.1522 2.91994 9.85935 3.21283C9.56646 3.50573 9.56646 3.9806 9.85935 4.27349L14.196 8.61019H1.36841C0.954195 8.61019 0.618408 8.94597 0.618408 9.36019C0.618408 9.7744 0.954195 10.1102 1.36841 10.1102H14.196L9.85935 14.4469C9.56646 14.7398 9.56646 15.2146 9.85935 15.5075C10.1522 15.8004 10.6271 15.8004 10.92 15.5075L16.537 9.89052C16.6777 9.74986 16.7567 9.5591 16.7567 9.36019C16.7567 9.16127 16.6777 8.97051 16.537 8.82985L10.92 3.21283Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
