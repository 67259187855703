import type { Response } from 'types/responses'
import type { Nullable } from 'types/helpers'
import { ApiClient, apiClient } from 'utils/requests.utils'

export interface FunnelNode {
  childs: FunnelNode[]
  contentPageId: number
  createdAt: string
  direction: string
  form: string
  funnelId: number
  funnelNodeLogs: { form: string }[]
  id: number
  name: Nullable<string>
  parentId: number
  prefs: {
    hideFooter: boolean
    hideHeader: boolean
  }
  redirectionUrl: Nullable<string>
  sellerId: number
  skipForm: string
  updatedAt: string
}

export interface Funnel {
  copyFromId: Nullable<number>
  createdAt: string
  funnelNode: FunnelNode
  funnelNodeId: number
  id: number
  name: string
  sellerId: number
  sharingId: Nullable<number>
  sharingItemId: Nullable<number>
  updatedAt: string
}

interface SkipFunnel {
  skipped: boolean
}

export interface AcceptFunnelReq {
  funnelNodeId?: number
  productId?: number
  ticketId?: number
  ticketDateId?: number
  ticketsCount?: number
  pricingPlanId?: number
  pricingPlanIdIdIdId?: number
}

export interface AcceptFunnel {
  clientSecret: string
  paymentMethod: string
  redirectLink: string
  successLink: string
  token: string
}

const BASE_URL = (username, token) => `/shop/${username}/orders/${token}/funnel`

export const createFunnelsApi = ({ GET, POST }: ApiClient) => ({
  fetchFunnel: (username: string, token: string) => GET<Response<Funnel>>(`${BASE_URL(username, token)}`),

  fetchFunnelPreview: (username: string, token: string) =>
    GET<Response<Funnel>>(`/shop/${username}/funnels/${token}?preview=true`),

  acceptPostsell: (username: string, token: string, data: AcceptFunnelReq) =>
    POST<Response<AcceptFunnel>>(`${BASE_URL(username, token)}/accept_funnel_node`, data),

  rejectPostsell: (username: string, token: string, data: { funnelNodeId?: number }) =>
    POST<Response<{ success: boolean; error: string }>>(`${BASE_URL(username, token)}/reject_funnel_node`, data),

  skipPostsell: (username: string, token: string, data: { funnelNodeId: number }) =>
    POST<Response<SkipFunnel>>(`${BASE_URL(username, token)}/skip_funnel_node`, data),

  redirectPostsell: (username: string, token: string, data) =>
    POST<Response<any>>(`${BASE_URL(username, token)}/redirect_funnel_node`, data),
})

export type FunnelsApi = ReturnType<typeof createFunnelsApi>

const api = createFunnelsApi(apiClient)
export const acceptPostsell = api.acceptPostsell
