import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { createTrackingEventApi, TrackingEvent, TrackingEventApi } from 'shop/api/trackingEvents.api'

export class TrackingEventsStore extends SharedStore<TrackingEvent> {
  storeName = 'TrackingEventsStore'
  declare childApi: TrackingEventApi

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)
    this.childApi = createTrackingEventApi(root.apiClientV2)
  }

  fetchEvents = async (username: string, trackingCodeId: number) => {
    const { data } = await this.childApi.fetchEvents(username, trackingCodeId)

    return data
  }
}
