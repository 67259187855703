import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMinusCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
    <path d='M7 10H13' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </EloBaseIcon>
)
