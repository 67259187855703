import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

import { FromSwagger, Nullable } from 'types/helpers'
import { CookiesConsentAttributes } from 'types/__generated__/api'

const BASE_URL = 'shop/cookies_consents'

export interface CookiesConsentsApiParams {
  sellerId: Nullable<number>
  page: {
    size: number
    number: number
  }
}
export type CookiesConsents = FromSwagger<CookiesConsentAttributes>

export const createCookiesConsentsApi = ({ GET }: ApiClientV2) => ({
  fetchList: (data: CookiesConsentsApiParams) => GET<CookiesConsents[]>(BASE_URL, data),
})

export type CookiesConsentsApi = ReturnType<typeof createCookiesConsentsApi>
