import React from 'react'

import { get } from './lodash.utils'

import { FONT_STYLE_SELECT_VALUES, FONT_STYLE_UNDERLINE } from '../constants/forms.constants'
import { POSITIONS } from '../constants/general.constants'

export * from 'utils/fontsShared.utils'

export const CUSTOM_FONT_STYLE_ELEMENT_ID = 'custom_fonts_styles'
export const CUSTOM_FONT_LINK_ELEMENTS_CLASS = 'custom_fonts_link'

/**
 * Sort Fonts by label
 *
 * @param {Object} a Left Element of an array
 * @param {Object} b Right Element of an array
 *
 * @returns {Object} Sorting result -1, 0, 1
 */
export const sortFontByLabel = (a, b) => {
  const labelA = a.label ? a.label.toUpperCase() : a.toUpperCase()
  const labelB = b.label ? b.label.toUpperCase() : b.toUpperCase()

  if (labelA < labelB) {
    return -1
  }

  if (labelA > labelB) {
    return 1
  }

  return 0
}

/**
 * Froala Editor expect fonts to be specified as key:value pairs.
 * In order to provide it together with google fonts we need to convert
 * them, concat, and then sort.
 *
 * @param {Object} froalaFonts Fromala Fonts
 * @param {Array} googleFonts Google Fonts
 *
 * @returns {Object} Sorted Froala Fonts
 */
export const getSortedFroalaFonts = (froalaFonts = {}, googleFonts = [], customFonts = []) => {
  const froalaFontsResult = {}
  const froalaFontsSortedResult = {}

  googleFonts.forEach((font) => {
    froalaFontsResult[font.value] = font.label
  })

  customFonts.forEach((font) => {
    froalaFontsResult[font.value] = font.label
  })

  Object.keys(froalaFonts).forEach((key) => {
    froalaFontsResult[key] = froalaFonts[key]
  })

  Object.keys(froalaFontsResult)
    .sort(sortFontByLabel)
    .forEach((key) => {
      froalaFontsSortedResult[key] = froalaFontsResult[key]
    })

  return froalaFontsSortedResult
}

/**
 * This function injects font face and link preload into HTML struture of the document.
 * In case it's font page and user uploaded new/edit existing font, we need to remove
 * previously included fonts and then generate new styles & links
 *
 * @param {Array} fonts Fonts List
 * @param {Boolean} fontsPage Indicate whether this is font page update
 *
 * @returns {undefined}
 */
export const injectFontList = (fonts = [], fontsPage = false) => {
  if (document.head && fonts && fonts.length) {
    /* During update on font page we need to re-create styles/link objects to reflect changes */
    if (fontsPage) {
      const injectedStyles = document.querySelector(`#${CUSTOM_FONT_STYLE_ELEMENT_ID}`)
      injectedStyles && injectedStyles.remove()

      const injectedLinks = document.querySelectorAll(`.${CUSTOM_FONT_LINK_ELEMENTS_CLASS}`) || []
      injectedLinks && injectedLinks.forEach((link) => link.remove())
    }

    const fontStyles = document.createElement('style')
    fontStyles.id = CUSTOM_FONT_STYLE_ELEMENT_ID

    fonts.forEach((fontItem) => {
      const fontURL = get(fontItem, 'file.original', '')
      const fontName = get(fontItem, 'prefs.fontName', '')
      const fontWeight = get(fontItem, 'prefs.fontWeight', '')
      const fontStyle = get(fontItem, 'prefs.fontStyle', '')
      const fontDisplay = get(fontItem, 'prefs.fontDisplay', '')
      const fontFormat = get(fontItem, 'prefs.fontFormat', '')

      /* Create Preload Link */
      const preloadLinkTag = document.createElement('link')
      preloadLinkTag.rel = 'preload'
      preloadLinkTag.as = 'font'
      preloadLinkTag.type = `font/${fontFormat}`
      preloadLinkTag.crossOrigin = 'anonymous'
      preloadLinkTag.href = fontURL
      preloadLinkTag.className = CUSTOM_FONT_LINK_ELEMENTS_CLASS
      document.head.appendChild(preloadLinkTag)

      /* Create Font Family Styles */
      const textNode = document.createTextNode(`
        @font-face {
            font-family: '${fontName}';
            font-style: ${fontStyle};
            font-weight: ${Number(fontWeight)};
            font-display: ${fontDisplay};
            src: url('${fontURL}');
        }
      `)

      fontStyles.appendChild(textNode)
    })

    document.head.appendChild(fontStyles)
  }
}

/**
 * Formats Fonts in Select field to have a preview
 *
 * @param {Array} customFontsList List of custom fonts
 *
 * @returns {Node} Formatted Select Field Option
 */
export const formatFontSelectOptions = (options) => {
  const { value, label } = options

  return <span style={{ fontFamily: value }}>{label}</span>
}

/**
 * Formats Font Styles in Select field to have a preview
 *
 * @param {Array} customFontsList List of font styles options
 *
 * @returns {Node} Formatted Select Field Option
 */
export const formatFontStyleOptions = (options) => {
  const { value, label } = options
  const fontStyle =
    value === FONT_STYLE_SELECT_VALUES.boldItalic || value === FONT_STYLE_SELECT_VALUES.italic
      ? FONT_STYLE_SELECT_VALUES.italic
      : FONT_STYLE_SELECT_VALUES.regular

  const fontWeight =
    value === FONT_STYLE_SELECT_VALUES.boldItalic || value === FONT_STYLE_SELECT_VALUES.bold
      ? FONT_STYLE_SELECT_VALUES.bold
      : FONT_STYLE_SELECT_VALUES.regular

  const textDecoration = value === FONT_STYLE_UNDERLINE ? FONT_STYLE_UNDERLINE : 'none'

  return (
    <span
      style={{
        fontStyle,
        fontWeight,
        textDecoration,
      }}
    >
      {label}
    </span>
  )
}

const checkBoldItalic = (style) => (value) => (value === 'boldItalic' ? style : value)

export const getFontStyle = checkBoldItalic('italic')
export const getFontWeight = checkBoldItalic('bold')

export const getFontStyles = (value, withImportant = false) =>
  withImportant
    ? {
        fontStyle: `${getFontStyle(value)} !important`,
        fontWeight: `${getFontWeight(value)} !important`,
        textDecoration: `${value} !important`,
      }
    : {
        fontStyle: getFontStyle(value),
        fontWeight: getFontWeight(value),
        textDecoration: value,
      }

export const getAlignmentPosition = (item) => {
  if (item === POSITIONS.left) {
    return {
      left: '0%',
    }
  }
  if (item === POSITIONS.right) {
    return {
      left: 'unset',
      right: '0%',
    }
  }
  if (item === POSITIONS.center) {
    return {
      left: '50%',
      transform: 'translate(-50%)',
    }
  }

  return {}
}

// replace licensed font families to free fonts
const replacementsFonts = {
  '"Gill Sans Std"': 'Lato',
  'Gill Sans Std': 'Lato',
  GothamProBlack: 'Montserrat Black',
  GothaProMed: 'Montserrat Medium',
  GothaProReg: 'Montserrat Reg',
  GothaProLight: 'Montserrat Light',
  GothamPro: 'Montserrat Reg',
  'GothamPro, serif': 'Montserrat Reg',
  'GothamPro,serif': 'Montserrat Reg',
  FranklinGothicBook: 'Work Sans',
  FuturaPT: 'Montserrat Extra Bold',
  Helvetica: 'Nunito Sans',
  'Helvetica, sans-serif': 'Nunito Sans, sans-serif',
  'Helvetica,sans-serif': 'Nunito Sans, sans-serif',
  'Helvetica Neue': 'Nunito Sans',
  '"Helvetica Neue"': 'Nunito Sans',
  Muli: 'Mulish',
  Virtual: 'Lora, sans-serif',
  'Source Sans Pro': 'SourceSans3',
  '"Source Sans Pro"': 'SourceSans3',
  'Source Serif Pro': 'SourceSerif4',
  '"Source Serif Pro"': 'SourceSerif4',
  'Rotis Sans Serif': 'Roboto, sans-serif',
  '"Rotis Sans Serif"': 'Roboto, sans-serif',
  'Eczar SemiBold': 'Eczar',
  '"Eczar SemiBold"': 'Eczar',
  'Josefin Sans Regular': 'Josefin Sans',
  '"Josefin Sans Regular"': 'Josefin Sans',
  Arial: 'Nunito Sans, sans-serif',
  'Arial, sans-serif': 'Nunito Sans, sans-serif',
  'Arial,sans-serif': 'Nunito Sans, sans-serif',
  'Arial,Helvetica,sans-serif': 'Nunito Sans, sans-serif',
  'Arial,Helvetica, sans-serif': 'Nunito Sans, sans-serif',
  'Arial, Helvetica,sans-serif': 'Nunito Sans, sans-serif',
  'Arial, Helvetica, sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue", Helvetica, Arial, sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue",Helvetica, Arial, sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue", Helvetica,Arial, sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue", Helvetica, Arial,sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue",Helvetica,Arial, sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue", Helvetica,Arial,sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue",Helvetica, Arial,sans-serif': 'Nunito Sans, sans-serif',
  '"Helvetica Neue",Helvetica,Arial,sans-serif': 'Nunito Sans, sans-serif',
  'Georgia,serif': 'Merriweather, serif',
  'Georgia, serif': 'Merriweather, serif',
  'Impact,Charcoal,sans-serif': 'Anton, sans-serif',
  'Impact, Charcoal,sans-serif': 'Anton, sans-serif',
  'Impact,Charcoal, sans-serif': 'Anton, sans-serif',
  'Impact, Charcoal, sans-serif': 'Anton, sans-serif',
  'Tahoma,Geneva,sans-serif': 'Roboto, sans-serif',
  'Tahoma, Geneva,sans-serif': 'Roboto, sans-serif',
  'Tahoma,Geneva, sans-serif': 'Roboto, sans-serif',
  'Tahoma, Geneva, sans-serif': 'Roboto, sans-serif',
  'Times New Roman,Times,serif': 'Libre Baskerville,serif',
  'Times New Roman, Times, serif': 'Libre Baskerville,serif',
  '"Times New Roman", Times, serif': 'Libre Baskerville,serif',
  '"Times New Roman", Times,serif': 'Libre Baskerville,serif',
  '"Times New Roman",Times, serif': 'Libre Baskerville,serif',
  '"Times New Roman",Times,serif': 'Libre Baskerville,serif',
  'Times New Roman, Times,sans-serif': 'Libre Baskerville,serif',
  'Times New Roman,Times, sans-serif': 'Libre Baskerville,serif',
  'Times New Roman,Times,sans-serif': 'Libre Baskerville,serif',
  '"Times New Roman", Times,sans-serif': 'Libre Baskerville,serif',
  '"Times New Roman",Times, sans-serif': 'Libre Baskerville,serif',
  '"Times New Roman",Times,sans-serif': 'Libre Baskerville,serif',
  '"Times New Roman", Times, serif, -webkit-standard': 'Libre Baskerville,serif',
  '"Times New Roman",Times,serif,-webkit-standard': 'Libre Baskerville,serif',
  '"Times New Roman", Times,serif,-webkit-standard': 'Libre Baskerville,serif',
  '"Times New Roman",Times, serif,-webkit-standard': 'Libre Baskerville,serif',
  '"Times New Roman",Times,serif, -webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman,Times,serif,-webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman, Times,serif,-webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman,Times, serif,-webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman,Times,serif, -webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman, Times, serif,-webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman,Times, serif, -webkit-standard': 'Libre Baskerville,serif',
  'Times New Roman, Times, serif, -webkit-standard': 'Libre Baskerville,serif',
  'Verdana,Geneva,sans-serif': 'Roboto, sans-serif',
  'Verdana, Geneva,sans-serif': 'Roboto, sans-serif',
  'Verdana,Geneva, sans-serif': 'Roboto, sans-serif',
  'Verdana, Geneva, sans-serif': 'Roboto, sans-serif',
}

const replacementsObject = Object.keys(replacementsFonts).reduce(
  (acc, key) => ({
    ...acc,
    [`font-family: ${key};`]: `font-family: ${replacementsFonts[key]};`,
    [`font-family:${key};`]: `font-family: ${replacementsFonts[key]};`,
  }),
  {}
)

export const cleanupHTMLStyleFonts = (string?: string): string => {
  if (typeof string !== 'string') return string

  const regex = new RegExp(`(${Object.keys(replacementsObject).join('|')})`, 'g')
  return string.replace(regex, (match) => replacementsObject[match])
}

export const cleanupFonts = (string?: string): string => {
  if (typeof string !== 'string') return string

  const regex = new RegExp(`^(${Object.keys(replacementsFonts).join('|')})$`, 'g')
  return string.replace(regex, (match) => replacementsFonts[match])
}
