import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMailBlackedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M18.75 0.5H0.75C0.551088 0.5 0.360322 0.579018 0.21967 0.71967C0.0790176 0.860322 0 1.05109 0 1.25V14C0 14.3978 0.158035 14.7794 0.43934 15.0607C0.720644 15.342 1.10218 15.5 1.5 15.5H18C18.3978 15.5 18.7794 15.342 19.0607 15.0607C19.342 14.7794 19.5 14.3978 19.5 14V1.25C19.5 1.05109 19.421 0.860322 19.2803 0.71967C19.1397 0.579018 18.9489 0.5 18.75 0.5ZM18 14H1.5V2.95531L9.24281 10.0531C9.38118 10.1801 9.56217 10.2506 9.75 10.2506C9.93783 10.2506 10.1188 10.1801 10.2572 10.0531L18 2.95531V14Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
