import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloItalicIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M11.875 4.375L8.125 15.625' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M5 15.625H11.25' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M8.75 4.375H15' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
  </EloBaseIcon>
)
