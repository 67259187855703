import { action, makeObservable, observable } from 'mobx'

export class AudioPlayersStore {
  storeName = 'AudioPlayersStore'

  constructor() {
    makeObservable(this)
  }

  @observable fixedPlayerId = null

  @action setFixedPlayerId = (id: string | null) => {
    this.fixedPlayerId = id
  }
}

export default new AudioPlayersStore()
