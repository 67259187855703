import type { Nullable, FromSwagger } from 'types/helpers'
import { ShopCheckoutSellerAttributes, ShopSellerAttributes } from 'types/__generated__/api'

import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

interface SellerParams {
  username: string
  domainName: Nullable<string>
  subdomainName: Nullable<string>
}

export type SellerCheckout = FromSwagger<ShopCheckoutSellerAttributes>
export type Seller = FromSwagger<ShopSellerAttributes>

// export default fetchItem
const BASE_URL = (username: string): string => `shop/${username}/checkout/seller`
const BASE_URL_REDUCED = (username: string): string => `shop/${username}/seller`

export const createSellerApi = ({ GET }: ApiClientV2) => ({
  fetchItem: (data: SellerParams) =>
    GET<SellerCheckout>(BASE_URL(data.username), {
      ...data,
    }),
  fetchReducedItem: (data: SellerParams) =>
    GET<Seller>(BASE_URL_REDUCED(data.username), {
      ...data,
    }),
})

export type SellerApi = ReturnType<typeof createSellerApi>
