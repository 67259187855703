import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloSelectIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 12C5.10457 12 6 11.1046 6 10C6 8.89543 5.10457 8 4 8C2.89543 8 2 8.89543 2 10C2 11.1046 2.89543 12 4 12ZM9.99999 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 9.99999 8C8.89542 8 7.99999 8.89543 7.99999 10C7.99999 11.1046 8.89542 12 9.99999 12ZM18 10C18 11.1046 17.1045 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1045 8 18 8.89543 18 10Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
