import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMoreHorizontalIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z'
      fill='currentColor'
    />
    <path
      d='M4 12C5.10457 12 6 11.1046 6 10C6 8.89543 5.10457 8 4 8C2.89543 8 2 8.89543 2 10C2 11.1046 2.89543 12 4 12Z'
      fill='currentColor'
    />
    <path
      d='M16 12C17.1046 12 18 11.1046 18 10C18 8.89543 17.1046 8 16 8C14.8954 8 14 8.89543 14 10C14 11.1046 14.8954 12 16 12Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
