import { isWindowEnv } from 'utils/env.utils'

export function snakeCaseToCamelCase(word = '') {
  return (word || '').replace(/_(.)/g, function (match, firstAfterHypen) {
    return firstAfterHypen.toUpperCase()
  })
}

export function camelToSnakeCase(word = '') {
  return (word || '')
    .replace(/\.?([A-Z]|\d+)/g, (x, y) => `_${y.toLowerCase()}`)
    .replace(/^_(?!destroy)/, '')
    .replace(/__/, '_')
    .replace('b_2b', 'b2b')
    .replace('b_2c', 'b2c')
    .replace('v_2', 'v2')
}

export function formatLocale(word) {
  return camelToSnakeCase(word.replace(/ /g, '').replace(/[{()}]/g, ''))
}

export const escapeQuotesInString = (str = '') => str.replace(/'/g, "\\'").replace(/"/g, '\\"')

export const escapeQuotesAndSlashes = (string) => string.replace(/\\/g, '&bsol;').replace(/"/g, '\\"')

export function doNothing(word) {
  return word
}

export function isPureObject(value) {
  return value != null && typeof value === 'object'
}

// This function do not handle circular references
// Array of arrays is not handled
function deepObjectPropNamesConvertor(object, convertorFunction) {
  function deepArrayConvertor(item) {
    if (isPureObject(item)) {
      return deepObjectPropNamesConvertor(item, convertorFunction)
    }

    return item
  }

  if (!object) return {}

  if (!Object.keys(object).length) return object

  const disableConversion = object.__disableKeysConversion

  delete object.__disableKeysConversion

  if (disableConversion) {
    convertorFunction = doNothing
  }

  return Object.keys(object).reduce((acc, keyName) => {
    if (isWindowEnv() && object[keyName] instanceof File) {
      acc[convertorFunction(keyName)] = object[keyName]
    } else if (Array.isArray(object[keyName])) {
      acc[convertorFunction(keyName)] = object[keyName].map(deepArrayConvertor)
    } else if (isPureObject(object[keyName])) {
      acc[convertorFunction(keyName)] = deepObjectPropNamesConvertor(object[keyName], convertorFunction)
    } else {
      acc[convertorFunction(keyName)] = object[keyName]
    }
    return acc
  }, {})
}

export function deepObjectPropNamesToSnakeCase(object) {
  return deepObjectPropNamesConvertor(object, camelToSnakeCase)
}

export function deepObjectPropNamesToCamelCase(object) {
  return deepObjectPropNamesConvertor(object, snakeCaseToCamelCase)
}

function swapKeyValue(object) {
  return Object.keys(object).reduce((acc, key) => {
    if (typeof object[key] === 'string') {
      acc[object[key]] = key
    }
    return acc
  }, {})
}

export function deepConvertValuesToCamelCase(object) {
  const swappedObject = swapKeyValue(object)
  const convertedObject = deepObjectPropNamesToCamelCase(swappedObject)
  return swapKeyValue(convertedObject)
}

export function capitalize(word) {
  if (!word || typeof word !== 'string') {
    return ''
  }

  const loweredWord = word.toLowerCase()

  return loweredWord[0].toUpperCase() + loweredWord.slice(1)
}

export const convertExceptJsonBodyToSnakeCase = (data) => {
  const convertedData = deepObjectPropNamesToSnakeCase(data)

  I18n.availableLanguages.map((l) => {
    const locale = snakeCaseToCamelCase(l)
    return (convertedData[`json_body_${locale}`] = data[`jsonBody${locale[0].toUpperCase()}${locale.slice(1)}`])
  })

  return convertedData
}

export const convertExceptJsonBodyToCamelCase = (data) => {
  const convertedData = deepObjectPropNamesToCamelCase(data)

  I18n.availableLanguages.map(
    (locale) => (convertedData[`jsonBody${locale[0].toUpperCase()}${locale.slice(1)}`] = data[`json_body_${locale}`])
  )

  return convertedData
}
