import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDraftIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.875 2.5C8.875 2.22386 9.09886 2 9.375 2H11.875C12.0103 2 12.1331 2.05377 12.2231 2.14111L12.2286 2.14645L16.6036 6.52145L16.6078 6.52579C16.6958 6.61591 16.75 6.73913 16.75 6.875V10.625C16.75 10.9011 16.5261 11.125 16.25 11.125C15.9739 11.125 15.75 10.9011 15.75 10.625V7.375H11.875C11.5989 7.375 11.375 7.15114 11.375 6.875V3H9.375C9.09886 3 8.875 2.77614 8.875 2.5ZM12.375 6.375V3.70711L15.0429 6.375H12.375Z'
      fill='currentColor'
    />
    <path
      d='M3.75 13.875C4.02614 13.875 4.25 14.0989 4.25 14.375V16.875C4.25 16.9082 4.26317 16.9399 4.28661 16.9634C4.31005 16.9868 4.34185 17 4.375 17H5.625C5.90114 17 6.125 17.2239 6.125 17.5C6.125 17.7761 5.90114 18 5.625 18H4.375C4.07663 18 3.79048 17.8815 3.57951 17.6705C3.36853 17.4595 3.25 17.1734 3.25 16.875V14.375C3.25 14.0989 3.47386 13.875 3.75 13.875Z'
      fill='currentColor'
    />
    <path
      d='M4.28661 3.03661C4.31005 3.01317 4.34185 3 4.375 3H6.25C6.52614 3 6.75 2.77614 6.75 2.5C6.75 2.22386 6.52614 2 6.25 2H4.375C4.07663 2 3.79048 2.11853 3.57951 2.32951C3.36853 2.54048 3.25 2.82663 3.25 3.125V5C3.25 5.27614 3.47386 5.5 3.75 5.5C4.02614 5.5 4.25 5.27614 4.25 5V3.125C4.25 3.09185 4.26317 3.06005 4.28661 3.03661Z'
      fill='currentColor'
    />
    <path
      d='M16.75 16.875V13.75C16.75 13.4739 16.5261 13.25 16.25 13.25C15.9739 13.25 15.75 13.4739 15.75 13.75V16.875C15.75 16.9082 15.7368 16.9399 15.7134 16.9634C15.6899 16.9868 15.6582 17 15.625 17H15C14.7239 17 14.5 17.2239 14.5 17.5C14.5 17.7761 14.7239 18 15 18H15.625C15.9234 18 16.2095 17.8815 16.4205 17.6705C16.6315 17.4595 16.75 17.1734 16.75 16.875Z'
      fill='currentColor'
    />
    <path
      d='M3.75 7.625C4.02614 7.625 4.25 7.84886 4.25 8.125V11.25C4.25 11.5261 4.02614 11.75 3.75 11.75C3.47386 11.75 3.25 11.5261 3.25 11.25V8.125C3.25 7.84886 3.47386 7.625 3.75 7.625Z'
      fill='currentColor'
    />
    <path
      d='M8.75 17C8.47386 17 8.25 17.2239 8.25 17.5C8.25 17.7761 8.47386 18 8.75 18H11.875C12.1511 18 12.375 17.7761 12.375 17.5C12.375 17.2239 12.1511 17 11.875 17H8.75Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
