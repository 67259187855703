export const CUSTOM_CLASS_NAME_OPTION = 'customClassName'

export const HEADER_CLASS_NAMES = {
  /* Header */
  containerClassName: 'cs-header-container',
  yellowMessageClassName: 'cs-header-yellow-message',
  logoContainerClassName: 'cs-header-logo',
  logoLinkContainerClassName: 'cs-header-logo-link',
  myAccountContainerClassName: 'cs-header-my-account',
  menuWrapperContainerClassName: 'cs-header-menu',
  subMenuWrapperContainerClassName: 'cs-header-sub-menu',
  socialItemWrapperContainerClassName: 'cs-header-social-item',

  /* Desktop Specific */
  desktopActiveClassName: 'cs-header-desktop-active',
  desktopSocialsClassName: 'cs-header-desktop-social',

  /* Mobile Specific */
  mobileContainerClassName: 'cs-header-mobile-container',
  mobileActiveClassName: 'cs-header-mobile-active',
  mobileSocialsClassName: 'cs-header-mobile-social',
}

export const FOOTER_CLASS_NAMES = {
  containerClassName: 'cs-footer-container',
  footerColumnClassName: 'cs-footer-column',
  footerMenuTitleClassName: 'cs-footer-menu-title',
  footerMenuItemClassName: 'cs-footer-menu-item',
  footerPoweredByClassName: 'cs-footer-powered-by',
}

export const MEMBERSHIP_PRODUCTS_LIST_CLASS_NAMES = {
  containerClassName: 'cs-membership-list-container',
  listContainerClassName: 'cs-membership-list',
  listItemClassName: 'cs-membership-list-item',
  listTitleClassName: 'cs-membership-list-title',
  listBackgroundImageClassName: 'cs-membership-list-background-image',
  listDescriptionClassName: 'cs-membership-list-description',
  listDetailsClassName: 'cs-membership-list-details',
  gridContainerClassName: 'cs-membership-grid',
  gridItemClassName: 'cs-membership-grid-item',
  gridTitleClassName: 'cs-membership-grid-title',
  gridDetailsClassName: 'cs-membership-grid-details',
}

export const SELLER_PRODUCTS_LIST_CLASS_NAMES = {
  containerClassName: 'cs-products-list-container',
  listContainerClassName: 'cs-products-list',
  listItemClassName: 'cs-products-list-item',
  listImageClassName: 'cs-products-list-image',
  listTitleClassName: 'cs-products-list-title',
  listButtonClassName: 'cs-products-list-button',
  listDescriptionClassName: 'cs-products-list-description',
  listDetailsClassName: 'cs-products-list-details',
  listPriceContainerClassName: 'cs-products-list-price-container',
  listPriceClassName: 'cs-products-list-price',
  listCurrencyClassName: 'cs-products-list-currency',
  listOldPriceClassName: 'cs-products-list-old-price',
  gridContainerClassName: 'cs-products-grid',
  gridItemClassName: 'cs-products-grid-item',
  gridTitleClassName: 'cs-products-grid-title',
  gridDetailsClassName: 'cs-products-grid-details',
  gridButtonClassName: 'cs-products-grid-button',
  gridPriceContainerClassName: 'cs-products-grid-price-container',
}

export const EXTRAS_PRODUCTS_CLASS_NAMES = {
  controlsContainerClassName: 'cs-products-controls-container',
  searchContainerClassName: 'cs-products-search-container',
  categoriesDropdownContainerClassName: 'cs-products-categories-dropdown-container',
  categoriesTabsContainerClassName: 'cs-products-categories-tabs-container',
  categoriesTabsCategoriesListClassName: 'cs-products-categories-tabs-categories-list',
  productNotFoundContainerClassName: 'cs-products-not-found-container',
}

export const POPULAR_PRODUCTS_CLASS_NAMES = {
  containerClassName: 'cs-popular-container',
  controlsClassName: 'cs-popular-controls',
  listContainerClassName: 'cs-popular-list',
  listItemClassName: 'cs-popular-list-item',
  listImageClassName: 'cs-popular-list-image',
  listTitleClassName: 'cs-popular-list-title',
  listDescriptionClassName: 'cs-popular-list-description',
  listButtonClassName: 'cs-popular-list-button',
  listDetailsClassName: 'cs-popular-list-details',
  listPriceContainerClassName: 'cs-popular-list-price-container',
  listPriceClassName: 'cs-popular-list-price',
  listCurrencyClassName: 'cs-popular-list-currency',
  listOldPriceClassName: 'cs-popular-list-old-price',
  gridContainerClassName: 'cs-popular-grid',
  gridItemClassName: 'cs-popular-grid-item',
  gridDetailsClassName: 'cs-popular-grid-details',
  gridTitleClassName: 'cs-popular-grid-title',
  gridPriceClassName: 'cs-popular-grid-price',
  gridImageClassName: 'cs-popular-grid-image',
  gridButtonClassName: 'cs-popular-grid-button',
  sliderContainerClassName: 'cs-popular-slider',
  sliderItemClassName: 'cs-popular-slider-item',
  sliderTitleClassName: 'cs-popular-slider-title',
  sliderDetailsClassName: 'cs-popular-slider-details',
  sliderPriceClassName: 'cs-popular-slider-price',
  sliderButtonClassName: 'cs-popular-slider-button',
}

export const DEFAULT_PRODUCT_CLASS_NAMES = {
  containerClassName: 'cs-product-container',
  productNameClassName: 'cs-product-name',
  productTopContentClassName: 'cs-product-top',
  productCoverClassName: 'cs-product-cover',
  productBottomClassName: 'cs-product-bottom',
  productPriceContainerClassName: 'cs-product-price-container',
  productPriceClassName: 'cs-product-price',
  productOldPriceClassName: 'cs-product-old-price',
}

export const TEXT_CLASS_NAMES = {
  containerClassName: 'cs-text-container',
  overlayClassName: 'cs-text-overlay',
  textWrapperClassName: 'cs-text-wrapper',
}

export const BUTTON_CLASS_NAMES = {
  containerClassName: 'cs-button-container',
}

export const PICTURE_CLASS_NAMES = {
  containerClassName: 'cs-image-container',
}

export const FILE_CLASS_NAMES = {
  containerClassName: 'cs-file-container',
}

export const VIDEO_CLASS_NAMES = {
  containerClassName: 'cs-video-container',
  emptyContainerClassName: 'cs-video-empty',
  playerContainerClassName: 'cs-video-player',
}

export const AUDIO_CLASS_NAMES = {
  containerClassName: 'cs-audio-container',
  containerWrapperClassName: 'cs-audio-container-wrapper',
  imageContainerClassName: 'cs-audio-image-container',
  playerContainerClassName: 'cs-audio-player-container',
  speedContainerClassName: 'cs-audio-speed-container',
  detailsContainerClassName: 'cs-audio-details-container',
  infoContainerClassName: 'cs-audio-info-container',
}

export const BANNER_CLASS_NAMES = {
  containerClassName: 'cs-banner-container',
  btnContainerClassName: 'cs-banner-btn',
}

export const TESTIMONIALS_CLASS_NAMES = {
  containerClassName: 'cs-testimonials-container',
  emptyContainerClassName: 'cs-testimonials-empty',
  textContainerClassName: 'cs-testimonials-text',
  userContainerClassName: 'cs-testimonials-users',
}

export const COURSE_MAIN_CONTAINERS_CLASS_NAME = {
  containerClassName: 'cs-course-main-container',
  lessonsContainerClassName: 'cs-course-lesson-container',
  overviewContainerClassName: 'cs-course-lesson-area-container',
}

export const COURSE_BANNER_CLASS_NAMES = {
  containerClassName: 'cs-course-banner-container',
  infoContainerClassName: 'cs-course-banner-info',
}

export const COURSE_PAGE_NAV_CLASS_NAMES = {
  containerClassName: 'cs-course-breadcrumb-container',
  resetContainerClassName: 'cs-course-breadcrumb-reset-btn',
  breadcrumbsContainerClassName: 'cs-course-breadcrumb',
}

export const COURSE_COMMENTS_FORM_CLASS_NAMES = {
  containerClassName: 'cs-lesson-comments-form',
}

export const COURSE_COMMENTS_CLASS_NAMES = {
  containerClassName: 'cs-lesson-comments',
}

export const COURSE_COMMENT_CLASS_NAMES = {
  containerClassName: 'cs-lesson-comments-content',
}

export const COURSE_LESSONS_NAV_CLASS_NAMES = {
  containerClassName: 'cs-course-lesson-btns-container',
  btnContainerClassName: 'cs-course-lesson-btn-next',
}

export const COURSE_LESSONS_LIST_CLASS_NAMES = {
  containerClassName: 'cs-course-lessons-overview-container',
  itemContainerClassName: 'cs-course-lessons-overview-item',
}

export const COURSE_CUSTOM_BTN_CLASS_NAMES = {
  containerClassName: 'cs-course-lesson-btn',
  sideBtnContainerClassName: 'cs-course-lesson-nav-btn',
}

export const COURSE_SIDE_NAV_CLASS_NAMES = {
  containerClassName: 'cs-course-side-nav-container',
}

export const COURSE_CONTENT_CLASS_NAMES = {
  containerClassName: 'cs-lesson-content',
  countdownContainerClassName: 'cs-lesson-content-countdown',
  assignmentSuccessContainerClassName: 'cs-lesson-content-assignment-success',
}

export const COURSE_QUIZ_CLASS_NAMES = {
  resultsContainerClassName: 'cs-course-quiz-results',
  formContainerClassName: 'cs-course-quiz-form',
}

export const GAP_CLASS_NAMES = {
  containerClassName: 'cs-gap-container',
}
