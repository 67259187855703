import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloListBulletsIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M6.5625 4.5H17.5625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.78125 5.53125C3.35079 5.53125 3.8125 5.06954 3.8125 4.5C3.8125 3.93046 3.35079 3.46875 2.78125 3.46875C2.21171 3.46875 1.75 3.93046 1.75 4.5C1.75 5.06954 2.21171 5.53125 2.78125 5.53125Z'
      fill='currentColor'
    />
    <path d='M6.5625 10H17.5625' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M2.78125 11.0312C3.35079 11.0312 3.8125 10.5695 3.8125 10C3.8125 9.43046 3.35079 8.96875 2.78125 8.96875C2.21171 8.96875 1.75 9.43046 1.75 10C1.75 10.5695 2.21171 11.0312 2.78125 11.0312Z'
      fill='currentColor'
    />
    <path
      d='M6.5625 15.5H17.5625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.78125 16.5312C3.35079 16.5312 3.8125 16.0695 3.8125 15.5C3.8125 14.9305 3.35079 14.4688 2.78125 14.4688C2.21171 14.4688 1.75 14.9305 1.75 15.5C1.75 16.0695 2.21171 16.5312 2.78125 16.5312Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
