import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCheckboxUnselectedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <rect
      x='1.53418'
      y='1.53516'
      width='16.9318'
      height='16.9318'
      rx='3.375'
      stroke='currentColor'
      strokeWidth='1.25'
    />
  </EloBaseIcon>
)
