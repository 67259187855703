import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBackIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M7.19531 11.5703H2.19531V6.57031' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M17.5 14.3758C17.5005 12.8921 17.061 11.4415 16.2369 10.2076C15.4128 8.97372 14.2413 8.012 12.8705 7.44411C11.4997 6.87622 9.99128 6.72769 8.53606 7.01731C7.08084 7.30693 5.74422 8.02168 4.69531 9.07114L2.19531 11.5711'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </EloBaseIcon>
)
