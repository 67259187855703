import { makeObservable, override } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'

import SharedStore from 'shared/stores/shared.store'

import { UserApi, createUserApi, User } from 'shop/api/user.api'

import { getSearchParams } from 'utils/queryString.utils'
import { setParamsToCookies } from 'utils/cookiesConsents.utils'
import { getAffiliateCookies } from 'utils/affiliatePrograms.utils'

import { SIGN_UP_NEW_PROFILE_TYPE_PARAM } from 'constants/login.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'

import { getCookies } from 'libs/common/cookies'

export class UserStore extends SharedStore<User> {
  storeName = 'UserStore'
  declare childApi: UserApi

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)

    this.childApi = createUserApi(root.apiClientV2)
  }

  @override
  async fetchItem(reqData: { token: string }) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem(reqData)

    this.item = resp.data
    this.toggleLoading(false)

    return resp
  }

  setAffiliateCookies = () => {
    const params = getSearchParams()
    if (params.invite_program || params.affiliate_node_token) {
      setParamsToCookies(params)
    }
  }

  isBecomeSellerSignIn = (): boolean => {
    const preferredProfileType = getCookies(SIGN_UP_NEW_PROFILE_TYPE_PARAM)
    const affiliateCookies = getAffiliateCookies()
    const epid = affiliateCookies?.epid
    const { profileTypes = [] } = this.item

    return (
      (Boolean(preferredProfileType) || epid) && !profileTypes.includes(preferredProfileType || ACTIVE_PROFILES.seller)
    )
  }

  @override
  hydrate(key, data) {
    if (key === 'item') {
      this.item = data
    }
  }
}
