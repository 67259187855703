import { action, computed, observable, toJS, makeObservable } from 'mobx'
import queryString from 'query-string'

import { getCookies, setCookies } from 'libs/common/cookies'
import { camelToSnakeCase } from 'utils/nameStyle.utils'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { getRelativeDataFilter } from 'utils/filter.utils'

import {
  DEFAULT_FULL_LIST,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  PAGINATION_KEYS,
} from 'constants/pagination.constants'
import { HTTPResponse } from 'types/responses'

class SharedStore<I extends Record<string, any>> {
  storeName = ''
  storeName2 = ''
  childApi = null

  @observable loading = true
  @observable customLoadingEnabled = false
  @observable customLoading = true
  @observable setFiltersDataToUrl = true
  @observable list: I[] = []
  @observable item: I = {} as I
  @observable defaultSortingKey = 'id'
  @observable defaultSortingDirection = 'desc'

  @observable pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
    totalCountMax: undefined,
  }

  @observable scopes = new Map()
  @observable filters = new Map()
  @observable prevFilters = new Map()
  @observable expands = []
  @observable counts = []
  @observable query = ''
  @observable urlParamsData = {}

  @observable history = {} as any

  @observable reseted = {
    search: false,
    filter: false,
  }

  @action.bound
  setItem<T extends HTTPResponse>(res: T) {
    const { data, success } = res || {}
    if (data && success) this.item = data
  }

  // TODO: refactor to qs.parse. Should be @computed
  getAllUrlParams = () => {
    const keyPairs = {}
    const params = window.location.search.substring(1).split('&')
    let splited = []
    for (let i = params.length - 1; i >= 0; i--) {
      if (params[i].length) {
        splited = params[i].split('=')
        keyPairs[splited[0]] = decodeURIComponent(splited[1])
      }
    }
    return keyPairs
  }

  @action.bound
  processFetchListResponse({ data, success }, params?: { labelIds: number[] }) {
    if (success) {
      const { list = [], totalCount = 0, totalCountMax } = data || {}
      this.setList(list)
      this.updatePagination({ total: totalCount, totalCountMax })

      //handle out of range page query param
      const pageCount = this.pagination.total ? Math.ceil(this.pagination.total / this.pagination.per) : DEFAULT_PAGE

      if (this.pagination.page > pageCount) {
        this.handlePaginationChange(PAGINATION_KEYS.page, params.labelIds ? DEFAULT_PAGE : pageCount, !params.labelIds)
      }
      if (this.pagination.page < DEFAULT_PAGE) {
        this.handlePaginationChange(PAGINATION_KEYS.page, DEFAULT_PAGE, !params.labelIds)
      }
    }
  }
  // CRUD operations
  @action.bound
  async fetchList(data = {}) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchList({
      expand: toJS(this.expands),
      counts: toJS(this.counts),
      ...this.getParamsWoRelativeData(this.queryParams),
      ...this.getFormattedRelativeData(),
      ...this.urlParamsData,
      ...data,
    })

    this.processFetchListResponse(resp, { labelIds: data['labelIds'] })

    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async fetchEditableList(dataToSend) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchList({
      page: DEFAULT_PAGE,
      per: DEFAULT_FULL_LIST,
      ...dataToSend,
    })
    const { success, data } = resp
    if (success) {
      const { list = [] } = data || {}
      this.setList(list)
    }
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  // TODO: verify that in ALL places id is required
  async fetchItem(id: any = '', data = {}) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem(id, {
      expand: toJS(this.expands),
      counts: toJS(this.counts),
      ...data,
    })
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async createItem(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.createItem(data || this.item)
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async updateItem(id, data) {
    this.toggleLoading(true)
    const resp = await this.childApi.updateItem(id || this.item.id, data || this.item)
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  @action.bound
  async deleteItem(id, data?) {
    this.toggleLoading(true)
    const resp = await this.childApi.deleteItem(id, data)
    this.toggleLoading(false)
    return resp
  }
  // END of CRUD

  @action.bound
  async duplicateItem(id, data) {
    this.toggleLoading(true)
    const resp = await this.childApi.duplicateItem(id, data)
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  // Hack for disabling loading globally
  @action.bound
  toggleLoading = (value) => {
    if (this.customLoadingEnabled) {
      this.loading = false
      this.customLoading = value
    } else {
      this.loading = value
    }
  }

  @action.bound
  toggleCustomLoading = (value?: boolean) => {
    this.customLoadingEnabled = value || !this.customLoadingEnabled
  }

  @action.bound
  toggleFiltersDataStoringInUrl = (bool) => {
    this.setFiltersDataToUrl = bool
  }
  // END of Disabling loading globally

  //handling URL query params

  // set URL history to work with filters and search in URL params
  @action.bound
  setHistory = (history) => {
    if (!history) return false

    this.history = history
    const queryParams = queryString.parse(this.history.location.search, { arrayFormat: 'bracket' })

    if (queryParams.page) {
      this.updatePagination({ page: Number(queryParams.page) })
      queryParams.page = null
    }

    if (queryParams.per) {
      this.updatePagination({ per: Number(queryParams.per) })
      queryParams.per = null
    }

    if (queryParams.query) {
      this.setQuery(queryParams.query)
      queryParams.query = null
    }

    for (const filterKey in queryParams) {
      if (!!queryParams[filterKey] && !this.scopes.get(filterKey)) {
        this.filters.set(filterKey, queryParams[filterKey])
      }
    }
  }

  @action.bound
  handleQueryStringChange = () => {
    const formedQueryString = queryString.stringify(this.queryParams, { arrayFormat: 'bracket' })

    if (!isEmpty(this.history)) {
      this.history.replace({
        search: formedQueryString,
      })
    }
  }

  // filters/query methods
  @action.bound
  async fetchFullList(data = {}) {
    const resp = await this.fetchList({
      page: DEFAULT_PAGE,
      per: DEFAULT_FULL_LIST,
      ...data,
    })

    if (this.pagination.total > DEFAULT_FULL_LIST) {
      return this.fetchList({
        per: this.pagination.total,
        ...data,
      })
    }

    return resp
  }

  @action.bound
  handlePaginationChange(key, value, refetch = true, params = null) {
    this.updatePagination({
      [key]: value,
    })

    if (key === PAGINATION_KEYS.per) {
      this.updatePagination({ page: DEFAULT_PAGE })
    }

    if (this.setFiltersDataToUrl) {
      this.handleQueryStringChange()
    }

    if (refetch) {
      this.fetchList({ labelIds: params?.selectedLabelIds })
    }
  }

  @action.bound
  handleScopeChange = (key, value) => {
    this.scopes.set(key, value)
  }

  @action.bound
  setCounts = (value) => {
    this.counts = [...this.counts, ...value]
  }

  @action.bound
  setExpands = (value) => {
    this.expands = [...this.expands, ...value]
  }

  @action.bound
  setUrlParamsData = (value = {}) => {
    this.urlParamsData = { ...this.urlParamsData, ...value }
  }

  @action.bound
  resetUrlParamsData = () => (this.urlParamsData = {})

  @action.bound resetExpands = () => (this.expands = [])

  @action.bound
  resetHistory = () => (this.history = {})

  @action.bound
  setPrevFilters = () => {
    this.prevFilters = this.queryParams
  }

  @action.bound
  handleFilterChange(key, value) {
    this.filters.set(key, value)
  }

  @action.bound
  setQuery = (query) => (this.query = query)

  @action.bound
  handleQuerySearch(value, searchFieldId) {
    this.setQuery(value)
    this.applyFilters(searchFieldId)
  }

  @action.bound
  setPagination = (pagination) => (this.pagination = pagination)

  @action.bound
  updatePagination = (pagination) => {
    this.setPagination({
      ...this.pagination,
      ...pagination,
    })
  }

  @action.bound
  applyFilters = (searchFieldId) => {
    this.updatePagination({ page: DEFAULT_PAGE })

    if (this.setFiltersDataToUrl) {
      this.handleQueryStringChange()
    }
    this.fetchList().then(() => {
      if (searchFieldId) {
        document.getElementById(searchFieldId)?.focus()
      }
    })
  }

  @action.bound
  resetFilters = () => {
    this.reseted = {
      ...this.reseted,
      filter: true,
    }
    this.filters = new Map()
  }

  @action.bound
  resetData() {
    this.filters = new Map()

    const pagination = {
      per: DEFAULT_ITEMS_PER_PAGE,
      page: DEFAULT_PAGE,
      total: 0,
    }
    this.setPagination(pagination)
    this.setQuery('')
    this.reseted = {
      search: true,
      filter: true,
    }
    this.scopes = new Map()
    this.resetExpands()
  }

  @action.bound
  resetScopes() {
    this.scopes = new Map()
  }

  @action.bound
  toggleReseted(type) {
    this.reseted = {
      ...this.reseted,
      [type]: !this.reseted[type],
    }
  }

  @action.bound
  resetItem() {
    this.item = {} as I
  }

  @action.bound
  setList(list) {
    this.list = list
  }

  @action.bound
  resetList() {
    this.setList([])
  }

  @action.bound
  handleSort(newSortingKey) {
    if (this.listSortingKey() === newSortingKey) {
      const newSortingDirection = this.listSortingDirection() === 'desc' ? 'asc' : 'desc'
      setCookies(`${this.storeKey}_sorting_direction`, newSortingDirection)
    } else {
      setCookies(`${this.storeKey}_sorting_key`, newSortingKey)
    }
    this.fetchList()
  }

  // TODO: -> @computed
  cookiesSortingKey() {
    return getCookies(`${this.storeKey}_sorting_key`)
  }

  cookiesSortingDirection() {
    return getCookies(`${this.storeKey}_sorting_direction`)
  }

  listSortingKey() {
    return this.cookiesSortingKey() || this.defaultSortingKey
  }

  listSortingDirection() {
    return this.cookiesSortingDirection() || this.defaultSortingDirection
  }

  getFormattedRelativeData = () => {
    const {
      created_relative_period,
      created_relative_time,
      created_relative_at_period,
      created_relative_at_time,
      success_relative_at_period,
      success_relative_at_time,
    } = Object.fromEntries(this.filters)

    const relativeFilterObject = {
      createdAt: {
        period: created_relative_period,
        time: created_relative_time,
        fromKey: 'created_from_time',
        tillKey: 'created_till_time',
      },
      createdAtTime: {
        period: created_relative_at_period,
        time: created_relative_at_time,
        fromKey: 'created_from_time',
        tillKey: 'created_till_time',
      },
      successAtTime: {
        period: success_relative_at_period,
        time: success_relative_at_time,
        fromKey: 'success_from_time',
        tillKey: 'success_till_time',
      },
    }

    return Object.values(relativeFilterObject).reduce(
      (prevItem, item) => ({
        ...prevItem,
        ...getRelativeDataFilter(item),
      }),
      {}
    )
  }

  getParamsWoRelativeData = (data) => {
    const {
      created_relative_period,
      created_relative_time,
      created_relative_at_period,
      created_relative_at_time,
      success_relative_at_period,
      success_relative_at_time,
      ...restParams
    } = data

    return restParams
  }

  @computed get csvQueryParams() {
    const params = {
      ...Object.fromEntries(this.filters),
      ...Object.fromEntries(this.scopes),
      locale: I18n.locale,
    }

    if (this.query) params.query = this.query

    return params
  }

  readCsvQueryParams() {
    const params = {
      ...Object.fromEntries(this.filters),
      ...Object.fromEntries(this.scopes),
      locale: I18n.locale,
    }

    if (this.query) params.query = this.query

    return params
  }

  @computed get queryParams() {
    let params = {
      page: this.pagination.page,
      query: this.query,
      ...Object.fromEntries(this.scopes),
      ...Object.fromEntries(this.filters),
      per: this.pagination.per,
    }

    if (this.listSortingKey() && (this.listSortingKey() as string).length) {
      params = {
        ...params,
        sortKey: this.listSortingKey(),
        sortDir: this.listSortingDirection(),
      }
    }

    return params
  }
  // END of filters/query methods

  @computed get storeKey() {
    return camelToSnakeCase(this.storeName2 || this.storeName).replace('_store', '')
  }

  @computed get loadingValue() {
    return this.customLoadingEnabled ? this.customLoading : this.loading
  }

  @computed get isTotalCountOverflowed() {
    return !!this.pagination.totalCountMax && this.pagination.total === this.pagination.totalCountMax
  }

  constructor() {
    makeObservable(this, null, { autoBind: true })
  }

  @action.bound
  hydrate(key, data) {
    if (key === 'item') {
      // TODO: rm after EVERY response will be covered by types
      // @ts-ignore
      this.setItem({ data: data, success: !isEmpty(data) }) // TODO: SSR - can be data === {} and success === true
    } else if (key === 'list') {
      this.setList(data)
    } else if (key === 'loading') {
      this.loading = data
    } else if (key === 'expands') {
      this.expands = data
    } else if (key === 'scopes') {
      this.scopes = new Map()
      for (const key in data) {
        this.scopes.set(key, data[key])
      }
    }
  }
}

export default SharedStore
