import React from 'react'
import { toast } from 'react-toastify'

const NOTIFY_STATUSES = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
}

const iconStyles = {
  fontSize: 24,
}

const titleStyles = {
  fontFamily: 'Montserrat Medium',
  lineHeight: '14px',
}

const Icon = ({ type }) => {
  switch (type) {
    case NOTIFY_STATUSES.success: {
      return <i className='fas fa-check-circle' style={iconStyles} />
    }
    case NOTIFY_STATUSES.error: {
      return <i className='fas fa-times-circle' style={iconStyles} />
    }
    case NOTIFY_STATUSES.warning: {
      return <i className='fas fa-exclamation-circle' style={iconStyles} />
    }
    case NOTIFY_STATUSES.info: {
      return <i className='fas fa-info-circle' style={iconStyles} />
    }
    default: {
      return null
    }
  }
 }

const Title = ({ title }) => (
  <div style={titleStyles}>
    {title}
  </div>
)

export function notify(type, title, options = {}) {
  const label = typeof title !== 'undefined' ? title.toString() : title

  toast[type](<Title title={label} />, {
    closeButton: false,
    hideProgressBar: true,
    autoClose: 4000,
    icon: <Icon type={type} />,
    ...options
  })
}
