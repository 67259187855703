import { DEFAULT_COUNTRY_CODE } from 'constants/countries.constants'
import { camelToSnakeCase } from 'utils/nameStyle.utils'
import { ALLOWED_LANGUAGES, LANGUAGES } from './general.constants'

export const DEFAULT_LOCALE = DEFAULT_COUNTRY_CODE.toLowerCase()

export const DEFAULT_FALLBACK_LOCALE = 'en'

export const LANGUAGES_OPTIONS = Object.values(LANGUAGES)

export const LANGUAGES_OPTIONS_KEYS = Object.keys(LANGUAGES).map((key) => camelToSnakeCase(key))

export const ALLOWED_LANGUAGES_OPTIONS_KEYS = Object.keys(ALLOWED_LANGUAGES).map((key) => camelToSnakeCase(key))

export const getSupportedLocale = (locale) =>
  LANGUAGES_OPTIONS_KEYS.includes(locale) ? locale : DEFAULT_FALLBACK_LOCALE
