import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloInfoIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.3125 9.3125H10V14.125H10.6875'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.82812 7.25C10.3977 7.25 10.8594 6.78829 10.8594 6.21875C10.8594 5.64921 10.3977 5.1875 9.82812 5.1875C9.25858 5.1875 8.79688 5.64921 8.79688 6.21875C8.79688 6.78829 9.25858 7.25 9.82812 7.25Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
