import React from 'react'
import classNames from 'classnames'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import './_additional-info-toggle.scss'

/**
 * Toogle Component with additional info
 */

interface Props {
  open?: boolean
  openLabel?: string
  closeLabel?: string
  toggle?: () => void
}

export const AdditionalInfoToggle = (props: Props) => {
  const I18n = useI18n()

  const {
    open = false,
    openLabel = I18n.t('react.shared.button.hide_details'),
    closeLabel = I18n.t('react.shared.details'),
    toggle,
  } = props
  const btnClasses = classNames('additional-info-toggle', { 'additional-info-toggle--active': open })
  return (
    <button type='button' onClick={toggle} className={btnClasses}>
      <i
        className={
          open
            ? 'fas fa-caret-down additional-info-toggle__arrow-down'
            : 'fas fa-caret-right additional-info-toggle__arrow-right'
        }
      />
      {open ? openLabel : closeLabel}
    </button>
  )
}

AdditionalInfoToggle.displayName = 'AdditionalInfoToggle'
