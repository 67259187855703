import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCalendarExclamationMarkIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.75 1C14.75 0.654822 14.4702 0.375 14.125 0.375C13.7798 0.375 13.5 0.654822 13.5 1V1.31276H6.5V1C6.5 0.654822 6.22017 0.375 5.875 0.375C5.52982 0.375 5.25 0.654822 5.25 1V1.31276H2.72727C1.98043 1.31276 1.375 1.9182 1.375 2.66504V17.2105C1.375 17.9573 1.98043 18.5628 2.72727 18.5628H17.2727C18.0196 18.5628 18.625 17.9573 18.625 17.2105V2.66504C18.625 1.9182 18.0196 1.31276 17.2727 1.31276H14.75V1ZM5.25 4V2.56276H2.72727C2.67079 2.56276 2.625 2.60855 2.625 2.66504V5.87507H17.375V2.66504C17.375 2.60855 17.3292 2.56276 17.2727 2.56276H14.75V4C14.75 4.34518 14.4702 4.625 14.125 4.625C13.7798 4.625 13.5 4.34518 13.5 4V2.56276H6.5V4C6.5 4.34518 6.22017 4.625 5.875 4.625C5.52982 4.625 5.25 4.34518 5.25 4ZM17.375 7.12507H2.625V17.2105C2.625 17.267 2.67079 17.3128 2.72727 17.3128H17.2727C17.3292 17.3128 17.375 17.267 17.375 17.2105V7.12507Z'
      fill='currentColor'
    />
    <path d='M9.78125 9.5V12.5' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.79998 15.4C10.1866 15.4 10.5 15.0866 10.5 14.7C10.5 14.3134 10.1866 14 9.79998 14C9.41338 14 9.09998 14.3134 9.09998 14.7C9.09998 15.0866 9.41338 15.4 9.79998 15.4Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
