import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloShareTemplateIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.3081 3.93306C13.5521 3.68898 13.9479 3.68898 14.1919 3.93306L17.9419 7.68306C18.186 7.92714 18.186 8.32286 17.9419 8.56694L14.1919 12.3169C13.9479 12.561 13.5521 12.561 13.3081 12.3169C13.064 12.0729 13.064 11.6771 13.3081 11.4331L15.9911 8.75H13.1252H13.125C11.6005 8.7505 10.1193 9.25714 8.91379 10.1904C7.70827 11.1237 6.84674 12.4309 6.4644 13.9067C6.37784 14.2409 6.03678 14.4416 5.70264 14.355C5.36849 14.2685 5.16778 13.9274 5.25435 13.5933C5.70612 11.8494 6.72412 10.3048 8.14858 9.202C9.57304 8.0992 11.3233 7.50054 13.1248 7.5H13.125H15.9911L13.3081 4.81694C13.064 4.57286 13.064 4.17714 13.3081 3.93306ZM2.5 6.25C2.84518 6.25 3.125 6.52982 3.125 6.875V16.25H15C15.3452 16.25 15.625 16.5298 15.625 16.875C15.625 17.2202 15.3452 17.5 15 17.5H3.125C2.79348 17.5 2.47554 17.3683 2.24112 17.1339C2.0067 16.8995 1.875 16.5815 1.875 16.25V6.875C1.875 6.52982 2.15482 6.25 2.5 6.25Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
