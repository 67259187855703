import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import Head from 'next/head'

import { stringifySearchParams } from 'utils/queryString.utils'
import { useNextRouter } from 'shop/hooks/use-next-js-router'

const TRACKING_CODES = [
  'trc_mcmp_id',
  'trc_mag_id',
  'trc_mad_id',
  'trc_gcmp_id',
  'trc_gag_id',
  'trc_gad_id',
  'trc_ttc_id',
  'trc_ttag_id',
  'trc_ttad_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'trc_inf_un',
  'trc_inf_cn',
  'trc_inf_dc',
]

interface Props {
  csId: string
  staging: boolean
  tracifyEventShouldBeFired: boolean
  cleareTracifyQEvent: () => void
  isProductPage: boolean
}

export const Tracify: React.FC<Props> = observer(
  ({ csId, staging, tracifyEventShouldBeFired, cleareTracifyQEvent, isProductPage }): React.ReactElement => {
    const [inited, setInited] = useState(false)
    const [eventMap, setSrc] = useState([])

    const { pathname: path, params } = useNextRouter()

    useEffect(() => {
      setInited(true)
    }, [])

    useEffect(() => {
      if (!csId || !inited) {
        setSrc([])
        return
      }

      const domain = staging ? 'https://devevent.tracify.ai' : 'https://event.tracify.ai'
      const event = isProductPage ? 'productview' : 'pageview'

      const allowedParams = TRACKING_CODES.reduce(
        (acc, key) => (params[key] ? { ...acc, [key]: params[key] } : acc),
        {}
      )
      const stringifyAllowedParams = stringifySearchParams(allowedParams)
      const tracifyLink = `${domain}/tracify.js?csid=${csId}&eid=${event}${
        stringifyAllowedParams ? `&${stringifyAllowedParams}` : ''
      }`

      setSrc((eventMap) => [...eventMap, tracifyLink])
    }, [inited, path])

    useEffect(() => {
      if (!csId || !inited) {
        setSrc([])
        return
      }

      const domain = staging ? 'https://devevent.tracify.ai' : 'https://event.tracify.ai'
      const event = tracifyEventShouldBeFired ? 'addtocart' : ''

      const allowedParams = TRACKING_CODES.reduce(
        (acc, key) => (params[key] ? { ...acc, [key]: params[key] } : acc),
        {}
      )

      const stringifyAllowedParams = stringifySearchParams(allowedParams)
      const tracifyLink = `${domain}/tracify.js?csid=${csId}&eid=${event}${
        stringifyAllowedParams ? `&${stringifyAllowedParams}` : ''
      }`

      tracifyEventShouldBeFired && setSrc((eventMap) => [...eventMap, tracifyLink])
      return () => {
        cleareTracifyQEvent()
      }
    }, [inited, tracifyEventShouldBeFired])
    return (
      <>
        {eventMap.map(
          (src, idx) =>
            src && (
              <Head key={idx}>
                <script async src={src}></script>
              </Head>
            )
        )}
      </>
    )
  }
)
