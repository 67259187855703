// TODO: delete this hook and its usage on the _app.tsx after end of the redirection phase
import { useEffect } from 'react'
import { setCookie } from 'cookies-next'
import { isWindowEnv } from 'utils/env.utils'
import { in10000Days } from '@elo-kit/utils/dateTime.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { LocalStorageService } from 'utils/local-storage.utils'

interface RedirectionParams {
  cdata?: string
  ldata?: string
}

export const useRedirectionCookies = (params: RedirectionParams) => {
  useEffect(() => {
    const { cdata, ldata } = params

    if (cdata || ldata) {
      if (cdata) {
        const cookies = JSON.parse(atob(decodeURIComponent(cdata)))
        const hostname = isWindowEnv() ? window.location.hostname : getElopageConfig('hostname')

        Object.entries(cookies).forEach(([key, value]) => {
          setCookie(key, value, {
            expires: in10000Days(),
            path: '/',
            domain: hostname,
          })
        })
      }

      if (ldata) {
        try {
          const localStorages = JSON.parse(atob(decodeURIComponent(ldata)))
          Object.entries(localStorages).forEach(([key, value]) => {
            LocalStorageService.setItem(key, value)
          })
        } catch (error) {
          console.error('Error storing ldata in localStorage:', error)
        }
      }

      // Removing cdata and ldata from URL without causing a navigation redirect
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('cdata')
      newUrl.searchParams.delete('ldata')
      window.history.replaceState({}, '', newUrl.toString())
    }
  }, [params])
}
