import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDropboxIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M2 7L7.41538 3L18 10.5L12.8308 14.25L2 7Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinejoin='round'
    />
    <path
      d='M2 10.5L12.5846 3L18 6.75L7.16923 14.25L2 10.5Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinejoin='round'
    />
    <path
      d='M4.5 12.25V14.75L9.87209 18.25L15.5 14.75V12.25'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
