interface FieldItem {
  visible: string
  required: string
}

export const convertFieldState = (state: string): FieldItem => {
  switch (state) {
    case 'visible':
      return {
        visible: 'on',
        required: 'off',
      }
    case 'required':
      return {
        visible: 'on',
        required: 'on',
      }
    case 'protected':
      return {
        visible: 'on',
        required: 'on',
      }
    case 'hidden':
    default:
      return {
        visible: 'off',
        required: 'off',
      }
  }
}

export const convertFieldsObject = (fields: Record<string, string> = {}): Record<string, FieldItem> =>
  Object.keys(fields || {}).reduce(
    (acc, key) => ({
      ...acc,
      [key]: convertFieldState(fields[key]),
    }),
    {}
  )
