import { formatToMoney } from 'utils/money.utils'

import { Currency } from 'shared/api/currencies.api'

import {
  CURRENCY_SIGN_POSITIONS,
  CURRENCY_SIGNS,
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_SIGN,
  getCurrencyLabels,
} from 'constants/currencies.constants'
import { ascendingSort } from 'utils/helpers.utils'

export const getCurrencyUnit = (currenciesList: Currency[], id: number | string): string =>
  (currenciesList.find((item) => String(item.id) === String(id) || item.key === id) || {}).unit || DEFAULT_CURRENCY_SIGN

export const getCurrencySignPosition = (currencySign: string): CURRENCY_SIGN_POSITIONS =>
  currencySign === CURRENCY_SIGNS.gbp || currencySign === CURRENCY_SIGNS.usd
    ? CURRENCY_SIGN_POSITIONS.left
    : CURRENCY_SIGN_POSITIONS.right

export const getCurrencySeparator = (currencySign: string, separator: string): string =>
  currencySign === CURRENCY_SIGNS.chf ? ' ' : separator

export const getCurrencyKey = (currenciesList: Currency[], id: number): string =>
  (currenciesList.find((item) => String(item.id) === String(id)) || {}).key || DEFAULT_CURRENCY

export const convertToPrice = (
  currenciesList: Currency[],
  value: string | number,
  currencyId: number | string,
  separator = ''
) => {
  const currencySign = getCurrencyUnit(currenciesList, currencyId)
  const currencySignPosition = getCurrencySignPosition(currencySign)
  const separatorValue = getCurrencySeparator(currencySign, separator)

  return formatToMoney(value, currencySign, 2, separatorValue, currencySignPosition)
}

export const formatCurrencySelectOptions = (list: Currency[] = []) => {
  const labels = getCurrencyLabels()
  return ascendingSort(list, 'id').map(({ id, key }) => ({
    value: id,
    label: labels[key],
  }))
}
