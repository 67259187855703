import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloReceiptIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M5.66663 7.8999H14.3333'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.66663 10.7002H14.3333'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 17V3.7C2 3.51435 2.07024 3.3363 2.19526 3.20503C2.32029 3.07375 2.48986 3 2.66667 3H17.3333C17.5101 3 17.6797 3.07375 17.8047 3.20503C17.9298 3.3363 18 3.51435 18 3.7V17L15.3333 15.6L12.6667 17L10 15.6L7.33333 17L4.66667 15.6L2 17Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
