import React from 'react'
import { toJS } from 'mobx'

import { getCookies, setCookies, removeCookies, getAllCookies } from 'libs/common/cookies'
import { LocalStorageService } from 'utils/local-storage.utils'
import { TRACKING_CODE_TYPES } from 'constants/trackingCodes.constants'
import { CookiesConsentWidget } from '@elo-kit/components/cookies-consent-widget/cookies-consent/CookiesConsentWidget'
import { getSellerLink } from 'utils/helpersShared.utils'

const CookiesConsentWidgetComponent = (props) => {
  const {
    theme,
    preview,
    username,
    isPowerSeller,
    location,
    onSubmit,
    form,
    cookiesConsentsStore,
    cookiesConsentsStore: { list: consentsList },
    cookiesConsentCategoriesStore,
    cookiesConsentCategoriesStore: { list: categoriesList },
    sellerStore,
    trackingCodesStore,
    legalLinks,
    isCheckoutPage,
    themePages,
  } = props

  const forms = [
    ...new Set(...Object.values(toJS(trackingCodesStore?.codes) || {}).map(({ list }) => list.map(({ form }) => form))),
  ].map((item) => (item == TRACKING_CODE_TYPES.googleAnalytics4 ? TRACKING_CODE_TYPES.googleAnalytics : item))

  const trackingCodes = [
    TRACKING_CODE_TYPES.googleAnalytics,
    TRACKING_CODE_TYPES.googleTagManager,
    TRACKING_CODE_TYPES.facebookPixel,
  ]

  const filterdConsentsList = consentsList.filter((consent) =>
    trackingCodes.includes(consent.key) ? forms.includes(consent.key) : true
  )
  const filterdCategoriesList = categoriesList
  const powerSellerParam = sellerStore.item?.powerSeller && !isCheckoutPage ? '?power_seller=true' : ''
  return (
    <CookiesConsentWidget
      theme={theme}
      preview={preview}
      username={username}
      isPowerSeller={isPowerSeller}
      location={location}
      onSubmit={onSubmit}
      form={form}
      consentsList={filterdConsentsList}
      categoriesList={filterdCategoriesList}
      getCookies={getCookies}
      setCookies={setCookies}
      removeCookies={removeCookies}
      getAllCookies={getAllCookies}
      addItemToLocalStorage={(key, value) => LocalStorageService.setItem(key, value)}
      getItemFromLocalStorage={(key) => LocalStorageService.getItem(key)}
      cookiesConsentCategoriesStore={cookiesConsentCategoriesStore}
      cookiesConsentsStore={cookiesConsentsStore}
      sellerStore={sellerStore}
      legalLinks={legalLinks}
      baseURL={getSellerLink(sellerStore.item)}
      powerSellerParam={powerSellerParam}
      themePages={themePages}
    />
  )
}

export default CookiesConsentWidgetComponent
