import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloRadiobuttonSelectedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9.99964 13.636C12.008 13.636 13.636 12.008 13.636 9.99967C13.636 7.99137 12.008 6.36331 9.99964 6.36331C7.99133 6.36331 6.36328 7.99137 6.36328 9.99967C6.36328 12.008 7.99133 13.636 9.99964 13.636Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
