import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloSellerIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M3.00696 11.4346V16.9453C3.00696 17.1163 3.07537 17.2802 3.19714 17.4011C3.3189 17.5219 3.48406 17.5898 3.65626 17.5898H15.3438C15.516 17.5898 15.6811 17.5219 15.8029 17.4011C15.9247 17.2802 15.9931 17.1163 15.9931 16.9453V11.4346'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.49396 3.41016H15.5061C15.647 3.41131 15.7837 3.45734 15.8962 3.54147C16.0088 3.62559 16.0911 3.74337 16.1311 3.87744L17.2917 7.92188H1.70837L2.86901 3.87744C2.909 3.74337 2.99132 3.62559 3.10383 3.54147C3.21635 3.45734 3.35311 3.41131 3.49396 3.41016V3.41016Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.90282 7.92188V9.21094C6.90282 9.8947 6.62918 10.5505 6.14211 11.0339C5.65504 11.5174 4.99442 11.7891 4.3056 11.7891C3.61677 11.7891 2.95616 11.5174 2.46908 11.0339C1.98201 10.5505 1.70837 9.8947 1.70837 9.21094V7.92188'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0973 7.92188V9.21094C12.0973 9.8947 11.8236 10.5505 11.3366 11.0339C10.8495 11.5174 10.1889 11.7891 9.50005 11.7891C8.81123 11.7891 8.15061 11.5174 7.66354 11.0339C7.17647 10.5505 6.90283 9.8947 6.90283 9.21094V7.92188'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.2916 7.92188V9.21094C17.2916 9.8947 17.018 10.5505 16.5309 11.0339C16.0438 11.5174 15.3832 11.7891 14.6944 11.7891C14.0056 11.7891 13.345 11.5174 12.8579 11.0339C12.3708 10.5505 12.0972 9.8947 12.0972 9.21094V7.92188'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
