export const DATE_FORMATS = {
  dddMMMD: 'ddd, MMM D',
  DDMMYYYY: 'DD.MM.YYYY',
  DDMMYY: 'DD.MM.YY',
  DDMMYYHHmm: 'DD.MM.YY HH:mm',
  MMYYYY: 'MM.YYYY',
  MMDDYYYY: 'MM.DD.YYYY',
  DDMMYYYYHHmm: 'DD.MM.YYYY HH:mm',
  DDMMYYYYHHmmss: 'DD.MM.YYYY HH:mm:ss',
  DATE_RFC2822: 'ddd, DD MMM YYYY HH:mm:ss ZZ',
  TIMESTAMP: 'X',
  INPUT: 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ',
  NOT_SEPARATED_YYYYMMDD: 'YYYYMMDD',
  default: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  SKIP_TZ_DASHED_YYYYMMDDHHmm: 'YYYY-MM-DDTHH:mm:ss',
}

export const TIME_FORMATS = {
  HHmm: 'HH:mm',
  hhmm: 'hh:mm',
  HHmmss: 'HH:mm:ss',
}

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const getMonthsLabel = (day) => ({
  january: I18n.t('react.cabinet.common.months_names.january', { day }),
  february: I18n.t('react.cabinet.common.months_names.february', { day }),
  march: I18n.t('react.cabinet.common.months_names.march', { day }),
  april: I18n.t('react.cabinet.common.months_names.april', { day }),
  may: I18n.t('react.cabinet.common.months_names.may', { day }),
  june: I18n.t('react.cabinet.common.months_names.june', { day }),
  july: I18n.t('react.cabinet.common.months_names.july', { day }),
  august: I18n.t('react.cabinet.common.months_names.august', { day }),
  september: I18n.t('react.cabinet.common.months_names.september', { day }),
  october: I18n.t('react.cabinet.common.months_names.october', { day }),
  november: I18n.t('react.cabinet.common.months_names.november', { day }),
  december: I18n.t('react.cabinet.common.months_names.december', { day }),
})

export const PERIODS = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
}

export const MONTHS_KEYS = MONTHS.map((name) => name.slice(0, 3))

export const TIME_UNITS_IN_SEC = {
  minute: 60,
  hour: 3600,
  day: 86400,
  week: 604800,
}

export const TIME_UNITS_IN_MILLI_SEC = Object.keys(TIME_UNITS_IN_SEC).reduce(
  (result, key) => ({
    ...result,
    [key]: 1000 * TIME_UNITS_IN_SEC[key],
  }),
  {}
)

export const CURRENT_YEAR = new Date().getFullYear()

export const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const ONE_MONTH = 30
