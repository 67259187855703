import React from 'react'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'
import { get } from 'utils/lodash.utils'
import classNames from 'classnames'

import { EloButton } from '@elo-kit/components/elo-button'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import cookiesConsentFloatingBtnStyles from './CookiesConsentFloatingBtn.styles'

/**
 * Floating button for cookies consent
 */
export const CookiesConsentFloatingBtn = (props) => {
  const I18n = useI18n()

  const {
    onBtnClick,
    className,
    classes,
    preview,
    title = I18n.t('react.shared.cookies_consent.cookies_settings_floating_btn'),
  } = props
  const consentFloatBtnClassNames = get(classes, 'сonsentFloatBtn', className)
  const consentFloatBtnPreviewClassNames = get(classes, 'сonsentFloatBtnPreview', '')

  return (
    <EloButton.Floating
      onClick={onBtnClick}
      className={classNames(consentFloatBtnClassNames, { [consentFloatBtnPreviewClassNames]: preview })}
    >
      <i className='fas fa-cog' />
      {title}
    </EloButton.Floating>
  )
}

CookiesConsentFloatingBtn.propTypes = {
  /** Button click handler */
  onBtnClick: PropTypes.func,
  /** Custom class name */
  className: PropTypes.string,
  /** Button title */
  title: PropTypes.any,
  /** React JSS classes */
  classes: PropTypes.object,
  /** Preview flag */
  preview: PropTypes.bool,
}

CookiesConsentFloatingBtn.defaultProps = {
  onBtnClick: () => {},
}

/**
 * Floating button for cookies consent with React JSS styles
 */
export const CookiesConsentFloatingBtnWithStyles = withStyles(cookiesConsentFloatingBtnStyles)((props) => (
  <CookiesConsentFloatingBtn {...props} />
))

/**
 * Floating button for cookies consent with React JSS theme provider
 */
export const CookiesConsentFloatingBtnStyled = (props) => {
  const { theme } = props

  return (
    <ThemeProvider theme={theme || {}}>
      <CookiesConsentFloatingBtnWithStyles {...props} />
    </ThemeProvider>
  )
}

CookiesConsentFloatingBtnStyled.propTypes = {
  /** React JSS theme */
  theme: PropTypes.object,
}
