import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloSignOutIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M13.9531 6.39062L17.5625 10L13.9531 13.6094'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.9375 10H17.5625' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M7.9375 17.5625H3.125C2.94266 17.5625 2.7678 17.4901 2.63886 17.3611C2.50993 17.2322 2.4375 17.0573 2.4375 16.875V3.125C2.4375 2.94266 2.50993 2.7678 2.63886 2.63886C2.7678 2.50993 2.94266 2.4375 3.125 2.4375H7.9375'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
