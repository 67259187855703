import { apiClient } from 'utils/requests.utils'

const BASE_URL = (username) => `/shop/${username}/setup_intents`

export const createSetupIntentsApi = ({ POST }) => ({
  createItem: (username, data) =>
    POST(BASE_URL(username), {
      async: false,
      ...data,
    }),
})

const api = createSetupIntentsApi(apiClient)
const createItem = api.createItem
export default createItem
