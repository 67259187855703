import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBellIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M3.8302 7.9376C3.82905 7.12255 3.98917 6.31532 4.30133 5.56242C4.61349 4.80952 5.07152 4.12581 5.64904 3.55069C6.22657 2.97556 6.91217 2.52037 7.66636 2.21135C8.42056 1.90232 9.22844 1.74556 10.0435 1.7501C13.4466 1.77588 16.1708 4.60322 16.1708 8.01494V8.6251C16.1708 11.7017 16.8154 13.4892 17.3825 14.4688C17.4428 14.5732 17.4745 14.6915 17.4746 14.812C17.4748 14.9325 17.4432 15.0509 17.3831 15.1553C17.3231 15.2597 17.2366 15.3466 17.1325 15.4071C17.0283 15.4676 16.91 15.4997 16.7896 15.5001H3.21145C3.09097 15.4997 2.97272 15.4676 2.86854 15.4071C2.76437 15.3466 2.67792 15.2597 2.61787 15.1553C2.55782 15.0509 2.52626 14.9325 2.52637 14.812C2.52647 14.6915 2.55824 14.5732 2.61848 14.4688C3.18566 13.4892 3.8302 11.7017 3.8302 8.6251V7.9376Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.25 15.5V16.1875C7.25 16.9168 7.53973 17.6163 8.05546 18.132C8.57118 18.6478 9.27065 18.9375 10 18.9375C10.7293 18.9375 11.4288 18.6478 11.9445 18.132C12.4603 17.6163 12.75 16.9168 12.75 16.1875V15.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
