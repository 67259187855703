import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPackageIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 0C9.75903 0 9.52042 0.0599551 9.30748 0.17621L5.55281 2.18944C5.53068 2.19994 5.50904 2.21154 5.48797 2.2242L1.74793 4.22958L1.74793 4.22958L1.74531 4.231C1.55601 4.33347 1.39072 4.47728 1.2638 4.65344C1.23328 4.69088 1.20594 4.73184 1.18242 4.77612C1.1754 4.78932 1.16882 4.80264 1.16267 4.81606C1.05789 5.01335 1.00129 5.23376 1.00001 5.45977L1 5.45977V5.46399V13.5411L1.00001 13.5454C1.00145 13.8011 1.07371 14.0496 1.20643 14.2656C1.33895 14.4812 1.52602 14.6554 1.74531 14.7741L1.74793 14.7755L9.30756 18.829C9.52049 18.9452 9.75907 19.0051 10 19.0051C10.2409 19.0051 10.4795 18.9452 10.6924 18.829L18.2521 14.7755L18.2547 14.7741C18.474 14.6554 18.6611 14.4812 18.7936 14.2656C18.9263 14.0496 18.9985 13.8011 19 13.5454L18.25 13.5411H19L19 5.46399L19 5.45977C18.9987 5.23579 18.9431 5.01731 18.8402 4.82139C18.8325 4.80439 18.8242 4.78755 18.8152 4.7709C18.7914 4.72707 18.764 4.68655 18.7334 4.64952C18.6068 4.47515 18.4426 4.33271 18.2547 4.23099L18.2521 4.22958L10.6925 0.17621C10.4796 0.0599554 10.241 0 10 0ZM2.5 13.4768V6.32551L9.32335 9.95104L9.26013 17.1015L2.5 13.4768ZM10.7603 17.0906L17.5 13.4768V6.33599L14.9609 7.71094V11.5091C14.9609 11.9234 14.6252 12.2591 14.2109 12.2591C13.7967 12.2591 13.4609 11.9234 13.4609 11.5091V8.52321L10.8234 9.95148L10.7603 17.0906ZM10.0742 8.65139L12.6317 7.26644L5.87934 3.71638L3.34442 5.07558L10.0742 8.65139ZM16.6666 5.08149L14.2196 6.40659L7.47517 2.86071L10 1.50691L16.6666 5.08149Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
