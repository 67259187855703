import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { FromSwagger } from 'types/helpers'
import { CurrencyAttributes } from 'types/__generated__/api'

const BASE_URL = 'common/currencies'

export type Currency = FromSwagger<CurrencyAttributes, number>

export const createCurrenciesApi = ({ GET }: ApiClientV2) => ({
  fetchList: () => GET<Currency[]>(BASE_URL),
})

export type CurrenciesApi = ReturnType<typeof createCurrenciesApi>
