import ResizeObserver from 'resize-observer-polyfill'
import { POST_MESSAGE_ACTIONS } from '../containers/widget/common/variables'

// Size helpers used for resizing iframe
const getDocDimensions = (doc) => {
  doc = doc || document
  const body = doc.body
  const html = doc.documentElement
  const heights = [body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight]
  const widths = [body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth]
  return {
    height: Math.max.apply(null, heights),
    width: Math.max.apply(null, widths),
  }
}

export const getIframeWidth = () => getDocDimensions(document).width
export const getIframeHeight = () => getDocDimensions(document).height

// End of size helpers

// Messages to iframe
export const sendMessageToEmbeded = (props) => {
  const { msg, origin } = props
  window.parent.postMessage(msg, origin ?? '*') // origin can be null oO
}

const loadCompletedMessage = (origin = '*') => {
  const modalPageWrapper = document.getElementsByClassName('embeded-page-wrapper')
  const action = 'loadCompleted'
  if (modalPageWrapper) {
    sendMessageToEmbeded({
      msg: {
        action,
        width: modalPageWrapper.clientWidth,
      },
      origin,
    })
  } else {
    sendMessageToEmbeded({
      msg: {
        action,
      },
      origin,
    })
  }
}

const resizeIframeMessage = (origin = '*', height, msg) => {
  sendMessageToEmbeded({
    msg: {
      action: 'resize',
      height: height || getIframeHeight(),
      width: getIframeWidth(),
      ...msg,
    },
    origin,
  })
}

export const embedRedirectionMessage = (url, origin = '*') => {
  sendMessageToEmbeded({
    msg: {
      action: POST_MESSAGE_ACTIONS.redirection,
      url,
    },
    origin,
  })
}
// End of messages to iframe

// Receive messages logic
const msgReceivedActions = (data, handleMsgReceived) => {
  handleMsgReceived(data)
  loadCompletedMessage(data.url)
}

export const listenEmbedMessage = (handleMsgReceived) => {
  const handleMessage = (e) => {
    if (e.origin !== 'https://js.stripe.com') {
      msgReceivedActions(e.data, handleMsgReceived)
    }
  }
  if (window.addEventListener) {
    window.addEventListener('message', (e) => handleMessage(e), false)
  } else {
    window.attachEvent('onmessage', (e) => handleMessage(e))
  }
}
// End of receiving messages logic

// Resizing helpers

export const listenElementDimensionsChange = (elm, callback) => {
  const ro = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const { offsetHeight, offsetWidth } = entry.target || {}
      window.requestAnimationFrame(() =>
        callback({
          height: offsetHeight,
          width: offsetWidth,
        })
      )
    })
  })
  ro.observe(elm)
}

export const onElementWidthChange = (elm, callback) => {
  let lastWidth = elm.clientWidth,
    newWidth
  ;(function run() {
    newWidth = elm.clientWidth
    if (lastWidth != newWidth) {
      callback(newWidth)
      lastWidth = newWidth
    }
    lastWidth = newWidth
    if (elm.onElementWidthChangeTimer) {
      clearTimeout(elm.onElementWidthChangeTimer)
    }
    elm.onElementWidthChangeTimer = setTimeout(run, 200)
  })()
}

export const listenPageHeightChanged = (origin = '*', msg = {}) => {
  listenElementDimensionsChange(document.body, ({ height }) => {
    resizeIframeMessage(origin, height, msg)
  })
}
