import { observable, action, computed, toJS, makeObservable } from 'mobx'
import queryString from 'query-string'

import { isEmpty } from 'utils/validatorsShared.utils'

import { getNestedChildren } from 'utils/product.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { COURSE_FORMS } from 'constants/courses.constants'
import { desktopResolution } from 'constants/browser.constants'
import { isWindowEnv } from 'utils/env.utils'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { Product } from 'shop/api/products.api'
import { CourseTheme } from 'shop/api/courseThemes.api'
import { Lesson } from 'shop/api/lessons.api'

export class CourseViewsStore {
  storeName = 'CourseViewsStore'
  root: ShopRootStore

  constructor(root: ShopRootStore) {
    this.root = root
    makeObservable(this)
  }

  @observable inited = false
  @observable loading = false
  @observable cmpProps

  @observable mobileMenuActive = false
  @observable mobileSideMenuActive = false
  @observable showRetakeQuiz = false
  @observable showQuizResultModal = false
  @observable showQuizResults = false

  @observable courseSession = {} as { id: number }
  @observable product = {} as Product
  @observable lessons = []
  @observable activeLesson = {} as Lesson
  @observable activeLessonStatus

  @observable courseThemeData = {} as CourseTheme
  @observable membershipNavOpen = true

  @observable mainSideMenuOpen = true

  @observable showHeaderLinks = false

  @observable browserTabTitle = ''
  @observable sharingToken = null
  @observable communityModalIsOpenedByDefault = false

  @action setBrowserTabTitle = () => {
    this.browserTabTitle = document.title
  }

  @action setSideMenuState = (state) => {
    this.mainSideMenuOpen = state
  }

  @action setMembershipNavState = (state) => {
    this.membershipNavOpen = state
  }

  @action setSharingToken = (value) => {
    this.sharingToken = value
  }

  /** actions  */
  @action setActiveLesson = (activeLesson) => (this.activeLesson = activeLesson)
  @action setCourseThemeData = (courseThemeData) => (this.courseThemeData = courseThemeData)
  @action setCmpProps = (cmpProps) => (this.cmpProps = cmpProps)
  @action setInited = (inited) => (this.inited = inited)
  @action setLessons = (lessons) => (this.lessons = lessons)
  @action setLoading = (loading) => (this.loading = loading)
  @action setMobileMenuActive = (mobileMenuActive) => (this.mobileMenuActive = mobileMenuActive)
  @action setMobileSideMenuActive = (mobileSideMenuActive) => (this.mobileSideMenuActive = mobileSideMenuActive)
  @action setProduct = (product) => (this.product = product)
  /** actions */

  prepareCourse = async (props) => {
    // TODO: SSR - remove history and match router objects from store
    this.setInited(false)
    this.setLoading(true)
    const { productSlug, username, historyPush, historyReplace, productId: slug, lessonId, token } = props

    this.setCmpProps({
      productSlug,
      username,
      historyPush,
      historyReplace,
    })

    this.setSharingToken(token)
    const { is_preview } = getSearchParams()
    const isPreview = is_preview || this.sharingToken

    const { data: product, success: productSuccess } = await this.root.productsStore.fetchProduct(
      slug,
      { push: historyPush },
      isPreview,
      username
    )

    if (product && productSuccess) {
      const { courseThemeId, id: productId } = product

      const username = toJS(this.seller.username)

      if (courseThemeId) {
        const { data: courseTheme, success: courseThemeSuccess } = await this.root.courseThemesStore.fetchItem(
          courseThemeId,
          {
            username,
          }
        )

        const courseThemeData =
          courseThemeSuccess && courseTheme && !isEmpty(courseTheme) ? courseTheme : { form: COURSE_FORMS.default }

        this.setCourseThemeData(courseThemeData)
      } else {
        this.setCourseThemeData({ form: COURSE_FORMS.default })
      }

      this.setProduct(product)

      const data = {
        username,
        productId,
      }
      const resp = await this.root.lessonsStore.fetchFullList(data)
      const { list = [] } = resp.data || {}
      this.setLessons(getNestedChildren(list, null))
      const { lesson_id: redirectionLessonId } = getSearchParams()
      await this.selectFirstLesson(lessonId || redirectionLessonId)
    }
  }

  @action selectFirstLesson = async (lessonId) => {
    await this.selectLesson(lessonId || this.detectFirstLessonId(), null)
  }

  @action detectFirstLessonId = () => this.detectFirstLessonIdDeep(this.lessons)

  @action detectFirstLessonIdDeep = (lessons) => {
    // with this deep detecting we can do a lot of features in the future
    // like detect first not passed, detect first with quiz OR even
    // open for all payers lesson which seller select as promotion
    const detected = false
    let deepResult

    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].isCategory) {
        deepResult = this.detectFirstLessonIdDeep(lessons[i].children)
        if (deepResult) {
          return deepResult
        }
      } else if (lessons[i].id && (lessons[i].preview || this.sharingToken)) {
        return lessons[i].id
      }
    }

    return detected
  }

  selectLesson = async (lessonId, handleScrollChange) => {
    if (lessonId) {
      const resp = await this.root.lessonsStore.fetchItem(lessonId, {
        username: this.seller.username,
        productId: this.product.id,
        sharingToken: this.sharingToken,
      })

      if (resp.success && (resp.data.preview || this.sharingToken)) {
        this.setActiveLesson(resp.data)
        this.setQueryParams()
        this.setMobileMenuActive(false)
        this.setMobileSideMenuActive(false)
        this.setInited(true)
        this.setLoading(false)

        if (this.activeLesson.name && isWindowEnv()) {
          // TODO: SSR - don't use 'document' in store
          document.title = this.activeLesson.name
        }
      }

      if (handleScrollChange && desktopResolution) {
        handleScrollChange()
      } else if (isWindowEnv()) {
        // TODO: SSR - don't use 'window' in store
        window.scrollTo(0, 0)
      }
    }
  }

  setQueryParams = () => {
    // TODO: SSR - remove router from store - 'setQuery' is incompatible with SSR
    if (typeof window === 'undefined') return
    const newQueryString = queryString.stringify({
      course_session_id: this.courseSession.id,
      lesson_id: this.activeLesson.id,
    })

    this.cmpProps.historyReplace({ search: newQueryString })
  }

  toggleMobileMenu = () => {
    this.setMobileMenuActive(!this.mobileMenuActive)
  }

  toggleSideMobileMenu = () => {
    this.setMobileSideMenuActive(!this.mobileSideMenuActive)
  }

  isAppActive = (key) => this.root.sellerStore.isAppActive(key)

  @computed get seller() {
    return this.root.sellerStore.item
  }

  @computed get viewConfig() {
    const layout = this.courseThemeData.form || COURSE_FORMS.default

    return {
      layout,
      isShop: true,
      isPayerCabinet: false,
      shouldCompletePrevQuizzes: false,
      showPrevBtn: this.sharingToken,
      showNextBtn: this.sharingToken,
      backLink: `/s/${this.seller.username}/${this.product.slug}`,
      contentPagePreviewScope: `shop/${this.seller.username}`,
      currentProfileId: this.seller.id,
      currentProfileType: 'seller',
      contentToDisplayKey: 'contentPageCont',
      widgets: {
        quiz: false,
        content: true,
        comments: false,
      },
    }
  }

  @action hydrate = (key, data) => {
    switch (key) {
      case 'product':
        this.product = data
        break
      case 'courseThemeData':
        this.courseThemeData = data
        break
      case 'lessons':
        this.lessons = data
        break
      case 'inited':
        this.inited = data
        break
      case 'mobileMenuActive':
        this.mobileMenuActive = data
        break
      case 'activeLesson':
        this.activeLesson = data
        break
      case 'loading':
        this.loading = data
        break
      case 'membershipNavOpen':
        this.membershipNavOpen = data
        break
      case 'sharingToken':
        this.sharingToken = data
        break
    }
  }
}
