import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCheckboxIndeterminateIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 0.910156C2.79086 0.910156 1 2.70102 1 4.91016V15.092C1 17.3011 2.79086 19.092 5 19.092H15.1818C17.391 19.092 19.1818 17.3011 19.1818 15.092V4.91016C19.1818 2.70102 17.391 0.910156 15.1818 0.910156H5ZM5 9.37498C4.65482 9.37498 4.375 9.6548 4.375 9.99998C4.375 10.3452 4.65482 10.625 5 10.625H15C15.3452 10.625 15.625 10.3452 15.625 9.99998C15.625 9.6548 15.3452 9.37498 15 9.37498H5Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
