import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

import { SellerFontAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type SellerFonts = FromSwagger<SellerFontAttributes>

const BASE_URL = (username: string) => `shop/${username}/seller_fonts`

export const createSellerFontsApi = ({ GET }: ApiClientV2) => ({
  fetchList: (data: { username: string }) => GET<SellerFonts[]>(BASE_URL(data.username), data),
})

export type SellerFontsApi = ReturnType<typeof createSellerFontsApi>
