export enum CURRENCY_SIGNS {
  eur = '€',
  usd = '$',
  chf = 'CHF',
  gbp = '£',
}

export const CURRENCY_KEYS = {
  eur: 'eur',
  usd: 'usd',
  gbr: 'gbr',
  chf: 'chf',
  pln: 'pln',
  nok: 'nok',
  sek: 'sek',
  dkk: 'dkk',
  cad: 'cad',
  aud: 'aud',
  brl: 'brl',
  cny: 'cny',
  czk: 'czk',
  hkd: 'hkd',
  huf: 'huf',
  inr: 'inr',
  ils: 'ils',
  jpy: 'jpy',
  myr: 'myr',
  mxn: 'mxn',
  nzd: 'nzd',
  php: 'php',
  gbp: 'gbp',
  rub: 'rub',
  sgd: 'sgd',
  twd: 'twd',
  thb: 'thb',
  all: 'all',
  aed: 'aed',
  afn: 'afn',
  amd: 'amd',
  ang: 'ang',
  aoa: 'aoa',
  ars: 'ars',
  awg: 'awg',
  azn: 'azn',
  bam: 'bam',
  bdd: 'bdd',
  bdt: 'bdt',
  bgn: 'bgn',
  bif: 'bif',
  bmd: 'bmd',
  bnd: 'bnd',
  bob: 'bob',
  bsd: 'bsd',
  bwb: 'bwb',
  bzd: 'bzd',
  cdf: 'cdf',
  clp: 'clp',
  cop: 'cop',
  crc: 'crc',
  cve: 'cve',
  djf: 'djf',
  dop: 'dop',
  dzd: 'dzd',
  egp: 'egp',
  etb: 'etb',
  fjd: 'fjd',
  fkp: 'fkp',
  gel: 'gel',
  gip: 'gip',
  gmd: 'gmd',
  gnf: 'gnf',
  gtq: 'gtq',
  gyd: 'gyd',
  hnl: 'hnl',
  hrk: 'hrk',
  htg: 'htg',
  idr: 'idr',
  isk: 'isk',
  jmd: 'jmd',
  kes: 'kes',
  kgs: 'kgs',
  khr: 'khr',
  kmf: 'kmf',
  krw: 'krw',
  kyd: 'kyd',
  kzt: 'kzt',
  lak: 'lak',
  lbp: 'lbp',
  lkr: 'lkr',
  lrd: 'lrd',
  lsl: 'lsl',
  mad: 'mad',
  mdl: 'mdl',
  mga: 'mga',
  mkd: 'mkd',
  mmk: 'mmk',
  mnt: 'mnt',
  mop: 'mop',
  mro: 'mro',
  mur: 'mur',
  mvr: 'mvr',
  mwk: 'mwk',
  mzn: 'mzn',
  nad: 'nad',
  ngn: 'ngn',
  nio: 'nio',
  npr: 'npr',
  pab: 'pab',
  pen: 'pen',
  pgk: 'pgk',
  pkr: 'pkr',
  pyg: 'pyg',
  qar: 'qar',
  ron: 'ron',
  rsd: 'rsd',
  rwf: 'rwf',
  sar: 'sar',
  sbd: 'sbd',
  scr: 'scr',
  shp: 'shp',
  sll: 'sll',
  sos: 'sos',
  srd: 'srd',
  std: 'std',
  szl: 'szl',
  tjs: 'tjs',
  try: 'try',
  ttd: 'ttd',
  tzs: 'tzs',
  uah: 'uah',
  ugx: 'ugx',
  uyu: 'uyu',
  uzs: 'uzs',
  vnd: 'vnd',
  vuv: 'vuv',
  wst: 'wst',
  xaf: 'xaf',
  xcd: 'xcd',
  xof: 'xof',
  xpf: 'xpf',
  yer: 'yer',
  zar: 'zar',
  zmw: 'zmw',
}

export const DEFAULT_CURRENCY = CURRENCY_KEYS.eur
export const DEFAULT_CURRENCY_SIGN = CURRENCY_SIGNS.eur

export enum CURRENCY_SIGN_POSITIONS {
  left = 'left',
  right = 'right',
}

export const DEFAULT_CURRENCY_ID = 1

export const getCurrencyLabels = () => ({
  chf: I18n.t('react.shared.common.chf'),
  eur: I18n.t('react.shared.common.eur'),
  gbp: I18n.t('react.shared.common.gbp'),
  pln: I18n.t('react.shared.common.pln'),
  usd: I18n.t('react.shared.common.usd'),
})
