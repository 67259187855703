import NextNProgress from 'nextjs-progressbar'
import { invertColor } from '@elo-ui/utils/colors.utils'

export const ProgressBar = ({ color }: { color: string }) => (
  <NextNProgress
    color={invertColor(color)}
    transformCSS={(css) => (
      <style>
        {css}
        {`#nprogress .peg {box-shadow: none;} #nprogress .spinner {display: none !important;}`}
      </style>
    )}
  />
)
