import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import classNames from 'classnames'

import { POSITIONS } from '@elo-kit/constants/general.constants'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'

interface Props {
  body?: React.ReactNode
  bodyStyles?: object
  component?: boolean
  iconClass?: string
  id: string | number
  onClick?: () => void
  placement?: string
  title?: string
  className?: string
  tooltipClasses?: string
  innerClassName?: string
  dataTestIds?: {
    customIcon: string
    tooltipContent: string
    defaultIcon: string
  }
  ref?: React.ReactNode
  children?: React.ReactNode
}

/**
 * Info Tooltip - show information about error
 */
export const InfoTooltip: React.FC<Props> = ({
  body,
  bodyStyles,
  className,
  component,
  iconClass,
  id,
  onClick,
  title = '',
  tooltipClasses,
  children,
  innerClassName,
  placement = POSITIONS.top,
  dataTestIds = {
    customIcon: 'tooltip-custom-icon',
    tooltipContent: 'tooltip-content',
    defaultIcon: 'tooltip-default-icon',
  },
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggleTooltip = React.useCallback(() => {
    setTooltipOpen((prevstate) => !prevstate)
  }, [])

  const tooltipContainerClasses = classNames('popover-info-container', className)
  const tooltipBodyClasses = classNames('popover-content', { 'text-center': component })

  return (
    <span className={tooltipContainerClasses} onClick={onClick} role='button' tabIndex={0}>
      {children ||
        (iconClass ? (
          <i id={id.toString()} className={iconClass} data-testid={dataTestIds.customIcon} />
        ) : (
          <i id={id?.toString()} className='popover-info' data-testid={dataTestIds.defaultIcon}>
            <EloInfoIcon size={21}></EloInfoIcon>
          </i>
        ))}
      <Tooltip
        autohide={false}
        className={tooltipClasses}
        isOpen={tooltipOpen}
        placement={placement as any}
        target={id.toString()}
        toggle={toggleTooltip}
        innerClassName={innerClassName}
      >
        <div className='popover-title'>{title}</div>
        <div style={bodyStyles} className={tooltipBodyClasses} data-testid={dataTestIds.tooltipContent}>
          {component ? body : <div dangerouslySetInnerHTML={{ __html: body as string }} />}
        </div>
      </Tooltip>
    </span>
  )
}
