import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloUsersThreeIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 14.6895C11.7259 14.6895 13.125 13.2466 13.125 11.4668C13.125 9.68697 11.7259 8.24414 10 8.24414C8.27411 8.24414 6.875 9.68697 6.875 11.4668C6.875 13.2466 8.27411 14.6895 10 14.6895Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.3125 9.53321C16.0404 9.53195 16.7586 9.70613 17.4096 10.0418C18.0607 10.3776 18.6267 10.8655 19.0625 11.4668'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.9375 11.4668C1.37328 10.8655 1.93928 10.3776 2.59036 10.0418C3.24144 9.70613 3.95957 9.53195 4.6875 9.53321'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.49988 17.5897C5.91146 16.7205 6.5515 15.9879 7.3471 15.4755C8.1427 14.9631 9.06186 14.6914 9.99988 14.6914C10.9379 14.6914 11.8571 14.9631 12.6527 15.4755C13.4483 15.9879 14.0883 16.7205 14.4999 17.5897'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.68735 9.5332C4.21285 9.5337 3.74802 9.39493 3.34716 9.13311C2.94629 8.87129 2.62596 8.49723 2.42359 8.05464C2.22122 7.61204 2.14517 7.1192 2.20432 6.63369C2.26347 6.14818 2.45538 5.69006 2.75763 5.31285C3.05988 4.93564 3.45998 4.65492 3.91119 4.5035C4.3624 4.35208 4.84607 4.3362 5.30571 4.45773C5.76534 4.57926 6.18194 4.83317 6.50684 5.18979C6.83174 5.54642 7.05151 5.99103 7.14048 6.47168'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8595 6.47168C12.9485 5.99103 13.1682 5.54642 13.4931 5.18979C13.818 4.83317 14.2346 4.57926 14.6943 4.45773C15.1539 4.3362 15.6376 4.35208 16.0888 4.5035C16.54 4.65492 16.9401 4.93564 17.2423 5.31285C17.5446 5.69006 17.7365 6.14818 17.7957 6.63369C17.8548 7.1192 17.7788 7.61204 17.5764 8.05464C17.374 8.49723 17.0537 8.87129 16.6528 9.13311C16.252 9.39493 15.7871 9.5337 15.3126 9.5332'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
