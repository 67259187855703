import { makeObservable, override } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'

import SharedStore from 'shared/stores/shared.store'

import {
  CookiesConsents,
  CookiesConsentsApi,
  createCookiesConsentsApi,
  CookiesConsentsApiParams,
} from '../api/cookiesConsents.api'

export class CookiesConsentsStore extends SharedStore<CookiesConsents> {
  storeName = 'CookiesConsentsStore'
  declare childApi: CookiesConsentsApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createCookiesConsentsApi(root.apiClientV2)
    makeObservable(this)
  }

  @override
  async fetchList(params: CookiesConsentsApiParams): Promise<void> {
    if (this.list.length) {
      return
    }
    const { data } = await this.childApi.fetchList(params)
    this.list = data
  }
}
