import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloWarningOctagonIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M10 6.25V10.625' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13 2.5H7L2.5 7V13L7 17.5H13L17.5 13V7L13 2.5Z' stroke='currentColor' strokeWidth='1.25' />
    <path
      d='M10 14.2187C10.4315 14.2187 10.7812 13.869 10.7812 13.4375C10.7812 13.006 10.4315 12.6562 10 12.6562C9.56853 12.6562 9.21875 13.006 9.21875 13.4375C9.21875 13.869 9.56853 14.2187 10 14.2187Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
