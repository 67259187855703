import { makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { createSellerApi } from 'shop/api/seller.api'

export class SellersStore extends SharedStore {
  storeName = 'SellersStore'
  childApi

  constructor(root) {
    super(root)
    this.childApi = createSellerApi(root?.apiClientV2)
    makeObservable(this)
  }

  @override
  async fetchItem(data) {
    const resp = await this.childApi.fetchItem(data)

    this.item = resp.data
    return resp
  }
}
