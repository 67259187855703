import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDislinkIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M13.1267 3.75C12.1648 3.75 11.2424 4.1321 10.5622 4.81225L9.67941 5.69506C9.48414 5.89032 9.48414 6.2069 9.67941 6.40217C9.87467 6.59743 10.1913 6.59743 10.3865 6.40217L11.2693 5.51935C11.7619 5.02674 12.4301 4.75 13.1267 4.75C13.8234 4.75 14.4915 5.02674 14.9841 5.51935C15.4767 6.01196 15.7535 6.68008 15.7535 7.37674C15.7535 8.07339 15.4767 8.74151 14.9841 9.23412L14.1013 10.1169C13.906 10.3122 13.906 10.6288 14.1013 10.824C14.2965 11.0193 14.6131 11.0193 14.8084 10.824L15.6912 9.94123C16.3713 9.26108 16.7535 8.33861 16.7535 7.37674C16.7535 6.41487 16.3713 5.49239 15.6912 4.81225C15.0111 4.1321 14.0886 3.75 13.1267 3.75Z'
      fill='currentColor'
    />
    <path
      d='M8.00171 4.25171C8.00171 3.97557 7.77785 3.75171 7.50171 3.75171C7.22557 3.75171 7.00171 3.97557 7.00171 4.25171V6.12671C7.00171 6.40285 7.22557 6.62671 7.50171 6.62671C7.77785 6.62671 8.00171 6.40285 8.00171 6.12671V4.25171Z'
      fill='currentColor'
    />
    <path
      d='M13.0017 14.8767C13.0017 14.6006 12.7779 14.3767 12.5017 14.3767C12.2256 14.3767 12.0017 14.6006 12.0017 14.8767V16.7517C12.0017 17.0279 12.2256 17.2517 12.5017 17.2517C12.7779 17.2517 13.0017 17.0279 13.0017 16.7517V14.8767Z'
      fill='currentColor'
    />
    <path
      d='M3.25171 8.00171C3.25171 7.72557 3.47557 7.50171 3.75171 7.50171H5.62671C5.90285 7.50171 6.12671 7.72557 6.12671 8.00171C6.12671 8.27785 5.90285 8.50171 5.62671 8.50171H3.75171C3.47557 8.50171 3.25171 8.27785 3.25171 8.00171Z'
      fill='currentColor'
    />
    <path
      d='M14.3767 12.5017C14.1006 12.5017 13.8767 12.7256 13.8767 13.0017C13.8767 13.2779 14.1006 13.5017 14.3767 13.5017H16.2517C16.5279 13.5017 16.7517 13.2779 16.7517 13.0017C16.7517 12.7256 16.5279 12.5017 16.2517 12.5017H14.3767Z'
      fill='currentColor'
    />
    <path
      d='M5.90217 10.1794C6.09743 10.3747 6.09743 10.6913 5.90217 10.8865L5.01935 11.7693C4.52674 12.2619 4.25 12.9301 4.25 13.6267C4.25 13.9717 4.31794 14.3132 4.44995 14.6319C4.58195 14.9506 4.77544 15.2402 5.01935 15.4841C5.26327 15.728 5.55284 15.9215 5.87153 16.0535C6.19022 16.1855 6.53179 16.2535 6.87674 16.2535C7.57339 16.2535 8.24151 15.9767 8.73412 15.4841L9.61693 14.6013C9.8122 14.406 10.1288 14.406 10.324 14.6013C10.5193 14.7965 10.5193 15.1131 10.324 15.3084L9.44123 16.1912C8.76108 16.8713 7.83861 17.2535 6.87674 17.2535C6.40047 17.2535 5.92886 17.1596 5.48885 16.9774C5.04883 16.7951 4.64902 16.528 4.31225 16.1912C3.97547 15.8544 3.70833 15.4546 3.52607 15.0146C3.34381 14.5746 3.25 14.103 3.25 13.6267C3.25 12.6648 3.6321 11.7424 4.31225 11.0622L5.19506 10.1794C5.39032 9.98414 5.7069 9.98414 5.90217 10.1794Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
