import React from 'react'
import classNames from 'classnames'

import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { addTestId } from 'utils/e2e.utils'

import './_checkbox-field.scss'

interface Props {
  centered?: boolean
  className?: string
  testId?: string
  label?: React.ReactNode
  disabled?: boolean
  checked?: boolean
  labelClasses?: string
  name?: string
  onChange?: (event: React.FormEvent<HTMLInputElement>, checked: boolean) => void
  id?: string | number | boolean
  noMargin?: boolean
  topLabel?: string
  tooltipId?: string
  tooltipTitle?: string
  tooltipContent?: React.ReactNode | any[]
  tooltipIdCheck?: string
  tooltipBody?: string
  tooltipIdCheckBody?: string
  style?: object
}

export const CheckboxField = (props: Props) => {
  const {
    centered = false,
    checked = false,
    className,
    disabled = false,
    testId,
    label,
    name,
    onChange,
    id,
    style = {},
    noMargin,
    topLabel,
    tooltipId,
    tooltipContent,
    tooltipIdCheck,
    tooltipBody,
    tooltipIdCheckBody,
    labelClasses,
  } = props
  const I18n = useI18n()
  const checkMarkClassNames = classNames('custom-check-mark', {
    centered: !!centered,
  })

  const fieldClassNames = classNames(
    'field checkbox-field',
    {
      'field--disabled': disabled,
      'checkbox-field--no-margin': noMargin,
    },
    className
  )

  const labelClassNames = classNames(
    'checkbox-field__label',
    {
      'checkbox-field__label--active': checked,
    },
    labelClasses
  )

  const labelProps: { htmlFor?: string } = {}
  const fieldId = id || name

  if (!!fieldId && typeof fieldId !== 'boolean') {
    labelProps.htmlFor = String(fieldId)
  }

  const tooltipLabel = {
    partner_promotion: I18n.t('react.cabinet.help_icon.partner_promotion.content'),
    commissionOnGrossAmount: I18n.t('react.cabinet.help_icon.commissionOnGrossAmount.content'),
    forward_url_params: I18n.t('react.cabinet.help_icon.forward_url_params.content'),
    embed_forms_allowed: I18n.t('react.cabinet.help_icon.embed_forms_allowed.content'),
    affiliate_redirections_allowed: I18n.t('react.cabinet.help_icon.affiliate_redirections_allowed.content'),
    allow_in_app_registration: I18n.t('react.cabinet.help_icon.allow_in_app_registration.content'),
    activate_stream_chat: I18n.t('react.cabinet.help_icon.activate_stream_chat.content'),
    enable_confirm_email: I18n.t('react.cabinet.help_icon.enable_confirm_email.content'),
    monochromatic_color_tooltip: I18n.t('react.cabinet.help_icon.monochromatic_color_tooltip.content'),
    'hidden-checkbox-tooltip': I18n.t('react.cabinet.help_icon.hidden-checkbox-tooltip.content'),
    menu_unfold_lesson: I18n.t('react.cabinet.help_icon.menu_unfold_lesson.content'),
    quiz_auto_checked: I18n.t('react.cabinet.help_icon.quiz_auto_checked.content'),
    required_to_go_further: I18n.t('react.cabinet.help_icon.required_to_go_further.content'),
    allow_course_reset: I18n.t('react.cabinet.help_icon.allow_course_reset.content'),
    menu_unfold: I18n.t('react.cabinet.help_icon.menu_unfold.content'),
    future_commission: I18n.t('react.cabinet.help_icon.future_commission.content'),
    show_cancel_subscription: I18n.t('react.cabinet.help_icon.show_cancel_subscription.content'),
    sticky_header: I18n.t('react.cabinet.help_icon.sticky_header.content'),
    playbar_disabled: I18n.t('react.cabinet.help_icon.playbar_disabled.content'),
    live_template: I18n.t('react.cabinet.help_icon.live_template.content'),
    eu_wide_threshold: I18n.t('react.cabinet.help_icon.eu_wide_threshold.content'),
    save_column_selection: I18n.t('react.cabinet.help_icon.save_column_selection.content'),
    general_footer: I18n.t('react.cabinet.help_icon.general_footer.content'),
    hide_footer: I18n.t('react.cabinet.help_icon.hide_footer.content'),
    general_header: I18n.t('react.cabinet.help_icon.general_header.content'),
    hide_header: I18n.t('react.cabinet.help_icon.hide_header.content'),
  }

  return (
    <div className={fieldClassNames} data-testid='checkbox-field'>
      <label className='field__control checkbox-field__control' {...labelProps}>
        {topLabel && (
          <div className='field__label'>
            {topLabel}
            {tooltipId && <InfoTooltip id={`info_${tooltipId}_popover`} title='' body={tooltipContent} />}
          </div>
        )}
        <input
          id={String(fieldId)}
          type='checkbox'
          onChange={(event) => onChange && onChange(event, event.target.checked)}
          {...addTestId(testId ?? name)}
          checked={checked}
          disabled={disabled}
          name={name}
        />

        <span className={checkMarkClassNames} style={style}>
          <i className='boom' style={style} />
        </span>

        {label && (
          <label className={labelClassNames} {...labelProps}>
            {label}
            {tooltipIdCheck && (
              <InfoTooltip
                id={`info_${tooltipIdCheck}_popover`}
                title=''
                body={tooltipIdCheckBody || tooltipLabel[tooltipBody || tooltipIdCheck]}
              />
            )}
          </label>
        )}
      </label>
    </div>
  )
}
