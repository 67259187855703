import { FILE_ICONS } from '../constants/files.constants'
import { getFileExtension } from './files.utils'

interface Digital {
  fileName?: string
  fileUrl?: string
  file?: { thumb?: string; original?: string }
  image?: string
}

type Extension = keyof typeof FILE_ICONS

export const getThemeProps = (block: {
  content?: { backgroundImage?: string; logo?: string }
  cover?: { file?: { original?: string } }
}) => {
  const { content = {}, cover = {} } = block
  const { file = {} } = cover

  return {
    ...content,
    backgroundImage: file.original || content.backgroundImage || content.logo,
  }
}

export const getDigitalIcon = ({ fileName, fileUrl, file: { thumb, original } = {}, image }: Digital = {}) => {
  const extension = getFileExtension(fileName as string).toLowerCase()
  const imageSrc = thumb || fileUrl || original

  if (image && imageSrc) {
    return `<img src="${imageSrc}" alt="digital-icon" />`
  }

  return `<i class="${FILE_ICONS[extension as Extension] || 'fas fa-file'}"></i>`
}
