import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { Response } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = (username) => `/shop/${username}/orders`

interface DownloadGood {
  message: Nullable<string>
  id: number
  redirectPath?: string
}

interface ZipFileStatus {
  fileStatus: 'pending' | 'in_progress' | 'delayed' | 'ready' | 'error'
  id: number
}

export const createDownloadGoodsApi = ({ GET }: ApiClientV2) => ({
  downloadGoods: (username: string, token: string, data: { goodId: string; sellableItemId: number }) =>
    GET<Response<DownloadGood>>(`${BASE_URL(username)}/${token}/download_goods`, data),

  checkZipFileStatus: (username: string, token: string) =>
    GET<Response<ZipFileStatus>>(`${BASE_URL(username)}/${token}/check_zip_file_status`),
})

export type DownloadGoodsApi = ReturnType<typeof createDownloadGoodsApi>
