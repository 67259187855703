import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTagIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M9.58562 2.02343L3.28093 3.28124L2.02312 9.58593C2.00341 9.68659 2.00878 9.79056 2.03875 9.88866C2.06873 9.98676 2.12239 10.076 2.195 10.1484L10.3512 18.3047C10.4087 18.3635 10.4774 18.4102 10.5532 18.4421C10.629 18.4741 10.7104 18.4905 10.7927 18.4905C10.8749 18.4905 10.9563 18.4741 11.0321 18.4421C11.1079 18.4102 11.1766 18.3635 11.2341 18.3047L18.3044 11.2344C18.3632 11.1769 18.4099 11.1082 18.4418 11.0324C18.4738 10.9566 18.4902 10.8752 18.4902 10.793C18.4902 10.7107 18.4738 10.6293 18.4418 10.5535C18.4099 10.4777 18.3632 10.409 18.3044 10.3516L10.1481 2.1953C10.0757 2.12269 9.98645 2.06903 9.88835 2.03906C9.79025 2.00909 9.68629 2.00372 9.58562 2.02343Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.56213 7.5C7.0799 7.5 7.49963 7.08027 7.49963 6.5625C7.49963 6.04473 7.0799 5.625 6.56213 5.625C6.04437 5.625 5.62463 6.04473 5.62463 6.5625C5.62463 7.08027 6.04437 7.5 6.56213 7.5Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
