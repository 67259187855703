import * as Sentry from '@sentry/react'

const NAMESPACE = 'ELO'

function composeKey(key: string, namespace: string): string {
  return `${namespace}-${key}`
}

export class LocalStorageService {
  static isWindowEnv = () => typeof window !== 'undefined'

  static isLocalStorageSupported = (): boolean => {
    const testKey = 'testKey'

    if (!this.isWindowEnv()) return false

    if (!navigator.cookieEnabled) return false

    try {
      localStorage.setItem(testKey, 'test')
      localStorage.removeItem(testKey)
      return true
    } catch (error) {
      return false
    }
  }

  static getItem = (key: string, withPrefix?: boolean): any => {
    let value = ''

    if (this.isLocalStorageSupported()) {
      try {
        // check if string is serializable
        value = localStorage.getItem(withPrefix ? composeKey(key, NAMESPACE) : key) || ''
        return JSON.parse(value)
      } catch {
        try {
          // fallback - try converting to serializable string and parse again
          return JSON.parse(JSON.stringify(value))
        } catch (error) {
          // here somebody really screw up with local storage
          Sentry.captureException(error)
        }
      }
    }
  }

  static setItem = (key: string, value: any, withPrefix?: boolean) => {
    if (this.isLocalStorageSupported()) {
      try {
        localStorage.setItem(withPrefix ? composeKey(key, NAMESPACE) : key, JSON.stringify(value))
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }

  static removeItem = (key: string, withPrefix?: boolean) => {
    if (this.isLocalStorageSupported()) {
      try {
        return localStorage.removeItem(withPrefix ? composeKey(key, NAMESPACE) : key)
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }
}
