import { createUseStyles } from 'react-jss'

export const useLoadingMaskStyles = createUseStyles(({ useDynamicPosition = false }) => ({
  screenHeightContainer: {
    height: '100vh',
  },

  lmask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.7)',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 9999,

    '&:after': {
      content: '""',
      opacity: 1,
      border: '6px solid rgba(43, 255, 153, .2)',
      borderLeft: '6px solid #2bff99',
      borderRadius: '50px',
      width: '70px',
      height: '70px',
      animation: 'spinoffPulse 1s infinite linear',
      margin: '-15px 0 0 -15px',
      position: 'absolute',
      top: useDynamicPosition ? '100px' : 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
      animationName: '$spinoffPulse',
    },
  },

  lmaskLight: {
    backButtonColor: '#FFFFFF',
    opacity: 1,
  },

  lmaskDark: {
    background: 'rgba(0, 0, 0, 0.3)',
  },

  '@keyframes spinoffPulse': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))
