import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import classNames from 'classnames'

import { SIZES } from '@elo-kit/constants/general.constants'

import { EloButton } from '@elo-kit/components/elo-button'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { AnyCallback } from 'types/helpers'

import './_modals.scss'

interface MessageProps {
  children?: React.ReactNode | any[]
  centered?: boolean
  bold?: boolean
}

export const Message: React.FC<MessageProps> = ({ children, centered, bold }) => {
  const classes = classNames('elo-modal__message-text', {
    'elo-modal__message-text--centered': centered,
    'elo-modal__message-text--bold': bold,
  })

  return <div className={classes}>{children}</div>
}

interface EloModalProps {
  children?: React.ReactNode | any[]
  cancel?: boolean
  centered?: boolean
  noMargin?: boolean
  submitButtonChild?: string
  size?: string
  submitDisabled?: boolean
  close?: () => void | boolean
  closeOnSubmit?: boolean
  customButtonProps?: object
  customButtonContainerClassName?: string
  customSubmitButtonProps?: object
  className?: string
  iconProps?: object
  isOpen?: boolean
  submit?: AnyCallback
  success?: boolean
  title?: React.ReactNode
  toggle?: () => void
  onlySubmit?: boolean
  onOpened?: () => void
  cancelButtonChild?: string
  onCancel?: () => void
  onClose?: () => void
  headerIcon?: React.ReactNode
  testId?: string
  zIndex?: number | string
}

export const EloModal = (props: EloModalProps): JSX.Element => {
  const I18n = useI18n()
  const {
    cancel = true,
    centered = true,
    children,
    className,
    iconProps,
    isOpen,
    noMargin = false,
    size = SIZES.medium,
    submit,
    submitButtonChild = I18n.t('react.shared.button.save'),
    success,
    submitDisabled = false,
    title,
    toggle,
    close = true,
    onlySubmit,
    onOpened,
    cancelButtonChild = I18n.t('react.shared.button.cancel'),
    customButtonProps = null,
    customButtonContainerClassName = 'elo-modal__bottom-buttons__left-block',
    onCancel,
    onClose,
    headerIcon,
    customSubmitButtonProps = {},
    testId,
    zIndex,
  } = props

  const modalClasses = classNames(
    `elo-modal elo-modal--${size}`,
    {
      'elo-modal--success': success,
    },
    className
  )
  const bottomButtonsClasses = classNames('elo-modal__bottom-buttons', {
    'elo-modal__bottom-buttons--centered': centered,
    'elo-modal__bottom-buttons--no-margin': noMargin,
  })

  return (
    <Modal isOpen={isOpen} className={modalClasses} zIndex={zIndex} onOpened={onOpened} data-testid={testId}>
      <ModalHeader>
        {close && <i className='fas fa-times elo-modal__close-btn' onClick={onClose || toggle} />}
        {headerIcon && <div className='fields-container--center-align'>{headerIcon}</div>}
        <div className='elo-modal__title'>
          {iconProps && <i {...iconProps} />}
          {title}
        </div>
      </ModalHeader>
      <ModalBody>
        {children}

        {(cancel || submit || customButtonProps) && (
          <div className={bottomButtonsClasses}>
            {customButtonProps && (
              <div className={customButtonContainerClassName}>
                <EloButton {...customButtonProps} />
              </div>
            )}
            {cancel && (
              <EloButton onClick={onCancel || toggle} outlined grey={!submit}>
                {cancelButtonChild}
              </EloButton>
            )}

            {submit && (
              <EloButton
                onClick={() => {
                  submit()
                  if (!onlySubmit) {
                    toggle && toggle()
                  }
                }}
                disabled={submitDisabled}
                {...customSubmitButtonProps}
              >
                {submitButtonChild}
              </EloButton>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export const FullScreen = (props) => {
  const { children, isOpen, title, saveAndClose, onOpened, testId, subTitle } = props

  return (
    <Modal isOpen={isOpen} className='elo-modal elo-modal__full-screen' onOpened={onOpened} data-testid={testId}>
      <ModalHeader>
        <div className='elo-modal__full-screen--close-btn' onClick={saveAndClose}>
          <i className='fal fa-times' />
          <div>{I18n.t('react.shared.button.save_close')}</div>
        </div>
        <div className='elo-modal__title-container'>
          <div className='elo-modal__title'>{title}</div>
          <div className='elo-modal__sub-title'>{subTitle}</div>
        </div>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

EloModal.FullScreen = FullScreen
EloModal.Message = Message
EloModal.displayName = 'EloModal'
