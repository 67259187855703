import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBrowserIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.3333 3H2.66667C2.29848 3 2 3.3134 2 3.7V16.3C2 16.6866 2.29848 17 2.66667 17H17.3333C17.7015 17 18 16.6866 18 16.3V3.7C18 3.3134 17.7015 3 17.3333 3Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M2.25 6.875H17.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </EloBaseIcon>
)
