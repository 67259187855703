import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloRedirectionIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.5625 7.59375V2.4375H12.4062'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.375 8.625L17.5625 2.4375'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8125 11.375V16.875C14.8125 17.0573 14.7401 17.2322 14.6111 17.3611C14.4822 17.4901 14.3073 17.5625 14.125 17.5625H3.125C2.94266 17.5625 2.7678 17.4901 2.63886 17.3611C2.50993 17.2322 2.4375 17.0573 2.4375 16.875V5.875C2.4375 5.69266 2.50993 5.5178 2.63886 5.38886C2.7678 5.25993 2.94266 5.1875 3.125 5.1875H8.625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
