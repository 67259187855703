import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloStarSelectedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M18.2164 8.00306L18.2164 8.00306L18.218 8.00825C18.2582 8.13605 18.2606 8.27423 18.2247 8.40374C18.189 8.53287 18.1179 8.64381 18.025 8.7245C18.0249 8.72464 18.0247 8.72478 18.0245 8.72492L14.3945 11.8572L14.0496 12.1548L14.1548 12.5982L15.3036 17.4398L15.3055 17.4477L15.3075 17.4556C15.3324 17.5513 15.3359 17.6522 15.3175 17.7499C15.2991 17.8476 15.2597 17.9377 15.204 18.0136C15.1485 18.0895 15.0787 18.1488 15.0015 18.1889C14.926 18.2281 14.8445 18.2485 14.7628 18.25C14.6661 18.2486 14.5696 18.2189 14.4843 18.1614L14.4843 18.1614L14.4761 18.156L10.4191 15.4977L10.2319 15.375H10.008H9.99197H9.76767L9.5802 15.4981L5.81345 17.9724C5.81331 17.9725 5.81317 17.9726 5.81303 17.9727C5.69695 18.0486 5.56463 18.0852 5.43261 18.0811C5.30134 18.077 5.17152 18.0327 5.05964 17.9502C4.94109 17.8597 4.84739 17.7314 4.79491 17.579C4.74201 17.4254 4.73473 17.2577 4.77451 17.099L4.77545 17.0952L5.86 12.6702L5.96836 12.2281L5.62619 11.9279L1.97888 8.72789L1.97889 8.72788L1.97548 8.72493C1.88234 8.6442 1.81109 8.53309 1.77526 8.40374C1.73939 8.27423 1.7418 8.13605 1.78198 8.00825L1.78198 8.00825L1.7827 8.00594C1.82101 7.88268 1.89279 7.77848 1.98436 7.70431C2.07533 7.63063 2.18214 7.58931 2.29058 7.58159C2.29087 7.58157 2.29117 7.58155 2.29147 7.58153L7.02931 7.265L7.50625 7.23313L7.67877 6.78735L9.47832 2.13735L9.47834 2.13736L9.4802 2.13245C9.52507 2.01406 9.60146 1.91685 9.69516 1.85035C9.78762 1.78473 9.8937 1.75105 10 1.75001C10.1063 1.75105 10.2124 1.78473 10.3048 1.85035C10.3985 1.91685 10.4749 2.01406 10.5198 2.13245L10.5205 2.13439L12.288 6.75939L12.4585 7.20564L12.935 7.23975L17.707 7.58142L17.707 7.58143L17.7118 7.58174C17.8197 7.58878 17.9261 7.62956 18.0167 7.70293C18.1076 7.77659 18.1788 7.88032 18.2164 8.00306Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </EloBaseIcon>
)
