import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCalendarSelectIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.2727 1.9375H2.72727C2.32561 1.9375 2 2.26311 2 2.66477V17.2102C2 17.6119 2.32561 17.9375 2.72727 17.9375H17.2727C17.6744 17.9375 18 17.6119 18 17.2102V2.66477C18 2.26311 17.6744 1.9375 17.2727 1.9375Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M14.125 1V4' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.875 1V4' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M2.4375 6.5H17.5625'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 10L9.10833 14L7 11.8182'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
