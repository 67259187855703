export const THEMES = {
  default: 'default',
  custom: 'custom',
}

export const MEMBERSHIP_THEMES_FILTER_LIST = ['activeTheme', 'isImported', 'createdAt']
export const SHOP_THEMES_FILTER_LIST = ['activeTheme', 'isImported', 'createdAt']
export const APP_THEMES_FILTER_LIST = ['activeTheme', 'createdAt']

export const COURSE_THEMES = {
  activeLinkColor: 'activeLinkColor',
  btnColor: 'buttonColor',
  btnBorderColor: 'buttonBorderColor',
  btnFont: 'buttonFont',
  btnSize: 'buttonSize',
  btnStyle: 'buttonStyle',
  btnFontColor: 'buttonFontColor',
  breadCrumbLinkColor: 'breadCrumbLinkColor',
  breadCrumbLinkFont: 'breadCrumbLinkFont',
  breadCrumbLinkStyle: 'breadCrumbLinkStyle',
  breadCrumbLinkSize: 'breadCrumbLinkSize',
  linkColor: 'linkColor',
  linkSize: 'linkSize',
  linkStyle: 'linkStyle',
  linkFont: 'linkFont',
  titleColor: 'titleColor',
  showOverview: 'showOverview',
  showBreadcrumb: 'showBreadcrumb',
  showFavicon: 'showFavicon',
  showBanner: 'showOverviewBanner',
  showLessonBanner: 'showLessonBanner',
  showLessonBannerText: 'showLessonBannerText',
  showOverviewBannerText: 'showOverviewBannerText',
  topNavigation: 'topNavigationEnabled',
  fullWidth: 'fullWidth',
  bottomNavigation: 'bottomNavigationEnabled',
  courseOverview: 'courseOverview',
  banner: 'banner',
  favicon: 'favicon',
  bannerOpacity: 'bannerOpacity',
  bannerOverlay: 'bannerOverlay',
  bannerFont: 'bannerFont',
  bannerStyle: 'bannerStyle',
  bannerColor: 'bannerColor',
  bannerSize: 'bannerSize',
  overviewTitle: 'overviewTitle',
  overviewTitleFont: 'overviewTitleFont',
  overviewTitleStyle: 'overviewTitleStyle',
  overviewTitleColor: 'overviewTitleColor',
  overviewTitleSize: 'overviewTitleSize',
  overviewItemsFont: 'overviewItemsFont',
  overviewItemsStyle: 'overviewItemsStyle',
  overviewItemsColor: 'overviewItemsColor',
  overviewItemsSize: 'overviewItemsSize',
  overviewItemsBackground: 'overviewItemsBackground',
  lesson: 'lesson',
  sidebarPosition: 'sidebarPosition',
  sidebarBackground: 'sidebarBackground',
  sidebarActiveColor: 'sidebarActiveColor',
  sidebarFont: 'sidebarFont',
  sidebarStyle: 'sidebarStyle',
  sidebarFontColor: 'sidebarFontColor',
  sidebarSize: 'sidebarSize',
  progressBackground: 'progressBackground',
  unfoldedMenus: 'unfoldedMenus',
  progressColor: 'progressColor',
  progressFont: 'progressFont',
  progressStyle: 'progressStyle',
  progressFontColor: 'progressFontColor',
  progressSize: 'progressSize',
  css: 'css',
  css2: 'css2',
  form: 'form',
  title: 'title',
  courseOverviewCustomClassName: 'courseOverviewCustomClassName',
  courseLessonsCustomClassName: 'courseLessonsCustomClassName',
  courseCustomClassName: 'courseCustomClassName',
  notificationsColor: 'notificationsColor',
}

export const COURSE_THEME_STYLE = {
  default: 'default',
  custom: 'custom',
}

export const getSidebarPositionsOptions = () => [
  {
    value: 'right',
    label: I18n.t('react.cabinet.sidebar_on_right'),
  },
  {
    value: 'left',
    label: I18n.t('react.cabinet.sidebar_on_left'),
  },
  {
    value: 'none',
    label: I18n.t('react.cabinet.no_sidebar'),
  },
]

export const SIDEBAR_POSITIONS = {
  right: 'right',
  left: 'left',
  none: 'none',
}

export enum PREVIEW_TYPES {
  course = 'course',
  tickets = 'tickets',
  download = 'download',
  default = 'default',
}

export interface productPreviewType {
  type: PREVIEW_TYPES
  label: string
}

export const getProductPreviewTypes = (): productPreviewType[] => [
  {
    type: PREVIEW_TYPES.course,
    label: I18n.t('react.shared.course_view'),
  },
  {
    type: PREVIEW_TYPES.tickets,
    label: I18n.t('react.shared.ticket_view'),
  },
  {
    type: PREVIEW_TYPES.download,
    label: I18n.t('react.shared.download_view'),
  },
  {
    type: PREVIEW_TYPES.default,
    label: I18n.t('react.shared.default_view'),
  },
]

export const ALLOWED_BLOCK_TYPES = [
  'text',
  'picture',
  'video',
  'file',
  'testimonials',
  'popular_products',
  'header',
  'banner',
  'footer',
]

export const MEMBERSHIP_ALLOWED_BLOCK_TYPES = [...ALLOWED_BLOCK_TYPES, 'membership_products']

export const THEME_PAGES = {
  payment: 'paymentPageTheme',
  funnel: 'funnelPageTheme',
  thankYou: 'thankYouPageTheme',
  manage: 'managePageTheme',
}

export const THEME_PAGES_LIST = Object.values(THEME_PAGES)

export const getThemePageLocales = () => ({
  paymentPageTheme: I18n.t('react.shared.payment_page_theme'),
  funnelPageTheme: I18n.t('react.shared.funnel_page_theme'),
  thankYouPageTheme: I18n.t('react.shared.thank_you_page_theme'),
  managePageTheme: I18n.t('react.shared.manage_pay_later_page_theme'),
})

export const THEME_FORMS = {
  default: 'default',
  custom: 'custom',
  namotto: 'namotto',
}

export const THEME_TRACKING_COOKIES_PARAMS = ['sf_lead_source', 'theme_name', 'theme_category']
export const THEME_SHARING_PARAMS = ['shared_theme_token', 'shared_theme_type']

export const CUSTOM_CSS_CONFIG_FIELD_NAME = 'customCss'
export const CUSTOM_CSS_FEATURE_FLAG = 'custom_css'
export const ELOPAGE_BADGE_FEATURE_FLAG = 'elopage-badge'

export const THEME_SLUGS = {
  shopThemes: 'shop_themes',
  membershipThemes: 'membership_themes',
  products: 'products',
  orderBumpsThemes: 'addon_themes',
  paymentPage: 'payment_page_templates',
  affiliateLandings: 'affiliate_landings',
  funnels: 'funnels',
  sharings: 'sharings',
}
export const THEME_PAGES_FORM = {
  main: 'main',
  product: 'product',
  default: 'default',
}

export const DEFAULT_SHOP_THEME_PAGE_IDS = {
  main: 'default_shop_main_page',
  product: 'default_shop_product_page',
  thankYou: 'default_shop_thank_you_page',
}

export const SHOP_PAGE_TYPE = {
  main: 'shop_main_page',
  product: 'shop_product_page',
  subPage: 'shop_sub_page',
  thankYou: 'shop_thank_you_page',
}

export const SLUG_VALIDATION_REGEX = /[^a-zA-Z0-9-_]/g
