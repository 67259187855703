export const TICKET_FORM_WITHOUT_DATES = 'without_dates'
export const PRODUCT_TYPE_IDS = {
  digital: 'service',
  download: 'download',
  course: 'course',
  eventTickets: 'event',
  membership: 'membership',
  bundle: 'bundle',
  licenseKey: 'license_key',
  certificate: 'certificate',
}
export const TICKET_VIEW = {
  dropDown: 'drop_down',
  list: 'list',
}

export const BUBBLE_TYPE = {
  totalViews: 'total_views',
  totalPurchases: 'total_purchases',
  recentPurchase: 'recent_purchase',
}
