import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMegaphoneIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M1.70837 4.26686V15.3837C1.70827 15.4831 1.73153 15.5812 1.77632 15.6702C1.82111 15.7592 1.8862 15.8367 1.96647 15.8965C2.04674 15.9564 2.13999 15.9971 2.23887 16.0152C2.33774 16.0334 2.43954 16.0287 2.53624 16.0013L16.821 11.8866C16.9567 11.8483 17.0761 11.7672 17.161 11.6558C17.246 11.5443 17.2919 11.4086 17.2917 11.269V8.38154C17.2919 8.24202 17.246 8.10625 17.161 7.9948C17.0761 7.88336 16.9567 7.80231 16.821 7.76394L2.53624 3.64925C2.43954 3.62192 2.33774 3.61716 2.23887 3.63535C2.13999 3.65353 2.04674 3.69417 1.96647 3.75405C1.8862 3.81394 1.82111 3.89143 1.77632 3.98042C1.73153 4.06941 1.70827 4.16747 1.70837 4.26686Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0451 12.6882V15.5997C14.0451 15.7699 13.9767 15.9331 13.8549 16.0535C13.7332 16.1738 13.568 16.2414 13.3958 16.2414H10.7986C10.6264 16.2414 10.4612 16.1738 10.3395 16.0535C10.2177 15.9331 10.1493 15.7699 10.1493 15.5997V5.83838'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
