import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpTwoStepSec = (props) => {
  const { height, viewBox, width } = props
  /* eslint-disable */
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <defs>
        <polygon points='69.536 99.28 69.536 88 67.28 88 64 88.928 64.592 91.328 66.48 90.896 66.48 99.28' />
        <path d='M126.864,99.392 L126.864,96.864 L122.24,96.864 L124.16,95.52 C125.077333,94.8693333 125.738667,94.264 126.144,93.704 C126.549333,93.144 126.752,92.4426667 126.752,91.6 C126.752,90.5333333 126.370667,89.6666667 125.608,89 C124.845333,88.3333333 123.850667,88 122.624,88 C121.589333,88 120.730667,88.192 120.048,88.576 C119.365333,88.96 118.704,89.568 118.064,90.4 L118.064,90.4 L120.16,92.144 C120.608,91.6213333 121,91.2506667 121.336,91.032 C121.672,90.8133333 122.021333,90.704 122.384,90.704 C122.757333,90.704 123.058667,90.8053333 123.288,91.008 C123.517333,91.2106667 123.632,91.488 123.632,91.84 C123.632,92.1813333 123.522667,92.504 123.304,92.808 C123.085333,93.112 122.714667,93.4613333 122.192,93.856 L122.192,93.856 L118,97.056 L118,99.392 L126.864,99.392 Z' />
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='249' rx='8' />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -47.000000) '
            x='53'
            y='23'
            width='115'
            height='48'
            rx='4'
          />
          <path
            d='M136.940549,55 C138.052088,55 138.938534,55.8954305 138.938534,57 C138.938534,58.1122704 138.044006,59 136.940549,59 L136.940549,59 L101.997985,59 C100.886447,59 100,58.1045695 100,57 L100,57 L100.00548,56.8497808 C100.081752,55.8088611 100.944685,55 101.997985,55 L101.997985,55 Z M154.00693,45 C155.097692,45 156,45.8954305 156,47 C156,48.1122704 155.107672,49 154.00693,49 L154.00693,49 L101.99307,49 C100.902308,49 100,48.1045695 100,47 L100,47 L100.005467,46.8497808 C100.081551,45.8088611 100.942361,45 101.99307,45 L101.99307,45 Z M154.00693,35 C155.097692,35 156,35.8954305 156,37 C156,38.1122704 155.107672,39 154.00693,39 L154.00693,39 L101.99307,39 C100.902308,39 100,38.1045695 100,37 L100,37 L100.005467,36.8497808 C100.081551,35.8088611 100.942361,35 101.99307,35 L101.99307,35 Z'
            fill='#E5E3E5'
          />
          <rect fill='#E5E3E5' x='65' y='35' width='24' height='23.9835004' rx='4' />
          <path
            d='M81.875,52 C82.4963226,52 83,51.4403585 83,50.75 L83,43.25 C83,42.5596415 82.4963226,42 81.875,42 L72.125,42 C71.5036774,42 71,42.5596415 71,43.25 L71,50.75 C71,51.4403585 71.5036774,52 72.125,52 L81.875,52 Z M73.5,46 C72.6715785,46 72,45.3284215 72,44.5 C72,43.6715785 72.6715785,43 73.5,43 C74.3284215,43 75,43.6715785 75,44.5 C75,45.3284215 74.3284215,46 73.5,46 Z M82,50 L73,50 L73,48.817906 L74.6761017,47.0567178 C74.785965,46.9413029 74.964035,46.9413029 75.0738754,47.0567178 L76.375,48.4238747 L79.5511017,45.0865611 C79.660965,44.9711463 79.839035,44.9711463 79.9488754,45.0865611 L82,47.2417807 L82,50 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 160.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -160.000000) '
            x='53'
            y='79'
            width='115'
            height='162'
            rx='4'
          />
          <path
            d='M153.99328,202 C155.099341,202 156,202.895431 156,204 C156,205.11227 155.101561,206 153.99328,206 L153.99328,206 L67.00672,206 C65.900659,206 65,205.104569 65,204 L65,204 L65.0055042,203.849781 C65.0821098,202.808861 65.9488155,202 67.00672,202 L67.00672,202 Z M153.99328,193 C155.099341,193 156,193.895431 156,195 C156,196.11227 155.101561,197 153.99328,197 L153.99328,197 L67.00672,197 C65.900659,197 65,196.104569 65,195 L65,195 L65.0055042,194.849781 C65.0821098,193.808861 65.9488155,193 67.00672,193 L67.00672,193 Z M153.99328,184 C155.099341,184 156,184.895431 156,186 C156,187.11227 155.101561,188 153.99328,188 L153.99328,188 L67.00672,188 C65.900659,188 65,187.104569 65,186 L65,186 L65.0055042,185.849781 C65.0821098,184.808861 65.9488155,184 67.00672,184 L67.00672,184 Z M153.99328,175 C155.099341,175 156,175.895431 156,177 C156,178.11227 155.101561,179 153.99328,179 L153.99328,179 L67.00672,179 C65.900659,179 65,178.104569 65,177 L65,177 L65.0055042,176.849781 C65.0821098,175.808861 65.9488155,175 67.00672,175 L67.00672,175 Z M153.99328,166 C155.099341,166 156,166.895431 156,168 C156,169.11227 155.101561,170 153.99328,170 L153.99328,170 L67.00672,170 C65.900659,170 65,169.104569 65,168 L65,168 L65.0055042,167.849781 C65.0821098,166.808861 65.9488155,166 67.00672,166 L67.00672,166 Z'
            fill='#E5E3E5'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='65' y='213' width='90' height='15.821267' rx='7.91063348' />
          <rect
            id='Rectangle-18-Copy-55'
            fill='#E5E3E5'
            transform='translate(85.500000, 93.703620) scale(1, -1) rotate(-180.000000) translate(-85.500000, -93.703620) '
            x='74'
            y='89'
            width='23'
            height='9.40723982'
            rx='3'
          />
          <path
            d='M109,83.0018785 C109,80.791702 110.797968,79 113.008925,79 L168,79 L168,108 L109,108 L109,83.0018785 Z'
            id='Rectangle-18-Copy-12'
            fill='#E5E3E5'
            transform='translate(138.500000, 93.500000) scale(1, -1) rotate(-180.000000) translate(-138.500000, -93.500000) '
          />
          <rect
            fill='#FFFFFF'
            transform='translate(142.500000, 93.703620) scale(1, -1) rotate(-180.000000) translate(-142.500000, -93.703620) '
            x='131'
            y='89'
            width='23'
            height='9.40723982'
            rx='3'
          />
          <rect
            fill='#B8B9BC'
            fillRule='nonzero'
            transform='translate(111.000000, 137.000000) scale(1, -1) rotate(-180.000000) translate(-111.000000, -137.000000) '
            x='66'
            y='118'
            width='90'
            height='38'
            rx='4'
          />
          <path
            d='M121.963284,146.831683 C123.332381,146.831683 124.442245,145.72182 124.442245,144.352723 L124.442245,129.47896 C124.442245,128.109863 123.332381,127 121.963284,127 L100.47896,127 C99.1098633,127 98,128.109863 98,129.47896 L98,144.352723 C98,145.72182 99.1098633,146.831683 100.47896,146.831683 L121.963284,146.831683 Z M103.784241,135.676362 C102.186978,135.676362 100.892121,134.381504 100.892121,132.784241 C100.892121,131.186978 102.186978,129.892121 103.784241,129.892121 C105.381504,129.892121 106.676362,131.186978 106.676362,132.784241 C106.676362,134.381504 105.381504,135.676362 103.784241,135.676362 Z M121.136964,143.526403 L101.305281,143.526403 L101.305281,141.047442 L104.998605,137.354068 C105.240691,137.112032 105.633072,137.112032 105.875107,137.354068 L108.742162,140.221122 L115.740767,133.222467 C115.982853,132.980432 116.375234,132.980432 116.617269,133.222467 L121.136964,137.742162 L121.136964,143.526403 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
            opacity='0.5'
          />
          <path
            d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
            fill='#D7DADF'
          />
          <path
            d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
            fill='#FFFFFF'
          />
          <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
        </g>
      </g>
    </svg>
  )
  /* eslint-enable */
}

BumpTwoStepSec.displayName = 'BumpTwoStepSec'
BumpTwoStepSec.propTypes = propTypes
BumpTwoStepSec.defaultProps = defaultProps
