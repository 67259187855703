import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloFileSearchIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <g clipPath='url(#clip0_70_1382)'>
      <path
        d='M15.625 17.5H4.375C4.20924 17.5 4.05027 17.4342 3.93306 17.3169C3.81585 17.1997 3.75 17.0408 3.75 16.875V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V16.875C16.25 17.0408 16.1842 17.1997 16.0669 17.3169C15.9497 17.4342 15.7908 17.5 15.625 17.5Z'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.875 2.5V6.875H16.25'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.2344 13.1094L12.4844 14.3594'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.6875 13.75C10.8956 13.75 11.875 12.7706 11.875 11.5625C11.875 10.3544 10.8956 9.375 9.6875 9.375C8.47938 9.375 7.5 10.3544 7.5 11.5625C7.5 12.7706 8.47938 13.75 9.6875 13.75Z'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_70_1382'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </EloBaseIcon>
)
