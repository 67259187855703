import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpTwoColumnOne = (props) => {
  const { height, viewBox, width } = props
  /* eslint-disable */
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='259' rx='8' />
          <g>
            <path
              d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
              fill='#D7DADF'
            />
            <path
              d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
              fill='#FFFFFF'
            />
            <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
          </g>
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(109.500000, 193.000000) scale(1, -1) rotate(-180.000000) translate(-109.500000, -193.000000) '
            x='19'
            y='135'
            width='181'
            height='116'
            rx='4'
          />
          <path
            d='M95.9948231,187 C97.1035316,187 98,187.895431 98,189 C98,190.11227 97.1022517,191 95.9948231,191 L95.9948231,191 L33.0051769,191 C31.8964684,191 31,190.104569 31,189 L31,189 L31.0054999,188.849781 C31.0820467,187.808861 31.948086,187 33.0051769,187 L33.0051769,187 Z M95.9948231,177 C97.1035316,177 98,177.895431 98,179 C98,180.11227 97.1022517,181 95.9948231,181 L95.9948231,181 L33.0051769,181 C31.8964684,181 31,180.104569 31,179 L31,179 L31.0054999,178.849781 C31.0820467,177.808861 31.948086,177 33.0051769,177 L33.0051769,177 Z M95.9948231,167 C97.1035316,167 98,167.895431 98,169 C98,170.11227 97.1022517,171 95.9948231,171 L95.9948231,171 L33.0051769,171 C31.8964684,171 31,170.104569 31,169 L31,169 L31.0054999,168.849781 C31.0820467,167.808861 31.948086,167 33.0051769,167 L33.0051769,167 Z M95.9948231,157 C97.1035316,157 98,157.895431 98,159 C98,160.11227 97.1022517,161 95.9948231,161 L95.9948231,161 L33.0051769,161 C31.8964684,161 31,160.104569 31,159 L31,159 L31.0054999,158.849781 C31.0820467,157.808861 31.948086,157 33.0051769,157 L33.0051769,157 Z M71.9980004,147 C73.1018839,147 74,147.895431 74,149 C74,150.11227 73.1036743,151 71.9980004,151 L71.9980004,151 L33.0019996,151 C31.8981161,151 31,150.104569 31,149 L31,149 L31.0054912,148.849781 C31.0819167,147.808861 31.9465836,147 33.0019996,147 L33.0019996,147 Z'
            fill='#E5E3E5'
          />
          <line
            x1='109.641844'
            y1='147.213801'
            x2='109.5'
            y2='238.5'
            id='Line'
            stroke='#EAEAEA'
            strokeLinecap='square'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='120' y='223' width='67' height='15.821267' rx='7.91063348' />
          <path
            d='M184.994823,207 C186.103532,207 187,207.895431 187,209 C187,210.11227 186.102252,211 184.994823,211 L184.994823,211 L122.005177,211 C120.896468,211 120,210.104569 120,209 L120,209 L120.0055,208.849781 C120.082047,207.808861 120.948086,207 122.005177,207 L122.005177,207 Z M184.994823,197 C186.103532,197 187,197.895431 187,199 C187,200.11227 186.102252,201 184.994823,201 L184.994823,201 L122.005177,201 C120.896468,201 120,200.104569 120,199 L120,199 L120.0055,198.849781 C120.082047,197.808861 120.948086,197 122.005177,197 L122.005177,197 Z M184.994823,187 C186.103532,187 187,187.895431 187,189 C187,190.11227 186.102252,191 184.994823,191 L184.994823,191 L122.005177,191 C120.896468,191 120,190.104569 120,189 L120,189 L120.0055,188.849781 C120.082047,187.808861 120.948086,187 122.005177,187 L122.005177,187 Z M184.994823,177 C186.103532,177 187,177.895431 187,179 C187,180.11227 186.102252,181 184.994823,181 L184.994823,181 L122.005177,181 C120.896468,181 120,180.104569 120,179 L120,179 L120.0055,178.849781 C120.082047,177.808861 120.948086,177 122.005177,177 L122.005177,177 Z M184.994823,167 C186.103532,167 187,167.895431 187,169 C187,170.11227 186.102252,171 184.994823,171 L184.994823,171 L122.005177,171 C120.896468,171 120,170.104569 120,169 L120,169 L120.0055,168.849781 C120.082047,167.808861 120.948086,167 122.005177,167 L122.005177,167 Z M184.994823,157 C186.103532,157 187,157.895431 187,159 C187,160.11227 186.102252,161 184.994823,161 L184.994823,161 L122.005177,161 C120.896468,161 120,160.104569 120,159 L120,159 L120.0055,158.849781 C120.082047,157.808861 120.948086,157 122.005177,157 L122.005177,157 Z M160.998,147 C162.101884,147 163,147.895431 163,149 C163,150.11227 162.103674,151 160.998,151 L160.998,151 L122.002,151 C120.898116,151 120,150.104569 120,149 L120,149 L120.005491,148.849781 C120.081917,147.808861 120.946584,147 122.002,147 L122.002,147 Z'
            fill='#E5E3E5'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(109.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-109.500000, -47.000000) '
            x='19'
            y='23'
            width='181'
            height='48'
            rx='4'
          />
          <path
            d='M102.940549,55 C104.052088,55 104.938534,55.8954305 104.938534,57 C104.938534,58.1122704 104.044006,59 102.940549,59 L102.940549,59 L67.997985,59 C66.8864466,59 66,58.1045695 66,57 L66,57 L66.0054802,56.8497808 C66.0817524,55.8088611 66.9446855,55 67.997985,55 L67.997985,55 Z M51,35 C53.209139,35 55,36.790861 55,39 L55,54.9835004 C55,57.1926394 53.209139,58.9835004 51,58.9835004 L35,58.9835004 C32.790861,58.9835004 31,57.1926394 31,54.9835004 L31,39 C31,36.790861 32.790861,35 35,35 L51,35 Z M186.009328,45 C187.105285,45 188,45.8954305 188,47 C188,48.1122704 187.108746,49 186.009328,49 L186.009328,49 L67.9906724,49 C66.8947148,49 66,48.1045695 66,47 L66,47 L66.0054602,46.8497808 C66.0814532,45.8088611 66.9412279,45 67.9906724,45 L67.9906724,45 Z M186.009328,35 C187.105285,35 188,35.8954305 188,37 C188,38.1122704 187.108746,39 186.009328,39 L186.009328,39 L67.9906724,39 C66.8947148,39 66,38.1045695 66,37 L66,37 L66.0054602,36.8497808 C66.0814532,35.8088611 66.9412279,35 67.9906724,35 L67.9906724,35 Z M47.875,52 C48.4963226,52 49,51.4403585 49,50.75 L49,43.25 C49,42.5596415 48.4963226,42 47.875,42 L38.125,42 C37.5036774,42 37,42.5596415 37,43.25 L37,50.75 C37,51.4403585 37.5036774,52 38.125,52 L47.875,52 Z M39.5,46 C38.6715785,46 38,45.3284215 38,44.5 C38,43.6715785 38.6715785,43 39.5,43 C40.3284215,43 41,43.6715785 41,44.5 C41,45.3284215 40.3284215,46 39.5,46 Z M48,50 L39,50 L39,48.817906 L40.6761017,47.0567178 C40.785965,46.9413029 40.964035,46.9413029 41.0738754,47.0567178 L42.375,48.4238747 L45.5511017,45.0865611 C45.660965,44.9711463 45.839035,44.9711463 45.9488754,45.0865611 L48,47.2417807 L48,50 Z'
            fill='#E5E3E5'
          />
          <rect
            fill='#B8B9BC'
            fillRule='nonzero'
            transform='translate(109.500000, 103.000000) scale(1, -1) rotate(-180.000000) translate(-109.500000, -103.000000) '
            x='19'
            y='79'
            width='181'
            height='48'
            rx='4'
          />
          <path
            d='M119.963284,113.831683 C121.332381,113.831683 122.442245,112.72182 122.442245,111.352723 L122.442245,96.4789604 C122.442245,95.1098633 121.332381,94 119.963284,94 L98.4789604,94 C97.1098633,94 96,95.1098633 96,96.4789604 L96,111.352723 C96,112.72182 97.1098633,113.831683 98.4789604,113.831683 L119.963284,113.831683 Z M101.784241,102.676362 C100.186978,102.676362 98.8921205,101.381504 98.8921205,99.784241 C98.8921205,98.1869779 100.186978,96.8921205 101.784241,96.8921205 C103.381504,96.8921205 104.676362,98.1869779 104.676362,99.784241 C104.676362,101.381504 103.381504,102.676362 101.784241,102.676362 Z M119.136964,110.526403 L99.3052806,110.526403 L99.3052806,108.047442 L102.998605,104.354068 C103.240691,104.112032 103.633072,104.112032 103.875107,104.354068 L106.742162,107.221122 L113.740767,100.222467 C113.982853,99.9804315 114.375234,99.9804315 114.617269,100.222467 L119.136964,104.742162 L119.136964,110.526403 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
            opacity='0.5'
          />
        </g>
      </g>
    </svg>
  )
  /* eslint-enable */
}

BumpTwoColumnOne.displayName = 'BumpTwoColumnOne'
BumpTwoColumnOne.propTypes = propTypes
BumpTwoColumnOne.defaultProps = defaultProps
