import I18n from 'i18n-js'

export * from './transfersShared.constants'
import { PAYMENT_SYSTEMS } from './paymentSettingShared.constants'
export const SELLER_TRANSFERS_FILTER_LIST_COMMON = [
  'campaignId',
  'hideFree',
  'multiAffiliateProgram',
  'multiCurrencyId',
  'multiFunnelId',
  'multiFunnelNodeId',
  'multiPayerId',
  'multiPeriodType',
  'multiProductId',
  'multiProductCategory',
  'multiProductIntName',
  'multiPublisherId',
  'multiTicketId',
  'multiTransferForm',
  'multiTransferState',
  'payoutId',
  'successAtTime',
  'transferCreatedAtTime',
  'multiPricingPlanId',
  'transferProvider',
  'createdRelativeAtTime',
  'successRelativeAtTime',
  'transferCountry',
  'invoiceDate',
]

export const SELLER_TRANSFERS_FILTER_LIST = [...SELLER_TRANSFERS_FILTER_LIST_COMMON, 'paymentFormType']

export const SELLER_TRANSFERS_FILTER_LIST_FOR_NS = [...SELLER_TRANSFERS_FILTER_LIST_COMMON, 'paymentFormTypeForNS']

export const SELLER_TRANSFERS_FILTER_LIST_WITH_SALES_TEAM = [
  ...SELLER_TRANSFERS_FILTER_LIST_COMMON,
  'paymentFormType',
  'multiSalesMemberId',
]

export const SELLER_TRANSFERS_FILTER_LIST_FOR_NS_WITH_SALES_TEAM = [
  ...SELLER_TRANSFERS_FILTER_LIST_COMMON,
  'paymentFormTypeForNS',
  'multiSalesMemberId',
]

export const PUBLISHER_TRANSFERS_FILTER_LIST = [
  'seller',
  'affiliateProgram',
  'transferForm',
  'createdAt',
  'productId',
  'paymentFormType',
  'transferState',
]

export const TEAM_MEMBERS_FILTER_LIST = ['createdAt']

export const PAYER_TRANSFERS_FILTER_LIST = ['transferForm', 'paymentFormType', 'transferState', 'createdAt']

export const TRANSFER_STATES = {
  successful: 'successful',
  denied: 'denied',
  error: 'error',
  pending: 'pending',
  canceled: 'canceled',
  expired: 'expired',
  waiting: 'waiting',
}
export const TRANSFER_STATES_VALUES = Object.values(TRANSFER_STATES)

export const CANCELLED_TRANSFER_TYPES = {
  refund: 'Refund',
  chargeback: 'Chargeback',
}
export const CANCELLED_TRANSFER_TYPES_VALUES = Object.values(CANCELLED_TRANSFER_TYPES)

export const TRANSFER_TYPES = {
  payment: 'Payment',
  payout: 'Payout',
  ...CANCELLED_TRANSFER_TYPES,
}
export const TRANSFER_TYPES_VALUES = Object.values(TRANSFER_TYPES)

export const PAYMENT_FORM_TYPE_COMMON = [
  {
    label: I18n.t('react.shared.payment_type.cashin'),
    value: 'Cashin',
  },
  {
    label: I18n.t('react.shared.payment_type.payment'),
    value: 'Payment',
  },
  {
    label: I18n.t('react.shared.payment_type.refund'),
    value: 'Refund',
  },
  {
    label: I18n.t('react.shared.payment_type.chargeback'),
    value: 'Chargeback',
  },
]

export const PAYMENT_FORM_TYPE_OPTIONS = [
  ...PAYMENT_FORM_TYPE_COMMON,
  {
    label: I18n.t('react.shared.payout'),
    value: 'Payout',
  },
]

export const PAYMENT_FORM_TYPE_OPTIONS_FOR_NORMAL_SELLER = [
  ...PAYMENT_FORM_TYPE_COMMON,
  {
    label: I18n.t('react.shared.payment_type.cashout'),
    value: 'Payout',
  },
]

export const TRANSFERABLE_TYPE = [
  ...PAYMENT_FORM_TYPE_OPTIONS,
  {
    label: I18n.t('react.shared.payment_type.cashout'),
    value: 'Cashout',
  },
  {
    label: 'Monthly Invoice Transfers',
    value: 'SubsPayment',
  },
  {
    label: 'StampPayment',
    value: 'StampPayment',
  },
]

export const TRANSFER_PROVIDERS_BASE = {
  noProvider: 'no_provider',
  lemonWay: 'lemonway',
  mangoPay: 'mango_pay',
  stripe: 'stripe',
  elopageConnect: 'elopage_connect',
  paypalRest: 'paypal_rest',
  paypalNvp: 'paypal_nvp',
  inAppPurchase: 'in_app_purchase',
}

export const TRANSFER_PROVIDERS = {
  ...TRANSFER_PROVIDERS_BASE,
  creditReformInkasso: 'credit_reform_inkasso',
}

export const TRANSFER_PROVIDERS_LIST = Object.values(TRANSFER_PROVIDERS)
export const TRANSFER_PROVIDERS_BASE_LIST = Object.values(TRANSFER_PROVIDERS_BASE)

export const TRANSFER_PROVIDERS_ADMIN = {
  ...TRANSFER_PROVIDERS,
  directBank: PAYMENT_SYSTEMS.directBank,
}
export const TRANSFER_PROVIDERS_ADMIN_LIST = Object.values(TRANSFER_PROVIDERS_ADMIN)

export const CASHOUT_TRANSFER_STATE_OPTIONS = [
  {
    label: I18n.t('react.shared.state.successful'),
    value: TRANSFER_STATES.successful,
  },
  {
    label: I18n.t('react.shared.deals.status_pending'),
    value: TRANSFER_STATES.pending,
  },
  {
    label: I18n.t('react.shared.state.canceled'),
    value: TRANSFER_STATES.canceled,
  },
]

export const TRANSFER_STATE_OPTIONS = [
  {
    label: I18n.t('react.shared.state.successful'),
    value: TRANSFER_STATES.successful,
  },
  {
    label: I18n.t('react.shared.state.denied'),
    value: TRANSFER_STATES.denied,
  },
  {
    label: I18n.t('react.shared.state.error'),
    value: TRANSFER_STATES.error,
  },
  {
    label: I18n.t('react.shared.state.pending'),
    value: TRANSFER_STATES.pending,
  },
  {
    label: I18n.t('react.shared.state.canceled'),
    value: TRANSFER_STATES.canceled,
  },
  {
    label: I18n.t('react.shared.state.expired'),
    value: TRANSFER_STATES.expired,
  },
  {
    label: I18n.t('react.shared.state.waiting'),
    value: TRANSFER_STATES.waiting,
  },
]
export const TRANSFER_STATE_ADMIN_OPTIONS = [
  ...TRANSFER_STATE_OPTIONS,
  {
    value: 'partially_paid',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.partially_paid'),
  },
]
export const VIBAN_TRANSFER_STATE_OPTIONS = [
  {
    label: I18n.t('react.shared.state.successful'),
    value: TRANSFER_STATES.successful,
  },
  {
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.partially_paid'),
    value: 'partially_paid',
  },
  {
    label: I18n.t('react.shared.state.canceled'),
    value: TRANSFER_STATES.canceled,
  },
  {
    label: I18n.t('react.shared.state.waiting'),
    value: TRANSFER_STATES.waiting,
  },
]

export const EXPAND_TRANSFER_PARAMS = [
  'transferable.invoice',
  'transferable.credit_memo',
  'transferable.order.owner.user_profile',
  'transferable.order.owner.user',
  'transferable.order.product.covers',
  'refunds.transfer',
  'transferable.team_member_commissions.team_member.user',
  'transferable.team_member_commissions.team_member.user_profile',
  'transferable.publisher_commissions.publisher.user',
  'transferable.publisher_commissions.publisher.user_profile',
  'transferable.order.deal.incentive_plan',
  'transferable.sales_team_member_commission',
]

export const OVERVIEW_SUMMARY_KEYS = {
  total: 'total',
  vatAmount: 'vatAmount',
  net: 'net',
  fee: 'fee',
  earned: 'earned',
  payouted: 'payouted',
  toPayOut: 'to_pay_out',
  inAppPurchases: 'inAppPurchases',
}

export const SELLER_TRANSFERS_CSV_PARAMS = [
  {
    label: I18n.t('react.shared.transfers.csv.id'),
    csvKey: 'id_for_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.related_id'),
    csvKey: 'related_id',
  },
  {
    label: I18n.t('react.cabinet.common.invoice_number'),
    csvKey: 'invoice_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.memo'),
    csvKey: 'credit_memo_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.date'),
    csvKey: 'created',
  },
  {
    label: I18n.t('react.shared.transfers.csv.success_date'),
    csvKey: 'success_date',
  },
  {
    label: I18n.t('react.shared.transfers.csv.type'),
    csvKey: 'type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.form'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_id'),
    csvKey: 'product_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.provider'),
    csvKey: 'provider',
  },
  {
    label: I18n.t('react.shared.transfers.csv.status'),
    csvKey: 'status',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ps'),
    csvKey: 'power_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.inst'),
    csvKey: 'to_be_paid',
  },
  {
    label: I18n.t('react.shared.transfers.csv.total'),
    csvKey: 'total',
  },
  {
    label: I18n.t('react.shared.transfers.csv.amount'),
    csvKey: 'amount',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'currency_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.fee'),
    csvKey: 'fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.service_fee'),
    csvKey: 'service_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_fee'),
    csvKey: 'publisher_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.jv_fee'),
    csvKey: 'jv_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.processing_fee'),
    csvKey: 'processing_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.vat_rate'),
    csvKey: 'vat_rate',
  },
  {
    label: I18n.t('react.shared.transfers.csv.vat_amount'),
    csvKey: 'vat_amount',
  },
  {
    label: I18n.t('react.shared.transfers.csv.seller_business'),
    csvKey: 'seller_business',
  },
  {
    label: I18n.t('react.shared.transfers.csv.recurring'),
    csvKey: 'recurring',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_name'),
    csvKey: 'pricing_plan_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_id'),
    csvKey: 'pricing_plan_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.coupon_code'),
    csvKey: 'coupon_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.campaign_id'),
    csvKey: 'campaign_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.payouts_ids'),
    csvKey: 'payout_ids',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_name'),
    csvKey: 'sellable_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.is_upsell'),
    csvKey: 'is_upsell',
  },
  {
    label: I18n.t('react.shared.transfers.csv.upsell_id'),
    csvKey: 'upsell_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.upsell_addons'),
    csvKey: 'upsell_addons',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_name'),
    csvKey: 'ticket_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.location_short'),
    csvKey: 'ticket_location',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_date'),
    csvKey: 'ticket_dates',
  },
  {
    label: I18n.t('react.shared.transfers.csv.tickets_count'),
    csvKey: 'ticket_count',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ticket_code'),
    csvKey: 'ticket_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.license_key'),
    csvKey: 'license_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_first_name'),
    csvKey: 'first_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_last_name'),
    csvKey: 'last_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_email'),
    csvKey: 'email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_phone'),
    csvKey: 'buyer_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_country'),
    csvKey: 'buyer_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_city'),
    csvKey: 'city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street'),
    csvKey: 'street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street_number'),
    csvKey: 'street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_zip'),
    csvKey: 'zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_company'),
    csvKey: 'company',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_vat_no'),
    csvKey: 'vat_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_name'),
    csvKey: 'receiver_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_email'),
    csvKey: 'receiver_email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_phone'),
    csvKey: 'receiver_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_country'),
    csvKey: 'receiver_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_city'),
    csvKey: 'receiver_city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street'),
    csvKey: 'receiver_street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street_number'),
    csvKey: 'receiver_street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_zip'),
    csvKey: 'receiver_zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_company'),
    csvKey: 'receiver_company',
  },
  {
    label: I18n.t('react.cabinet.common.teamMember.name'),
    csvKey: 'team_member_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_id'),
    csvKey: 'publisher_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.first_tier'),
    csvKey: 'first_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.second_tier'),
    csvKey: 'second_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.third_tier'),
    csvKey: 'third_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.fourth_tier'),
    csvKey: 'fourth_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.fifth_tier'),
    csvKey: 'fifth_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.add_id_1'),
    csvKey: 'add_id_1',
  },
  {
    label: I18n.t('react.shared.transfers.csv.add_id_2'),
    csvKey: 'add_id_2',
  },
  {
    label: I18n.t('react.cabinet.common.order_id'),
    csvKey: 'order_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.order_rate_id'),
    csvKey: 'order_rate_id',
  },
  {
    label: I18n.t('react.common.net'),
    csvKey: 'net',
  },
  {
    label: I18n.t('react.shared.ext_id'),
    csvKey: 'ext_id',
  },
  {
    label: I18n.t('react.common.credit_memo_nr'),
    csvKey: 'payout_numbers',
  },
  {
    label: I18n.t('react.shared.transfers.csv.earnings'),
    csvKey: 'earnings',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_category'),
    csvKey: 'product_groups',
  },
  {
    label: I18n.t('react.cabinet.product.form.internal_name'),
    csvKey: 'internal_name',
  },
  {
    label: I18n.t('react.cabinet.transfers.csv.additional_address'),
    csvKey: 'additional_address',
  },
  {
    label: I18n.t('react.cabinet.common.invoice_id'),
    csvKey: 'invoice_id',
  },
  {
    label: I18n.t('react.cabinet.common.transfer_payer_country'),
    csvKey: 'transfer_payer_country',
  },
  {
    label: I18n.t('react.cabinet.common.invoice_date'),
    csvKey: 'invoice_created_at',
  },
  {
    label: I18n.t('react.cabinet.common.cost_center'),
    csvKey: 'cost_center',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.receiver_state'),
    csvKey: 'receiver_state',
  },
  {
    label: I18n.t('react.shared.transfers.csv.category'),
    csvKey: 'category',
  },
  {
    label: I18n.t('react.shared.transfers.csv.offline_event_country_code'),
    csvKey: 'offline_event_country_code',
  },
]

export const SELLER_TRANSFERS_CSV_PARAMS_WITH_SALES_TEAM = [
  {
    label: I18n.t('react.shared.transfers.csv.id'),
    csvKey: 'id_for_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.related_id'),
    csvKey: 'related_id',
  },
  {
    label: I18n.t('react.cabinet.common.invoice_number'),
    csvKey: 'invoice_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.memo'),
    csvKey: 'credit_memo_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.date'),
    csvKey: 'created',
  },
  {
    label: I18n.t('react.shared.transfers.csv.success_date'),
    csvKey: 'success_date',
  },
  {
    label: I18n.t('react.shared.transfers.csv.type'),
    csvKey: 'type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.form'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_id'),
    csvKey: 'product_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.provider'),
    csvKey: 'provider',
  },
  {
    label: I18n.t('react.shared.transfers.csv.status'),
    csvKey: 'status',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ps'),
    csvKey: 'power_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.inst'),
    csvKey: 'to_be_paid',
  },
  {
    label: I18n.t('react.shared.transfers.csv.total'),
    csvKey: 'total',
  },
  {
    label: I18n.t('react.shared.transfers.csv.amount'),
    csvKey: 'amount',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'currency_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.fee'),
    csvKey: 'fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.service_fee'),
    csvKey: 'service_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_fee'),
    csvKey: 'publisher_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.sales_member_fee'),
    csvKey: 'sales_team_member_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.jv_fee'),
    csvKey: 'jv_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.processing_fee'),
    csvKey: 'processing_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.vat_rate'),
    csvKey: 'vat_rate',
  },
  {
    label: I18n.t('react.shared.transfers.csv.vat_amount'),
    csvKey: 'vat_amount',
  },
  {
    label: I18n.t('react.shared.transfers.csv.seller_business'),
    csvKey: 'seller_business',
  },
  {
    label: I18n.t('react.shared.transfers.csv.recurring'),
    csvKey: 'recurring',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_name'),
    csvKey: 'pricing_plan_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_id'),
    csvKey: 'pricing_plan_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.coupon_code'),
    csvKey: 'coupon_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.campaign_id'),
    csvKey: 'campaign_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.payouts_ids'),
    csvKey: 'payout_ids',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_name'),
    csvKey: 'sellable_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.is_upsell'),
    csvKey: 'is_upsell',
  },
  {
    label: I18n.t('react.shared.transfers.csv.upsell_id'),
    csvKey: 'upsell_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.upsell_addons'),
    csvKey: 'upsell_addons',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_name'),
    csvKey: 'ticket_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.location_short'),
    csvKey: 'ticket_location',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_date'),
    csvKey: 'ticket_dates',
  },
  {
    label: I18n.t('react.shared.transfers.csv.tickets_count'),
    csvKey: 'ticket_count',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ticket_code'),
    csvKey: 'ticket_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.license_key'),
    csvKey: 'license_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_first_name'),
    csvKey: 'first_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_last_name'),
    csvKey: 'last_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_email'),
    csvKey: 'email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_phone'),
    csvKey: 'buyer_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_country'),
    csvKey: 'buyer_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_city'),
    csvKey: 'city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street'),
    csvKey: 'street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street_number'),
    csvKey: 'street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_zip'),
    csvKey: 'zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_company'),
    csvKey: 'company',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_vat_no'),
    csvKey: 'vat_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_name'),
    csvKey: 'receiver_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_email'),
    csvKey: 'receiver_email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_phone'),
    csvKey: 'receiver_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_country'),
    csvKey: 'receiver_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_city'),
    csvKey: 'receiver_city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street'),
    csvKey: 'receiver_street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street_number'),
    csvKey: 'receiver_street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_zip'),
    csvKey: 'receiver_zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_company'),
    csvKey: 'receiver_company',
  },
  {
    label: I18n.t('react.cabinet.common.teamMember.name'),
    csvKey: 'team_member_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_id'),
    csvKey: 'publisher_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.sales_member_name'),
    csvKey: 'sales_team_member_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.sales_member_id'),
    csvKey: 'sales_team_member_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.first_tier'),
    csvKey: 'first_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.second_tier'),
    csvKey: 'second_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.third_tier'),
    csvKey: 'third_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.fourth_tier'),
    csvKey: 'fourth_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.fifth_tier'),
    csvKey: 'fifth_tier',
  },
  {
    label: I18n.t('react.shared.transfers.csv.add_id_1'),
    csvKey: 'add_id_1',
  },
  {
    label: I18n.t('react.shared.transfers.csv.add_id_2'),
    csvKey: 'add_id_2',
  },
  {
    label: I18n.t('react.cabinet.common.order_id'),
    csvKey: 'order_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.order_rate_id'),
    csvKey: 'order_rate_id',
  },
  {
    label: I18n.t('react.common.net'),
    csvKey: 'net',
  },
  {
    label: I18n.t('react.shared.ext_id'),
    csvKey: 'ext_id',
  },
  {
    label: I18n.t('react.common.credit_memo_nr'),
    csvKey: 'payout_numbers',
  },
  {
    label: I18n.t('react.shared.transfers.csv.earnings'),
    csvKey: 'earnings',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_category'),
    csvKey: 'product_groups',
  },
  {
    label: I18n.t('react.cabinet.product.form.internal_name'),
    csvKey: 'internal_name',
  },
  {
    label: I18n.t('react.cabinet.transfers.csv.additional_address'),
    csvKey: 'additional_address',
  },
  {
    label: I18n.t('react.cabinet.common.invoice_id'),
    csvKey: 'invoice_id',
  },
  {
    label: I18n.t('react.cabinet.common.transfer_payer_country'),
    csvKey: 'transfer_payer_country',
  },
  {
    label: I18n.t('react.cabinet.common.invoice_date'),
    csvKey: 'invoice_created_at',
  },
  {
    label: I18n.t('react.cabinet.common.cost_center'),
    csvKey: 'cost_center',
  },
  {
    label: I18n.t('react.cabinet.invoice.csv.receiver_state'),
    csvKey: 'receiver_state',
  },
  {
    label: I18n.t('react.shared.transfers.csv.category'),
    csvKey: 'category',
  },
  {
    label: I18n.t('react.shared.transfers.csv.offline_event_country_code'),
    csvKey: 'offline_event_country_code',
  },
]

export const PAYER_TRANSFERS_CSV_PARAMS = [
  {
    label: I18n.t('react.shared.transfers.csv.id'),
    csvKey: 'id_for_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.date'),
    csvKey: 'created',
  },
  {
    label: I18n.t('react.shared.transfers.csv.success_date'),
    csvKey: 'success_date',
  },
  {
    label: I18n.t('react.shared.transfers.csv.type'),
    csvKey: 'type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.form'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.status'),
    csvKey: 'status',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'currency_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.total'),
    csvKey: 'amount',
  },
]

export const PUBLISHER_TRANSFERS_CSV_PARAMS = [
  {
    label: I18n.t('react.shared.transfers.csv.id'),
    csvKey: 'id_for_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.date'),
    csvKey: 'created',
  },
  {
    label: I18n.t('react.shared.transfers.csv.type'),
    csvKey: 'type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.form'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.status'),
    csvKey: 'status',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_fee'),
    csvKey: 'publisher_fee',
  },
]

export const TEAM_MEMBER_TRANSFERS_CSV_PARAMS = [
  {
    label: I18n.t('react.shared.transfers.csv.id'),
    csvKey: 'id_for_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.date'),
    csvKey: 'created',
  },
  {
    label: I18n.t('react.shared.transfers.csv.type'),
    csvKey: 'type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.form'),
    csvKey: 'form',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.status'),
    csvKey: 'status',
  },
  {
    label: I18n.t('shared.common.commission'),
    csvKey: 'tm_comission',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'currency_key',
  },
]
