import { PRODUCT_TYPE_IDS } from './productsShared.constants'
import { getDefaultProductsList } from './contentPageShared.constants'

export * from './contentPageShared.constants'

export const getDefaultPopularProductsList = () => [
  ...getDefaultProductsList(),
  {
    name: `${I18n.t('react.shared.product')} 4`,
    form: 'digital',
    id: 'product-list-item-4',
    free: false,
    displayPrice: 300,
    displayCurrencyId: 1,
    pricingPlans: [
      {
        currencyKey: 1,
        useNetPrice: true,
      },
    ],
    description: I18n.t('react.shared.product_description_default'),
  },
]

const getDefaultMenuItems = () => [
  {
    name: I18n.t('react.shared.menu_item'),
    url: '/',
    id: 'menu-item-1',
  },
  {
    name: I18n.t('react.shared.menu_item'),
    url: '/',
    id: 'menu-item-2',
  },
  {
    name: I18n.t('react.shared.menu_item'),
    url: '/',
    id: 'menu-item-3',
  },
]

export const getDefaultSectionMenuItems = () => [
  {
    name: I18n.t('react.shared.section'),
    id: 'section-item-1',
    submenu: getDefaultMenuItems(),
  },
  {
    name: I18n.t('react.shared.section'),
    id: 'section-item-2',
    submenu: getDefaultMenuItems(),
  },
]

export const getAllProductsCategories = () => ({
  color: '#1E84D7',
  description: I18n.t('react.shared.category_description'),
  hidden: false,
  id: 0,
  position: 0,
  productIds: [],
  title: `${I18n.t('react.shared.all_products')}`,
})

export const DEFAULT_PRODUCT = {
  id: 'product-1',
  name: I18n.t('react.shared.product'),
  form: PRODUCT_TYPE_IDS.digital,
  free: false,
  displayPrice: 50,
  displayOldPrice: 100,
  displayCurrencyId: 2,
  pricingPlans: [
    {
      currencyKey: 2,
    },
  ],
  slug: 'product',
  sellerUsername: 'some_fake_username',
  description: I18n.t('react.shared.product_description_default'),
  menuUnfold: true,
  covers: [],
}

export const DEFAULT_LESSONS = [
  {
    id: 1,
    name: `${I18n.t('react.shared.menu_item')} 1`,
    isCategory: true,
    active: true,
    static: false,
    overview: true,
    position: 1,
    children: [
      {
        id: 2,
        parentId: 1,
        name: `${I18n.t('react.shared.menu_item')} 2`,
        isCategory: false,
        active: true,
        static: false,
        overview: false,
        preview: true,
        position: 1,
        children: [],
      },
      {
        id: 3,
        parentId: 1,
        name: `${I18n.t('react.shared.menu_item')} 3`,
        isCategory: true,
        active: true,
        static: false,
        overview: false,
        position: 2,
        children: [],
      },
      {
        id: 4,
        parentId: 1,
        name: `${I18n.t('react.shared.menu_item')} 4`,
        isCategory: false,
        active: true,
        static: false,
        overview: false,
        position: 3,
        children: [],
      },
    ],
  },
  {
    id: 5,
    name: `${I18n.t('react.shared.menu_item')} 5`,
    isCategory: false,
    active: true,
    static: false,
    overview: false,
    preview: true,
    position: 2,
    children: [],
  },
  {
    id: 6,
    name: `${I18n.t('react.shared.menu_item')} 6`,
    isCategory: true,
    active: true,
    static: false,
    overview: false,
    position: 3,
    children: [
      {
        id: 7,
        parentId: 6,
        name: `${I18n.t('react.shared.menu_item')} 7`,
        isCategory: true,
        active: true,
        static: false,
        overview: false,
        position: 1,
        children: [
          {
            id: 8,
            parentId: 7,
            name: `${I18n.t('react.shared.menu_item')} 8`,
            isCategory: false,
            active: true,
            static: false,
            overview: false,
            preview: true,
            position: 1,
            children: [],
          },
        ],
      },
      {
        id: 9,
        parentId: 6,
        name: `${I18n.t('react.shared.menu_item')} 9`,
        isCategory: false,
        active: true,
        static: false,
        overview: false,
        position: 2,
        children: [],
      },
      {
        id: 10,
        parentId: 6,
        name: `${I18n.t('react.shared.menu_item')} 10`,
        isCategory: false,
        active: true,
        static: true,
        overview: false,
        preview: true,
        position: 3,
        children: [],
        quizEnabled: true,
      },
    ],
  },
]

export const BLOCK_POSITIONS = {
  right: 'right',
  bottom: 'bottom',
  center: 'center',
}

export const VIDEO_CODE_MIN_FROM_VALUE = 1
export const VIDEO_CODE_MIN_DURATION = 2

export const DEFAULT_ORDER = {
  allowedPaymentMethods: [],
  canChangePlan: false,
  canContinue: true,
  cancelAt: null,
  company: 'elopay GmbH',
  currencyId: 1,
  currencyKey: 'eur',
  currentPlanId: 1111,
  fullyPaid: false,
  funnelId: null,
  funnelNodeId: null,
  hasPendingTransfers: false,
  id: 2222,
  invoices: [
    {
      created: '25.11.2021 12:00',
      deadline: null,
      downloadLink: 'http://test',
      id: 3333,
      number: '0000000004489',
      paymentLink: 'http://test',
      state: 'paid',
      total: 1000,
    },
  ],
  isTestPeriodPassed: true,
  keepAccess: true,
  mandateUrl: null,
  moneyHolder: {
    cardProvider: 'stripe',
    bankWireProvider: 'stripe',
    paypalProvider: 'paypal_rest',
  },
  nextChargeAmount: 0,
  nextChargeDate: null,
  owner: {
    fullName: I18n.t('react.shared.page_builder.default.full_name'),
    email: I18n.t('react.shared.page_builder.default.email'),
    address: I18n.t('react.shared.page_builder.default.address'),
  },
  paidCount: 1,
  payer: {
    fullName: I18n.t('react.shared.page_builder.default.full_name'),
    email: I18n.t('react.shared.page_builder.default.email'),
    address: I18n.t('react.shared.page_builder.default.address'),
  },
  paymentForm: 'card',
  paymentState: 'payd',
  paypalWithImmediateAccess: false,
  periodType: 'one_time',
  plansToChange: [],
  product: {
    id: 4444,
    additionalInfo: null,
    form: 'membership',
    cover: null,
    name: I18n.t('react.shared.page_builder.default.membership_product'),
  },
  products: [],
  protectionUrl: false,
  provider: 'stripe',
  rates: [],
  relatedOrders: ['C8LVvgS9v9zzVgUD9Rjq'],
  reseller: { id: 1 },
  sellableWithPeriod: { planPrefs: { testPeriodHideAmounts: false, price: null, pastDue: '14', customStartDay: '' } },
  sellables: [
    {
      id: 5555,
      product: {
        id: 4444,
        additionalInfo: null,
        form: 'membership',
        cover: null,
        name: I18n.t('react.shared.page_builder.default.membership_product'),
      },
      sellableItems: [
        {
          expired: false,
          fileExists: true,
          fileName: 'Lisense key',
          id: 6666,
          lessThen500Mb: true,
          licenseKeys: ['test-key'],
          lifetimeHours: 48,
          link: 'http://test',
          linkAll: 'http://test',
          linkFile: 'http://test',
          needStamping: false,
          product: {
            additionalinfo: null,
            cover: null,
            form: 'license_key',
            goods: [],
            id: 9999,
            name: I18n.t('react.shared.page_builder.default.lisense_key'),
            slug: 'test-ticket',
          },
          ticket: null,
          ticketDate: null,
        },
        {
          expired: false,
          fileExists: true,
          fileName: I18n.t('react.shared.page_builder.default.ticket_on_event'),
          id: 6666,
          lessThen500Mb: true,
          licenseKeys: [],
          lifetimeHours: 48,
          link: 'http://test',
          linkAll: 'http://test',
          linkFile: 'http://test',
          needStamping: false,
          product: {
            additionalinfo: null,
            cover: null,
            form: 'event',
            goods: [],
            id: 9999,
            name: I18n.t('react.shared.page_builder.default.ticket_on_event'),
            slug: 'test-ticket',
          },
          ticket: {
            countryCode: null,
            id: 1101,
            locationAddress: 'Address',
            locationshortName: 'Location',
            online: true,
          },
          ticketDate: {
            additionalText: I18n.t('react.shared.page_builder.default.additional_description'),
            endDate: null,
            eventForm: 'without_dates',
            id: 1102,
            startDate: null,
            timeZoneName: 'Europe/Country',
          },
        },
        {
          expired: false,
          fileExists: true,
          fileName: I18n.t('react.shared.page_builder.default.online_course'),
          id: 6666,
          lessThen500Mb: true,
          licenseKeys: [],
          lifetimeHours: 48,
          link: 'http://test',
          linkAll: 'http://test',
          linkFile: 'http://test',
          needStamping: false,
          product: {
            additionalinfo: null,
            cover: null,
            form: 'course',
            goods: [],
            id: 9999,
            name: I18n.t('react.shared.page_builder.default.online_course'),
            slug: 'course-ticket',
          },
          ticket: null,
          ticketDate: null,
        },
      ],
    },
  ],
  sendEmail: true,
  sepaWithImmediateAccess: false,
  sofortWithImmediateAccess: false,
  token: 'testToken',
  totalAmount: '1000.0',
  tracked: true,
  transfers: [
    {
      created: '25.11.2021 12:00',
      currencyKey: 'eur',
      form: 'card',
      idForSeller: 2996337,
      inProgress: false,
      invoiceLink: 'http://test',
      provider: 'stripe',
      providerData: {},
      state: 'successful',
      successDate: '25.11.2021 12:00',
      total: '10.0',
      withExtId: true,
    },
  ],
  withCustomStart: false,
  withTestPeriod: false,
}
