import { override, makeObservable } from 'mobx'

import { convertToPrice, getCurrencyKey, getCurrencySignPosition, getCurrencyUnit } from 'utils/currenciesShared.utils'
import { CURRENCY_SIGN_POSITIONS } from 'constants/currencies.constants'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import SharedStore from 'shared/stores/shared.store'

import { CurrenciesApi, createCurrenciesApi, Currency } from 'shop/api/currencies.api'

export class CurrenciesStore extends SharedStore<Currency> {
  storeName = 'CurrenciesStore'
  declare childApi: CurrenciesApi

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)

    this.childApi = createCurrenciesApi(root.apiClientV2)
  }

  @override
  async fetchList(): Promise<void> {
    if (this.list.length) {
      return
    }

    const { data } = await this.childApi.fetchList()

    this.list = data.map((currency) => ({
      ...currency,
      id: currency.id,
      key: currency.key.toLowerCase(),
    }))
  }

  // TODO: rm any after migrate cabinets to countries with v2 api
  getKey = (id: number) => getCurrencyKey(this.list as any, id)

  convertToPrice = (value: string | number, currencyId: number | string, separator = '') =>
    convertToPrice(this.list as any, value || 0, currencyId, separator)

  getUnit = (id: number): string => getCurrencyUnit(this.list as any, id)

  getCurrencySignPosition = (currencySign: string): CURRENCY_SIGN_POSITIONS => getCurrencySignPosition(currencySign)
}
