import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { FromSwagger } from 'types/helpers'
import { UserAttributes } from 'types/__generated__/api'

const BASE_URL = 'shop/user'

export type User = FromSwagger<UserAttributes, number>

export const createUserApi = ({ GET }: ApiClientV2) => ({
  fetchItem: (data?: { token: string }) => GET<User>(BASE_URL, data),
})

export type UserApi = ReturnType<typeof createUserApi>
