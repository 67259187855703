import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCloseIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.9744 3.4776C18.2673 3.1847 18.2673 2.70983 17.9744 2.41694C17.6815 2.12404 17.2066 2.12404 16.9137 2.41694L10.1957 9.135L3.4776 2.41694C3.1847 2.12404 2.70983 2.12404 2.41694 2.41694C2.12404 2.70983 2.12404 3.1847 2.41694 3.4776L9.135 10.1957L2.41694 16.9137C2.12404 17.2066 2.12404 17.6815 2.41694 17.9744C2.70983 18.2673 3.1847 18.2673 3.4776 17.9744L10.1957 11.2563L16.9137 17.9744C17.2066 18.2673 17.6815 18.2673 17.9744 17.9744C18.2673 17.6815 18.2673 17.2066 17.9744 16.9137L11.2563 10.1957L17.9744 3.4776Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
