import { notify } from 'libs/common/notify'
import { isWindowEnv } from 'utils/env.utils'

export const setFavicon = (icon) => {
  // TODO: SSR - setFavicon on SSR too
  if (!isWindowEnv()) return
  const favIcon = document.getElementById('pageFavicon')
  favIcon.href = icon
  document.head.appendChild(favIcon)
}

export const appendScript = (src, attributes, inBody) => {
  const container = inBody ? document.getElementsByTagName('body')[0] : document.getElementsByTagName('head')[0]

  const scriptTag = document.createElement('script')

  attributes.forEach(({ key, val }) => {
    scriptTag.setAttribute(key, val)
  })

  scriptTag.setAttribute('src', src)

  container.appendChild(scriptTag)
}

export const copyTextToClipboard = (copyText, message) => {
  const element = document.createElement('textarea')
  element.value = copyText
  element.setAttribute('readonly', '')
  element.style.position = 'absolute'
  element.style.left = '-9999px'
  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
  notify('success', message || I18n.t('react.shared.notific.copied'))
}

export const unmountDomElement = (element) => {
  if (element?.parentElement) {
    element.parentElement.removeChild(element)
  }
}

export const resetFileUploadInputValue = (id = '') => {
  const fileUploadInput = document.querySelector(`#${id}`)

  if (fileUploadInput) {
    fileUploadInput.value = ''
  }
}
