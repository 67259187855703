export const FILE_UPLOAD_DEFAULT_ACCEPTED_FILES = 'audio/*, video/*, image/*, application/*, text/*'
export const FILE_UPLOAD_ACCEPT_VIDEO_FILES = 'video/mp4, video/x-m4v, video/*'
export const FILE_UPLOAD_ACCEPT_IMAGE_FILES = '.jpg, .jpeg, .png, .gif'
export const FILE_UPLOAD_ACCEPT_APP_INTRO_SCREEN_IMAGE_FILES = '.jpg, .jpeg, .gif'
export const FILE_UPLOAD_ACCEPT_ALL_AUDIO_FILES = 'audio/*'
export const FILE_UPLOAD_ACCEPT_AUDIO_FILES = 'audio/mp3, audio/flac, audio/aac, audio/wav'
export const FILE_UPLOAD_ACCEPT_TEXT_FILES = 'text/*'
export const FLATTER_ACCEPT_IMAGE_FILES = '.jpg, .jpeg, .png, .gif, .bmp, .webp'
export const FILE_UPLOAD_DOCUMENT_FILES = 'image/*, application/pdf'
export const FILE_UPLOAD_PDF_FILES = 'application/pdf'
export const FILE_UPLOAD_ALL_FILES = 'all/'
export const FILE_UPLOAD_JSON_FILES = '.json'
export const FILE_UPLOAD_ACCEPT_IMAGE_PNG = '.png'
export const FILE_UPLOAD_IOS_PUSH_NOTIFICATION_FILES = '.plist'
export const FILE_UPLOAD_KEYSTORE_CERTIFICATE = '.keystore'
export const FILE_UPLOAD_P8_FILES = '.p8'
export const FILE_UPLOAD_ACCEPT_POST_FILES = '.jpg, .jpeg, .png'

export const FILE_ACCEPT_VERIFICATION_FILES = [FILE_UPLOAD_ACCEPT_IMAGE_FILES, FILE_UPLOAD_PDF_FILES].join(', ')
export const FONT_DROPBOX_EXTENSIONS = ['.woff', '.ttf', '.otf']
export const PDF_DROPBOX_EXTENSIONS = ['.pdf']
export const AUDIO_FILE_DROPBOX_EXTENSIONS = ['.mp3', '.flac', '.aac', '.wav']
export const ANDROID_AUDIO_FILE_DROPBOX_EXTENSIONS = ['audio']
export const FILE_UPLOAD_FONT_FILES = FONT_DROPBOX_EXTENSIONS.join(',')
export const FILE_UPLOAD_ACCEPT_POST_EXTENSIONS = ['jpg', 'jpeg', 'png']

export const DROPBOX_EXTENSIONS = ['image', 'images']
export const DROPBOX_IMAGE_EXTENSIONS = FILE_UPLOAD_ACCEPT_IMAGE_FILES.split(', ')
export const DROPBOX_APP_KEY = 'fnoon3atz19aay6'

export const FILE_ICONS = {
  pdf: 'fas fa-file-pdf',
  doc: 'fas fa-file-word',
  docx: 'fas fa-file-word',
  mp4: 'fas fa-video',
  m4v: 'fas fa-video',
  avi: 'fas fa-video',
  mkv: 'fas fa-video',
  jpeg: 'fas fa-images',
  jpg: 'fas fa-images',
  png: 'fas fa-images',
  raw: 'fas fa-images',
  mp3: 'fas fa-file-audio',
  flac: 'fas fa-file-audio',
  wav: 'fas fa-file-audio',
}

export const FILE_TYPES = {
  picture: 'picture',
  video: 'video',
  audio: 'audio',
}

export const CONTENT_TYPES = {
  picture: 'image',
  audio: 'audio',
  pdf: FILE_UPLOAD_PDF_FILES,
}

export const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'raw']

export const VIDEO_EXTENSIONS = ['avi', 'flv', 'mov', 'm4v', 'mp4', 'mkv', 'wmv', 'rm', 'qt']

export const AUDIO_EXTENSIONS = ['mp3', 'wav', 'aac', 'flac']

const transformToDropboxExtension = (array) => array.map((item) => `.${item}`)

export const DROPBOX_VIDEO_EXTENSIONS = transformToDropboxExtension(VIDEO_EXTENSIONS)

export const FILE_UNITS = {
  bytes: 'B',
  kb: 'KB',
  mb: 'MB',
  gb: 'GB',
  tb: 'TB',
  pb: 'PB',
  eb: 'EB',
  zb: 'ZB',
  yb: 'YB',
}

export const FILE_UNITS_LIST = Object.values(FILE_UNITS)

export const MAX_ALLOWED_FILE_SIZE = {
  value: 3.5,
  units: 'GB',
}

export const MAX_ALLOWED_VIDEO_SIZE = {
  value: 4,
  units: 'GB',
}

export const MAX_ALLOWED_FONT_FILE_SIZE = {
  value: 10,
  units: 'MB',
}

export const MAX_ALLOWED_APP_INTRO_IMAGE_SIZE = {
  value: 500,
  units: 'MB',
}

const getFilesPageTabsLabel = () => ({
  digitals: I18n.t('react.cabinet.files.tab_digitals'),
  covers: I18n.t('react.cabinet.files.tab_covers'),
  videos: I18n.t('react.cabinet.files.tab_videos'),
})
export const FILES_PAGE_TABS = ['digitals', 'covers', 'videos'].map((item) => ({
  name: item,
  label: getFilesPageTabsLabel()[item],
  link: `/cabinet/${item}`,
}))

export const DOCUMENT_TYPES = {
  imprint: 'imprint',
  privacy: 'privacy',
  terms: 'terms_of_business',
  vatExempt: 'vat_exempt',
  identity: 'identity',
  businessCertificateOwnership: 'business_certificate_ownership',
  bankAccountDetails: 'bank_account_details',
  vatIdOrExemption: 'vat_id_or_exemption',
}

export const UPLOAD_WISTIA_VIDEO_URL = 'https://upload.wistia.com/'

export const UPLOAD_TABLE = {
  startPage: 1,
  showPerPage: 10,
}

export const getFileUploadLocales = () => ({
  title: I18n.t('react.cabinet.product.select_file'),
  description: I18n.t('react.cabinet.product.drag_file'),
  modalTitle: I18n.t('react.shared.button.file_upload'),
  modalDescription: I18n.t('react.cabinet.product.upload_file_description'),
  generalLabel: I18n.t('react.cabinet.product.upload_file'),
  loaderText: I18n.t('react.cabinet.product.uploading'),
  submitText: I18n.t('react.shared.button.save'),
  cancelText: I18n.t('react.shared.cancel'),
  backText: I18n.t('react.shared.back'),
  editFileText: I18n.t('react.cabinet.product.edit_image'),
  saveChangesText: I18n.t('react.shared.button.save_changes'),
  imagePreviewTitle: I18n.t('react.cabinet.product.image_preview_title'),
  imagePreviewDescription: I18n.t('react.cabinet.product.image_preview_description'),
  imageCropDescription: I18n.t('react.cabinet.product.image_crop_description'),
  removeFileText: I18n.t('react.shared.delete'),
  confirmText: I18n.t('react.cabinet.product.sure'),
  removeText: I18n.t('react.shared.delete'),
  uploadErrorActionText: I18n.t('react.cabinet.common.retry'),
  cropText: I18n.t('react.cabinet.product.crop_image'),
  pcUpload: I18n.t('react.cabinet.product.pc_upload'),
  libraryUpload: I18n.t('react.cabinet.product.library_upload'),
  tableActionText: I18n.t('react.cabinet.product.select_file'),
  tableHeaderName: I18n.t('react.cabinet.common.name'),
  tableHeaderSize: I18n.t('react.cabinet.common.size'),
  tableHeaderUploaded: I18n.t('react.cabinet.common.uploaded'),
  tableSearchText: I18n.t('shared.common.search'),
  paginationGoToBtn: I18n.t('react.common.go'),
  paginationItemsOf: I18n.t('react.common.pagination.itemsOf'),
  paginationLabel: I18n.t('react.common.pagination.show'),
  paginationGoToText: I18n.t('react.common.pagination.page'),
  dropboxUpload: I18n.t('react.cabinet.product.dropbox_upload'),
})
