import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

const BASE_URL = (sellerId) => `/shop/${sellerId}/mobile_app/document`

export const createMobileAppDocumentsApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: string, data: { sellerId: number }) =>
    GET<Response<{ content: string }>>(`${BASE_URL(data.sellerId)}/${id}`, data),
})

export type MobileAppDocumentsApi = ReturnType<typeof createMobileAppDocumentsApi>
