import { getElopageConfig } from 'utils/elopageConfig.utils'

const apiPath = getElopageConfig('apiPath')

export const BANK_ACCOUNTS_TABS = [
  {
    key: 'bankAccounts',
    link: '/admin/bank_accounts',
    label: 'Bank accounts',
  },
  {
    key: 'bankAccountLegitimations',
    link: '/admin/bank_account_legitimations',
    label: 'Bank account legitimation',
  },
  {
    key: 'oldBankAccountLegitimations',
    link: `${apiPath}/admin/bank_account_legitimations`,
    label: 'BAL OLD, Deprecated!',
    target: '_blank',
    absolutePath: true,
  },
]

export const BANK_ACCOUNT_INTERNAL_STATE = {
  init: 'init',
  change: 'change',
  accepted: 'accepted',
  rejected: 'rejected',
}

export const BANK_ACCOUNT_EXTERNAL_STATE = {
  notSent: 'not_sent',
  activated: 'activated',
  deactivated: 'deactivated',
  waiting: 'waiting',
  activatedByLw: 'activated_by_lw',
  deactivatedByLw: 'deactivated_by_lw',
}
