import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloForvardIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M12.8047 11.5703H17.8047V6.57031' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M2.5 14.3758C2.49945 12.8921 2.93902 11.4415 3.76309 10.2076C4.58717 8.97372 5.75871 8.01199 7.1295 7.44411C8.50029 6.87622 10.0087 6.72769 11.4639 7.01731C12.9192 7.30693 14.2558 8.02168 15.3047 9.07114L17.8047 11.5711'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </EloBaseIcon>
)
