export const ITEMS_PER_PAGE_OPTIONS = [
  {
    value: '10',
    label: 10,
  },
  {
    value: '20',
    label: 20,
  },
  {
    value: '50',
    label: 50,
  },
  {
    value: '100',
    label: 100,
  },
  {
    value: '200',
    label: 200,
  },
  {
    value: '500',
    label: 500,
  },
]

export const PAGINATION_KEYS = {
  per: 'per',
  page: 'page',
}

export const BILLING_ITEMS_PER_PAGE = '4'
export const NOTIFICATION_ITEMS_PER_PAGE = 7
export const DEFAULT_PAGE = 1
export const DEFAULT_ITEMS_PER_PAGE = 10
export const DEFAULT_SHOP_ITEMS_PER_PAGE = 12
export const DEFAULT_FULL_LIST = 500

export const PAGE_RANGE_DISPLAY = 3
export const PAGE_SMALL_RANGE_DISPLAY = 1
export const MARGIN_PAGES_DISPALAY = 1

export const MOBILE_SCREEN_WIDTH = 414

export const MONTHLY_INVOICES_TABLE_KEY = 'MONTHLY_INVOICES_TABLE_KEY'
export const BILLING_TRANSFERS_TABLE_KEY = 'BILLING_TRANSFERS_TABLE_KEY'
export const WEBHOOK_ENDPOINTS_TABLE_KEY = 'WEBHOOK_ENDPOINTS_TABLE_KEY'
export const AFFILIATE_REDIRECT_TABLE_KEY = 'AFFILIATE_REDIRECT_TABLE_KEY'
export const LOG_EMAILS_TABLE_KEY = 'LOG_EMAILS_TABLE_KEY'
export const LOG_INTEGRATIONS_TABLE_KEY = 'LOG_INTEGRATIONS_TABLE_KEY'
export const LOG_WEBHOOLS_TABLE_KEY = 'LOG_WEBHOOLS_TABLE_KEY'
export const LOG_TRACKING_TABLE_KEY = 'LOG_TRACKING_TABLE_KEY'
export const VIDEO_TABLE_KEY = 'VIDEO_TABLE_KEY'

export const CASHOUTS_ITEMS_PER_PAGE_OPTIONS = [
  {
    value: '5',
    label: 5,
  },
  ...ITEMS_PER_PAGE_OPTIONS,
]
