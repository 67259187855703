import { observable, action, makeObservable } from 'mobx'

import { SHOP_ITEM_PRODUCT_EXPANDS, SOCIAL_PROOF_BUBBLE_EXPANDS } from 'constants/products.constants'
import { Nullable } from 'types/helpers'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { ProductsApi, ProductsV2Api, createProductsApi, createProductsV2Api } from 'shop/api/products.api'

interface OptInQuestion {
  mandatory: boolean
  id: number
  fieldType: string
  label: string
}

interface Product {
  id?: number
  slug?: string
  pricingPlans?: { id: number }[]
  form?: string
  name?: string
  description?: string
  cover?: string
  cancellationTermId?: number
  b2bCancellationTermId?: number
  optIn?: {
    optInQuestions: OptInQuestion[]
  }
  additionalFee?: {
    title: string
  }
  cancelLink?: string
  private?: boolean
  showCover?: boolean
  shortDescription?: string
  paymentPageTemplateId?: number
  accessPassword?: string
  protectionUrl?: string
  payMethodsWithMessages?: Nullable<string[]>
}

export class ProductsStore {
  storeName = 'ProductsStore'
  root: ShopRootStore
  childApi: ProductsApi
  productsApi: ProductsV2Api

  constructor(root: ShopRootStore) {
    this.root = root
    this.childApi = createProductsApi(root?.apiClient ?? apiClient)
    this.productsApi = createProductsV2Api(root.apiClientV2)
    makeObservable(this)
  }

  @observable loading = true
  @observable pagin = {
    page: 1,
    per: 12,
    itemsCount: 0,
  }
  @observable product: Product = {
    pricingPlans: [],
  }
  @observable products = []
  @observable activeProductForm = null
  @observable activeProductGroupId = null
  @observable accessibility = null

  @action fetchProducts = async (
    username?: string,
    data?: {
      page?: number
      per?: number
      name?: string
      productGroupId?: Nullable<number>
      sortKey?: string
      sortDir?: string
      id?: number[]
    }
  ) => {
    this.loading = true
    const resp = await this.productsApi.fetchProducts(username || this.root.sellerStore.item.username, {
      filter: {
        productGroupId: data?.productGroupId || this.activeProductGroupId,
        form: this.activeProductForm,
        name: data?.name,
        ids: data?.id,
      },
      page: {
        size: data?.per || this.pagin.per,
        number: data?.page || this.pagin.page,
      },
      sortKey: data?.sortKey,
      sortDir: data?.sortDir,
    })

    if (resp.status === 200) {
      this.pagin = {
        ...this.pagin,
        itemsCount: resp.totalCount,
      }
      this.products = resp.data
    }
    this.loading = false
    return resp
    // call and set team member list
    // this.teamMember = data.teamMember || {}
  }

  @action fetchProduct = async (
    slug?: string,
    history?: any,
    isPreview?: boolean,
    username?: string,
    withoutLimit?: boolean
  ) => {
    // TODO: ts - rewrite history as callback / do not use history in store
    const data = isPreview ? {} : { forShop: true }
    const requestData = {
      ...data,
      expand: [...SHOP_ITEM_PRODUCT_EXPANDS, ...SOCIAL_PROOF_BUBBLE_EXPANDS],
      ...(withoutLimit && !isPreview && { withoutLimit: true }),
    }
    this.loading = true
    const resp = await this.childApi.fetchProduct(username || this.root.sellerStore.item.username, slug, requestData)
    if (resp.success) {
      this.product = resp.data
      this.accessibility = !resp.data.accessPassword
    } else if (history) {
      const { keepRoutesOnRoot, username } = this.root.sellerStore.item

      if (keepRoutesOnRoot) {
        // TODO: SSR - remove history and match router objects from store
        history.push('/')
      } else {
        history.push(`/s/${username}`)
      }
    }
    this.loading = false
    return resp
  }

  @action setGroupId = (groupId: string, history?: any) => {
    // TODO: ts - rewrite history as callback / do not use history in store
    const shouldPushGroupId = this.activeProductGroupId !== groupId

    if (shouldPushGroupId) {
      if (history) {
        const searchGroupId = JSON.parse(groupId) ? `?group_id=${groupId}` : ''
        history.push({ search: searchGroupId })
      }
      this.activeProductGroupId = JSON.parse(groupId)
      this.pagin.page = 1
    }
  }

  @action setPage = (page: number): void => {
    this.pagin = {
      ...this.pagin,
      page,
    }
    this.fetchProducts()
  }

  @action hydrate = (key, data) => {
    if (key === 'product') {
      this.product = data
      this.accessibility = !data.accessPassword
      this.loading = false
    }
  }
}
