import { action, makeObservable, observable } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { StripeClient, StripeElement } from 'shop/types/stripe'

import SharedStore from 'shared/stores/shared.store'

interface DigitalPaymentMethods {
  applePay: boolean
  googlePay: boolean
}

export class PurchaseMethodsStore extends SharedStore<any> {
  storeName = 'PurchaseMethodsStore'
  root: ShopRootStore

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    makeObservable(this)
  }

  @observable stripeClient = null
  @observable stripeP24 = null
  @observable stripeIdeal = null
  @observable stripeCard = null
  @observable stripeDigitalMethod = null
  @observable stripeAuthentificationLoading = false
  @observable stripePaymentRequest = null
  @observable stripeDigitalPaymentMethods: DigitalPaymentMethods
  @observable paymethods: Record<string, never> = {}
  @observable fraudSessionIdentifier = null
  @observable stripeCardValid = false
  @observable digitalPaymentAllowed = false
  @observable shouldResetIntent = false
  @observable buyBtnDisabled = false
  @observable stripeElements = null

  @action setStripeElements = (value: StripeElement | string) => (this.stripeElements = value)
  @action setStripeCardValidity = (isValid: boolean) => (this.stripeCardValid = isValid)
  @action updatePaymethodsData = (paymethods) => (this.paymethods = paymethods)
  @action setStripeP24 = (value: StripeElement | string) => (this.stripeP24 = value)
  @action setStripeIdeal = (value: StripeElement | string) => (this.stripeIdeal = value)
  @action setStripeCard = (value) => (this.stripeCard = value)
  @action setStripeClient = (value: StripeClient) => (this.stripeClient = value)
  @action setStripeDigitalMethod = (value: StripeClient) => (this.stripeDigitalMethod = value)
  @action stripeAuthentificationLoadingToggle = (value) => (this.stripeAuthentificationLoading = value)
  @action setStripePaymentRequest = (value: StripeElement) => (this.stripePaymentRequest = value)
  @action setStripeDigitalPaymentMethods = (value) => (this.stripeDigitalPaymentMethods = value)
  @action setFraudSessionIdentifier = (value) => (this.fraudSessionIdentifier = value)
  @action setDigitalPaymentAllowed = (value: boolean) => (this.digitalPaymentAllowed = value)
  @action setBuyBtnDisabling = (value: boolean) => (this.buyBtnDisabled = value)
}
