import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloUnderlineIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M3.125 16.875H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M5 4.375V9.375C5 10.7011 5.52678 11.9729 6.46447 12.9105C7.40215 13.8482 8.67392 14.375 10 14.375C11.3261 14.375 12.5979 13.8482 13.5355 12.9105C14.4732 11.9729 15 10.7011 15 9.375V4.375'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </EloBaseIcon>
)
