import { getElopageConfig } from 'utils/elopageConfig.utils'
import { denyUrls, ignoreErrors, SENTRY_TRACES_SAMPLE_RATE } from 'libs/common/sentry'

export const config = {
  environment: getElopageConfig('env'),
  dsn: getElopageConfig('sentryConfig').publicDsn,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  ignoreErrors,
  denyUrls,
}
