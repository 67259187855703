import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDocumentDetailsIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M16.475 17.75H4.775C4.60261 17.75 4.43728 17.6798 4.31538 17.5547C4.19348 17.4297 4.125 17.2601 4.125 17.0833V2.41667C4.125 2.23986 4.19348 2.07029 4.31538 1.94526C4.43728 1.82024 4.60261 1.75 4.775 1.75H12.575L17.125 6.41667V17.0833C17.125 17.2601 17.0565 17.4297 16.9346 17.5547C16.8127 17.6798 16.6474 17.75 16.475 17.75Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0625 1.75V6.5625H16.875'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3575 13.4204L13.7325 14.7954'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6562 14.125C11.9852 14.125 13.0625 13.0477 13.0625 11.7188C13.0625 10.3898 11.9852 9.3125 10.6562 9.3125C9.32731 9.3125 8.25 10.3898 8.25 11.7188C8.25 13.0477 9.32731 14.125 10.6562 14.125Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
