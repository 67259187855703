import { userSessionCookieKey } from 'libs/common/cookies'

export const withUserSessionIdInterceptor = (userSessionId: string) => (request) => {
  if (userSessionId) {
    request.headers[userSessionCookieKey] = userSessionId
  }

  return request
}

export const withAuthorizationInterceptor = (accessToken: string) => (request) => {
  if (accessToken) {
    request.headers['authorization'] = accessToken
  }

  return request
}

export function requestTimeInterceptor(config) {
  config.headers['request-startTime'] = process.hrtime()
  return config
}
