// eslint-disable-next-line
import { GET, POST } from 'utils/requests.utils'
import { HTTPResponse, Response } from 'types/responses'

const BASE_URL = '/app/user'

interface ChangeEmail {
  user: {
    confirmed: boolean
    withPassword: boolean
    confirmationToken: string
  }
}

export const signUp = (data) =>
  POST(BASE_URL, {
    ...data,
    contentType: 'application/json;charset=utf-8',
  })

export const checkEmailUniqueness = (email) => GET(`${BASE_URL}/check_email`, { email })

export const checkUsernameUniqueness = (username) => GET(`${BASE_URL}/check_username`, { username })

export const sendMagicLink = (profileType: string, email: string, seller: string) =>
  POST<HTTPResponse>(`${BASE_URL}/send_magic_link`, {
    profileType,
    email,
    seller,
  })

export const sendMagicLinkToCreateUsername = (data) => POST(`${BASE_URL}/send_magic_link`, data)

export const acceptChangeEmail = (data: { token: string }) =>
  POST<Response<ChangeEmail>>(`${BASE_URL}/accept_change_email`, data)
