import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTextLinkIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M7.64377 4.46291L8.88127 3.22541C9.40522 2.74786 10.093 2.49056 10.8017 2.50696C11.5105 2.52337 12.1856 2.81223 12.6869 3.31351C13.1882 3.8148 13.4771 4.48995 13.4935 5.19868C13.5099 5.90741 13.2526 6.59522 12.775 7.11916L11.0063 8.88166C10.7514 9.13753 10.4484 9.34055 10.1149 9.47908C9.78133 9.61761 9.4237 9.68892 9.06252 9.68892C8.70135 9.68892 8.34372 9.61761 8.01017 9.47908C7.67661 9.34055 7.37369 9.13753 7.11877 8.88166'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M8.35629 11.5369L7.11879 12.7744C6.59485 13.252 5.90705 13.5093 5.19832 13.4929C4.48959 13.4765 3.81443 13.1876 3.31315 12.6863C2.81186 12.185 2.52301 11.5099 2.5066 10.8011C2.49019 10.0924 2.74749 9.40462 3.22504 8.88067L4.99379 7.11817C5.24871 6.8623 5.55163 6.65928 5.88518 6.52075C6.21874 6.38222 6.57636 6.31091 6.93754 6.31091C7.29872 6.31091 7.65634 6.38222 7.9899 6.52075C8.32346 6.65928 8.62638 6.8623 8.88129 7.11817'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </EloBaseIcon>
)
