import {
  PUBLISHER_AFFILIATE_PROGRAMS_PATH,
  PUBLISHER_AFFILIATE_NODES_PATH,
  PUBLISHER_STATISTICS_OVERVIEW_PATH,
  PUBLISHER_STATISTICS_PROGRAMS_PATH,
  PUBLISHER_STATISTICS_PRODUCTS_PATH,
  PUBLISHER_STATISTICS_SELLERS_PATH,
  PUBLISHER_STATISTICS_AFFILIATE_PATH,
} from 'constants/routes/publisher.constants'

export const AFFILIATE_PROGRAMS_TABS = [
  {
    key: 'affiliatePrograms',
    link: '/cabinet/affiliate_programs',
    label: I18n.t('react.shared.affiliateProgram.programs'),
  },
  {
    key: 'publishers',
    link: '/cabinet/affiliate_nodes',
    label: I18n.t('react.shared.publisher.publishers'),
  },
  {
    key: 'statistic',
    link: '/cabinet/affiliate_statistics',
    label: I18n.t('shared.common.statistics'),
  },
]

export const PUBLISHER_AFFILIATE_NODES_TABS = [
  {
    key: 'affiliatePrograms',
    link: PUBLISHER_AFFILIATE_PROGRAMS_PATH,
    label: I18n.t('react.shared.affiliateProgram.programs'),
  },
  {
    key: 'publishers',
    link: PUBLISHER_AFFILIATE_NODES_PATH,
    label: I18n.t('react.shared.publisher.publishers'),
  },
]

export const PROGRAMS_STATISTICS_TABS_KEYS = {
  overview: 'overview',
  programs: 'programs',
  publishers: 'publishers',
  products: 'products',
}

export const PROGRAMS_STATISTICS_TABS = [
  {
    key: PROGRAMS_STATISTICS_TABS_KEYS.overview,
    label: I18n.t('shared.common.overview'),
  },
  {
    key: PROGRAMS_STATISTICS_TABS_KEYS.programs,
    label: I18n.t('react.shared.affiliateProgram.programs'),
  },
  {
    key: PROGRAMS_STATISTICS_TABS_KEYS.publishers,
    label: I18n.t('react.shared.publisher.publishers'),
  },
  {
    key: PROGRAMS_STATISTICS_TABS_KEYS.products,
    label: I18n.t('shared.common.products'),
  },
]

export const PUBLISHER_STATISTICS_TABS = [
  {
    key: 'overview',
    link: PUBLISHER_STATISTICS_OVERVIEW_PATH,
    label: I18n.t('shared.common.overview'),
  },
  {
    key: 'programs',
    link: PUBLISHER_STATISTICS_PROGRAMS_PATH,
    label: I18n.t('react.shared.affiliateProgram.programs'),
  },
  {
    key: 'products',
    link: PUBLISHER_STATISTICS_PRODUCTS_PATH,
    label: I18n.t('shared.common.products'),
  },
  {
    key: 'sellers',
    link: PUBLISHER_STATISTICS_SELLERS_PATH,
    label: I18n.t('react.shared.affiliateProgram.sellers'),
  },
  {
    key: 'affiliate',
    link: PUBLISHER_STATISTICS_AFFILIATE_PATH,
    label: I18n.t('react.shared.affiliates'),
  },
]

export const PUBLISHER_STATISTICS_TABS_WITHOUT_DASHBOARD = [
  {
    key: 'overview',
    link: PUBLISHER_STATISTICS_OVERVIEW_PATH,
    label: I18n.t('shared.common.overview'),
  },
  {
    key: 'programs',
    link: PUBLISHER_STATISTICS_PROGRAMS_PATH,
    label: I18n.t('react.shared.affiliateProgram.programs'),
  },
  {
    key: 'products',
    link: PUBLISHER_STATISTICS_PRODUCTS_PATH,
    label: I18n.t('shared.common.products'),
  },
  {
    key: 'sellers',
    link: PUBLISHER_STATISTICS_SELLERS_PATH,
    label: I18n.t('react.shared.affiliateProgram.sellers'),
  },
]

export const AFFILIATE_PROGRAM_PARAMS = {
  name: 'name',
  cookiesExpire: 'cookiesExpire',
  form: 'form',
  lifetime: 'lifetime',
  bonus: 'bonus',
  disableLandingPage: 'disableLandingPage',
  url: 'redirectionUrl',
  commission: 'commission',
  landingBody: 'landingBody',
  provisionFromBrutto: 'provisionFromBrutto',
  someProductsCommissions: 'affiliateProgramCommissions',
  allowEmbedForms: 'allowEmbedForms',
  embedFormsAllowed: 'embedFormsAllowed',
  affiliateRedirectionsAllowed: 'affiliateRedirectionsAllowed',
  forwardUrlParams: 'forwardUrlParams',
  lifetimeDuration: 'lifetimeDuration',
  affiliateMarketplaceProduct: 'affiliateMarketplaceProduct',
}

export const AFFILIATE_PROGRAM_FORMS = {
  all: 'all_products',
  some: 'with_products',
}

export const STAGE_TYPE = {
  one: 'single',
  multiple: 'multiple',
}

export const PUBLISHER_STATUS = {
  active: 'accepted',
  canceled: 'canceled',
  requested: 'requested',
  rejected: 'rejected',
}

export const DEFAULT_BONUS = 0

export const MIN_PERCENTAGE = 0
export const MAX_PERCENTAGE = 100

export const MIN_COOKIES_EXPIRE = 15

export const DEFAULT_MULTIPLE_COMMISION = [0, 0, 0, 0, 0]

export const DEFAULT_COMMISION = [0]

export const STAGES_NUMBER = 5

export const PROFILE_VIEW = {
  yes: 'YES',
  no: 'NO',
}

export enum STATISTIC_OVERVIEW_TABLE_TYPE {
  overview = 'overview',
  details = 'details',
}

export const DEFAULT_LANDING_TEXT = `<div class="text-center">

	<h2 class="margin-top-0px">${I18n.t('react.shared.affiliate_landing_title', {
    program: '%{program}',
  })}</h2>

  <p>
    ${I18n.t('react.shared.affiliate_landing_text', {
      commission: '%{commission}',
      cookies: '%{cookies}',
    })}
  </p>

	<p>%{buttons}</p>
</div>
`

export const COMMISION_DURATION_TYPES = {
  none: 'none',
  lifetime: 'lifetime',
  fixed: 'fixed',
}

export const COMMISION_DURATION_OPTIONS = [
  {
    value: COMMISION_DURATION_TYPES.none,
    label: I18n.t('react.shared.affiliateProgram.commission_duration_none'),
  },
  {
    value: COMMISION_DURATION_TYPES.lifetime,
    label: I18n.t('react.shared.affiliateProgram.lifetime'),
  },
  {
    value: COMMISION_DURATION_TYPES.fixed,
    label: I18n.t('react.shared.affiliateProgram.commission_fixed_duration'),
  },
]

export const PUBLISHER_AFFILIATE_PROGRAM_CSV_PARAMS = [
  {
    label: I18n.t('shared.common.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('shared.common.program'),
    sortingKey: 'affiliate_program_id',
  },
  {
    label: I18n.t('shared.common.invited_at'),
    sortingKey: 'created_at',
  },
  {
    label: I18n.t('shared.common.status'),
    sortingKey: 'state',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.fromLandingPage'),
    sortingKey: 'from_landing_page',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.bonus'),
    sortingKey: 'bonus',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stages'),
    sortingKey: 'type',
  },
]

export const SELLER_AFFILIATE_PROGRAM_CSV_PARAMS = [
  {
    label: I18n.t('shared.common.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('shared.common.name'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('shared.common.created'),
    sortingKey: 'created_at',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.cookiesExpire'),
    sortingKey: 'cookies_expire',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.commission_duration'),
    sortingKey: 'lifetime',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.bonus'),
    sortingKey: 'bonus',
  },
  {
    label: I18n.t('react.cabinet.common.commission'),
    sortingKey: 'commission',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stages'),
    sortingKey: 'type',
  },
]

export const PRODUCT_LIST_TABLE_HEAD_SINGLE_PROGRAM = [
  {
    label: I18n.t('react.cabinet.product.list.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.product.list.product'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('react.cabinet.common.price'),
  },
  {
    label: I18n.t('react.shared.affiliate_program.marketplace.ready_to_sell'),
    key: 'readyToSell',
  },
  {
    label: I18n.t('react.cabinet.common.commission'),
  },
]

export const PRODUCT_LIST_TABLE_HEAD_MULTILEVEL_PROGRAM = [
  {
    label: I18n.t('react.cabinet.product.list.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.product.list.product'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('react.cabinet.common.price'),
  },
  {
    label: I18n.t('react.shared.affiliate_program.marketplace.ready_to_sell'),
    key: 'readyToSell',
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stage_without_percent', { stageNumber: 1 }),
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stage_without_percent', { stageNumber: 2 }),
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stage_without_percent', { stageNumber: 3 }),
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stage_without_percent', { stageNumber: 4 }),
  },
  {
    label: I18n.t('react.shared.affiliateProgram.stage_without_percent', { stageNumber: 5 }),
  },
]

export const AFFILIATE_PROGRAM_NAME_MAX_LENGTH = 180

export const AFFILITE_MARKETPLACE_DEFAULT_REDIRECTION = 'payment_page'

export const AFFILIATE_MARKETPLACE_REDIRECTIONS = {
  productPage: 'product_page',
  productPreviewLink: 'product_preview_link',
  checkoutPage: 'payment_page',
}

export const MARKETPLACE_PUBLISH_STATUS = {
  published: 'published',
  publishPending: 'publish_pending',
  inReview: 'in_review',
  rejected: 'rejected',
  revoked: 'revoked',
  unpublished: 'unpublished',
  adminUnpublished: 'admin_unpublished',
}

export const AFFILIATE_MARKETPLACE_PROGRAMS_TABLE_HEAD = [
  {
    label: I18n.t('react.cabinet.product.list.id'),
    sortingKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.common.name'),
    sortingKey: 'name',
  },
  {
    label: I18n.t('shared.common.type'),
  },
  {
    label: I18n.t('react.shared.common.cookie'),
  },
  {
    label: I18n.t('shared.common.products'),
  },
  {
    label: I18n.t('react.shared.affiliate_marketplace.affiliates'),
  },
  {
    label: I18n.t('react.cabinet.common.commission'),
  },
]
