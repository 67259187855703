import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTextIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.25 3.8125C2.25 3.39829 2.58579 3.0625 3 3.0625H10H17C17.4142 3.0625 17.75 3.39829 17.75 3.8125V5.8125C17.75 6.22671 17.4142 6.5625 17 6.5625C16.5858 6.5625 16.25 6.22671 16.25 5.8125V4.5625H10.75V15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H7C6.58579 16.75 6.25 16.4142 6.25 16C6.25 15.5858 6.58579 15.25 7 15.25H9.25V4.5625H3.75V5.8125C3.75 6.22671 3.41421 6.5625 3 6.5625C2.58579 6.5625 2.25 6.22671 2.25 5.8125V3.8125Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
