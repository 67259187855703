import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

import { CountryAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type PaymentCountry = FromSwagger<CountryAttributes>

const BASE_URL = (username) => `sellers/${username}/payment_countries`

export const createPaymentCountriesApi = ({ GET }: ApiClientV2) => ({
  fetchPaymentCountries: (username: string) => GET<PaymentCountry[]>(BASE_URL(username)),
})

export type PaymentCountriesApi = ReturnType<typeof createPaymentCountriesApi>
