import { ALLOWED_LANGUAGES } from 'constants/general.constants'
import { POSITIONS } from './general.constants'

export const NUMBER_FIELD_MIN = 1
export const NUMBER_FIELD_MAX = 1000000
export const NUMBER_FIELD_STEP = 1

export const PRICE_PLACEHOLDER = '00.00'
export const PERCENT_PLACEHOLDER = '00.00 %'
export const MIN_PRICE = 0
export const MIN_FLOAT_PRICE = 1
export const MIN_FLOAT = 0.33
export const FLOAT_NUMBER = 2

export const MIN_NUMBER_OF_INSTALLMENTS = 2

export const TEXT_FIELD_AUTOCOMPLETE = {
  on: 'on',
  off: 'new-password', // https://stackoverflow.com/questions/37503656/react-doesnt-render-autocomplete-off
}

export const TEXT_FIELD_TYPE = {
  email: 'email',
  password: 'password',
  text: 'text',
}

export const TEXT_EDITOR_TYPE = {
  simple: 'simple',
  mail: 'mail',
  rich: 'rich',
}

export const AUTOCOMPLETE_OFF = 'off'

export const TEXT_FIELD_MAX_LENGTH = 300
export const TEXT_FIELD_MIN = 2

export const SLUG_FIELD_MAX_LENGTH = 300

export const COLOR_PICKER_HASH = '#'
export const COLOR_PICKER_DEFAULT_VALUE = '#000000'
export const COLOR_PICKER_MAX_LENGTH = COLOR_PICKER_DEFAULT_VALUE.length
export const COLOR_PICKER_DEFAULT_COLORS_PRESET = [
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF',
]

export const LANGUAGE_SELECT_OPTIONS = Object.values(ALLOWED_LANGUAGES)

export const SORT_BY_SELECT_OPTIONS = [
  {
    label: I18n.t('react.cabinet.common.position'),
    value: 'position',
  },
  {
    label: I18n.t('react.cabinet.product.created'),
    value: 'created_at',
  },
]

export const SORTING_ORDER = [
  {
    label: I18n.t('react.shared.sorting_order_asc'),
    value: 'asc',
  },
  {
    label: I18n.t('react.shared.sorting_order_desc'),
    value: 'desc',
  },
]

export const FONT_STYLE_SELECT_OPTIONS = [
  {
    label: I18n.t('react.cabinet.common.regular'),
    value: 'normal',
  },
  {
    label: I18n.t('react.cabinet.common.italic'),
    value: 'italic',
  },
  {
    label: I18n.t('react.cabinet.common.bold'),
    value: 'bold',
  },
  {
    label: `${I18n.t('react.cabinet.common.bold')} ${I18n.t('react.cabinet.common.italic')}`,
    value: 'boldItalic',
  },
  {
    label: I18n.t('react.cabinet.common.underline'),
    value: 'underline',
  },
]

export const FONT_STYLE_UNDERLINE = 'underline'

export const FONT_STYLE_SELECT_VALUES = {
  regular: 'normal',
  italic: 'italic',
  bold: 'bold',
  boldItalic: 'boldItalic',
}

export const SELECT_WITH_LOAD_DELAY = 500

export const COPIED_TEXT_FIELD_TYPE = {
  oneLine: 'oneLine',
  multiLines: 'multiLines',
}

export const HORIZONTAL_ALIGNMENT_SELECT_OPTIONS = [
  {
    label: I18n.t('react.shared.position.left'),
    value: POSITIONS.left,
  },
  {
    label: I18n.t('react.shared.position.center'),
    value: POSITIONS.center,
  },
  {
    label: I18n.t('react.shared.position.right'),
    value: POSITIONS.right,
  },
  {
    label: I18n.t('react.shared.position.dynamic'),
    value: POSITIONS.dynamic,
  },
]
