import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface CancellationTermReq {
  username: string
}

interface CancellationTerm {
  checkboxVisible: boolean
  id: number
  value: string
}

const BASE_URL = (username) => `/shop/${username}/cancellation_terms`

export const createCancellationTermsApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: number, data: CancellationTermReq) =>
    GET<Response<CancellationTerm>>(`${BASE_URL(data.username)}/${id}`, data),
})

export type CancellationTermsApi = ReturnType<typeof createCancellationTermsApi>
