import { notify } from 'libs/common/notify'
import { createStripeClient } from '@elo-kit/utils/stripe.utils'

/**
 * Makes Sofort transaction
 *
 * @param {Object} stripeClient Stripe Client Instance
 * @param {String} redirectLink Link when user will be redirected after payment
 * @param {String} clientSecret Client Secret
 * @param {Object} payer Payer info (full name, email)
 *
 * @returns void
 */
export const handleSofortTransaction = (
  stripeClient,
  redirectLink,
  clientSecret,
  payer = {
    countryCode: '',
    billingDetails: {},
  }
) => {
  if (stripeClient && clientSecret) {
    return stripeClient.confirmSofortPayment(clientSecret, {
      payment_method: {
        sofort: {
          country: (payer.countryCode || '').toUpperCase(),
        },
        ...payer.billingDetails,
      },
      return_url: redirectLink,
    })
  }
}

/**
 * Makes Stripe card transaction
 *
 * @param {Object} stripeClient Stripe Client Instance
 * @param {String} redirectLink Link when user will be redirected after payment
 * @param {String} clientSecret Client Secret
 * @param {Object} payer Payer info (full name, email)
 * @param {Object} stripeCard Stripe card element
 * @param {String} id payment_method id for recurring payments
 *
 * @returns void
 */
export const handleStripeCardTransaction = (
  stripeClient,
  { id, stripeCard = {} } = {},
  clientSecret,
  { fullName, email } = {},
  redirectLink
) => {
  if (stripeClient && clientSecret) {
    const billingDetails = {}
    if (fullName) {
      billingDetails.name = fullName
    }
    if (email) {
      billingDetails.email = email
    }

    const paymentMethodData = id || {
      card: stripeCard,
      billing_details: billingDetails,
    }
    return stripeClient.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodData,
      return_url: redirectLink,
    })
  }
}

/**
 * Makes Stripe Apple/Google Pay transaction
 *
 * @param {Object} stripeClient Stripe Client Instance
 * @param {StripeElement} paymentRequest Stripe Payment Request Instance
 * @param {Function} handleOrder API call to manage order
 * @param {Function} handleRedirect Function which makes redirection
 * @param {Function} onCancel Function to handle pop up cancelation
 *
 * @returns void
 */
export const handleStripeDigitalTransaction = (
  stripeClient,
  paymentRequest,
  clientSecret,
  handleRedirect,
  handleClose
) => {
  if (stripeClient && clientSecret) {
    paymentRequest.on('paymentmethod', async (ev) => {
      const { paymentIntent, error: confirmError } = await stripeClient.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      )

      if (confirmError) {
        notify('error', confirmError.message)
      } else if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_source_action') {
        // Stripe.js handles the rest of the payment flow.
        const { error } = await stripeClient.confirmCardPayment(clientSecret)
        if (error) {
          notify('error', error.message)
        } else {
          handleRedirect()
        }
      } else {
        handleRedirect()
      }
    })
    if (handleClose) {
      paymentRequest.on('cancel', handleClose)
    }
  }
}

/**
 * Check whether Stripe is injected or not
 *
 * @returns {Boolean} true if injected
 */
export const isStripeInjected = () => window && window.Stripe

/**
 * Injects Stripe Script
 *
 * @param {Function} onLoadHandler On Load Handler
 *
 * @returns void
 */
export const injectStripeScript = (onLoadHandler = () => {}) => {
  /* Do nothing in case it's already injected */
  if (isStripeInjected()) {
    return
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://js.stripe.com/v3/'
  document.body.appendChild(script)

  script.onload = () => {
    onLoadHandler()
  }
}

const handleTranscationResponse = (data, handleSuccess, handleError) => {
  const { error, paymentIntent } = data
  if (paymentIntent) {
    handleSuccess()
  } else {
    error.message && notify('error', error.message)
    handleError()
  }
}

export const handleStripeCardRecuringPayment = (
  clientSecret,
  paymentMethod,
  stripePubKey,
  handleSuccess,
  handleError
) => {
  const handleTransaction = () => {
    const stripeClient = createStripeClient(stripePubKey)
    handleStripeCardTransaction(stripeClient, { id: paymentMethod }, clientSecret).then((data) =>
      handleTranscationResponse(data, handleSuccess, handleError)
    )
  }

  if (!isStripeInjected()) {
    injectStripeScript(handleTransaction)
  } else {
    handleTransaction()
  }
}

export const handleStripeSofortRecuringPayment = (
  clientSecret,
  stripePubKey,
  redirectLink,
  payerInfo,
  handleSuccess,
  handleError
) => {
  const handleTransaction = () => {
    const stripeClient = createStripeClient(stripePubKey)
    handleSofortTransaction(stripeClient, redirectLink, clientSecret, payerInfo).then((data) =>
      handleTranscationResponse(data, handleSuccess, handleError)
    )
  }

  if (!isStripeInjected()) {
    injectStripeScript(handleTransaction)
  } else {
    handleTransaction()
  }
}
