import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCheckCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M2.625 10C2.625 5.9269 5.9269 2.625 10 2.625C14.0731 2.625 17.375 5.9269 17.375 10C17.375 14.0731 14.0731 17.375 10 17.375C5.9269 17.375 2.625 14.0731 2.625 10ZM10 1.375C5.23654 1.375 1.375 5.23654 1.375 10C1.375 14.7635 5.23654 18.625 10 18.625C14.7635 18.625 18.625 14.7635 18.625 10C18.625 5.23654 14.7635 1.375 10 1.375ZM13.9337 7.95393C14.1823 7.7144 14.1896 7.31874 13.95 7.0702C13.7105 6.82166 13.3148 6.81435 13.0663 7.05388L8.83105 11.1355L6.93409 9.30425C6.68575 9.06451 6.29009 9.07148 6.05034 9.31982C5.8106 9.56816 5.81757 9.96382 6.06591 10.2036L8.39659 12.4536C8.63862 12.6872 9.02216 12.6874 9.26439 12.4539L13.9337 7.95393Z'
      fill='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
