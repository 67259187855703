import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBellCrossedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M3.74561 3.14014L16.1436 17.2197'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.46497 15.2998V15.8998C7.46497 16.5363 7.72836 17.1468 8.1972 17.5969C8.66604 18.0469 9.30192 18.2998 9.96497 18.2998C10.628 18.2998 11.2639 18.0469 11.7327 17.5969C12.2016 17.1468 12.465 16.5363 12.465 15.8998V15.2998'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.4546 15.2997H3.82328C3.71465 15.2993 3.60803 15.2694 3.5141 15.2131C3.42017 15.1567 3.34222 15.0759 3.28807 14.9787C3.23392 14.8815 3.20547 14.7713 3.20557 14.6591C3.20566 14.547 3.2343 14.4368 3.28862 14.3397C3.80004 13.4277 4.38119 11.7638 4.38119 8.89986V8.25988C4.38056 7.08902 4.72367 5.94545 5.36529 4.97998'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.19373 3.2518C8.04201 2.74887 9.00512 2.48924 9.98328 2.49982C13.0518 2.52382 15.5081 5.15574 15.5081 8.33164V8.89962C15.5081 11.0356 15.8336 12.4995 16.2055 13.4835'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
