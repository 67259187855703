import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloFilesIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M13.4375 18.25H3.8125C3.63016 18.25 3.4553 18.1776 3.32636 18.0486C3.19743 17.9197 3.125 17.7448 3.125 17.5625V5.1875C3.125 5.00516 3.19743 4.8303 3.32636 4.70136C3.4553 4.57243 3.63016 4.5 3.8125 4.5H10.6875L14.125 7.9375V17.5625C14.125 17.7448 14.0526 17.9197 13.9236 18.0486C13.7947 18.1776 13.6198 18.25 13.4375 18.25Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.875 4.5V2.4375C5.875 2.25516 5.94743 2.0803 6.07636 1.95136C6.2053 1.82243 6.38016 1.75 6.5625 1.75H13.4375L16.875 5.1875V14.8125C16.875 14.9948 16.8026 15.1697 16.6736 15.2986C16.5447 15.4276 16.3698 15.5 16.1875 15.5H14.125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5625 12.0625H10.6875'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5625 14.8125H10.6875'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
