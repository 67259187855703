import { computed, makeObservable } from 'mobx'

import { getNestedChildren } from 'utils/product.utils'
import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { LessonsApi, createLessonsApi } from 'shop/api/lessons.api'

export class LessonsStore extends SharedStore<any> {
  storeName = 'LessonsStore'
  declare childApi: LessonsApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createLessonsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }

  @computed get lessonsTree() {
    return getNestedChildren(this.list, null)
  }
}
