import React from 'react'
import moment from 'moment'

import {
  CAN_NOT_SELL_TEXT,
  PRODUCT_TYPE_APP_NAMES,
  PRODUCT_TYPE_IDS,
  PRODUCT_TYPE_REQUIRED_APPS,
  getShopProductTypeOptions,
} from 'constants/productsShared.constants'
import { getBundleBanDate } from 'utils/elopageConfig.utils'
import { COUPON_VALUE_FORM } from 'constants/coupons.constants'
import { NUMBER_REGEX, PRODUCT_KEY_REGEX } from 'constants/regex.constants'
import { marketingWebHosts } from 'libs/configs'

import { ascendingSort } from './helpers.utils'
import { snakeCaseToCamelCase } from './nameStyle.utils'

export const getNestedChildren = (models, parentId) => {
  const nestedTreeStructure = []

  for (let i = 0; i < models.length; i++) {
    // for-loop for perf reasons, huge difference in ie11
    const model = models[i]

    if (model.parentId == parentId) {
      model.children = getNestedChildren(models, model.id)
      nestedTreeStructure.push(model)
    }
  }

  return ascendingSort(nestedTreeStructure)
}

export const getCanSellProduct = (product, seller) => product.canBeSoldViaShop && (product.free || seller.canSell)

export const getCanNotSellTooltip = (product, seller) => {
  const {
    soldCount = 0,
    reservedCount = 0,
    limit = 0,
    free,
    form,
    anyPaymentMethodActive,
    hasDigitals,
    productAddons = [],
    canSell,
    activeTicketsCount,
    isSoldOut,
    reviewNotPassed,
    reviewState,
    published,
    archived,
    accessPassword,
  } = product || {}

  const noActivePayMethod = !free && !anyPaymentMethodActive
  const noDownload = form === PRODUCT_TYPE_IDS.download && !hasDigitals
  const noPayMethods = !free && !seller.paymentAccountDone
  const isBundle = PRODUCT_TYPE_IDS.bundle === form
  const isValidBundle = !isBundle || productAddons.length > 0

  const isLimitReached = Number(soldCount) + Number(reservedCount) >= Number(limit)

  const noActiveEvent = form === PRODUCT_TYPE_IDS.eventTickets && activeTicketsCount === 0

  const tooltipMessages = []

  const textLabels = {
    no_active_pay_method: I18n.t('react.cabinet.product.list.no_active_pay_method'),
    no_pricing_plans: I18n.t('react.cabinet.product.list.no_pricing_plans'),
    limit_reached: I18n.t('react.cabinet.product.list.limit_reached'),
    rejected: I18n.t('react.cabinet.product.list.rejected'),
    not_valid: I18n.t('react.cabinet.product.list.not_valid'),
    on_review: I18n.t('react.cabinet.product.list.on_review'),
    not_reviewed: I18n.t('react.cabinet.product.list.not_reviewed'),
    not_active: I18n.t('react.cabinet.product.list.not_active'),
    no_active_events: I18n.t('react.cabinet.product.list.no_active_events'),
    no_digitals: I18n.t('react.cabinet.product.list.no_digitals'),
    finish_registration: I18n.t('react.cabinet.product.list.finish_registration', {
      href: '/cabinet/profile/edit?seller_profile_edit=true',
    }),
    no_products: I18n.t('react.cabinet.product.list.no_products'),
    is_archived: I18n.t('react.cabinet.product.list.is_archived'),
    has_access_password: I18n.t('react.cabinet.product.list.has_access_password'),
  }

  if (noActivePayMethod || noPayMethods) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.noActivePayMethod })
  }

  if (!canSell) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.noPricingPlans })
  }

  if (isSoldOut && isLimitReached) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.limitReached })
  }

  if (seller.powerSeller && reviewNotPassed) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT[snakeCaseToCamelCase(reviewState)] })
  }

  if (!published) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.notActive })
  }

  if (noActiveEvent) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.noActiveEvents })
  }

  if (noDownload) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.noDigitals })
  }

  if (!isValidBundle) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.noProducts })
  }

  if (!seller.canSell && !free) {
    tooltipMessages.push({
      text: CAN_NOT_SELL_TEXT.finishRegistration,
      link: '/cabinet/profile/edit?seller_profile_edit=true',
    })
  }

  if (archived) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.archived })
  }

  if (accessPassword) {
    tooltipMessages.push({ text: CAN_NOT_SELL_TEXT.hasAccessPassword })
  }

  return tooltipMessages.map((message, i) => (
    <div key={i}>
      <span
        dangerouslySetInnerHTML={{
          __html: textLabels[message.text],
        }}
      />
    </div>
  ))
}

export const shouldBookApp = (sellerApps, form) => {
  const appRequired = PRODUCT_TYPE_REQUIRED_APPS[form]
  return appRequired && sellerApps.indexOf(PRODUCT_TYPE_APP_NAMES[form]) < 0
}

export const getEditProductOptions = (id, form) => {
  const options = {
    [PRODUCT_TYPE_IDS.digital]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
    ],
    [PRODUCT_TYPE_IDS.download]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.file_upload'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
    ],
    [PRODUCT_TYPE_IDS.course]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.content'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
    ],
    [PRODUCT_TYPE_IDS.membership]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.products'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
    ],
    [PRODUCT_TYPE_IDS.bundle]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.products'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
    ],
    [PRODUCT_TYPE_IDS.eventTickets]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.tickets_details'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.common.design'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=8`,
      },
    ],
    [PRODUCT_TYPE_IDS.licenseKey]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.add_license_keys'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
    ],
    [PRODUCT_TYPE_IDS.certificate]: [
      {
        label: I18n.t('react.cabinet.product.step_buttons.product_details'),
        link: `/cabinet/products/${id}/edit`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.keys_details'),
        link: `/cabinet/products/${id}/edit?step=2`,
      },
      {
        label: I18n.t('react.cabinet.common.design'),
        link: `/cabinet/products/${id}/edit?step=3`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.checkout'),
        link: `/cabinet/products/${id}/edit?step=4`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.market_upsell'),
        link: `/cabinet/products/${id}/edit?step=5`,
      },
      {
        label: I18n.t('react.cabinet.product.delivery'),
        link: `/cabinet/products/${id}/edit?step=6`,
      },
      {
        label: I18n.t('react.cabinet.product.step_buttons.pages'),
        link: `/cabinet/products/${id}/edit?step=7`,
      },
      {
        label: I18n.t('react.cabinet.product.advanced'),
        link: `/cabinet/products/${id}/edit?step=8`,
      },
    ],
  }

  return options[form] || []
}

export const getProductTypeIconClasses = (form, className = '') => {
  switch (form) {
    case PRODUCT_TYPE_IDS.digital:
      return {
        icon: 'fas fa-laptop',
        container: `cover__product-type-icon--digital ${className}`,
        color: 'green',
      }
    case PRODUCT_TYPE_IDS.download:
      return {
        icon: 'fas fa-arrow-alt-circle-down',
        container: `cover__product-type-icon--download ${className}`,
        color: 'light-blue',
      }
    case PRODUCT_TYPE_IDS.course:
      return {
        icon: 'fas fa-graduation-cap',
        container: `cover__product-type-icon--course ${className}`,
        color: 'orange',
      }
    case PRODUCT_TYPE_IDS.eventTickets:
      return {
        icon: 'fas fa-ticket-alt',
        container: `cover__product-type-icon--ticket ${className}`,
        color: 'red',
      }
    case PRODUCT_TYPE_IDS.membership:
      return {
        icon: 'fas fa-users',
        container: `cover__product-type-icon--membership ${className}`,
        color: 'violet',
      }
    case PRODUCT_TYPE_IDS.bundle:
      return {
        icon: 'fas fa-boxes',
        container: `cover__product-type-icon--bundle ${className}`,
        color: 'light-green',
        parentClass: 'hovered-square-widget__disabled',
      }
    case PRODUCT_TYPE_IDS.licenseKey:
      return {
        icon: 'fas fa-key',
        container: `cover__product-type-icon--license_key ${className}`,
        color: 'pink',
      }
    case PRODUCT_TYPE_IDS.certificate:
      return {
        icon: 'fas fa-certificate',
        container: `cover__product-type-icon--certificate ${className}`,
        color: 'blue',
      }
  }
}

export const hideProduct = (productTypeId, history, userCratedAt) => {
  if (productTypeId !== PRODUCT_TYPE_IDS.bundle) {
    return false
  }
  const hideProduct =
    moment(userCratedAt).isAfter(moment(getBundleBanDate())) || moment(moment()).diff(getBundleBanDate(), 'months') >= 1

  if (history.location.pathname.includes(`/${productTypeId}`)) {
    return false
  }

  return hideProduct
}

export const totalPlanAmount = (plan) => {
  const { prefs = {} } = plan
  if (prefs.customIntervals) {
    let total = 0
    if (prefs.paymentsCount > 1) {
      // prettier-ignore
      ;[...Array(parseInt(prefs.paymentsCount, 10))].forEach((e, i) => {
        total += parseFloat(prefs[`rate${i + 1}Amount`] || '')
      })
      return total
    } else {
      return 0
    }
  } else {
    return (
      parseFloat(prefs.firstAmount || '') + parseFloat(prefs.nextAmount || '') * (parseInt(prefs.paymentsCount, 10) - 1)
    )
  }
}

const isEvent = ({ form } = {}) => form === PRODUCT_TYPE_IDS.eventTickets

const pricingPlanExists = (aProduct) => {
  if (isEvent(aProduct)) {
    return (
      aProduct.tickets &&
      aProduct.tickets.length &&
      aProduct.tickets[0].pricingPlans &&
      aProduct.tickets[0].pricingPlans.length
    )
  }

  return aProduct && aProduct.pricingPlans && aProduct.pricingPlans.length
}

export const getPricingPlan = (aProduct) => {
  if (!pricingPlanExists(aProduct) || aProduct.free || aProduct.free) {
    return {}
  }

  const plan = isEvent(aProduct) ? aProduct.tickets[0].pricingPlans[0] : aProduct.pricingPlans[0]

  return plan || {}
}

export const getCurrencyIdFromProduct = (aProduct) => {
  if (!pricingPlanExists(aProduct)) {
    return ''
  }

  return isEvent(aProduct) ? aProduct.tickets[0].pricingPlans[0] : aProduct.pricingPlans[0]
}

const extractPriceFromProduct =
  (...pricePropNames) =>
  (aProduct) => {
    if (!pricingPlanExists(aProduct) || aProduct.free || aProduct.free) {
      return 0
    }

    const prefs = isEvent(aProduct) ? aProduct.tickets[0].pricingPlans[0].prefs : aProduct.pricingPlans[0].prefs

    if (pricePropNames.some(Boolean)) {
      return pricePropNames.map((prop) => prefs[prop]).filter(Boolean)[0]
    }

    return 0
  }

export const getProductPrice = extractPriceFromProduct('price', 'firstAmount', 'rate1Amount')

export const getProductPriceWithAppliedCoupon = (product, coupon) => {
  const { displayPrice, free } = product || {}
  const { active, limit, value, valueForm, ordersCount: applied } = coupon || {}
  const convertedPrice = Number(displayPrice)
  const convertedValue = Number(value)

  if (free) {
    return convertedPrice
  }

  if (active && (!limit || applied < limit)) {
    switch (valueForm) {
      case COUPON_VALUE_FORM.currency: {
        if (convertedPrice >= convertedValue) {
          return convertedPrice - convertedValue
        }
        return 0
      }
      case COUPON_VALUE_FORM.percents: {
        return Number((((100 - convertedValue) / 100) * convertedPrice).toFixed(2))
      }
      default:
        return 0
    }
  }

  return convertedPrice
}

export const getFirstCoverUrl = (aProduct, resolution = 's100') => {
  if (!(aProduct && aProduct.covers && aProduct.covers.length)) {
    return ''
  }

  return aProduct.covers[0].file && aProduct.covers[0].file[resolution]
}

export const isProtectionUrlValid = (url) => url.indexOf('%{protection_token}') >= 0

export const getProductForm = (productForm) =>
  (getShopProductTypeOptions().find((item) => item.value === productForm) || {}).label

export const randomString = (length) => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''

  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]

  return result
}

export const planUpgradeRedirection = (productId, step) => {
  const searchParams = productId ? `?product_id=${productId}&step=${step}` : ''

  return window.open(`https://${marketingWebHosts.production}/preise${searchParams}`, '_blank')
}

export const getValidAndErrorKeyFormat = (format = '') => {
  const validFormats = ['alphabetic', 'alphanumeric', 'numeric']
  const formatNumbers = format?.match(NUMBER_REGEX)
  const formatTypes =
    format &&
    format
      .split('%{')
      .slice(1)
      .map((part) => part.split('_')[0])
      .filter((word) => word !== '')
  const isTypeMatched = format && formatTypes?.some((word) => validFormats.includes(word))
  const isNumberValid = format && formatNumbers?.some((number) => number >= 3)

  const isMatchRegex = PRODUCT_KEY_REGEX.test(format)
  const getFormatError = () => {
    if (!isMatchRegex) {
      return I18n.t('react.cabinet.product.key_validation.bad_format', { parameter_X: '%{parameter_X}' })
    }

    if (isMatchRegex && !isNumberValid) {
      return I18n.t('react.cabinet.product.key_validation.format_size')
    }

    if (isMatchRegex && isNumberValid && !isTypeMatched) {
      return I18n.t('react.cabinet.product.key_validation.format_type')
    }
  }

  return {
    valid: isMatchRegex && isNumberValid && isTypeMatched,
    error: getFormatError(),
  }
}
