import { makeObservable } from 'mobx'

import { apiClient } from 'utils/requests.utils'
import SharedStore from './shared.store'

import { cookiesConsentsCategoriesApi } from '../api/cookiesConsentsCategories.api'

export class CookiesConsentCategoriesStore extends SharedStore {
  storeName = 'CookiesConsentCategoriesStore'
  childApi

  constructor(root) {
    super()
    this.childApi = cookiesConsentsCategoriesApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }
}

export default new CookiesConsentCategoriesStore()
