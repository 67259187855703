import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloShareIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5148 4.5C12.5148 3.5335 13.2983 2.75 14.2648 2.75C15.2313 2.75 16.0148 3.5335 16.0148 4.5C16.0148 5.4665 15.2313 6.25 14.2648 6.25C13.2983 6.25 12.5148 5.4665 12.5148 4.5ZM14.2648 1.25C12.4698 1.25 11.0148 2.70507 11.0148 4.5C11.0148 4.92269 11.0954 5.32653 11.2423 5.69696L7.54841 8.07068C6.95712 7.45601 6.12619 7.07353 5.20593 7.07353C3.41101 7.07353 1.95593 8.5286 1.95593 10.3235C1.95593 12.1185 3.41101 13.5735 5.20593 13.5735C6.07692 13.5735 6.86789 13.2309 7.4514 12.6731L11.2512 14.9278C11.0987 15.3043 11.0148 15.7159 11.0148 16.1471C11.0148 17.942 12.4698 19.3971 14.2648 19.3971C16.0597 19.3971 17.5148 17.942 17.5148 16.1471C17.5148 14.3521 16.0597 12.8971 14.2648 12.8971C13.4449 12.8971 12.6959 13.2007 12.1241 13.7016L8.26855 11.4138C8.38988 11.073 8.45593 10.706 8.45593 10.3235C8.45593 9.98923 8.40546 9.66671 8.31171 9.36319L12.103 6.92685C12.6774 7.43886 13.4347 7.75 14.2648 7.75C16.0597 7.75 17.5148 6.29493 17.5148 4.5C17.5148 2.70507 16.0597 1.25 14.2648 1.25ZM5.20593 8.57353C4.23943 8.57353 3.45593 9.35703 3.45593 10.3235C3.45593 11.29 4.23943 12.0735 5.20593 12.0735C6.17243 12.0735 6.95593 11.29 6.95593 10.3235C6.95593 9.35703 6.17243 8.57353 5.20593 8.57353ZM14.2648 14.3971C13.2983 14.3971 12.5148 15.1806 12.5148 16.1471C12.5148 17.1136 13.2983 17.8971 14.2648 17.8971C15.2313 17.8971 16.0148 17.1136 16.0148 16.1471C16.0148 15.1806 15.2313 14.3971 14.2648 14.3971Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
