import { makeObservable } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { createDownloadGoodsApi, DownloadGoodsApi } from 'shop/api/downloadGoods.api'

import SharedStore from 'shared/stores/shared.store'

export class DownloadGoodsStore extends SharedStore<any> {
  storeName = 'DownloadGoodsStore'
  declare childApi: DownloadGoodsApi
  root: ShopRootStore

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    this.childApi = createDownloadGoodsApi(root.apiClientV2)
    makeObservable(this)
  }

  downloadGoods = async (token: string, data: { goodId: string; sellableItemId: number }) =>
    await this.childApi.downloadGoods(this.root.sellerStore.item.username, token, data)

  checkZipFileStatus = async (token: string) =>
    await this.childApi.checkZipFileStatus(this.root.sellerStore.item.username, token)
}
