import { isEmpty } from '@elo-kit/utils/validators.utils'

import { getCookies, removeCookies, setCookies } from 'libs/common/cookies'

import {
  COOKIEBOT_MODES,
  COOKIES_CONSENTS,
  COOKIES_CONSENTS_ALL_ACCEPTED,
  COOKIES_CONSENTS_FEATURE_FLAG,
} from '@elo-kit/constants/cookiesConsents.constants'

import { DEFAULT_FULL_LIST, DEFAULT_PAGE } from 'constants/pagination.constants'

import { getParamsFromUrl } from './helpers.utils'

export const areCookiesAllowed = (cookiesIds = [], location = COOKIES_CONSENTS.shop, username = '') => {
  const isShopLocation = location === COOKIES_CONSENTS.shop
  const cookieKey = isShopLocation ? `${COOKIES_CONSENTS.shop}_${username.toLowerCase()}` : COOKIES_CONSENTS.app
  const cookies = getCookies(cookieKey) || ''
  const allowedCookiesIds = cookies.split(',')
  const isEmptyCookiesIds = !(cookiesIds && cookiesIds.length)

  return (
    cookies &&
    cookies.length &&
    (cookies === COOKIES_CONSENTS_ALL_ACCEPTED ||
      isEmptyCookiesIds ||
      (!isEmptyCookiesIds && cookiesIds.every((id) => allowedCookiesIds.includes(String(id)))))
  )
}

export const checkKeyInCookiesConsents = (key, consentsList = [], location = COOKIES_CONSENTS.shop, username) => {
  const cookieId = (consentsList.find((consent) => consent.key === key) || {}).id
  return cookieId && areCookiesAllowed([cookieId], location, username)
}

export const getCookiebotScript = (id, mode) => {
  const scriptData = {
    src: 'https://consent.cookiebot.com/uc.js',
    attributes: [
      {
        key: 'id',
        val: 'Cookiebot',
      },
      {
        key: 'type',
        val: 'text/javascript',
      },
      {
        key: 'data-cbid',
        val: id,
      },
      {
        key: 'data-culture',
        val: I18n.locale,
      },
    ],
  }

  if (COOKIEBOT_MODES.auto === mode) {
    scriptData.attributes.push({
      key: 'data-blockingmode',
      val: 'auto',
    })
  }

  return scriptData
}

export const getConsentFetchOptions = (isAppActive, sellerId) => {
  const additionalOptions = isAppActive(COOKIES_CONSENTS_FEATURE_FLAG) ? { sellerId } : { default: true }

  return {
    page: {
      size: DEFAULT_FULL_LIST,
      number: DEFAULT_PAGE,
    },
    ...additionalOptions,
  }
}

export const setParamsToCookies = (params = {}) =>
  Object.keys(params).forEach((paramName) => {
    if (params[paramName]) setCookies(paramName, params[paramName])
  })

export const setCookiesFromUrlParams = (paramsToSet = []) => {
  const paramsFromUrl = getParamsFromUrl(paramsToSet)
  !isEmpty(paramsFromUrl) && setParamsToCookies(paramsFromUrl)
}

export const removeParamsFromCookies = (params = []) =>
  params.forEach((paramName) => {
    removeCookies(paramName)
  })

export const getParamsFromCookies = (params = []) => {
  const paramsList = {}
  params.forEach((paramName) => {
    const cookieValue = getCookies(paramName)
    if (cookieValue) paramsList[paramName] = cookieValue
  })
  return paramsList
}
