import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloHandshakeIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <g clipPath='url(#clip0_96_2316)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.92762 3.44106C3.63215 3.29333 3.27273 3.40104 3.10722 3.68693L0.45911 8.26094C0.374677 8.40678 0.352714 8.58053 0.398203 8.7428C0.443692 8.90506 0.552778 9.04207 0.700719 9.12277L3.29666 10.5387L7.34332 13.8713C7.40745 13.9241 7.48147 13.9636 7.56105 13.9875L12.3758 15.4319C12.6074 15.5014 12.8582 15.4311 13.0199 15.2514L15.1865 12.844L17.274 10.5246L19.5755 9.37384C19.728 9.2976 19.8427 9.16239 19.8931 8.99954C19.9435 8.83668 19.9251 8.66031 19.8423 8.5113L17.435 4.17803C17.2949 3.92589 17.0005 3.80236 16.7224 3.87906L14.1044 4.60127L13.3769 4.80197L10.4099 3.43261C10.2725 3.3692 10.1168 3.35778 9.97166 3.40048L6.11504 4.53478L3.92762 3.44106ZM11.5099 5.317L10.0992 4.66591L6.34956 5.76874L4.19032 9.65538L8.04079 12.8264L12.3557 14.1208L13.8104 12.5045L11.3441 10.5667C9.79281 11.6216 7.73532 11.6203 6.17851 10.5305C6.03023 10.4267 5.93423 10.2638 5.91534 10.0837C5.89645 9.90374 5.95652 9.72438 6.08002 9.59206L9.45034 5.981C9.52912 5.8966 9.62974 5.83566 9.74104 5.80496L11.5099 5.317ZM3.89444 4.82202L5.10837 5.42898L3.11554 9.01608L1.86211 8.3324L3.89444 4.82202ZM18.4326 8.54773L17.1492 9.18943L15.1958 5.59689L16.581 5.21477L18.4326 8.54773ZM10.2437 6.96299L13.9585 5.9382L16.1285 9.92886L14.6485 11.5733L11.7378 9.28632C11.5096 9.10699 11.1879 9.1084 10.9612 9.28973L10.8485 9.37991C9.90006 10.1387 8.63095 10.3036 7.54101 9.8587L10.2437 6.96299ZM4.75401 13.1362C4.48155 12.9243 4.08888 12.9734 3.87696 13.2459C3.66504 13.5183 3.71412 13.911 3.98659 14.1229L6.15322 15.8081L6.25189 15.8848L6.37249 15.9177L9.0206 16.6399C9.35361 16.7307 9.6972 16.5344 9.78802 16.2014C9.87884 15.8684 9.68251 15.5248 9.34949 15.434L6.82198 14.7446L4.75401 13.1362Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_96_2316'>
        <rect width='20' height='20' fill='currentColor' />
      </clipPath>
    </defs>
  </EloBaseIcon>
)
