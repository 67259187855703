import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloAddCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.75 10C2.75 5.99594 5.99594 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10ZM10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25ZM10.0558 6.30762C10.4441 6.30762 10.7589 6.62242 10.7589 7.01074V9.2154H13.101C13.4893 9.2154 13.8041 9.5302 13.8041 9.91852C13.8041 10.3068 13.4893 10.6216 13.101 10.6216H10.7589V13.1011C10.7589 13.4895 10.4441 13.8043 10.0558 13.8043C9.66749 13.8043 9.35269 13.4895 9.35269 13.1011V10.6216H7.01062C6.62229 10.6216 6.3075 10.3068 6.3075 9.91852C6.3075 9.5302 6.62229 9.2154 7.01062 9.2154H9.35269V7.01074C9.35269 6.62242 9.66749 6.30762 10.0558 6.30762Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
