import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles, { ThemeProvider } from 'react-jss'

import { COOKIES_CONSENTS_LOCATION, COOKIES_CONSENTS_TYPE } from '@elo-kit/constants/cookiesConsents.constants'

import { createKibanaApi } from 'shop/api/kibana.api'
import { apiClient } from 'utils/requests.utils'

import { CookiesConsentFloatingBtn } from '../cookies-consent-floating-btn'
import { CookiesConsentRich } from '../cookies-consent-rich'
import { CookiesConsentSimple } from '../cookies-consent-simple'

import useCookiesConsentWidget from './CookiesConsentWidget.hook'
import cookiesConsentStyles from './CookiesConsentWidget.styles'

/** Cookies consent widget */
const CookiesConsentStyled = withStyles(cookiesConsentStyles)((props) => {
  const {
    username,
    isPowerSeller,
    location,
    classes,
    preview,
    form,
    legalLinks,
    baseURL,
    powerSellerParam,
    themePages,
  } = props

  const [firstBannerLoad, setFirstBannerLoad] = useState(true)
  const [cookiesValues, cookiesHandlers] = useCookiesConsentWidget(props)
  const { accepted } = cookiesValues
  const { toggleModal } = cookiesHandlers
  const kibanaApi = createKibanaApi(apiClient)
  const showSimpleBanner = preview || (!accepted && form === COOKIES_CONSENTS_TYPE.simple)

  useEffect(() => {
    if (props.location === 'shop' && showSimpleBanner && firstBannerLoad) {
      reportCookieConsent('banner_visibility', { visible: true })
      setFirstBannerLoad(false)
    }
  }, [])

  const reportCookieConsent = (eventType, data) => {
    if (props.location === 'shop') {
      const { id: sellerId } = props.sellerStore.item

      kibanaApi.reportCookieConsent(sellerId, eventType, data)
    }
  }

  const privacyLink =
    location === COOKIES_CONSENTS_LOCATION.shop
      ? `/s/${username}/document/privacy${isPowerSeller ? '?power_seller=true' : ''}`
      : '/privacy'

  const viewProps = {
    ...props,
    ...cookiesValues,
    ...cookiesHandlers,
    privacyLink,
    reportCookieConsent,
    legalLinks,
    baseURL,
    powerSellerParam,
    themePages,
  }

  return (
    <>
      {!preview && <CookiesConsentRich {...viewProps} />}

      {accepted && !preview && (
        <CookiesConsentFloatingBtn
          onBtnClick={() => {
            toggleModal()
            reportCookieConsent('open_change_settings', { source: 'open_change_settings_edit_button' })
          }}
          className={classes.consentFloatBtn}
        />
      )}

      {showSimpleBanner && cookiesValues.dataInited && !cookiesValues.showModal && (
        <CookiesConsentSimple {...viewProps} />
      )}
    </>
  )
})

/** Cookies consent widget with React JSS styles */
export const CookiesConsentWidget = (props) => {
  const { theme } = props

  return (
    <ThemeProvider theme={theme || {}}>
      <CookiesConsentStyled {...props} />
    </ThemeProvider>
  )
}

CookiesConsentWidget.propTypes = {
  /** React JSS theme */
  theme: PropTypes.object,
  /** Preview flag */
  preview: PropTypes.bool,
  /** Seller's username */
  username: PropTypes.string,
  /** Power seller flag */
  isPowerSeller: PropTypes.bool,
  /** Location */
  location: PropTypes.string,
  /** Submit handler */
  onSubmit: PropTypes.func,
  /** Consent form */
  form: PropTypes.string,
  /** Consents list from cookiesConsentsStore */
  consentsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Consents categories list from cookiesConsentCategoriesStore */
  categoriesList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Cookies getter */
  getCookies: PropTypes.func,
  /** Cookies setter */
  setCookies: PropTypes.func,
  /** Cookies remove handler */
  removeCookies: PropTypes.func,
  /** Cookies getAll handler */
  getAllCookies: PropTypes.func,
  /** Local Storage setter */
  addItemToLocalStorage: PropTypes.func,
  /** Local Storage getter */
  getItemFromLocalStorage: PropTypes.func,
}

CookiesConsentWidget.defaultProps = {
  consentsList: [],
  categoriesList: [],
  getCookies: () => {},
  setCookies: () => {},
  removeCookies: () => {},
  getAllCookies: () => {},
  addItemToLocalStorage: () => {},
  getItemFromLocalStorage: () => {},
}
