import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBellRingingIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M4.39062 8.75C4.39062 8.01337 4.53571 7.28395 4.81761 6.60339C5.09951 5.92282 5.51269 5.30445 6.03357 4.78357C6.55445 4.26269 7.17282 3.84951 7.85338 3.56761C8.53394 3.28572 9.26336 3.14062 10 3.14062C10.7366 3.14062 11.4661 3.28572 12.1466 3.56761C12.8272 3.84951 13.4455 4.26269 13.9664 4.78357C14.4873 5.30445 14.9005 5.92282 15.1824 6.60339C15.4643 7.28395 15.6094 8.01337 15.6094 8.75V8.75C15.6094 11.5469 16.1953 13.1719 16.7109 14.0625C16.7657 14.1574 16.7946 14.2649 16.7947 14.3745C16.7948 14.484 16.7661 14.5916 16.7115 14.6865C16.6569 14.7815 16.5783 14.8604 16.4836 14.9154C16.3889 14.9705 16.2814 14.9996 16.1719 15H3.82812C3.7186 14.9996 3.6111 14.9705 3.51639 14.9154C3.42169 14.8604 3.3431 14.7815 3.28851 14.6865C3.23391 14.5916 3.20523 14.484 3.20532 14.3745C3.20542 14.2649 3.2343 14.1574 3.28906 14.0625C3.80468 13.1719 4.39062 11.5469 4.39062 8.75Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.5 15V15.625C7.5 16.288 7.76339 16.9239 8.23223 17.3928C8.70107 17.8616 9.33696 18.125 10 18.125C10.663 18.125 11.2989 17.8616 11.7678 17.3928C12.2366 16.9239 12.5 16.288 12.5 15.625V15'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.3281 1.875C15.6007 2.67829 16.6277 3.81615 17.2969 5.16406'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.70312 5.16406C3.37228 3.81615 4.39933 2.67829 5.67187 1.875'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
