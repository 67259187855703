import axios, { type AxiosInstance } from 'axios'
import { stringify } from 'qs'
import * as NextJSSentry from '@sentry/nextjs'

import { getElopageConfig } from 'utils/elopageConfig.utils'

import { isWindowEnv } from 'utils/env.utils'
import { FINGER_PRINT_KEY } from 'utils/fingerprint.utils'

import { apiVersionV2 } from 'libs/configs'
import { getCookies, setCookies, removeCookies } from 'libs/common/cookies'

import {
  withAuthorizationInterceptor,
  withUserSessionIdInterceptor,
  requestTimeInterceptor,
} from 'utils/api-client/request-inteceptors'
import {
  withCamelCaseKeys,
  withDebugInterceptor,
  withDebugErrorInterceptor,
  withNormalizeData,
  withSerializedJSON,
  withRefreshTokenInterceptor,
  withNormalizeErrorInterceptor,
  responseTimeInterceptor,
} from 'utils/api-client/response-inteceptors'
import { LocalStorageService } from 'utils/local-storage.utils'

import { deepObjectPropNamesToSnakeCase } from '../nameStyle.utils'
import {
  ApiClientV2Options,
  AuthorizationCookiesResp,
  JSONAPIError,
  JSONResponse,
  removeAuthorizationCookiesFn,
  setAuthorizationCookiesFn,
} from './types'
import { memoize } from './helpers'

export class ApiClientV2 {
  constructor(options: ApiClientV2Options) {
    let apiDomain = getElopageConfig('apiPath')

    if (isWindowEnv()) {
      if (localStorage.getItem('api_endpoint')) {
        apiDomain = localStorage.getItem('api_endpoint').replace(/['"]+/g, '')
      }
    } else {
      apiDomain = getElopageConfig('apiPathSSR')
    }

    const {
      userLocale = I18n.locale,
      userSessionId,
      accessToken,
      refreshToken,
      fingerprint,
      setAuthorizationCookies,
      removeAuthorizationCookies,
    } = options

    this.setAuthorizationCookies = setAuthorizationCookies
    this.removeAuthorizationCookies = removeAuthorizationCookies
    this.axiosInstance = axios.create({
      baseURL: `${apiDomain}${apiVersionV2}`,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'Content-Language': userLocale,
        'Accept-Language': userLocale,
      },
    })
    this.debugMode = options.debugMode ?? false

    if (this.debugMode) {
      this.axiosInstance.interceptors.request.use(requestTimeInterceptor)
      this.axiosInstance.interceptors.response.use(responseTimeInterceptor)
    }

    this.axiosInstance.interceptors.request.use(withUserSessionIdInterceptor(userSessionId))
    this.axiosInstance.interceptors.request.use(withAuthorizationInterceptor(accessToken))

    if (process.env.NODE_ENV !== 'test') {
      this.axiosInstance.interceptors.response.use(
        (response) => response,
        withRefreshTokenInterceptor(this.axiosInstance, { refreshToken, fingerprint }, this.memoizedRefetchToken)
      )
      this.axiosInstance.interceptors.response.use(
        withDebugInterceptor(this.debugMode, this.serverSideLogs),
        withDebugErrorInterceptor(this.debugMode, this.serverSideLogs)
      )
      this.axiosInstance.interceptors.response.use(withNormalizeData())
      this.axiosInstance.interceptors.response.use(withCamelCaseKeys())
      this.axiosInstance.interceptors.response.use(withSerializedJSON())
    }
  }

  private axiosInstance: AxiosInstance
  private debugMode = false

  serverSideLogs: string[] = []
  setAuthorizationCookies: setAuthorizationCookiesFn
  removeAuthorizationCookies: removeAuthorizationCookiesFn

  private stringifyParams = (url: string, params?: Record<string, any>): string => {
    const paramsToSend = deepObjectPropNamesToSnakeCase(params)
    const paramsStringified = stringify(paramsToSend, { arrayFormat: 'brackets' })
    return paramsStringified ? `${url}?${paramsStringified}` : url
  }

  private request = async <T>(params): Promise<T> => {
    try {
      return (await this.axiosInstance.request(params)) as T
    } catch (error) {
      return error as T
    }
  }

  GET = <T>(url: string, queryParams?: Record<string, any>) => {
    try {
      return this.request<JSONResponse<T>>({
        method: 'GET',
        url: this.stringifyParams(url, queryParams),
      })
    } catch (err: any) {
      const ignoreErrorStatus = err?.status === 400 || err.status === 401 || err.status === 404

      if (!ignoreErrorStatus) {
        NextJSSentry.captureException(err)
      }

      throw err as JSONAPIError
    }
  }

  POST = <T>(url: string, data?: Record<string, any>, queryParams?: Record<string, any>) => {
    try {
      const { type, ...restData } = data

      const formattedData = {
        data: {
          type: type,
          attributes: restData,
        },
      }

      return this.request<JSONResponse<T>>({
        method: 'POST',
        url: this.stringifyParams(url, queryParams),
        data: JSON.stringify(deepObjectPropNamesToSnakeCase(formattedData)),
      })
    } catch (err: any) {
      const ignoreErrorStatus = err?.status === 400 || err.status === 401 || err.status === 404

      if (!ignoreErrorStatus) {
        NextJSSentry.captureException(err)
      }

      throw err as JSONAPIError
    }
  }

  private refetchToken = async ({ refreshToken, fingerprint }) => {
    const apiDomain = isWindowEnv() ? getElopageConfig('apiPath') : getElopageConfig('apiPathSSR')
    const form = new FormData()
    form.append('refresh_token', refreshToken)
    form.append('fingerprint', fingerprint)

    const resp = await this.request({
      baseURL: `${apiDomain}/v1`,
      method: 'POST',
      url: '/app/session/renew_token',
      data: form,
    })

    const response = resp as AuthorizationCookiesResp

    if (response.status === 200) {
      this.setAuthorizationCookies({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresAt: response.data.expires_at,
        refreshExpiresAt: response.data.refresh_expires_at,
      })
    } else {
      this.removeAuthorizationCookies()
    }

    return resp
  }

  private memoizedRefetchToken = memoize(this.refetchToken)
}

export class ApiClientV2Default {
  constructor() {
    const apiDomain = LocalStorageService.getItem('api_endpoint') || getElopageConfig('apiPath')
    const userLocale = I18n.locale
    const accessToken = getCookies('access_token') as string
    const refreshToken = getCookies('refresh_token') as string
    const fingerprint = getCookies(FINGER_PRINT_KEY) as string

    this.setAuthorizationCookies = ({ accessToken, refreshToken, expiresAt, refreshExpiresAt }) => {
      const expires = new Date(expiresAt)
      const refreshExpires = new Date(refreshExpiresAt)
      setCookies('access_token', accessToken, {
        expires,
      })
      setCookies('refresh_token', refreshToken, {
        expires: refreshExpires,
      })
    }

    this.removeAuthorizationCookies = () => {
      removeCookies('access_token')
      removeCookies('refresh_token')
      removeCookies(FINGER_PRINT_KEY)
    }

    this.axiosInstance = axios.create({
      baseURL: `${apiDomain}${apiVersionV2}`,
      headers: {
        Accept: 'application/json; charset=utf-8',
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Language': userLocale,
        'Accept-Language': userLocale,
      },
    })

    this.axiosInstance.interceptors.request.use(withAuthorizationInterceptor(accessToken))
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      withRefreshTokenInterceptor(this.axiosInstance, { refreshToken, fingerprint }, this.memoizedRefetchToken)
    )
    this.axiosInstance.interceptors.response.use((response) => response, withNormalizeErrorInterceptor())
    this.axiosInstance.interceptors.response.use(withNormalizeData())
    this.axiosInstance.interceptors.response.use(withCamelCaseKeys())
    this.axiosInstance.interceptors.response.use(withSerializedJSON())
  }

  private axiosInstance: AxiosInstance

  setAuthorizationCookies: setAuthorizationCookiesFn
  removeAuthorizationCookies: removeAuthorizationCookiesFn

  private stringifyParams = (url: string, params?: Record<string, any>): string => {
    const paramsToSend = deepObjectPropNamesToSnakeCase(params)
    const paramsStringified = stringify(paramsToSend, { arrayFormat: 'brackets' })
    return paramsStringified ? `${url}?${paramsStringified}` : url
  }

  private request = async <T>(params): Promise<T> => {
    try {
      return (await this.axiosInstance.request(params)) as T
    } catch (error: any) {
      return error as T
    }
  }

  GET = <T>(url: string, queryParams?: Record<string, any>) =>
    this.request<JSONResponse<T>>({
      method: 'GET',
      url: this.stringifyParams(url, queryParams),
    })

  POST = <T>(url: string, data?: Record<string, any>, queryParams?: Record<string, any>) =>
    this.request<JSONResponse<T>>({
      method: 'POST',
      url: this.stringifyParams(url, queryParams),
      data: JSON.stringify(deepObjectPropNamesToSnakeCase(data)),
    })

  PUT = <T>(url: string, data: Record<string, any>, queryParams?: Record<string, any>) =>
    this.request<JSONResponse<T>>({
      method: 'PUT',
      url: this.stringifyParams(url, queryParams),
      data: JSON.stringify(deepObjectPropNamesToSnakeCase(data)),
    })

  private refetchToken = async ({ refreshToken, fingerprint }) => {
    const apiDomain = getCookies('api_endpoint') || getElopageConfig('apiPath')
    const form = new FormData()
    form.append('refresh_token', refreshToken)
    form.append('fingerprint', fingerprint)

    const resp = await this.request({
      baseURL: `${apiDomain}/v1`,
      method: 'POST',
      url: '/app/session/renew_token',
      data: form,
    })

    const response = resp as AuthorizationCookiesResp

    if (response.status === 200) {
      this.setAuthorizationCookies({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresAt: response.data.expires_at,
        refreshExpiresAt: response.data.refresh_expires_at,
      })
    } else {
      this.removeAuthorizationCookies()
    }

    return resp
  }

  private memoizedRefetchToken = memoize(this.refetchToken)
}
