import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMembershipIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M5.60418 16.3439C7.03859 16.3439 8.2014 15.181 8.2014 13.7466C8.2014 12.3122 7.03859 11.1494 5.60418 11.1494C4.16977 11.1494 3.00696 12.3122 3.00696 13.7466C3.00696 15.181 4.16977 16.3439 5.60418 16.3439Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.70837 18.2917C2.16198 17.6869 2.75017 17.196 3.42637 16.8579C4.10257 16.5198 4.8482 16.3438 5.60421 16.3438C6.36022 16.3438 7.10585 16.5198 7.78204 16.8579C8.45824 17.196 9.04643 17.6869 9.50004 18.2917'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.60418 7.90294C7.03859 7.90294 8.2014 6.74012 8.2014 5.30572C8.2014 3.87131 7.03859 2.7085 5.60418 2.7085C4.16977 2.7085 3.00696 3.87131 3.00696 5.30572C3.00696 6.74012 4.16977 7.90294 5.60418 7.90294Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.70837 9.85075C2.16198 9.24594 2.75017 8.75505 3.42637 8.41695C4.10257 8.07885 4.8482 7.90283 5.60421 7.90283C6.36022 7.90283 7.10585 8.07885 7.78204 8.41695C8.45824 8.75505 9.04643 9.24594 9.50004 9.85075'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.3958 16.3439C14.8302 16.3439 15.993 15.181 15.993 13.7466C15.993 12.3122 14.8302 11.1494 13.3958 11.1494C11.9614 11.1494 10.7986 12.3122 10.7986 13.7466C10.7986 15.181 11.9614 16.3439 13.3958 16.3439Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.5 18.2917C9.95361 17.6869 10.5418 17.196 11.218 16.8579C11.8942 16.5198 12.6398 16.3438 13.3958 16.3438C14.1518 16.3438 14.8975 16.5198 15.5737 16.8579C16.2499 17.196 16.8381 17.6869 17.2917 18.2917'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.3958 7.90294C14.8302 7.90294 15.993 6.74012 15.993 5.30572C15.993 3.87131 14.8302 2.7085 13.3958 2.7085C11.9614 2.7085 10.7986 3.87131 10.7986 5.30572C10.7986 6.74012 11.9614 7.90294 13.3958 7.90294Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.5 9.85075C9.95361 9.24594 10.5418 8.75505 11.218 8.41695C11.8942 8.07885 12.6398 7.90283 13.3958 7.90283C14.1518 7.90283 14.8975 8.07885 15.5737 8.41695C16.2499 8.75505 16.8381 9.24594 17.2917 9.85075'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
