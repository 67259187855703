import axios from 'axios'

import { geoapifyApiKey } from 'constants/locationCode.constants'

const BASE_URL = `https://api.geoapify.com/v1/geocode/search?apiKey=${geoapifyApiKey}`

export const createGeoapifyApi = () => ({
  validateAddress: (city, postcode, country) =>
    axios.get(`${BASE_URL}&city=${city}&postcode=${postcode}&country=${country}`),

  getCountry: () => axios.get(`https://api.geoapify.com/v1/ipinfo?&apiKey=${geoapifyApiKey}`),
})

export type GeoapifyApi = ReturnType<typeof createGeoapifyApi>
