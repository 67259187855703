export const TEAM_MEMBER_PROFILE_PATH = '/team_member/profile/edit'
export const TEAM_MEMBER_BANK_ACCOUNTS_PATH = '/team_member/bank_accounts'
export const TEAM_MEMBER_OVERVIEW_PATH = '/team_member'
export const TEAM_MEMBER_INVITES_PATH = '/team_member/invites'
export const TEAM_MEMBER_PAYOUTS_SETTINGS = '/team_member/payouts_settings'
export const TEAM_MEMBER_PAYOUTS_SCHEDULE = '/team_member/payouts_balance'
export const TEAM_MEMBER_PAYOUTS_PATH = '/team_member/payouts'
export const TEAM_MEMBER_TRANSFERS_PATH = '/team_member/transfers'
export const TEAM_MEMBER_NOTIFIC_SETTINGS_PATH = '/team_member/notific_settings'
export const TEAM_MEMBER_RELOAD_PATH = '/cabinet*'
export const TEAM_MEMBER_NOT_FOUND_PATH = '*'
