import type { Response, PaginatedResponse } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface Params {
  username: string
  productId: number
}

export interface Lesson {
  id: number
  form: string
  name: string
  contentPageId: string
  contentPageAfterId: string
  contentPageBeforeId: string
}

const BASE_URL = (username, productId) => `/shop/${username}/products/${productId}/lessons`

export const createLessonsApi = ({ GET }: ApiClient) => ({
  fetchList: (data: Params) => GET<PaginatedResponse<Lesson[]>>(BASE_URL(data.username, data.productId), data),
  fetchItem: (id, data) => GET<Response<Lesson>>(`${BASE_URL(data.username, data.productId)}/${id}`, data),
})

export type LessonsApi = ReturnType<typeof createLessonsApi>
