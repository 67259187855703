import React from 'react'
import { toJS } from 'mobx'

import { getDefaultSectionMenuItems } from 'constants/contentPage.constants'
import { ELOPAGE_BADGE_FEATURE_FLAG } from 'constants/themes.constants'
import { linkToImage } from 'utils/ssr.utils'

import primeLogo from 'images/prime_logo.png'
import { Footer } from '@elo-kit/components/page-builder/blocks/footer/Footer'

import { GoodsItem } from 'shared/types/content-page'

interface Props {
  block: GoodsItem
  previewMode?: boolean
  pageBuilderPreview?: boolean
  baseURL?: string
  isCheckoutPage?: boolean
  contentPageStore
}

export const FooterPreview: React.FC<Props> = ({ block, previewMode, baseURL, isCheckoutPage, contentPageStore }) => {
  const { isAppActive, themePages, seller, view } = contentPageStore

  // remap I18n keys to locales inside localise context
  const localisedDefaultMenuItems = getDefaultSectionMenuItems().map((item, idx) => ({
    ...item,
    name: `${item.name} ${idx}`,
    submenu: item.submenu.map((subitem, idx) => ({
      ...subitem,
      name: `${subitem.name} ${idx}`,
    })),
  }))

  return (
    <Footer
      badgeAppKey={ELOPAGE_BADGE_FEATURE_FLAG}
      toJS={toJS}
      primeLogo={linkToImage(primeLogo)}
      block={block}
      baseURL={baseURL}
      previewMode={previewMode}
      isAppActive={isAppActive}
      themePages={themePages}
      seller={seller}
      view={view}
      defaultMenuItems={localisedDefaultMenuItems}
      isCheckoutPage={isCheckoutPage}
    />
  )
}
