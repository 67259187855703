import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'i18n-js'

/**
 * BACKWARD COMPATIBILITY
 * to be able to use it service-like way
 * (for constants, utils, classes)
 */
export default I18n

export type I18nType = typeof I18n
/**
 * PREFERABLE WAY
 * latest react wrapped version
 * (Context Api + hooks)
 */
export const I18nContext = React.createContext<I18nType | null>(null)

export const I18nProvider = ({ children }) => <I18nContext.Provider value={I18n}>{children}</I18nContext.Provider>

I18nProvider.propTypes = {
  /**
   * TODO: this needs to be improved as soon as cookies will be working for next.js
   */
  locale: PropTypes.string,
}

export const useI18n = () => React.useContext(I18nContext)
