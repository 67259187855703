import queryString from 'query-string'

import { isWindowEnv } from 'utils/env.utils'

// TODO: SSR - check usage of getSearchParams on SSR - it can be required param
export const getSearchParams = () => queryString.parse(isWindowEnv() ? window.location.search : '')

export const getRouterSearchParams = (location) => queryString.parse(isWindowEnv() ? location.search : '')

export const setSearchParams = (params: Record<string, any>) => {
  const prevParams = getSearchParams()
  window.location.search = queryString.stringify({ ...prevParams, ...params })
}

export const stringifySearchParams = (params: Record<string, any>, options?: Record<string, any>) =>
  queryString.stringify(params, options)
