import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloGearIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10.25 14.0312C12.3383 14.0312 14.0312 12.3383 14.0312 10.25C14.0312 8.16167 12.3383 6.46875 10.25 6.46875C8.16167 6.46875 6.46875 8.16167 6.46875 10.25C6.46875 12.3383 8.16167 14.0312 10.25 14.0312Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.638 5.29492C14.8376 5.47873 15.0266 5.6678 15.2052 5.86211L17.3558 6.16934C17.7062 6.77789 17.9763 7.42927 18.1593 8.10723L16.8516 9.84818C16.8516 9.84818 16.8752 10.3839 16.8516 10.6517L18.1593 12.3926C17.9771 13.0709 17.707 13.7224 17.3558 14.3305L15.2052 14.6378C15.2052 14.6378 14.8349 15.0238 14.638 15.2049L14.3308 17.3555C13.7222 17.7059 13.0708 17.976 12.3929 18.159L10.6519 16.8514C10.3846 16.875 10.1157 16.875 9.84842 16.8514L8.10747 18.159C7.42923 17.9769 6.77773 17.7067 6.16958 17.3555L5.86235 15.2049C5.66804 15.0211 5.47898 14.8321 5.29517 14.6378L3.14458 14.3305C2.79417 13.722 2.52408 13.0706 2.34106 12.3926L3.64875 10.6517C3.64875 10.6517 3.62511 10.116 3.64875 9.84818L2.34106 8.10723C2.52324 7.42898 2.79337 6.77749 3.14458 6.16934L5.29517 5.86211C5.47898 5.6678 5.66804 5.47873 5.86235 5.29492L6.16958 3.14434C6.77813 2.79393 7.42952 2.52384 8.10747 2.34082L9.84842 3.6485C10.1157 3.62486 10.3846 3.62486 10.6519 3.6485L12.3929 2.34082C13.0711 2.52299 13.7226 2.79312 14.3308 3.14434L14.638 5.29492Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
