import { COLORS } from '@elo-kit/constants/general.constants'
import { cleanupFonts } from 'utils/fonts.utils'

// TODO: update with using createUseStyles
const cookiesConsentFloatingBtnStyles = (theme = {}) => {
  const { eloBlue } = COLORS

  return {
    сonsentFloatBtnPreview: {
      '&.elo-btn--floating': {
        position: 'relative',
        marginBottom: 30,
        pointerEvents: 'none',
      },
    },
    consentFloatBtn: {
      '&.elo-btn': {
        color: theme.linkColor || eloBlue,
        fontFamily: cleanupFonts(theme.font) || 'Montserrat Reg',
        backgroundColor: `${theme.background || '#000000'}b3`,
        lineHeight: 1,
        padding: '8px 15px 6px',
        '&:hover': {
          opacity: 0.9,
        },
        '&:active': {
          opacity: 0.8,
        },
      },
    },
  }
}

export default cookiesConsentFloatingBtnStyles
