import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'
import { CancellationTermsApi, createCancellationTermsApi } from 'shop/api/cancellationTerms.api'

export class CancellationTermsStore extends SharedStore<any> {
  storeName = 'CancellationTerms'
  declare childApi: CancellationTermsApi

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)
    this.childApi = createCancellationTermsApi(root?.apiClient ?? apiClient)
  }
}
