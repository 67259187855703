import { makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { apiClient } from 'utils/requests.utils'

import { createSetupIntentsApi } from 'shop/api/setupIntents.api'

export class SetupIntents extends SharedStore {
  storeName = 'SetupIntents'
  root
  childApi

  constructor(root) {
    super()
    this.root = root
    this.childApi = createSetupIntentsApi(root?.apiClient ?? apiClient)
    makeObservable(this)

    this.loading = false
    this.item = {}
  }

  @override
  async createItem(data) {
    this.loading = true
    const resp = await this.childApi.createItem(this.root.sellerStore.item.username, data)
    this.item = resp.data
    this.loading = false
    return resp
  }

  @override
  hydrate(key, data) {
    if (key === 'item') {
      this.item = data
    } else if (key === 'loading') {
      this.loading = data
    }
  }
}
