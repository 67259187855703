const keySellerAccountIds = [
  '24652',
  '54629',
  '61929',
  '20900',
  '15750',
  '28091',
  '58445',
  '11473',
  '36840',
  '68241',
  '70451',
  '56890',
  '48328',
  '9613',
  '25332',
  '2966',
  '40737',
  '8083',
  '13355',
  '36479',
  '54916',
  '65741',
  '4200',
  '68236',
  '42161',
  '53721',
  '67419',
  '33417',
]

export function isKeySellerAccount(sellerId: string) {
  return keySellerAccountIds.includes(sellerId)
}
