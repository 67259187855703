import React, { PropsWithChildren, useMemo } from 'react'
import { StatsigClient, StatsigUser } from '@statsig/js-client'
import { StatsigProvider } from '@statsig/react-bindings'
import { runStatsigAutoCapture } from '@statsig/web-analytics'

import { useShopStores } from 'shop/hooks/use-store'

type Props = PropsWithChildren & {
  readonly clientSdkKey: string
  readonly user: StatsigUser
  readonly values: string
}

export function BootstrappedStatsigProvider({ clientSdkKey, user, values, children }: Props): React.JSX.Element {
  const { experimentsStore } = useShopStores()
  const client = useMemo(() => {
    const client = new StatsigClient(clientSdkKey, user)
    client.dataAdapter.setData(values)
    runStatsigAutoCapture(client)
    client.initializeSync()

    experimentsStore.setClient(client)

    return client
  }, [clientSdkKey, user, values])

  return <StatsigProvider client={client}>{children}</StatsigProvider>
}
