import { Nullable } from 'types/helpers'
import type { PaginatedResponse, ShallowResponse } from 'types/responses'

import { ApiClient } from 'utils/requests.utils'

export interface TicketAttendee {
  id: number
  fullName: string
  fullAddress: Nullable<string>
  firstName: string
  lastName: string
  email: string
  countryCode: Nullable<string>
  street: Nullable<string>
  streetNumber: number
  zip: Nullable<string>
  city: Nullable<string>
  ticketDateId: number
  phone: Nullable<string>
  salutation: Nullable<string>
  state: Nullable<string>
}

export interface EventAttendee {
  id: number
  canEdit: boolean
  codePrefix: string
  eventName: string
  payerName: string
  shortDescription: string
  state: string
  ticketCode: string
  ticketDate: string
  startDateInZoneFormatted: Nullable<string>
  ticketId: number
  ticketLocationName: string
  ticketName: string
  ticketAttendee: TicketAttendee
}

const BASE_URL = (username) => `/shop/${username}/orders`

export const createEventAttendeesApi = ({ GET, PUT }: ApiClient) => ({
  fetchList: ({
    username,
    token,
    ...rest
  }: {
    username: string
    token: string
  }): Promise<PaginatedResponse<EventAttendee[]>> => GET(`${BASE_URL(username)}/${token}/event_attendees`, rest),
  saveAttendee: (
    username: string,
    token: string,
    data: { ticketAttendeesAttributes: TicketAttendee[] }
  ): Promise<ShallowResponse<{ success: boolean }>> =>
    PUT(`${BASE_URL(username)}/${token}/update_event_attendees`, data),
})

export type EventAttendeesApi = ReturnType<typeof createEventAttendeesApi>
