import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface RedirectionReq {
  contentId: string
  pid: string
  productSlug: string
  affiliateToken: string
}

interface Redirection {
  forwardUrlParams: string
  redirectionUrl: string
}

const BASE_URL = (username) => `/shop/${username}/redirections`

export const createRedirectionsApi = ({ GET }: ApiClient) => ({
  getRedirection: (username: string, data: RedirectionReq): Promise<Response<Redirection>> =>
    GET(`${BASE_URL(username)}/check`, data),
})

export type RedirectionsApi = ReturnType<typeof createRedirectionsApi>
