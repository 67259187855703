import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCustomSortDefaultIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.41965 2.52507C9.56209 2.35097 9.77516 2.25 10.0001 2.25C10.2251 2.25 10.4381 2.35097 10.5806 2.52507L15.0806 8.02507C15.2641 8.24936 15.302 8.5593 15.1779 8.82117C15.0538 9.08305 14.7899 9.25 14.5001 9.25H5.50011C5.21032 9.25 4.94646 9.08305 4.82236 8.82117C4.69826 8.5593 4.73614 8.24936 4.91964 8.02507L9.41965 2.52507ZM9.41935 17.4752C9.56179 17.6493 9.77486 17.7502 9.99981 17.7502C10.2248 17.7502 10.4378 17.6493 10.5803 17.4752L15.0803 11.9752C15.2638 11.7509 15.3017 11.4409 15.1776 11.1791C15.0535 10.9172 14.7896 10.7502 14.4998 10.7502H5.49981C5.21002 10.7502 4.94616 10.9172 4.82206 11.1791C4.69796 11.4409 4.73584 11.7509 4.91934 11.9752L9.41935 17.4752Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
