import { action, computed, makeObservable, observable, override } from 'mobx'
import { PAYMENT_FORMS, PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'
import { VAT_TYPE } from 'constants/ordersShared.constants'
import { DEFAULT_CURRENCY } from 'constants/currencies.constants'

import {
  handleIdealTransaction,
  handleP24Transaction,
  handleSofortTransaction,
  handleStripeCardTransaction,
  handleTransactionResponse,
} from '@elo-kit/utils/stripe.utils'
import { omit } from 'utils/lodash.utils'

import SharedStore from 'shared/stores/shared.store'

import { PricingPlan } from 'types/pricing-plans'
import { Nullable } from 'types/helpers'
import { MappingToUpgrade } from 'types/upgrade-mappings'

import { Product } from 'shop/api/products.api'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

interface OrderRateSummary {
  gross: number
  net: number
  count: number
  vat: number
}

interface CreateAndChargeResponse {
  success: boolean
  error: string
  redirectLink: string
  clientSecret: string
  paymentMethod: number | string
}

interface BuildedOrderDetails {
  orderRates?: {
    state: string
    number: number
    orderRatePrices: {
      data: {
        cfgs: {
          mustPayVat: boolean
          isBusiness: boolean
          privateVatRate: number
          transferVatRate: number
          withVatNo: boolean
          businessVatRate: number
          vatBaseCountry: string
        }
        discount: OrderRateSummary
        fees: OrderRateSummary
        rate: OrderRateSummary
      }
    }[]
  }[]
  periodType?: string
  paymentState?: string
  paymentForm?: string
  id?: number
  createdAt?: string
  token?: string
  payerData?: {
    userAttributes?: any
    userProfileAttributes?: any
  }
  sellables?: {
    categoryKey: string
  }[]
}

interface SelectedMappingToUpgrade {
  [index: number]: MappingToUpgrade
}

interface TicketAttendeesAttribute {
  ticketDateId: number
  salutation: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  zip: string
  street: string
  streetNumber: string
  city: string
  phone: string
}

interface SellableItemsAttribute {
  productId: number
  ticketsCount: number
  ticketDateId: number
  ticketId: number
  ticketAttendeesAttributes: TicketAttendeesAttribute[]
}

interface Sellable {
  productId: number
  sellableId: number
  sellableType: 'Ticket'
  pricingPlanId: number
  priceCount: number
  sellableItemsAttributes: SellableItemsAttribute[]
}

interface PayerData {
  formType: string
  formTypeForVat: string
  userProfileAttributes: {
    countryCode: string
    vatNo: Nullable<string>
  }
}

interface BuildParams {
  productId: number
  productSlug: string
  transfer: {
    form: string
  }
  sellables: Sellable[]
  payer: PayerData
}

interface TicketUpgrade {
  paymentTicketId: number
  oldProductId: number
  oldTicketId: number
  oldTicketDateId: number
  newProductId: number
  newTicketDateId: number
  newTicketId: number
  upgradeCount: number
}

interface TicketUpgrades {
  [index: number]: TicketUpgrade
}

interface CreateAndChargeParams extends BuildParams {
  ticketUpgradeOrderId: string
  ticketUpgrades: TicketUpgrades
}

export class UpgradeTicketsStore extends SharedStore<UpgradeTicketsStore> {
  storeName = 'UpgradeTicketsStore'
  declare childApi
  root: ShopRootStore

  @observable buildedOrderData: BuildedOrderDetails = {}
  @observable selectedData: SelectedMappingToUpgrade | Record<string, never> = {}
  @observable buildOrderLoading = false
  @observable createAndChargeResponse: CreateAndChargeResponse
  @observable fraudSessionIdentifier = null
  @observable stripePaymentRequest = null

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)
    this.root = root
  }

  @action toggleBuildOrderLoading = (value: boolean) => (this.buildOrderLoading = value)

  @action setStripePaymentRequest = (value) => (this.stripePaymentRequest = value)

  @action setSelectedData = (data: SelectedMappingToUpgrade) => (this.selectedData = data)

  @action setFraudSessionIdentifier = (value) => (this.fraudSessionIdentifier = value)

  @computed get paramsForSubmit(): BuildParams {
    const preparedData = Object.values(this.selectedData)
    const sellables = preparedData.map((item) => {
      const paymentTicket = this.root.ordersStore.ticketUpgradeData.paymentTickets.find(
        (paymentTicket) => String(paymentTicket.id) === String(item.paymentTicketId)
      )
      const ticketAttendeesAttributes = [omit(paymentTicket.ticketAttendee, ['id', 'fullName', 'fullAddress'])]

      return {
        productId: item.upgradeItemData.product.id,
        sellableId: item.upgradeItemData.ticketDates[0].ticketId,
        sellableType: 'Ticket',
        pricingPlanId: item.upgradeItemData.pricingPlans[0].id,
        priceCount: item[item.upgradeTicketDateId],
        sellableItemsAttributes: [
          {
            productId: item.upgradeItemData.product.id,
            ticketsCount: item[item.upgradeTicketDateId],
            ticketDateId: item.upgradeTicketDateId,
            ticketId: item.upgradeItemData.ticketDates[0].ticketId,
            ticketAttendeesAttributes,
          },
        ],
      }
    }) as Sellable[]

    return {
      productId: this.upgradeWithHighestPrice.product.id,
      productSlug: this.upgradeWithHighestPrice.product.slug,
      transfer: {
        form: this.root.ordersStore.data.paymentForm,
      },
      sellables,
      payer: {
        formType: this.root.ordersStore.data.payerData.formType,
        formTypeForVat: this.root.ordersStore.data.payerData.formTypeForVat,
        userProfileAttributes: {
          vatNo:
            this.root.ordersStore.data.payerData.formTypeForVat === 'business'
              ? this.root.ordersStore.data.payerData.userProfileAttributes.vatNo
              : null,
          countryCode: this.root.ordersStore.data.payerData.userProfileAttributes.countryCode,
        },
      },
    }
  }

  @computed get createAndChargeParams(): CreateAndChargeParams {
    const preparedData = Object.values(this.selectedData)
    const ticketUpgrades = preparedData.reduce((acc, item, index) => {
      const paymentTicket = this.root.ordersStore.ticketUpgradeData.paymentTickets.find(
        (paymentTicket) => String(paymentTicket.id) === String(item.paymentTicketId)
      )
      return {
        ...acc,
        [index]: {
          paymentTicketId: item.paymentTicketId,
          oldProductId: this.root.ordersStore.data.product.id,
          oldTicketId: paymentTicket.ticketId,
          oldTicketDateId: paymentTicket.ticketDate.id,
          newProductId: item.upgradeItemData.product.id,
          newTicketDateId: item.upgradeTicketDateId,
          newTicketId: item.upgradeItemData.ticketDates[0].ticketId,
          upgradeCount: item[item.upgradeTicketDateId],
        },
      }
    }, {}) as TicketUpgrades

    const paramsForSubmit = {
      ...this.paramsForSubmit,
    }

    if (this.fraudSessionIdentifier) {
      const paypalFraudData = {
        paypalFraudToken: this.fraudSessionIdentifier,
      }

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...paypalFraudData,
      }
    }

    return {
      ...paramsForSubmit,
      ticketUpgradeOrderId: this.root.ordersStore.data.token,
      ticketUpgrades,
    }
  }

  @computed get upgradeWithHighestPrice(): { product: Product; pricingPlan: PricingPlan } {
    const upgradeWithHighestPrice = Object.values(this.selectedData).reduce(
      (accumulator, currentValue) => {
        const { price, firstAmount } = currentValue.upgradeItemData.pricingPlans[0].prefs
        const { price: currentPrice, firstAmount: currentFirstAmount } = accumulator?.pricingPlan?.prefs || {}
        const displayPrice = price || firstAmount || 0
        const displayCurrentPrice = currentPrice || currentFirstAmount || 0
        if (Number(displayPrice) >= Number(displayCurrentPrice)) {
          accumulator = {
            product: currentValue.upgradeItemData.product,
            pricingPlan: currentValue.upgradeItemData.pricingPlans[0],
          }
        }
        return accumulator
      },
      {} as { product: Product; pricingPlan: PricingPlan }
    )
    return upgradeWithHighestPrice
  }

  totalPrice = (orderRateIndex = 0) => {
    const { orderRates = [] } = this.buildedOrderData || {}
    const { orderRatePrices = [] } = orderRates[orderRateIndex] || {}

    const total = orderRatePrices.reduce(
      (result, { data }) => {
        const {
          cfgs: { mustPayVat },
          discount,
          fees,
          rate,
        } = data || {}
        const priceKey = mustPayVat ? VAT_TYPE.gross : VAT_TYPE.net

        const getSum = (currentValue, value) =>
          value ? Number((currentValue + value[priceKey] * value.count).toFixed(2)) : currentValue

        const rateSum = getSum(result.rateSum, rate)
        const feesSum = getSum(result.feesSum, fees)
        const discountSum = getSum(result.discountSum, discount)

        return {
          rateSum,
          feesSum,
          discountSum,
        }
      },
      {
        rateSum: 0,
        feesSum: 0,
        discountSum: 0,
      }
    )

    return total.rateSum + total.feesSum + total.discountSum
  }

  checkDigitalMethodsAvailability = async (stripeClient) => {
    const { product, pricingPlan } = this.upgradeWithHighestPrice
    if (stripeClient) {
      const paymentRequestData = {
        country: this.root.ordersStore.data.payerData.userProfileAttributes.countryCode,
        currency: this.root.currenciesStore.getKey(pricingPlan?.currencyId) || DEFAULT_CURRENCY,
        total: {
          label: product?.name || '',
          /** price is being multiplied by 100 because it should be represented in cents */
          amount: (this.totalPrice() || 0) * 100,
        },
        requestPayerName: false,
        requestPayerEmail: false,
      }
      try {
        const paymentRequest = stripeClient.paymentRequest(paymentRequestData)
        this.setStripePaymentRequest(paymentRequest)
        paymentRequest.on('cancel', this.toggleLoading)
        await paymentRequest.canMakePayment()
      } catch (error) {}
    }
  }

  handleCancellationTerm = async () => {
    const { formType } = this.root.ordersStore.data.payerData || {}
    const { cancellationTermId, b2bCancellationTermId } = this.root.ordersStore.ticketUpgradeData?.product || {}
    const {
      item: { username },
    } = this.root.sellerStore

    const hasB2BCancellationTerm =
      b2bCancellationTermId && b2bCancellationTermId !== this.root.cancellationTermsStore.item.id
    if (hasB2BCancellationTerm && formType === 'business') {
      await this.root.cancellationTermsStore.fetchItem(b2bCancellationTermId, { username })
    } else if (cancellationTermId && cancellationTermId !== this.root.cancellationTermsStore.item.id) {
      await this.root.cancellationTermsStore.fetchItem(cancellationTermId, { username })
    }
  }

  @action buildOrder = async (username: string) => {
    this.toggleBuildOrderLoading(true)
    const resp = await this.root.ordersStore.childApi.buildOrder(username, this.paramsForSubmit, false)
    this.buildedOrderData = resp.data
    this.toggleBuildOrderLoading(false)
  }

  @action createAndChargeOrder = async (username) => {
    this.toggleLoading(true)
    const resp = await this.root.ordersStore.childApi.createAndCharge(username, this.createAndChargeParams)
    this.createAndChargeResponse = resp
    this.toggleLoading(false)
    return resp
  }

  @action payForUpgrades = (resp, data) => {
    const { stripeElement, stripeClient } = data
    this.toggleLoading(true)
    const { paymentMethod, redirectLink, clientSecret, success } = resp || this.createAndChargeResponse
    const { paymentForm, provider } = this.root.ordersStore.ticketUpgradeData
    if (success) {
      const sofortProvider = provider
      const shouldHandleStripe = redirectLink && clientSecret && stripeClient
      const isP24 = paymentForm === PAYMENT_FORMS.p24
      const isIdeal = paymentForm === PAYMENT_FORMS.ideal
      const isStripeCard = paymentForm === PAYMENT_FORMS.card
      const isStripeSofort =
        paymentForm === PAYMENT_FORMS.sofort &&
        (sofortProvider === PAYMENT_PROVIDERS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect)
      const isDigitalPayment = paymentForm === PAYMENT_FORMS.applePay || paymentForm === PAYMENT_FORMS.googlePay

      if (shouldHandleStripe && isP24) {
        handleP24Transaction(stripeClient, stripeElement, redirectLink, clientSecret, {
          fullName: this.root.ordersStore.data.payer.fullName,
          email: this.root.ordersStore.data.payer.email,
          tosShowAndAccepted: false,
        })
      }

      if (shouldHandleStripe && isIdeal) {
        handleIdealTransaction(stripeClient, stripeElement, redirectLink, clientSecret, {
          fullName: this.root.ordersStore.data.payer.fullName,
          email: this.root.ordersStore.data.payer.email,
        })
      }

      if (shouldHandleStripe && isStripeSofort) {
        handleSofortTransaction(stripeClient, redirectLink, clientSecret, {
          countryCode: this.root.ordersStore.data.payerData.userProfileAttributes.countryCode,
          billingDetails: this.root.ordersStore.getBillingDetails(),
        })
      }

      if (shouldHandleStripe && (isStripeCard || isDigitalPayment)) {
        if (paymentMethod) {
          handleStripeCardTransaction(stripeClient, { id: paymentMethod }, clientSecret).then((result) =>
            handleTransactionResponse(
              result,
              () => (window.location.href = redirectLink),
              () => this.toggleLoading(false),
              stripeClient,
              clientSecret
            )
          )
        } else {
          if (isDigitalPayment) {
            this.checkDigitalMethodsAvailability(stripeClient)
          }
          this.root.ordersStore.setStripePaymentParams({
            redirectLink,
            clientSecret,
            payerFullName: this.root.ordersStore.data.payer.fullName,
            payerCountryCode:
              this.root.ordersStore.data.payer.countryCode || this.root.ordersStore.data.payer.country?.code,
            payerEmail: this.root.ordersStore.data.payer.email,
          })
          this.toggleLoading(false)
          this.root.ordersStore.togglePopup('postsellPayment')
        }
      }

      if (!shouldHandleStripe || !(isP24 || isStripeCard || isIdeal || isStripeSofort || isDigitalPayment)) {
        window.location.href = redirectLink
      }
    } else {
      this.toggleLoading(false)
    }
    return resp
  }

  @override
  hydrate(key, data) {
    if (key === 'item') {
      this.item = data
    } else if (key === 'buildedOrderData') {
      this.buildedOrderData = data
    } else if (key === 'selectedData') {
      this.selectedData = data
    }
  }
}
