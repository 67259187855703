import { ORDER_RATES_STATES } from './orderRates.constants'

interface RatesStateOptions {
  value: string
  label: string
}
export const ORDER_RATES_STATES_OPTIONS: RatesStateOptions[] = [
  {
    value: ORDER_RATES_STATES.paid,
    label: I18n.t(`react.cabinet.order_rates.state.paid`),
  },
  {
    value: ORDER_RATES_STATES.unpaid,
    label: I18n.t(`react.cabinet.order_rates.state.unpaid`),
  },
  {
    value: ORDER_RATES_STATES.skipped,
    label: I18n.t(`react.cabinet.order_rates.state.skipped`),
  },
  {
    value: ORDER_RATES_STATES.chargebacked,
    label: I18n.t(`react.cabinet.order_rates.state.chargebacked`),
  },
  {
    value: ORDER_RATES_STATES.debt,
    label: I18n.t(`react.cabinet.order_rates.state.debt`),
  },
  {
    value: ORDER_RATES_STATES.refunded,
    label: I18n.t(`react.cabinet.order_rates.state.refunded`),
  },
  {
    value: ORDER_RATES_STATES.pending,
    label: I18n.t(`react.cabinet.order_rates.state.pending`),
  },
  {
    value: ORDER_RATES_STATES.partlyPaid,
    label: I18n.t(`react.cabinet.order_rates.state.partly_paid`),
  },
]
