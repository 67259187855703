import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloHummerIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.00514 1.87871L2.98909 6.89477L4.24481 8.12128L9.26086 3.10523L8.00514 1.87871ZM7.20862 0.907477C7.64431 0.471787 8.34941 0.46764 8.79019 0.898174L10.2248 2.29939C10.6646 2.72898 10.6769 3.42994 10.2585 3.87461L14.1277 7.74384C14.566 7.37253 15.222 7.38976 15.6402 7.7982L17.0747 9.19941C17.5229 9.63717 17.5272 10.3567 17.0842 10.7997L11.8913 15.9925C11.4556 16.4282 10.7505 16.4324 10.3097 16.0019L8.87516 14.6006C8.42698 14.1629 8.42275 13.4433 8.86575 13.0003L8.8661 13L8.43749 12.5714L3.71697 17.2919C2.89299 18.1159 1.5595 18.1237 0.725885 17.3095C-0.121713 16.4816 -0.129718 15.1208 0.708083 14.283L5.4286 9.56249L4.99893 9.13282C4.56103 9.52804 3.88651 9.51866 3.45976 9.10183L2.02518 7.70061C1.57701 7.26286 1.57277 6.54332 2.01577 6.10032L7.20862 0.907477ZM13.2411 8.62499L9.37499 4.75887L5.88387 8.24999L9.74999 12.1161L13.2411 8.62499ZM6.31249 10.4464L7.5536 11.6875L2.83308 16.408C2.49321 16.7479 1.94316 16.7511 1.59931 16.4153C1.24969 16.0738 1.24639 15.5125 1.59197 15.1669L6.31249 10.4464ZM14.8551 8.77874L9.83906 13.7948L11.0948 15.0213L16.1108 10.0053L14.8551 8.77874Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
