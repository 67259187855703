import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

type OnOff = 'on' | 'off'

type Field = 'hidden' | 'visible' | 'required' | 'protected'

interface CommonFields {
  [key: string]: string
  city: Field
  email: Field
  fullName: Field
  street: Field
  streetNumber: Field
  zip: Field
}

export interface PayerForms {
  attendee: CommonFields & {
    country: Field
    company: Field
    phone: Field
    vatNo: Field
  }
  attendeesForm?: {
    opened: OnOff
  }
  business: CommonFields & {
    company: Field
    country: Field
    vatNo: Field
  }
  couponField: {
    visible: OnOff
  }
  emailConfirmation: {
    visible: OnOff
  }
  emailNewsletter: {
    text: string
    visible: OnOff
  }
  free: CommonFields
  gift: CommonFields & {
    company: Field
    country: Field
    vatNo: Field
  }
  includeType: {
    attendee: OnOff
    business: OnOff
    gift: OnOff
    private: OnOff
  }
  private: CommonFields & {
    country: Field
    additionalAddress: Field
    phone: Field
  }
}

interface MenuList {
  default?: boolean
  id: number
  url: string
  name: string
  linkType?: string
  submenu?: MenuList[]
}

interface Header {
  backgroundImage?: string
  logo?: string
  customClassName?: string
  menuColor?: string
  menuList?: MenuList[]
  menuFont?: string
  backgroundColor?: string
  iconColor?: string
  // sharing block
  showSharingOption?: boolean
  // my account switcher block
  myAccountSwitcher?: boolean
  accountColor?: string
  accountFont?: string
  accountSize?: string
  accountStyle?: string
  // Language switcher block
  languageSwitcher?: boolean
  languageColor?: string
  languageFont?: string
  languageSize?: string
  languageStyle?: string
  // Website & social accounts block
  socialsColor?: string
  socialsIsShown?: string
  socialsTitle?: string
  facebook?: string
  instagram?: string
  pinterest?: string
  tumblr?: string
  twitter?: string
  website?: string
  youtube?: string
  linkedin?: string
  xing?: string
}

interface Footer {
  id?: number
  menuList?: {
    id: number
    name: string
    default?: boolean
    submenu?: MenuList[]
  }[]
  backgroundColor?: string
  customClassName?: string
  menuColor?: string
  menuFont?: string
  menuSize?: string
  menuStyle?: string
  submenuColor?: string
  submenuFont?: string
  submenuSize?: string
  submenuStyle?: string
}

export interface ThemePagePrefs {
  showCategories: boolean
  bannerImageUrl: string
  descriptionValue: string
  showFavicon: boolean
  faviconUrl: string
  languages: string[]
  active?: boolean
  products?: string[]
  headerHidden?: boolean
  footerHidden?: boolean
  headerGeneral?: boolean
  noFollow?: boolean
  showFooter: boolean
  showHeader: boolean
  metaTagsEnabled: boolean
  footerFunnelPageHidden: boolean
  showDescription: boolean
  header: Header
  footer: Footer
  cookies: {
    font: string
    color: string
    linkColor: string
  }
  paymentPageTheme: {
    active: boolean
    headerGeneral?: boolean
    headerHidden: boolean
    header: Header
    footer: Footer
    footerGeneral: boolean
  }
  funnelPageTheme: {
    active: boolean
    headerGeneral?: boolean
    headerHidden: boolean
    header: Header
    footer: Footer
    footerGeneral: boolean
  }
  thankYouPageTheme: {
    header: Header
    footer: Footer
    active: boolean
    headerGeneral?: boolean
    headerHidden: boolean
    footerGeneral: boolean
  }
  managePageTheme: {
    active: boolean
    headerGeneral?: boolean
    headerHidden: boolean
    header: Header
    footer: Footer
    footerGeneral: boolean
  }
}

export interface ShopPage {
  slug: string
  form: string
  prefs: ThemePagePrefs
  contentPageId: number
  default: boolean
}

export interface ShopTheme {
  id: number
  form: string
  prefs: ThemePagePrefs
}

const BASE_URL = (username) => `shop/${username}`

export const createShopThemeApi = ({ GET }: ApiClientV2) => ({
  fetchShopTheme: (username: string, id: string | number) => GET<ShopTheme>(`${BASE_URL(username)}/shop_themes/${id}`),
  fetchShopPages: (username: string, themeId: number) =>
    GET<ShopPage[]>(`${BASE_URL(username)}/shop_themes/${themeId}/theme_pages`),
  fetchPayerForms: (username: string, themeId: number) =>
    GET<PayerForms>(`${BASE_URL(username)}/shop_themes/${themeId}/payer_forms`),
})

export type ShopThemeApi = ReturnType<typeof createShopThemeApi>
