import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDigitalProductsIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M3.18628 13.8684V5.31579C3.18628 4.96682 3.3168 4.63214 3.54913 4.38539C3.78147 4.13863 4.09658 4 4.42515 4H15.5749C15.9035 4 16.2186 4.13863 16.451 4.38539C16.6833 4.63214 16.8138 4.96682 16.8138 5.31579V13.8684'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.94739 13.8687H18.0526V15.1844C18.0526 15.5334 17.9221 15.8681 17.6898 16.1148C17.4575 16.3616 17.1424 16.5002 16.8138 16.5002H3.18625C2.85769 16.5002 2.54258 16.3616 2.31024 16.1148C2.07791 15.8681 1.94739 15.5334 1.94739 15.1844V13.8687Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.2388 6.63135H8.76111'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
