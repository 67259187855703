import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloHandCoinsIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.125 4.75C11.125 3.85254 11.8525 3.125 12.75 3.125C13.5242 3.125 14.172 3.66644 14.3353 4.39133C13.6434 4.80718 13.1496 5.51882 13.0287 6.3513C12.9384 6.36686 12.8453 6.375 12.75 6.375C11.8525 6.375 11.125 5.64746 11.125 4.75ZM13.1337 7.59954C13.0081 7.61634 12.8799 7.625 12.75 7.625C11.1622 7.625 9.875 6.33782 9.875 4.75C9.875 3.16218 11.1622 1.875 12.75 1.875C14.0815 1.875 15.2016 2.78019 15.5285 4.00879C15.6016 4.00297 15.6755 4 15.75 4C17.2688 4 18.5 5.23122 18.5 6.75C18.5 8.26878 17.2688 9.5 15.75 9.5C14.5277 9.5 13.4916 8.70255 13.1337 7.59954ZM17.25 6.75C17.25 7.57843 16.5784 8.25 15.75 8.25C14.9216 8.25 14.25 7.57843 14.25 6.75C14.25 5.92157 14.9216 5.25 15.75 5.25C16.5784 5.25 17.25 5.92157 17.25 6.75ZM6.26886 9.54218C6.7461 9.11266 7.36543 8.875 8.00749 8.875H12.25C13.4236 8.875 14.375 9.8264 14.375 11C14.375 11.1352 14.3624 11.2675 14.3382 11.3957L17.2829 10.7413C18.4848 10.4742 19.625 11.3889 19.625 12.6201C19.625 13.4071 19.1459 14.1148 18.4152 14.4071L15.4821 15.5803C15.4559 15.5908 15.429 15.5995 15.4016 15.6063L10.4016 16.8563C10.352 16.8687 10.3011 16.875 10.25 16.875H2.5C1.60254 16.875 0.875 16.1475 0.875 15.25V12.75C0.875 11.8525 1.60254 11.125 2.5 11.125H4.51016L6.26886 9.54218ZM13.0849 12.9547L17.554 11.9616C17.9753 11.8679 18.375 12.1885 18.375 12.6201C18.375 12.896 18.2071 13.144 17.9509 13.2465L15.0573 14.4039L10.1731 15.625H5.375V12.0284L7.10506 10.4713C7.35277 10.2484 7.67423 10.125 8.00749 10.125H12.25C12.7332 10.125 13.125 10.5168 13.125 11C13.125 11.3293 12.943 11.6162 12.6742 11.7655L12.1814 11.875H9.25C8.90482 11.875 8.625 12.1548 8.625 12.5C8.625 12.8452 8.90482 13.125 9.25 13.125H12.25C12.5464 13.125 12.8286 13.0643 13.0849 12.9547ZM4.125 12.375H2.5C2.29289 12.375 2.125 12.5429 2.125 12.75V15.25C2.125 15.4571 2.29289 15.625 2.5 15.625H4.125V12.375Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
