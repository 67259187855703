import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCheckboxSelectedIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.90918 0.910156C2.70004 0.910156 0.90918 2.70102 0.90918 4.91016V15.092C0.90918 17.3011 2.70004 19.092 4.90918 19.092H15.091C17.3001 19.092 19.091 17.3011 19.091 15.092V4.91016C19.091 2.70102 17.3001 0.910156 15.091 0.910156H4.90918ZM15.2639 7.61775C15.4951 7.36143 15.4747 6.96622 15.2184 6.73504C14.9621 6.50385 14.5669 6.52422 14.3357 6.78054L8.62764 13.109L5.22569 9.94171C4.97306 9.7065 4.57758 9.72062 4.34237 9.97326C4.10716 10.2259 4.12128 10.6214 4.37392 10.8566L8.24058 14.4566C8.36316 14.5707 8.5264 14.6308 8.69371 14.6235C8.86103 14.6162 9.01841 14.5421 9.13058 14.4177L15.2639 7.61775Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
