export const checkBoldItalic = (style: string) => (value: string) => value === 'boldItalic' ? style : value

export const getFontStyle = checkBoldItalic('italic')

export const getFontWeight = checkBoldItalic('bold')

export const getFontStyles = (value = 'normal', withImportant = false) => {
  const styleValue = getFontStyle(value)
  const weightValue = getFontWeight(value)
  const fontStyle = ['normal', 'italic', 'boldItalic'].includes(styleValue) ? styleValue : 'normal'

  const fontWeight = weightValue === 'bold' ? weightValue : 'normal'

  const textDecoration = value === 'underline' ? value : 'none'

  return withImportant
    ? {
        fontStyle: `${fontStyle} !important`,
        fontWeight: `${getFontWeight(value)} !important`,
        textDecoration: `${textDecoration} !important`,
      }
    : {
        fontStyle,
        fontWeight,
        textDecoration,
      }
}
