export enum STEPS {
  MINUS_ONE = -1, // DELETION NOT POSSIBLE
  ZERO, // QUESTINNAIRE
  ONE, // DELETION CHECK
  TWO, // DELETION POSSIBLE
  THREE, // DELETION CONFIRMATION
  FOUR, //  DELETION NOT POSSIBLE, DEACTIVATION POSSIBLE
  FIVE, //  DEACTIVATION CONFIRMATION
}

export const DELETION_REASONS = [
  'dont_need_anymore',
  'my_account_not_successful',
  'changing_my_business_strategy',
  'switching_to_another_platform',
  'too_expensive',
  'other',
]

export const DELETION_REASON_LABELS = {
  dont_need_anymore: I18n.t('react.cabinet.seller_deletion.questionnaire.dont_need_anymore'),
  my_account_not_successful: I18n.t('react.cabinet.seller_deletion.questionnaire.my_account_not_successful'),
  changing_my_business_strategy: I18n.t('react.cabinet.seller_deletion.questionnaire.changing_my_business_strategy'),
  switching_to_another_platform: I18n.t('react.cabinet.seller_deletion.questionnaire.switching_to_another_platform'),
  too_expensive: I18n.t('react.cabinet.seller_deletion.questionnaire.too_expensive'),
  other: I18n.t('react.cabinet.seller_deletion.questionnaire.other'),
  other_description: I18n.t('react.cabinet.seller_deletion.questionnaire.other_description'),
}

export const DELETION_STATES = {
  empty: 'empty',
  inactivePlan: 'inactive_plan',
  dataScrambled: 'data_scrambled',
  deleted: 'deleted',
}

export const DELETION_STATES_LABELS = {
  empty: I18n.t('react.shared.empty'),
  inactivePlan: I18n.t('react.shared.inactive_plan'),
  dataScrambled: I18n.t('react.shared.data_scrambled'),
  deleted: I18n.t('react.shared.deleted'),
}

export const DELETION_STATES_OPTIONS = Object.keys(DELETION_STATES).map((key) => ({
  value: DELETION_STATES[key],
  label: DELETION_STATES_LABELS[key],
}))

export const WARNING_KEYS = [
  'readonly_access',
  'customer_access',
  'unpublish_products',
  'shop_empty',
  'uploaded_videos',
  'uploaded_media_files',
  'subdomain',
  'afilliate',
  'outstanding_invoice',
  'irreversible_process',
  'legal_retention_periods',
]

export const CHECK_RESULT_LOCALES = {
  riskyTransfers: {
    success: I18n.t('react.cabinet.seller_deletion.check_result.no_transactions'),
    failed: I18n.t('react.cabinet.seller_deletion.check_result.transactions_reversed'),
  },
  activeOrders: {
    success: I18n.t('react.cabinet.seller_deletion.check_result.no_active_orders'),
    failed: I18n.t('react.cabinet.seller_deletion.check_result.active_orders'),
  },
  notPayOutedTransfers: {
    success: I18n.t('react.cabinet.seller_deletion.check_result.no_pending_payouts'),
    failed: I18n.t('react.cabinet.seller_deletion.check_result.have_pending_payouts'),
  },
  unpaidMonthlyInvoices: {
    success: I18n.t('react.cabinet.seller_deletion.check_result.no_unpaid_invoices'),
    failed: I18n.t('react.cabinet.seller_deletion.check_result.unpaid_invoices'),
  },
}

export const MODAL_TITLE = {
  [STEPS.MINUS_ONE]: I18n.t('react.cabinet.seller_deletion.deletion_not_possible_title'),
  [STEPS.ZERO]: I18n.t('react.cabinet.seller_deletion.why_are_you_deleting'),
  [STEPS.ONE]: I18n.t('react.cabinet.seller_deletion.preparing_account_title'),
  [STEPS.TWO]: I18n.t('react.cabinet.seller_deletion.ready_to_be_deleted'),
  [STEPS.THREE]: I18n.t('react.cabinet.seller_deletion.confirm_account_deletion'),
  [STEPS.FOUR]: I18n.t('react.cabinet.seller_deletion.first_deactivate_account'),
  [STEPS.FIVE]: I18n.t('react.cabinet.seller_deletion.confirm_account_deactivation'),
}

export const ACCOUNT_STATUS = {
  active: 'Active',
  deactivated: 'Deactivated',
  deleted: 'Deleted',
}

export const DELETION_TYPE = {
  deactivation: 'deactivation',
  deletion: 'deletion',
}
