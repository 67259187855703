export const PUBLISHER_DETAILS_PATH = '/publish/details'
export const PUBLISHER_PROFILE_PATH = '/publish/profile/edit'
export const PUBLISHER_BANK_ACCOUNTS_PATH = '/publish/bank_accounts'
export const PUBLISHER_NOTIFICATIONS_PATH = '/publish/email_notification'
export const PUBLISHER_OVERVIEW_PATH = '/publish'
export const PUBLISHER_ORDERS_PATH = '/publish/orders'
export const PUBLISHER_TRANSFERS_PATH = '/publish/transfers'
export const PUBLISHER_AFFILIATE_PROGRAMS_PATH = '/publish/affiliate_programs'
export const PUBLISHER_AFFILIATE_MARKETPLACE_PATH = '/publish/affiliate_marketplace'
export const PUBLISHER_AFFILIATE_MARKETPLACE_PRODUCT_PATH = '/publish/affiliate_marketplace/:id'
export const PUBLISHER_AFFILIATE_NODES_PATH = '/publish/affiliate_nodes'
export const PUBLISHER_CREATE_AFFILIATE_NODES_PATH = '/publish/affiliate_nodes/new'
export const PUBLISHER_STATISTICS_OVERVIEW_PATH = '/publish/statistics'
export const PUBLISHER_STATISTICS_PROGRAMS_PATH = '/publish/statistics_programs'
export const PUBLISHER_STATISTICS_PRODUCTS_PATH = '/publish/statistics_products'
export const PUBLISHER_STATISTICS_SELLERS_PATH = '/publish/statistics_sellers'
export const PUBLISHER_STATISTICS_AFFILIATE_PATH = '/publish/statistics_affiliate'
export const PUBLISHER_PAYOUTS_SETTINGS = '/publish/payouts_settings'
export const PUBLISHER_PAYOUTS_SCHEDULE_PATH = '/publish/payouts_balance'
export const PUBLISHER_PAYOUTS_PATH = '/publish/payouts'
export const PUBLISHER_EMBEDDABLE_ITEMS_PATH = '/publish/embeddable_items'
export const PUBLISHER_CREATE_EMBEDDABLE_ITEMS_PATH = '/publish/embeddable_items/new'
export const PUBLISHER_EDIT_EMBEDDABLE_ITEMS_PATH = '/publish/embeddable_items/:id/edit'
export const PUBLISHER_PREVIEW_EMBEDDABLE_ITEMS_PATH = '/publish/embeddable_items/:id/preview'
export const PUBLISHER_NOT_FOUND_PATH = '*'
