export const FUNNEL_FORMS = {
  page: 'node_page',
  link: 'node_link',
}

export const FUNNEL_FORMS_OPTIONS = [
  {
    value: 'node_page',
    label: I18n.t('react.cabinet.common.funnelPage'),
  },
  {
    value: 'node_link',
    label: I18n.t('react.cabinet.funnels.form.link'),
  },
]

export const FUNNEL_DIRECTIONS_OPTIONS = [
  {
    value: 'yes',
    label: I18n.t('react.cabinet.funnels.add_node_yes'),
  },
  {
    value: 'no',
    label: I18n.t('react.cabinet.funnels.add_node_no'),
  },
]

export const FUNNEL_STATISTIC_METRICS = [
  {
    key: 'views',
    label: I18n.t('react.cabinet.seller_statistics.visitors'),
    icon: 'fas fa-eye',
  },
  {
    key: 'sold',
    label: I18n.t('react.cabinet.seller_statistics.customers'),
    icon: 'fas fa-shopping-basket',
  },
  {
    key: 'rejected',
    label: I18n.t('react.cabinet.seller_statistics.canceled'),
    icon: 'fas fa-times-circle',
  },
  {
    key: 'skipped',
    label: I18n.t('react.cabinet.seller_statistics.previously_purchased'),
    icon: 'fas fa-check-square',
  },
  {
    key: 'revenue',
    label: I18n.t('react.cabinet.seller_statistics.revenue'),
    icon: 'fas fa-wallet',
  },
]

export const FUNNEL_LOGS_FORMS = {
  accepted: 'accepted',
  rejected: 'rejected',
  skipped: 'skipped',
  redirected: 'redirected',
}

export const FUNNEL_SKIP_FORMS = {
  noSkip: 'no_skip',
  anySold: 'any_sold',
  anyToSell: 'any_to_sell',
}

export const DEFAULT_FUNNEL_STATISTIC_DATA = {
  sold: 0,
  views: 0,
}
