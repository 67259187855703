import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCardholderIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.125 4H3.375C2.61561 4 2 4.62183 2 5.38889V15.1111C2 15.8782 2.61561 16.5 3.375 16.5H17.125C17.8844 16.5 18.5 15.8782 18.5 15.1111V5.38889C18.5 4.62183 17.8844 4 17.125 4Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 9.55567H6.88125C7.03958 9.55479 7.19324 9.60983 7.31565 9.71128C7.43805 9.81273 7.5215 9.9542 7.55156 10.1112C7.68285 10.7358 8.02252 11.2959 8.51373 11.6978C9.00493 12.0997 9.61788 12.3189 10.25 12.3189C10.8821 12.3189 11.4951 12.0997 11.9863 11.6978C12.4775 11.2959 12.8171 10.7358 12.9484 10.1112C12.9785 9.9542 13.0619 9.81273 13.1844 9.71128C13.3068 9.60983 13.4604 9.55479 13.6187 9.55567H18.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M2 6.77783H18.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </EloBaseIcon>
)
