import { observable, action, override, makeObservable } from 'mobx'

import { COLORS } from '@elo-kit/constants/general.constants'
import { ORDER_BUMPS_POSITIONS_DEFAULT, PAYMENT_PAGE_BUTTON_OPTIONS } from 'constants/paymentPageTemplates.constants'

import { setFavicon } from 'utils/dom.utils'
import { getDefaultShopTheme } from 'utils/themesShared.utils'
import { getSearchParams } from 'utils/queryString.utils'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { PaymentPageTemplate, DisplayData, ThemeApi, createThemeApi } from 'shop/api/theme.api'

interface ThemePage {
  slug: string
  form: string
  css: string
  themePages: {
    contentPageId: number
    slug: string
    prefs: { metaTagsEnabled?: boolean }
    form: string
  }[]
  dataCollections: Record<string, unknown>
  prefs?: DisplayData
  contentPageId: number
  default: boolean
}

interface Data {
  form: string
  css: string
  prefs?: DisplayData
  themePages: ThemePage[]
}

export class ThemeStore extends SharedStore<any> {
  storeName = 'ThemeStore'
  declare childApi: ThemeApi
  root

  constructor(root?: ShopRootStore) {
    super()
    this.childApi = createThemeApi(root?.apiClient ?? apiClient)
    this.root = root
    makeObservable(this)
  }

  @observable data: Data = {
    form: 'default',
    css: '',
    prefs: {} as DisplayData,
    themePages: [],
  }

  @observable displayData: Partial<DisplayData> = {
    header: {},
    footer: {},
    headerHidden: false,
    footerHidden: false,
  }

  @observable ppTemplate: Partial<PaymentPageTemplate> = {
    layout: 'default',
    theme: {
      upsellPosition: ORDER_BUMPS_POSITIONS_DEFAULT,
      paymentApplyCouponBtnColor: COLORS.eloNeutralBlack,
      paymentApplyCouponBtnFontColor: '#ffffff',
      paymentApplyCouponBorderRadius: 22,
      paymentBgColor: null,
      paymentBuyBtnText: PAYMENT_PAGE_BUTTON_OPTIONS[0].value,
      paymentBuyBtnColor: null,
      paymentBuyBtnFontColor: null,
      paymentBuyBtnBorderRadius: 22,
      paymentPageColor: null,
      paymentRecommendedBadgeColor: COLORS.eloBlue,
      paymentRecommendedBadgeTextColor: COLORS.eloWhite,
      paymentCountdownBgColor: '#6ce369',
      paymentCountdownBorderColor: '#6ce369',
      paymentCountdownTextColor: '#21282e',
      paymentCountdownTimeColor: '#21282e',
    },
    buyerInformation: {
      private: {},
      business: {},
      attendee: null,
      free: {},
      gift: null,
      includeType: {
        private: 'on',
        business: 'on',
        gift: 'on',
      },
    },
  }

  @action setData = (data) =>
    (this.data = {
      ...this.data,
      ...data,
    })

  @action setPpTemplate = (ppTemplate: Partial<PaymentPageTemplate>) =>
    (this.ppTemplate = {
      ...this.ppTemplate,
      ...ppTemplate,
      theme: {
        ...this.ppTemplate.theme,
        ...ppTemplate.theme,
      },
    })

  @action mergeDisplayData = (object: DisplayData) => {
    if (this.displayData.headerHidden === true && object?.headerHidden === false) {
      this.displayData = {
        ...this.displayData,
        ...object,
        headerHidden: true,
      }
    } else {
      this.displayData = {
        ...this.displayData,
        ...object,
      }
    }
  }

  @action setDisplayData = (object: Partial<DisplayData>) => (this.displayData = object)

  @action resetDisplayData = () => {
    this.setDisplayData(this.data.prefs)
  }

  @action fetchInitTheme = async (username: string, sellerThemeId: number, isCustomizationAppActive: boolean) => {
    const themeId = (getSearchParams()?.shop_theme_id || sellerThemeId) as number

    await this.fetchShopTheme(username, themeId, isCustomizationAppActive)

    this.loading = false
  }

  @action fetchShopTheme = async (username: string, reseller: string | number, isCustomizationAppActive: boolean) => {
    const resp = await this.childApi.fetchShopTheme(username, reseller)

    const { data, success } = resp

    if (success) {
      const { buyerInformation, prefs } = data

      const shopThemeData = isCustomizationAppActive ? data : getDefaultShopTheme(data)

      this.setData(shopThemeData)

      this.resetDisplayData()
      this.setPpTemplate({ buyerInformation })

      const { showFavicon, faviconUrl } = prefs
      if (showFavicon) {
        faviconUrl && setFavicon(faviconUrl)
      } else {
        setFavicon('/favicon.ico')
      }
    }
  }

  @action fetchMembershipTheme = async (username: string, id: string | number) => {
    const resp = await this.childApi.fetchMembershipTheme(username, id)
    const { data, success } = resp
    success && this.setData(data)
  }

  fetchPaymentPageTemplate = async (username: string, paymentPageTemplateId: number) => {
    const resp = await this.childApi.fetchPaymentPageTemplate(username, paymentPageTemplateId)

    const { data, success } = resp

    if (success) {
      this.setPpTemplate(data)
    }
  }

  getApplyCouponBtnStyle = () => {
    const { paymentApplyCouponBtnColor, paymentApplyCouponBtnFontColor, paymentApplyCouponBorderRadius } =
      this.ppTemplate.theme

    return {
      backgroundColor: paymentApplyCouponBtnColor,
      color: paymentApplyCouponBtnFontColor,
      borderRadius: `${paymentApplyCouponBorderRadius}px`,
      border: 'none',
    }
  }

  getBuyBtnStyle = (disabled: number) => {
    const { paymentBuyBtnColor, paymentBuyBtnFontColor, paymentBuyBtnBorderRadius } = this.ppTemplate.theme

    return {
      backgroundColor: disabled ? '' : paymentBuyBtnColor,
      color: paymentBuyBtnFontColor,
      borderRadius: `${paymentBuyBtnBorderRadius}px`,
    }
  }

  fetchPaymentPageTemplatePreview = async (data: { username: string; token: string | number }) => {
    const resp = await this.childApi.fetchPaymentPageTemplatePreview(data)

    const { data: paymentPageData, success } = resp

    if (success) {
      this.setPpTemplate(paymentPageData)
    }
  }

  @override hydrate(key, data) {
    // TODO: ppTemplate is used to store data from two different endpoints
    // and should be hydrated in propper ordeder
    if (key === 'ppTemplate') {
      this.setPpTemplate(data)
    } else if (key === 'displayData') {
      this.displayData = data
    } else if (key === 'data') {
      this.setData(data)
      const { buyerInformation } = data
      this.resetDisplayData()
      this.setPpTemplate({ buyerInformation })
    }
  }
}

export default new ThemeStore()
