import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloViewIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M10 4.87502C3.75 4.87502 1.25 10.5 1.25 10.5C1.25 10.5 3.75 16.125 10 16.125C16.25 16.125 18.75 10.5 18.75 10.5C18.75 10.5 16.25 4.87502 10 4.87502Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 13.625C11.7259 13.625 13.125 12.2259 13.125 10.5C13.125 8.77409 11.7259 7.37498 10 7.37498C8.27411 7.37498 6.875 8.77409 6.875 10.5C6.875 12.2259 8.27411 13.625 10 13.625Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
