import type { Nullable } from 'types/helpers'
import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface FetchParams {
  token: string
  code: string
}
interface UpdateParams {
  token: string
  code: string
  password: string
}

const BASE_URL = (username, token) => `/shop/${username}/payment_tickets/${token}`

export interface PaymentTicket {
  ticketId: number
  ticketPassword: string
  badgeCls: 'success' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' // TODO: ts - separate type
  event: string
  id: number
  locationName: string
  publisher: Nullable<string>
  state: 'expired' | 'refunded' | 'unused' | 'used' // TODO: ts - separate type
  ticketAddress: string
  ticketEmail: string
  ticketDate: string
  ticketName: string
  unused: boolean
  pricingPlan: {
    // TODO: ts - separate type
    name: string
    form: 'installment' | 'limited_subscription' | 'one_time' | 'subscription'
  }
}

export const createTicketsApi = ({ GET, PATCH }: ApiClient) => ({
  fetchItem: (username: string, data: FetchParams) =>
    GET<Response<PaymentTicket>>(BASE_URL(username, data.token), data),

  updateItem: (username: string, data: UpdateParams) =>
    PATCH<Response<PaymentTicket>>(BASE_URL(username, data.token), data),
})

export type TicketsApi = ReturnType<typeof createTicketsApi>
