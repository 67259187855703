import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { createTrackingLogApi, TrackingLog, TrackingLogApi } from 'shop/api/trackingLog.api'

export class TrackingLogStore extends SharedStore<TrackingLog> {
  storeName = 'TrackingLogStore'
  declare childApi: TrackingLogApi

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)
    this.childApi = createTrackingLogApi(root.apiClientV2)
  }

  trackingLog = async (data: {
    form: string
    username: string
    trackingCodeId: number
    trackingEventId?: Record<string, never> | number
    sellerId?: number
    trackingCodeForm?: string
    info?: {
      name?: string
      form?: string
      code: string
      customerToken: string | boolean
      orderId?: number
    }
    orderId?: number
  }) => await this.childApi.trackingLog(data)
}
