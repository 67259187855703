import {
  apiVersion,
  apiEndpoints,
  apiEndpointsSSR,
  apiEndpointsCommunity,
  webProtocols,
  webHosts,
  facebookAppId,
  cookiesDomains,
  reCaptchaKey,
  amplifyConfig,
  sentryConfig,
  sentryConfigCabinets,
  marketingWebHosts,
  metabaseHosts,
} from 'libs/configs'
// eslint-disable-next-line
import { LocalStorageService } from 'utils/local-storage.utils'

const integrationServiceEndpoints = {
  test: 'https://auth.staging.myablefy.com',
  development: 'https://auth.staging.myablefy.com',
  staging: 'https://auth.staging.myablefy.com',
  uat: 'https://auth.uat.myablefy.com',
  production: 'https://auth.myablefy.com',
}

const elopageConfig = {
  apiVersion,
  mode: process.env.NODE_ENV,
  env: process.env.ENV,
  apiPath: apiEndpoints[process.env.ENV],
  apiPathSSR: apiEndpointsSSR[process.env.ENV],
  apiPathCommunity: apiEndpointsCommunity[process.env.ENV],
  fbAppId: facebookAppId,
  webHost: webHosts[process.env.ENV],
  webProtocol: webProtocols[process.env.ENV],
  cookiesDomain: cookiesDomains[process.env.ENV],
  reCaptchaKey,
  amplifyConfig: amplifyConfig[process.env.ENV],
  integrationServiceHost: integrationServiceEndpoints[process.env.ENV],
  hostname: '',
  isNextApp: process.env.NEXT_PUBLIC_IS_NEXT_JS_APP,
  sentryConfig: sentryConfig[process.env.ENV],
  sentryConfigCabinets: sentryConfigCabinets[process.env.ENV],
  marketingWebHost: marketingWebHosts[process.env.ENV],
  metabaseHost: metabaseHosts[process.env.ENV],
}

type ElopageConfigKey = keyof typeof elopageConfig
type AmplifyConfig = keyof typeof amplifyConfig.production
type SentryConfig = keyof typeof sentryConfig.production
type ElopageConfig = string | AmplifyConfig | SentryConfig

export const getElopageConfig = (key: ElopageConfigKey): ElopageConfig => elopageConfig[key]

export const setElopageConfig = (key: ElopageConfigKey, value: string): void => {
  elopageConfig[key] = value
}

//TODO: Need to set the correct date  before release
export const getBundleBanDate = () => LocalStorageService.getItem('bundleBanDate') || '2022-11-10'

//TODO: Need to set the correct date  before release
export const getPerformancePeriodReleaseDate = () => {
  const date = LocalStorageService.getItem('performancePeriodRelease', false)
  return (typeof window !== 'undefined' && date) || '2023-03-19'
}
