import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloCaretRightIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M7.25 3.125L14.25 10.125L7.25 17.125'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
