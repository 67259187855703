import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpTwoStepThird = (props) => {
  const { height, viewBox, width } = props
  /* eslint-disable */
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='249' rx='8' />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -47.000000) '
            x='53'
            y='23'
            width='115'
            height='48'
            rx='4'
          />
          <path
            d='M136.940549,55 C138.052088,55 138.938534,55.8954305 138.938534,57 C138.938534,58.1122704 138.044006,59 136.940549,59 L136.940549,59 L101.997985,59 C100.886447,59 100,58.1045695 100,57 L100,57 L100.00548,56.8497808 C100.081752,55.8088611 100.944685,55 101.997985,55 L101.997985,55 Z M154.00693,45 C155.097692,45 156,45.8954305 156,47 C156,48.1122704 155.107672,49 154.00693,49 L154.00693,49 L101.99307,49 C100.902308,49 100,48.1045695 100,47 L100,47 L100.005467,46.8497808 C100.081551,45.8088611 100.942361,45 101.99307,45 L101.99307,45 Z M154.00693,35 C155.097692,35 156,35.8954305 156,37 C156,38.1122704 155.107672,39 154.00693,39 L154.00693,39 L101.99307,39 C100.902308,39 100,38.1045695 100,37 L100,37 L100.005467,36.8497808 C100.081551,35.8088611 100.942361,35 101.99307,35 L101.99307,35 Z'
            fill='#E5E3E5'
          />
          <rect id='Rectangle-Copy-11' fill='#E5E3E5' x='65' y='35' width='24' height='23.9835004' rx='4' />
          <path
            d='M81.875,52 C82.4963226,52 83,51.4403585 83,50.75 L83,43.25 C83,42.5596415 82.4963226,42 81.875,42 L72.125,42 C71.5036774,42 71,42.5596415 71,43.25 L71,50.75 C71,51.4403585 71.5036774,52 72.125,52 L81.875,52 Z M73.5,46 C72.6715785,46 72,45.3284215 72,44.5 C72,43.6715785 72.6715785,43 73.5,43 C74.3284215,43 75,43.6715785 75,44.5 C75,45.3284215 74.3284215,46 73.5,46 Z M82,50 L73,50 L73,48.817906 L74.6761017,47.0567178 C74.785965,46.9413029 74.964035,46.9413029 75.0738754,47.0567178 L76.375,48.4238747 L79.5511017,45.0865611 C79.660965,44.9711463 79.839035,44.9711463 79.9488754,45.0865611 L82,47.2417807 L82,50 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(110.500000, 160.000000) scale(1, -1) rotate(-180.000000) translate(-110.500000, -160.000000) '
            x='53'
            y='79'
            width='115'
            height='162'
            rx='4'
          />
          <path
            d='M53,83.0018785 C53,80.791702 54.7979678,79 57.0089246,79 L112,79 L112,108 L53,108 L53,83.0018785 Z'
            fill='#E5E3E5'
            transform='translate(82.500000, 93.500000) scale(-1, -1) rotate(-180.000000) translate(-82.500000, -93.500000) '
          />
          <path
            d='M153.99328,154 C155.099341,154 156,154.895431 156,156 C156,157.11227 155.101561,158 153.99328,158 L153.99328,158 L67.00672,158 C65.900659,158 65,157.104569 65,156 L65,156 L65.0055042,155.849781 C65.0821098,154.808861 65.9488155,154 67.00672,154 L67.00672,154 Z M153.99328,145 C155.099341,145 156,145.895431 156,147 C156,148.11227 155.101561,149 153.99328,149 L153.99328,149 L67.00672,149 C65.900659,149 65,148.104569 65,147 L65,147 L65.0055042,146.849781 C65.0821098,145.808861 65.9488155,145 67.00672,145 L67.00672,145 Z M153.99328,136 C155.099341,136 156,136.895431 156,138 C156,139.11227 155.101561,140 153.99328,140 L153.99328,140 L67.00672,140 C65.900659,140 65,139.104569 65,138 L65,138 L65.0055042,137.849781 C65.0821098,136.808861 65.9488155,136 67.00672,136 L67.00672,136 Z M153.99328,127 C155.099341,127 156,127.895431 156,129 C156,130.11227 155.101561,131 153.99328,131 L153.99328,131 L67.00672,131 C65.900659,131 65,130.104569 65,129 L65,129 L65.0055042,128.849781 C65.0821098,127.808861 65.9488155,127 67.00672,127 L67.00672,127 Z M153.99328,118 C155.099341,118 156,118.895431 156,120 C156,121.11227 155.101561,122 153.99328,122 L153.99328,122 L67.00672,122 C65.900659,122 65,121.104569 65,120 L65,120 L65.0055042,119.849781 C65.0821098,118.808861 65.9488155,118 67.00672,118 L67.00672,118 Z'
            fill='#E5E3E5'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='65' y='213' width='90' height='15.821267' rx='7.91063348' />
          <path
            d='M69.536,88 L69.536,99.28 L66.48,99.28 L66.48,90.896 L64.592,91.328 L64,88.928 L67.28,88 L69.536,88 Z M93.9931517,89 C95.6640085,89 97,90.3442086 97,92.002374 L97,92.002374 L97,95.4048658 C97,97.0594117 95.6537881,98.4072398 93.9931517,98.4072398 L93.9931517,98.4072398 L77.0068483,98.4072398 C75.3359915,98.4072398 74,97.0630312 74,95.4048658 L74,95.4048658 L74,92.002374 C74,90.3478281 75.3462119,89 77.0068483,89 L77.0068483,89 Z'
            fill='#FFFFFF'
          />
          <g transform='translate(118.000000, 88.000000)' fill='#E5E3E5'>
            <path d='M4.624,-4.08562073e-13 C5.85066667,-4.08562073e-13 6.84533333,0.333333333 7.608,1 C8.37066667,1.66666667 8.752,2.53333333 8.752,3.6 C8.752,4.44266667 8.54933333,5.144 8.144,5.704 C7.73866667,6.264 7.07733333,6.86933333 6.16,7.52 L6.16,7.52 L4.24,8.864 L8.864,8.864 L8.864,11.392 L4.22062385e-12,11.392 L4.22062385e-12,9.056 L4.192,5.856 L4.380875,5.710125 C4.80495833,5.37470833 5.11266667,5.074 5.304,4.808 C5.52266667,4.504 5.632,4.18133333 5.632,3.84 C5.632,3.488 5.51733333,3.21066667 5.288,3.008 C5.05866667,2.80533333 4.75733333,2.704 4.384,2.704 C4.02133333,2.704 3.672,2.81333333 3.336,3.032 C3,3.25066667 2.608,3.62133333 2.16,4.144 L2.16,4.144 L0.064,2.4 L0.278123457,2.13096296 C0.851226337,1.43565432 1.44118519,0.917333333 2.048,0.576 C2.73066667,0.192 3.58933333,-4.08562073e-13 4.624,-4.08562073e-13 Z M32.9931517,1 C34.6640085,1 36,2.34420863 36,4.002374 L36,4.002374 L36,7.40486582 C36,9.05941172 34.6537881,10.4072398 32.9931517,10.4072398 L32.9931517,10.4072398 L16.0068483,10.4072398 C14.3359915,10.4072398 13,9.06303119 13,7.40486582 L13,7.40486582 L13,4.002374 C13,2.3478281 14.3462119,1 16.0068483,1 L16.0068483,1 Z' />
          </g>
          <rect
            fill='#B8B9BC'
            fillRule='nonzero'
            transform='translate(111.000000, 185.000000) scale(1, -1) rotate(-180.000000) translate(-111.000000, -185.000000) '
            x='66'
            y='166'
            width='90'
            height='38'
            rx='4'
          />
          <path
            d='M121.963284,194.831683 C123.332381,194.831683 124.442245,193.72182 124.442245,192.352723 L124.442245,177.47896 C124.442245,176.109863 123.332381,175 121.963284,175 L100.47896,175 C99.1098633,175 98,176.109863 98,177.47896 L98,192.352723 C98,193.72182 99.1098633,194.831683 100.47896,194.831683 L121.963284,194.831683 Z M103.784241,183.676362 C102.186978,183.676362 100.892121,182.381504 100.892121,180.784241 C100.892121,179.186978 102.186978,177.892121 103.784241,177.892121 C105.381504,177.892121 106.676362,179.186978 106.676362,180.784241 C106.676362,182.381504 105.381504,183.676362 103.784241,183.676362 Z M121.136964,191.526403 L101.305281,191.526403 L101.305281,189.047442 L104.998605,185.354068 C105.240691,185.112032 105.633072,185.112032 105.875107,185.354068 L108.742162,188.221122 L115.740767,181.222467 C115.982853,180.980432 116.375234,180.980432 116.617269,181.222467 L121.136964,185.742162 L121.136964,191.526403 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
            opacity='0.5'
          />
          <g>
            <path
              d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
              fill='#D7DADF'
            />
            <path
              d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
              fill='#FFFFFF'
            />
            <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
          </g>
        </g>
      </g>
    </svg>
  )
  /* eslint-enable */
}

BumpTwoStepThird.displayName = 'BumpTwoStepThird'
BumpTwoStepThird.propTypes = propTypes
BumpTwoStepThird.defaultProps = defaultProps
