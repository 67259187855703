import isURL from 'is-url'

import { LINK_TYPE } from '../constants//block.constants'

import { POSITIONS, ZERO_DATA, PADDING_UNITS, SLASH_SEPARATOR } from '../constants/general.constants'

import { getWindow, getArrayItemById } from './helpers.utils'

interface MenuItem {
  url?: string
  default?: boolean
  linkType?: string
}

export const getPadding = (padding: number, defaultValue = ZERO_DATA, units = PADDING_UNITS.px) =>
  `${padding || (padding ?? defaultValue)}${units}`

export const getAlignment = (position: keyof typeof POSITIONS) => {
  switch (position) {
    case POSITIONS.right:
    case POSITIONS.bottom:
      return 'flex-end'
    case POSITIONS.top:
    case POSITIONS.left:
      return 'flex-start'
    case POSITIONS.center:
    default:
      return 'center'
  }
}

export const internalURL = (
  baseURL = '',
  menuItem: MenuItem,
  themePages: { id: number; slug: string }[] = [],
  username = ''
) => {
  const protectedWindow: { location?: { pathname: string } } = getWindow()

  const mainPath = baseURL || protectedWindow?.location?.pathname || ''
  const formattedMenuItemUrl =
    menuItem?.url?.[0] === SLASH_SEPARATOR ? menuItem?.url?.replace(SLASH_SEPARATOR, '') : menuItem?.url
  const shouldSlice = mainPath[mainPath.length - 1] === SLASH_SEPARATOR
  const formattedUrl = shouldSlice ? mainPath.slice(0, -1) : mainPath

  if (menuItem?.default) {
    return `/s/${username}${menuItem.url}`
  }

  if (menuItem?.linkType === LINK_TYPE.themePage) {
    const slugString = getArrayItemById(themePages, formattedMenuItemUrl) || { slug: '' }

    return `${formattedUrl}/${slugString?.slug}`
  }

  return isURL(menuItem?.url || '') ? menuItem?.url : `${formattedUrl}/${formattedMenuItemUrl}`
}
