import { arrayLast } from 'utils/array.utils'
import { getSearchParams } from 'utils/queryString.utils'

import { FUNNEL_LOGS_FORMS } from 'constants/funnels.constants'

export const getFunnelNodeLog = (logs = []) => {
  const skipLog = logs.find(({ form }) => form === FUNNEL_LOGS_FORMS.skip)
  const acceptRejectLog = logs.find(
    ({ form }) => form === FUNNEL_LOGS_FORMS.accepted || form === FUNNEL_LOGS_FORMS.rejected
  )
  return skipLog || acceptRejectLog || arrayLast(logs) || {}
}

export const isFunnelRoute = (path = '') => {
  const { funnel } = getSearchParams()
  const funnelOn = funnel === 'on'
  const isManage = path.includes('manage')
  const isAutoPay = path.includes('autopay')
  const isPayment = path.includes('payment')

  return funnelOn && !isManage && !isAutoPay && isPayment
}
