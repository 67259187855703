export const FONT_DISPLAY_DEFAULT = 'swap'
export const FONT_WEIGHT_DEFAULT = '400'
export const FALLBACK_FONT_DEFAULT = 'Montserrat'
export const FONT_STYLE_DEFAULT = 'normal'

export const CUSTOM_FONTS_FEATURE_FLAG = 'custom_fonts'
export const DEFAULT_CUSTOM_FONT_CONFIG = {
  fontWeight: FONT_WEIGHT_DEFAULT,
  fontDisplay: FONT_DISPLAY_DEFAULT,
  fallbackFont: FALLBACK_FONT_DEFAULT,
  fontStyle: FONT_STYLE_DEFAULT,
}

export const FONT_STYLE_OPTIONS = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'italic',
    label: 'Italic',
  },
]

export const FONT_DISPLAY_OPTIONS = [
  {
    value: 'auto',
    label: 'Auto',
  },
  {
    value: 'block',
    label: 'Block',
  },
  {
    value: 'swap',
    label: 'Swap',
  },
  {
    value: 'fallback',
    label: 'Fallback',
  },
  {
    value: 'optional',
    label: 'Optional',
  },
]

export const FONT_WEIGHT_OPTIONS = [
  {
    value: '100',
    label: '100 (Thin)',
  },
  {
    value: '200',
    label: '200 (Extra Thin)',
  },
  {
    value: '300',
    label: '300 (Light)',
  },
  {
    value: '400',
    label: '400 (Normal)',
  },
  {
    value: '500',
    label: '500 (Medium)',
  },
  {
    value: '600',
    label: '600 (Semi Bold)',
  },
  {
    value: '700',
    label: '700 (Bold)',
  },
  {
    value: '800',
    label: '800 (Extra Bold)',
  },
  {
    value: '900',
    label: '900 (Black)',
  },
]

export const FONT_FAMILY_GOOGLE_FONTS = [
  {
    value: 'Abhaya Libre',
    label: 'Abhaya Libre',
  },
  {
    value: 'Abril FatFace',
    label: 'Abril FatFace',
  },
  {
    value: 'Alegreya',
    label: 'Alegreya',
  },
  {
    value: 'Alice',
    label: 'Alice',
  },
  {
    value: 'Amatic SC',
    label: 'Amatic SC',
  },
  {
    value: 'Anonymous Pro',
    label: 'Anonymous Pro',
  },
  {
    value: 'Anton',
    label: 'Anton',
  },
  {
    value: 'Arapey',
    label: 'Arapey',
  },
  {
    value: 'Arimo',
    label: 'Arimo',
  },
  {
    value: 'Arvo',
    label: 'Arvo',
  },
  {
    value: 'B612',
    label: 'B612',
  },
  {
    value: 'Bebas Neue',
    label: 'Bebas Neue',
  },
  {
    value: 'Belleza',
    label: 'Belleza',
  },
  {
    value: 'Blinker',
    label: 'Blinker',
  },
  {
    value: 'Cairo',
    label: 'Cairo',
  },
  {
    value: 'BioRhyme',
    label: 'BioRhyme',
  },
  {
    value: 'Cardo',
    label: 'Cardo',
  },
  {
    value: 'Caveat',
    label: 'Caveat',
  },
  {
    value: 'Caveat Brush',
    label: 'Caveat Brush',
  },
  {
    value: 'Comfortaa',
    label: 'Comfortaa',
  },
  {
    value: 'Concert One',
    label: 'Concert One',
  },
  {
    value: 'Cormorant',
    label: 'Cormorant',
  },
  {
    value: 'Cormorant Garamond',
    label: 'Cormorant Garamond',
  },
  {
    value: 'Courier Prime',
    label: 'Courier Prime',
  },
  {
    value: 'Crimson Text',
    label: 'Crimson Text',
  },
  {
    value: 'Exo 2',
    label: 'Exo 2',
  },
  {
    value: 'Fira Sans',
    label: 'Fira Sans',
  },
  {
    value: 'Fjalla One',
    label: 'Fjalla One',
  },
  {
    value: 'Forum',
    label: 'Forum',
  },
  {
    value: 'Frank Ruhl Libre',
    label: 'Frank Ruhl Libre',
  },
  {
    value: 'IBM Plex Serif',
    label: 'IBM Plex Serif',
  },
  {
    value: 'Inter',
    label: 'Inter',
  },
  {
    value: 'Karla',
    label: 'Karla',
  },
  {
    value: 'Lato',
    label: 'Lato',
  },
  {
    value: 'Lobster',
    label: 'Lobster',
  },
  {
    value: 'Lora',
    label: 'Lora',
  },
  {
    value: 'Merriweather',
    label: 'Merriweather',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'Montserrat Med',
    label: 'Montserrat Med',
  },
  {
    value: 'Montserrat Extra Bold',
    label: 'Montserrat Extra Bold',
  },
  {
    value: 'Montserrat Light',
    label: 'Montserrat Light',
  },
  {
    value: 'Montserrat Black',
    label: 'Montserrat Black',
  },
  {
    value: 'Nixie One',
    label: 'Nixie One',
  },
  {
    value: 'Noto Sans',
    label: 'Noto Sans',
  },
  {
    value: 'Noto Serif',
    label: 'Noto Serif',
  },
  {
    value: 'Nunito',
    label: 'Nunito',
  },
  {
    value: 'Old Standard TT',
    label: 'Old Standard TT',
  },
  {
    value: 'Oswald',
    label: 'Oswald',
  },
  {
    value: 'Oxygen',
    label: 'Oxygen',
  },
  {
    value: 'Playfair Display',
    label: 'Playfair Display',
  },
  {
    value: 'Poppins',
    label: 'Poppins',
  },
  {
    value: 'PT Sans',
    label: 'PT Sans',
  },
  {
    value: 'Quicksand',
    label: 'Quicksand',
  },
  {
    value: 'PT Serif',
    label: 'PT Serif',
  },
  {
    value: 'Rakkas',
    label: 'Rakkas',
  },
  {
    value: 'Roboto Slab',
    label: 'Roboto Slab',
  },
  {
    value: 'Roboto Slab Light',
    label: 'Roboto Slab Light',
  },
  {
    value: 'Roboto Slab Thin',
    label: 'Roboto Slab Thin',
  },
  {
    value: 'Rubik',
    label: 'Rubik',
  },
  {
    value: 'Sanchez',
    label: 'Sanchez',
  },
  {
    value: 'Shrikhand',
    label: 'Shrikhand',
  },
  {
    value: 'SourceSans3',
    label: 'Source Sans 3',
  },
  {
    value: 'SourceSerif4',
    label: 'Source Serif 4',
  },
  {
    value: 'Spectral',
    label: 'Spectral',
  },
  {
    value: 'Tenor Sans',
    label: 'Tenor Sans',
  },
  {
    value: 'Titillium Web',
    label: 'Titillium Web',
  },
  {
    value: 'Ubuntu',
    label: 'Ubuntu',
  },
  {
    value: 'Varela',
    label: 'Varela',
  },
  {
    value: 'Vollkorn',
    label: 'Vollkorn',
  },
  {
    value: 'Work Sans',
    label: 'Work Sans',
  },
  {
    value: 'Yatra One',
    label: 'Yatra One',
  },
  {
    value: 'Yeseva One',
    label: 'Yeseva One',
  },
  {
    value: 'Inconsolata',
    label: 'Inconsolata',
  },
  {
    value: 'Fraunces',
    label: 'Fraunces',
  },
  {
    value: 'Public Sans',
    label: 'Public Sans',
  },
  {
    value: 'Red Hat Display',
    label: 'Red Hat Display',
  },
  {
    value: 'Lexend Deca',
    label: 'Lexend Deca',
  },
  {
    value: 'Sansita',
    label: 'Sansita',
  },
  {
    value: 'Tourney',
    label: 'Tourney',
  },
  {
    value: 'Big Shoulders Stencil Display',
    label: 'Big Shoulders Stencil Display',
  },
  {
    value: 'MuseoModerno',
    label: 'MuseoModerno',
  },
  {
    value: 'Grandstander',
    label: 'Grandstander',
  },
  {
    value: 'Catamaran',
    label: 'Catamaran',
  },
  {
    value: 'Style Script',
    label: 'Style Script',
  },
  {
    value: 'Varta',
    label: 'Varta',
  },
  {
    value: 'Mulish',
    label: 'Mulish',
  },
  {
    value: 'Figtree',
    label: 'Figtree',
  },
  {
    value: 'Jost',
    label: 'Jost',
  },
  {
    value: 'Libre Franklin',
    label: 'Libre Franklin',
  },
  {
    value: 'Libre Baskerville',
    label: 'Libre Baskerville',
  },
  {
    value: 'Eczar',
    label: 'Eczar',
  },
  {
    value: 'Josefin Sans',
    label: 'Josefin Sans',
  },
]

export const FROALA_FONTS_UNSORTED = {
  ABeeZee: 'ABeeZee',
  'Archivo Black': 'Archivo Black',
  Amaranth: 'Amaranth',
  'Nunito Sans,sans-serif': 'Nunito Sans',
  Barlow: 'Barlow',
  Cardo: 'Cardo',
  Comfortaa: 'Comfortaa',
  'Crimson Text': 'Crimson Text',
  'Dancing Script': 'Dancing Script',
  Exo: 'Exo',
  'Fjalla One': 'Fjalla One',
  ArchivoLight: 'Archivo Light',
  ArchivoReg: 'Archivo',
  ArchivoMed: 'Archivo Medium',
  Inder: 'Inder',
  'Indie Flower': 'Indie Flower',
  'Lilita One': 'Lilita One',
  'Lobster Two': 'Lobster Two',
  Merienda: 'Merienda',
  Montserrat: 'Montserrat',
  'Old Standard TT': 'Old Standard TT',
  'Open Sans': 'Open Sans',
  Oswald: 'Oswald',
  'PT Sans Narrow': 'PT Sans Narrow',
  'PT Sans': 'PT Sans',
  Pacifico: 'Pacifico',
  'Patrick Hand': 'Patrick Hand',
  Playball: 'Playball',
  Poppins: 'Poppins',
  Raleway: 'Raleway',
  Righteous: 'Righteous',
  Roboto: 'Roboto',
  Tauri: 'Tauri',
  'Yanone Kaffeesatz': 'Yanone Kaffeesatz',
  Varta: 'Varta',
  Mulish: 'Mulish',
  Figtree: 'Figtree',
  Jost: 'Jost',
  'Libre Franklin': 'Libre Franklin',
}

export const TICKET_DESIGN_FONT_OPTIONS = [
  {
    value: 'Open Sans',
    label: 'Open Sans',
  },
  {
    value: 'Roboto',
    label: 'Roboto',
  },
  {
    value: 'ABeeZee',
    label: 'ABeeZee',
  },
  {
    value: 'Mulish',
    label: 'Mulish',
  },
  {
    value: 'Old Standard TT',
    label: 'Old Standard TT',
  },
  {
    value: 'Fjalla One',
    label: 'Fjalla One',
  },
  {
    value: 'Inder',
    label: 'Inder',
  },
  {
    value: 'Tauri',
    label: 'Tauri',
  },
  {
    value: 'Indie Flower',
    label: 'Indie Flower',
  },
  {
    value: 'Lobster Two',
    label: 'Lobster Two',
  },
  {
    value: 'Pacifico',
    label: 'Pacifico',
  },
  {
    value: 'Righteous',
    label: 'Righteous',
  },
  {
    value: 'Dancing Script',
    label: 'Dancing Script',
  },
  {
    value: 'Playball',
    label: 'Playball',
  },
  {
    value: 'Amaranth',
    label: 'Amaranth',
  },
  {
    value: 'Patrick Hand',
    label: 'Patrick Hand',
  },
  {
    value: 'Merienda',
    label: 'Merienda',
  },
  {
    value: 'Lilita One',
    label: 'Lilita One',
  },
  {
    value: 'Poppins',
    label: 'Poppins',
  },
]

export const FONT_FAMILY_SELECT_OPTIONS = [
  {
    value: 'ABeeZee',
    label: 'ABeeZee',
  },
  {
    value: 'Amaranth',
    label: 'Amaranth',
  },
  {
    value: 'Nunito Sans,sans-serif',
    label: 'Nunito Sans',
  },
  {
    value: 'Barlow',
    label: 'Barlow',
  },
  {
    value: 'Dancing Script',
    label: 'Dancing Script',
  },
  {
    value: 'Exo',
    label: 'Exo',
  },
  {
    value: 'ArchivoReg',
    label: 'Archivo',
  },
  {
    value: 'ArchivoMed',
    label: 'Archivo Medium',
  },
  {
    value: 'ArchivoBlack',
    label: 'Archivo Black',
  },
  {
    value: 'ArchivoLight',
    label: 'Archivo Light',
  },
  {
    value: 'Inder',
    label: 'Inder',
  },
  {
    value: 'Indie Flower',
    label: 'Indie Flower',
  },
  {
    value: 'Lilita One',
    label: 'Lilita One',
  },
  {
    value: 'Lobster Two',
    label: 'Lobster Two',
  },
  {
    value: 'Merienda',
    label: 'Merienda',
  },
  {
    value: 'Open Sans',
    label: 'Open Sans',
  },
  {
    value: 'Pacifico',
    label: 'Pacifico',
  },
  {
    value: 'Patrick Hand',
    label: 'Patrick Hand',
  },
  {
    value: 'Playball',
    label: 'Playball',
  },
  {
    value: 'PT Sans Narrow',
    label: 'PT Sans Narrow',
  },
  {
    value: 'Raleway',
    label: 'Raleway',
  },
  {
    value: 'Righteous',
    label: 'Righteous',
  },
  {
    value: 'Roboto',
    label: 'Roboto',
  },
  {
    value: 'Tauri',
    label: 'Tauri',
  },
  {
    value: 'Yanone Kaffeesatz',
    label: 'Yanone Kaffeesatz',
  },
  ...FONT_FAMILY_GOOGLE_FONTS,
]

export const NEW_TEXT_EDITOR_FONT_OPTIONS = [
  {
    value: 'ABeeZee',
    label: 'ABeeZee',
  },
  {
    value: 'Amaranth',
    label: 'Amaranth',
  },
  {
    value: 'Nunito Sans',
    label: 'Nunito Sans',
  },
  {
    value: 'Cardo',
    label: 'Cardo',
  },
  {
    value: 'Comfortaa',
    label: 'Comfortaa',
  },
  {
    value: 'Crimson Text',
    label: 'Crimson Text',
  },
  {
    value: 'Dancing Script',
    label: 'Dancing Script',
  },
  {
    value: 'Exo',
    label: 'Exo',
  },
  {
    value: 'Fjalla One',
    label: 'Fjalla One',
  },
  {
    value: 'Anton',
    label: 'Anton',
  },
  {
    value: 'Inder',
    label: 'Inder',
  },
  {
    value: 'Indie Flower',
    label: 'Indie Flower',
  },
  {
    value: 'Lilita One',
    label: 'Lilita One',
  },
  {
    value: 'Lobster Two',
    label: 'Lobster Two',
  },
  {
    value: 'Merienda',
    label: 'Merienda',
  },
  {
    value: 'Mulish',
    label: 'Mulish',
  },
  {
    value: 'Old Standard TT',
    label: 'Old Standard TT',
  },
  {
    value: 'Open Sans',
    label: 'Open Sans',
  },
  {
    value: 'Oswald',
    label: 'Oswald',
  },
  {
    value: 'PT Sans Narrow',
    label: 'PT Sans Narrow',
  },
  {
    value: 'Pacifico',
    label: 'Pacifico',
  },
  {
    value: 'Patrick Hand',
    label: 'Patrick Hand',
  },
  {
    value: 'Playball',
    label: 'Playball',
  },
  {
    value: 'Raleway',
    label: 'Raleway',
  },
  {
    value: 'Righteous',
    label: 'Righteous',
  },
  {
    value: 'Roboto',
    label: 'Roboto',
  },
  {
    value: 'Tauri',
    label: 'Tauri',
  },
  {
    value: 'Libre Baskerville',
    label: 'Libre Baskerville',
  },
  {
    value: 'Yanone Kaffeesatz',
    label: 'Yanone Kaffeesatz',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'Abhaya Libre',
    label: 'Abhaya Libre',
  },
  {
    value: 'Abril Fatface',
    label: 'Abril Fatface',
  },
  {
    value: 'Alegreya',
    label: 'Alegreya',
  },
  {
    value: 'Alice',
    label: 'Alice',
  },
  {
    value: 'Amatic SC',
    label: 'Amatic SC',
  },
  {
    value: 'Anonymous Pro',
    label: 'Anonymous Pro',
  },
  {
    value: 'Arapey',
    label: 'Arapey',
  },
  {
    value: 'Archivo Black',
    label: 'Archivo Black',
  },
  {
    value: 'Arimo',
    label: 'Arimo',
  },
  {
    value: 'Arvo',
    label: 'Arvo',
  },
  {
    value: 'B612',
    label: 'B612',
  },
  {
    value: 'Belleza',
    label: 'Belleza',
  },
  {
    value: 'Barlow',
    label: 'Barlow',
  },
  {
    value: 'Bebas Neue',
    label: 'Bebas Neue',
  },
  {
    value: 'Big Shoulders Stencil Display',
    label: 'Big Shoulders Stencil Display',
  },
  {
    value: 'IBM Plex Sans',
    label: 'IBM Plex Sans',
  },
  {
    value: 'BioRhyme',
    label: 'BioRhyme',
  },
  {
    value: 'Blinker',
    label: 'Blinker',
  },
  {
    value: 'Cairo',
    label: 'Cairo',
  },
  {
    value: 'Catamaran',
    label: 'Catamaran',
  },
  {
    value: 'Caveat',
    label: 'Caveat',
  },
  {
    value: 'Caveat Brush',
    label: 'Caveat Brush',
  },
  {
    value: 'Concert One',
    label: 'Concert One',
  },
  {
    value: 'Cormorant',
    label: 'Cormorant',
  },
  {
    value: 'PT Serif',
    label: 'PT Serif',
  },
  {
    value: 'Cormorant Garamond',
    label: 'Cormorant Garamond',
  },
  {
    value: 'Courier Prime',
    label: 'Courier Prime',
  },
  {
    value: 'Playfair Display',
    label: 'Playfair Display',
  },
  {
    value: 'Eczar',
    label: 'Eczar',
  },
  {
    value: 'Nunito',
    label: 'Nunito',
  },
  {
    value: 'Exo 2',
    label: 'Exo 2',
  },
  {
    value: 'Public Sans',
    label: 'Public Sans',
  },
  {
    value: 'Figtree',
    label: 'Figtree',
  },
  {
    value: 'Fira Sans',
    label: 'Fira Sans',
  },
  {
    value: 'Quicksand',
    label: 'Quicksand',
  },
  {
    value: 'Forum',
    label: 'Forum',
  },
  {
    value: 'Frank Ruhl Libre',
    label: 'Frank Ruhl Libre',
  },
  {
    value: 'Fraunces',
    label: 'Fraunces',
  },
  {
    value: 'Merriweather',
    label: 'Merriweather',
  },
  {
    value: 'Lato',
    label: 'Lato',
  },
  {
    value: 'Noto Serif',
    label: 'Noto Serif',
  },
  {
    value: 'Grandstander',
    label: 'Grandstander',
  },
  {
    value: 'IBM Plex Serif',
    label: 'IBM Plex Serif',
  },
  {
    value: 'Inconsolata',
    label: 'Inconsolata',
  },
  {
    value: 'Inter',
    label: 'Inter',
  },
  {
    value: 'Jost',
    label: 'Jost',
  },
  {
    value: 'Karla',
    label: 'Karla',
  },
  {
    value: 'Lexend Deca',
    label: 'Lexend Deca',
  },
  {
    value: 'Oxygen',
    label: 'Oxygen',
  },
  {
    value: 'Libre Franklin',
    label: 'Libre Franklin',
  },
  {
    value: 'Lobster',
    label: 'Lobster',
  },
  {
    value: 'Poppins',
    label: 'Poppins',
  },
  {
    value: 'Lora',
    label: 'Lora',
  },
  {
    value: 'PT Sans',
    label: 'PT Sans',
  },
  {
    value: 'MuseoModerno',
    label: 'MuseoModerno',
  },
  {
    value: 'Nixie One',
    label: 'Nixie One',
  },
  {
    value: 'Noto Sans',
    label: 'Noto Sans',
  },
  {
    value: 'Rakkas',
    label: 'Rakkas',
  },
  {
    value: 'Red Hat Display',
    label: 'Red Hat Display',
  },
  {
    value: 'Rubik',
    label: 'Rubik',
  },
  {
    value: 'Sanchez',
    label: 'Sanchez',
  },
  {
    value: 'Sansita',
    label: 'Sansita',
  },
  {
    value: 'Shrikhand',
    label: 'Shrikhand',
  },
  {
    value: 'Spectral',
    label: 'Spectral',
  },
  {
    value: 'Style Script',
    label: 'Style Script',
  },
  {
    value: 'Tenor Sans',
    label: 'Tenor Sans',
  },
  {
    value: 'Titillium Web',
    label: 'Titillium Web',
  },
  {
    value: 'Tourney',
    label: 'Tourney',
  },
  {
    value: 'Ubuntu',
    label: 'Ubuntu',
  },
  {
    value: 'Varela',
    label: 'Varela',
  },
  {
    value: 'Varta',
    label: 'Varta',
  },
  {
    value: 'Vollkorn',
    label: 'Vollkorn',
  },
  {
    value: 'Work Sans',
    label: 'Work Sans',
  },
  {
    value: 'Yatra One',
    label: 'Yatra One',
  },
  {
    value: 'Yeseva One',
    label: 'Yeseva One',
  },
  {
    value: 'Source Serif 4',
    label: 'Source Serif 4',
  },
]
