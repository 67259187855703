import { EMPTY_TEXT_EDITOR } from 'constants/courses.constants'
import { ALLOWED_LANGUAGES } from 'constants/general.constants'

type Prefs = {
  footer?: {
    menuList?: {
      id: number
      name: string
      default?: boolean
      submenu?: {
        default?: boolean
        id: number
        url: string
        name: string
        linkType?: string
      }[]
    }[]
  }
  header?: {
    backgroundImage?: string
    logo?: string
    languageSwitcher?: boolean
    myAccountSwitcher?: boolean
    showSharingOption?: boolean
    customClassName?: string
    menuColor?: string
  }
  languages?: string[]
  headerHidden?: boolean
  faviconUrl?: string
  showFavicon?: boolean
  showCategories?: boolean
  bannerImageUrl?: string
  descriptionValue?: string
  noFollow?: boolean
}

export const getBackgroundImageCss = (imageUrl: string): string | 'none' => (imageUrl ? `url(${imageUrl})` : 'none')

export const getDefaultShopTheme = ({ prefs = {} as Prefs } = {}) => ({
  id: 11,
  name: '',
  layout: '',
  default: true,
  template: false,
  themePages: [
    {
      id: 22,
      name: 'Main',
      contentPageId: 33,
      css: null,
      default: false,
      form: 'main',
      position: 0,
      prefs: {
        metaTagsEnabled: false,
      },
      required: false,
      slug: '',
      themableId: 44,
      themableType: 'ShopTheme',
    },
    {
      id: 55,
      name: 'Products page',
      contentPageId: 66,
      css: null,
      default: true,
      form: 'product',
      position: 0,
      prefs: {
        metaTagsEnabled: false,
      },
      required: false,
      slug: ':product_slug',
      themableId: 77,
      themableType: 'ShopTheme',
    },
  ],
  prefs: {
    footer: {
      tumblr: '',
      twitter: '',
      website: '',
      youtube: '',
      facebook: '',
      instagram: '',
      pinterest: '',
      menuFont: 'Montserrat Reg',
      menuList: (prefs?.footer?.menuList || []).filter((item) => item.default),
      menuSize: '14',
      menuColor: '#1e84d7',
      menuStyle: '',
      submenuFont: 'Montserrat Reg',
      submenuSize: '14',
      socialsColor: '#1E84D7',
      socialsTitle: '',
      submenuColor: '#1e84d7',
      submenuStyle: '',
      backgroundColor: '#FFFFFF',
      socialsIsShown: '',
      customClassName: '',
    },
    header: {
      socialsColor: '#1e84d7',
      sharingOptionsColor: '#1e84d7',
      languageColor: '#1e84d7',
      languageSwitcher: true,
      myAccountSwitcher: true,
      accountColor: '#1e84d7',
      iconColor: '#1e84d7',
      logo: '',
      ...prefs.header,
      menuList: [],
      menuFont: 'Montserrat Medium',
      menuSize: '14',
      menuColor: '#1e84d7',
      menuStyle: '',
      accountFont: 'Montserrat Medium',
      accountSize: '14',
      accountStyle: '',
      languageFont: 'Montserrat Medium',
      languageSize: '14',
      languageStyle: 'normal',
      backgroundColor: '#FFFFFF',
      customClassName: '',
    },
    languages: prefs.languages || Object.keys(ALLOWED_LANGUAGES),
    headerHidden: prefs.headerHidden,
    faviconUrl: prefs.faviconUrl,
    showFavicon: prefs.showFavicon,
    showCategories: prefs.showCategories === false ? prefs.showCategories : true,
    bannerImageUrl: prefs.bannerImageUrl,
    descriptionValue: prefs.descriptionValue,
    noFollow: prefs.noFollow,
  },
  form: 'default',
  dataCollections: {},
  sharingId: null,
})

export const getDefaultShopMainPage = ({ prefs = {} as Prefs } = {}) => ({
  id: 9640,
  name: null,
  area: null,
  categoryId: null,
  live: false,
  prefs: {},
  template: false,
  contentBlocks: [
    {
      id: 63191,
      name: null,
      children: [
        {
          id: 63204,
          name: null,
          children: [],
          content: {
            list: null,
            text:
              prefs.bannerImageUrl &&
              `<img src='${prefs.bannerImageUrl}' id='default-shop-banner-img' onerror='(function(){const imgObj=document.getElementById("default-shop-banner-img"); imgObj?.remove();})()' style='width: 100%;'>`,
            width: '100',
            maxWidth: '1140',
            paddingTop: '0',
            paddingLeft: '15',
            transparency: '1',
            paddingRight: '15',
            paddingUnits: 'px',
            paddingBottom: '0',
            backgroundColor: '#FFFFFF',
          },
          contentPageId: 9640,
          coverId: null,
          embeddableItemId: null,
          form: 'text',
          isRow: false,
          lessonId: null,
          parentId: 63191,
          position: 1,
          products: [],
          purpose: 0,
          template: null,
          embeddableItem: null,
          goods: [],
        },
      ],
      content: {
        list: null,
      },
      contentPageId: 9640,
      coverId: null,
      embeddableItemId: null,
      form: 'text',
      isRow: true,
      lessonId: null,
      parentId: null,
      position: 0,
      products: [],
      purpose: 0,
      template: false,
      embeddableItem: null,
      goods: [],
    },
    {
      id: 63205,
      name: null,
      children: [
        {
          id: 63190,
          name: null,
          children: [],
          content: {
            list: null,
            text: prefs.descriptionValue && prefs.descriptionValue !== EMPTY_TEXT_EDITOR ? prefs.descriptionValue : '',
            width: '100',
            maxWidth: '1140',
            paddingTop: '15',
            paddingLeft: '15',
            transparency: '1',
            paddingRight: '15',
            paddingUnits: 'px',
            paddingBottom: '15',
            backgroundColor: '#FFFFFF',
          },
          contentPageId: 9640,
          coverId: null,
          embeddableItemId: null,
          form: 'text',
          isRow: false,
          lessonId: null,
          parentId: 63205,
          position: 0,
          products: [],
          purpose: 0,
          sellerId: null,
          template: false,
          embeddableItem: null,
          goods: [],
        },
      ],
      content: {
        list: null,
      },
      contentPageId: 9640,
      coverId: null,
      embeddableItemId: null,
      form: 'text',
      isRow: true,
      lessonId: null,
      parentId: null,
      position: 1,
      products: [],
      purpose: 0,
      sellerId: null,
      template: false,
      embeddableItem: null,
      goods: [],
    },
    {
      id: 63189,
      name: null,
      children: [
        {
          id: 63157,
          name: null,
          children: [],
          content: {
            view: 'grid',
            radius: '4',
            sortKey: 'createdAt',
            priceFont: 'Montserrat Medium',
            priceSize: '18',
            buttonFont: 'Montserrat Medium',
            buttonSize: '16',
            buttonText: 'Buy now',
            priceColor: '#FFFFFF',
            priceStyle: 'normal',
            searchFont: 'Montserrat Reg',
            searchSize: '13',
            buttonColor: '#1E84D7',
            buttonStyle: 'normal',
            productFont: 'Montserrat Medium',
            productSize: '16',
            searchColor: '#1E84D7',
            searchStyle: 'normal',
            transparency: '0',
            categoryFont: 'Montserrat Medium',
            categorySize: '14',
            currencyFont: 'Montserrat Medium',
            currencySize: '30',
            productColor: '#4a4a4a',
            productStyle: 'normal',
            categoryColor: '#1e84d7',
            categoryStyle: 'normal',
            currencyColor: '#808080',
            currencyStyle: 'normal',
            itemsPerPage: '12',
            oldPriceFont: 'Montserrat Medium',
            oldPriceSize: '14',
            categoriesView: 'tabs',
            oldPriceColor: '#787878',
            oldPriceStyle: 'normal',
            showCategories: prefs.showCategories,
            backgroundColor: '#FFFFFF',
            buttonAnimation: 'transparency',
            descriptionFont: 'Montserrat Medium',
            descriptionSize: '16',
            priceBackground: '#1e84d7',
            buttonTextColor: '#FFFFFF',
            buttonViewStyle: 'filled',
            descriptionColor: '#4A4A4A',
            descriptionStyle: 'normal',
            searchBackground: '#FFFFFF',
            showAllProducts: true,
            productBackground: '#FFFFFF',
            categoryBackground: '#FFFFFF',
            searchActiveColor: '#1E84D7',
            categoryActiveColor: '#4a4a4a',
            categoryDescriptionFont: 'Montserrat Reg',
            categoryDescriptionSize: '14',
            categoryDescriptionColor: '#4a4a4a',
            categoryDescriptionStyle: 'normal',
            imageCoverForm: 'contain',
          },
          contentPageId: 9640,
          coverId: null,
          embeddableItemId: null,
          form: 'seller_products',
          isRow: false,
          lessonId: null,
          parentId: 63189,
          position: 0,
          products: [],
          purpose: 0,
          sellerId: null,
          template: false,
          embeddableItem: null,
          goods: [],
        },
      ],
      content: {
        list: null,
      },
      contentPageId: 9640,
      coverId: null,
      embeddableItemId: null,
      form: 'text',
      isRow: true,
      lessonId: null,
      parentId: null,
      position: 3,
      products: [],
      purpose: 0,
      sellerId: null,
      template: false,
      embeddableItem: null,
      goods: [],
    },
  ],
})

export const getDefaultShopProductPage = () => ({
  id: 9640,
  name: null,
  area: null,
  categoryId: null,
  live: false,
  prefs: {},
  template: false,
  contentBlocks: [
    {
      id: 63189,
      name: null,
      children: [
        {
          id: 63194,
          name: null,
          children: [],
          content: {
            list: null,
            planFont: 'Montserrat Medium',
            planSize: '14',
            textFont: 'Montserrat Medium',
            textSize: '36',
            background: '#FFFFFF',
            planColor: '#4A4A4A',
            planStyle: 'normal',
            priceFont: 'Montserrat Medium',
            priceSize: '70',
            textColor: '#4a4a4a',
            textStyle: 'bold',
            buttonFont: 'Montserrat Medium',
            buttonSize: '15',
            priceColor: '#4a4a4a',
            priceStyle: 'bold',
            arrowsColor: '#1E84D7',
            buttonColor: '#1E84D7',
            buttonStyle: 'normal',
            currencyFont: 'Montserrat Medium',
            currencySize: '40',
            payInfoFont: 'Montserrat Medium',
            payInfoSize: '14',
            coursePreview: true,
            currencyColor: '#808080',
            currencyStyle: 'normal',
            oldPriceFont: 'Montserrat Medium',
            oldPriceSize: '14',
            payInfoColor: '#9e9e9e',
            payInfoStyle: 'normal',
            arrowFontSize: '40',
            oldPriceColor: '#4a4a4a',
            oldPriceStyle: 'normal',
            planTitleFont: 'Montserrat Medium',
            planTitleSize: '24',
            ticketsPreview: true,
            backButtonFont: 'Montserrat Medium',
            backButtonSize: '16',
            downloadPreview: true,
            planTitleColor: '#4a4a4a',
            planTitleStyle: 'normal',
            backButtonColor: '#1E84D7',
            backButtonStyle: 'normal',
            buttonTextColor: '#FFFFFF',
            ticketsTitleFont: 'Montserrat Medium',
            ticketsTitleSize: '24',
            downloadTitleFont: 'Montserrat Medium',
            downloadTitleSize: '24',
            previewButtonFont: 'Montserrat Reg',
            previewButtonSize: '13',
            ticketsTitleColor: '#4a4a4a',
            ticketsTitleStyle: 'normal',
            downloadTitleColor: '#4a4a4a',
            downloadTitleStyle: 'normal',
            previewButtonColor: '#1E84D7',
            previewButtonStyle: 'normal',
            ticketsDetailsFont: 'Montserrat Medium',
            ticketsDetailsSize: '14',
            downloadDetailsFont: 'Montserrat Medium',
            downloadDetailsSize: '14',
            ticketsDetailsColor: '#4a4a4a',
            ticketsDetailsStyle: 'normal',
            downloadDetailsColor: '#4a4a4a',
            downloadDetailsStyle: 'normal',
            previewButtonTextColor: '#FFFFFF',
          },
          contentPageId: 9654,
          coverId: null,
          embeddableItemId: null,
          form: 'product',
          isRow: false,
          lessonId: null,
          parentId: 63198,
          position: 0,
          products: [],
          purpose: 0,
          sellerId: null,
          template: null,
          embeddableItem: null,
          goods: [],
        },
      ],
      content: {
        list: null,
      },
      contentPageId: 9640,
      coverId: null,
      embeddableItemId: null,
      form: 'text',
      isRow: true,
      lessonId: null,
      parentId: null,
      position: 3,
      products: [],
      purpose: 0,
      sellerId: null,
      template: false,
      embeddableItem: null,
      goods: [],
    },
  ],
})

export const getDefaultShopThankYouPage = () => ({
  id: 9641,
  name: null,
  area: null,
  categoryId: null,
  live: false,
  prefs: {},
  template: false,
  contentBlocks: [
    {
      id: 63189,
      name: null,
      children: [
        {
          id: 63194,
          name: null,
          children: [],
          content: {
            list: null,
            additionalTextColor: '#9e9e9e',
            additionalTextFont: 'Montserrat Medium',
            backgroundColor: '#FFFFFF',
            buttonAnimation: 'transparency',
            buttonColor: '#1E84D7',
            buttonFont: 'Montserrat Reg',
            buttonStyle: 'normal',
            buttonTextColor: '#FFFFFF',
            headerColor: '#4A4A4A',
            headerFont: 'Montserrat Medium',
            headerStyle: 'bold',
            maxWidth: '1200',
            priceColor: '#545454',
            priceFont: 'Montserrat Medium',
            priceStyle: 'bold',
            productNameColor: '#4A4A4A',
            productNameFont: 'Montserrat Medium',
            productNameStyle: 'normal',
            radius: '4',
            secondaryButtonColor: '#1E84D7',
            secondaryButtonFont: 'Montserrat Reg',
            secondaryButtonStyle: 'normal',
            secondaryCornerRadius: '4',
            width: '100',
          },
          contentPageId: 9654,
          coverId: null,
          embeddableItemId: null,
          form: 'thank_you_page',
          isRow: false,
          lessonId: null,
          parentId: 63198,
          position: 0,
          products: [],
          purpose: 0,
          sellerId: null,
          template: null,
          embeddableItem: null,
          goods: [],
        },
      ],
      content: {
        list: null,
      },
      contentPageId: 9640,
      coverId: null,
      embeddableItemId: null,
      form: 'text',
      isRow: true,
      lessonId: null,
      parentId: null,
      position: 3,
      products: [],
      purpose: 0,
      sellerId: null,
      template: false,
      embeddableItem: null,
      goods: [],
    },
  ],
})
