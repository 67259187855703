import { formatDateTime, getGMTTimezone } from '@elo-kit/utils/dateTime.utils'
import { Country } from 'shared/api/countries.api'

import { getCountryName } from './countries.utils'
import { isNumber } from './validators.utils'

export const getTicketLimit = (limit) => (isNumber(limit) ? limit : I18n.t('react.cabinet.common.unlimited'))

interface Ticket {
  id: number
  name: string
  online: boolean
  locationAddress: string
  countryCode: string
  locationShortName: string
}

interface TicketDate {
  id: number
  startDate: string
  timeZoneName: string
  endDate: string
  additionalText: string
  expired?: boolean
}

export const getFormattedTicketLocation = (ticket: Ticket, countries: Country[]) => {
  const { online, locationAddress, countryCode, locationShortName = '' } = ticket || {}

  if (online) {
    return I18n.t('react.shared.online_location')
  }

  if (locationAddress && countryCode) {
    return `${locationAddress}, ${getCountryName(countries, countryCode)} ${
      locationShortName ? `(${locationShortName})` : ''
    }`
  }

  return locationShortName
}

export const getLocationShortName = (ticket: Ticket) => {
  const { online, locationShortName = '' } = ticket || {}
  if (online) return ''
  return !!locationShortName ? `(${locationShortName})` : ''
}

export const getFormattedUpgradableTicketLocation = (ticket: Ticket, countries: Country[]) => {
  const { online, locationAddress, countryCode } = ticket || {}

  if (online) {
    return I18n.t('react.shared.online_location')
  }

  if (locationAddress && countryCode) {
    return `${locationAddress}, ${getCountryName(countries, countryCode)}`
  }
}

export const getFormattedTicketLabel = (ticket: Ticket, countries: Country[]) => {
  const { id, name, online, locationAddress, countryCode } = ticket || {}
  const locationShortName = getLocationShortName(ticket)

  if (online) {
    return `${id} - ${name} ${locationShortName} - ${I18n.t('react.shared.online_location')}`
  }

  if (locationAddress && countryCode) {
    return `${id} - ${name} ${locationShortName} - ${locationAddress}, ${getCountryName(countries, countryCode)}`
  }

  return `${id} - ${name} ${`${locationShortName}`}`
}

export const getFormattedTicketDates = (ticketDate: TicketDate) => {
  const { startDate, timeZoneName, endDate, additionalText } = ticketDate || {}

  if (!(startDate || endDate)) {
    return additionalText || ''
  }

  if (startDate && endDate) {
    return `${formatDateTime(startDate, null, timeZoneName)} - ${formatDateTime(
      endDate,
      null,
      timeZoneName
    )} ${getGMTTimezone(timeZoneName)}`
  }

  if (startDate) {
    return `${formatDateTime(startDate, null, timeZoneName)} ${getGMTTimezone(timeZoneName)}`
  }

  return ''
}

export const getFormattedTicketDate = (ticketDate: TicketDate): string | { dateStart; dateEnd; startTime; endTime } => {
  const { startDate, timeZoneName, endDate, additionalText } = ticketDate || {}

  if (!(startDate || endDate)) {
    return additionalText || ''
  }

  const [dateStart, startTime] = formatDateTime(startDate, null, timeZoneName).split(' ')
  const [dateEnd, endTime] = formatDateTime(endDate, null, timeZoneName).split(' ')

  return {
    dateStart,
    dateEnd,
    startTime,
    endTime,
  }
}

export const getNotExpiredTicketDates = (list) => (list || []).filter((item) => !item.expired)

export const formatTicketDatesOptions = (list = [], withId = false) => {
  const results = []

  list.forEach((item) => {
    if (item.startDate) {
      const label = getFormattedTicketDates(item)

      results.push({
        label: withId ? `${item.id} - ${label}` : label,
        value: item.id,
      })
    } else if (withId && item.id) {
      results.push({
        label: item.id,
        value: item.id,
      })
    }
  })

  return results
}
