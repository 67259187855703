import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloShoppingBagOpenIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.75 3.75V5.25H17.25V3.75H2.75ZM1.25 3.7V6V16.3C1.25 17.0659 1.8502 17.75 2.66667 17.75H17.3333C18.1498 17.75 18.75 17.0659 18.75 16.3V6V3.7C18.75 2.93409 18.1498 2.25 17.3333 2.25H2.66667C1.8502 2.25 1.25 2.93409 1.25 3.7ZM17.25 16.25V6.75H2.75V16.25H17.25ZM7.75 10C7.75 9.58579 7.41421 9.25 7 9.25C6.58579 9.25 6.25 9.58579 6.25 10C6.25 10.9946 6.64509 11.9484 7.34835 12.6517C8.05161 13.3549 9.00544 13.75 10 13.75C10.9946 13.75 11.9484 13.3549 12.6517 12.6517C13.3549 11.9484 13.75 10.9946 13.75 10C13.75 9.58579 13.4142 9.25 13 9.25C12.5858 9.25 12.25 9.58579 12.25 10C12.25 10.5967 12.0129 11.169 11.591 11.591C11.169 12.0129 10.5967 12.25 10 12.25C9.40326 12.25 8.83097 12.0129 8.40901 11.591C7.98705 11.169 7.75 10.5967 7.75 10Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
