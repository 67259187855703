type Image = string

type OptimisedImage = {
  src: string
  height: number
  width: number
  blurDataURL?: string
}

// TODO: SSR - replace with custom Image component and remove custom webpack loader from next.config
export const linkToImage = (image: Image | OptimisedImage): string => (typeof image === 'string' ? image : image.src)
