import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPayerIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M17.8125 3H2.6875C2.3078 3 2 3.30221 2 3.675V15.825C2 16.1978 2.3078 16.5 2.6875 16.5H17.8125C18.1922 16.5 18.5 16.1978 18.5 15.825V3.675C18.5 3.30221 18.1922 3 17.8125 3Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M2 5.7002H18.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.6875 8.3999C13.6875 9.29501 13.3253 10.1535 12.6807 10.7864C12.036 11.4193 11.1617 11.7749 10.25 11.7749C9.33832 11.7749 8.46398 11.4193 7.81932 10.7864C7.17466 10.1535 6.8125 9.29501 6.8125 8.3999'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </EloBaseIcon>
)
