import { observable, action, override, makeObservable } from 'mobx'
import { NO, YES } from '@elo-kit/constants/general.constants'
import { FUNNEL_FORMS, FUNNEL_LOGS_FORMS } from 'constants/funnels.constants'

import { get } from 'utils/lodash.utils'
import { processFunnelRedirection } from 'utils/order.utils'
import { getFunnelNodeLog } from 'shop/utils/funnels.utils'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { FunnelNode, AcceptFunnelReq, FunnelsApi, createFunnelsApi } from 'shop/api/funnels.api'

interface CurrentFunnelNode {
  id?: number
  contentPageId?: number
  childs?: { direction: string }[]
  form?: string
  funnelNodeLogs?: { form: string }[]
}

interface Funnel {
  id?: number
  name?: string
}

export class FunnelsStore extends SharedStore<any> {
  storeName = 'FunnelsStore'
  root: ShopRootStore
  declare childApi: FunnelsApi

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    this.childApi = createFunnelsApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }

  @observable redirect = null
  @observable funnel: Funnel = {}
  @observable currentFunnelNode: CurrentFunnelNode

  @action setFunnel = (funnel = {}) => (this.funnel = funnel)
  @action setLoading = (value) => (this.loading = value)

  @action toggleHeaderAndFooter = (hideHeader: boolean, hideFooter: boolean) => {
    this.root.shopThemeStore.shopTheme.prefs = {
      // TODO: remove, avoid merge data from different resources
      ...this.root.shopThemeStore.shopTheme.prefs,
      showHeader: !hideHeader,
      showFooter: !hideFooter,
    }
  }

  @action setCurrentFunnelNode = async (
    username: string,
    orderToken: string,
    node: Partial<FunnelNode>,
    skipPostsell = false
  ) => {
    const { id, redirectionUrl, funnelNodeLogs = [], form, prefs } = node || {}
    const { hideFooter, hideHeader } = prefs || {} // TODO: check - looks like 'hideFooter', 'hideHeader' dead props

    if (id) {
      if (funnelNodeLogs.length) {
        const { form } = getFunnelNodeLog(funnelNodeLogs)

        switch (form) {
          case FUNNEL_LOGS_FORMS.accepted:
          case FUNNEL_LOGS_FORMS.skipped:
          case FUNNEL_LOGS_FORMS.redirected: {
            await this.setNextFunnelNode(username, orderToken, YES, node)
            break
          }
          case FUNNEL_LOGS_FORMS.rejected:
          default: {
            await this.setNextFunnelNode(username, orderToken, NO, node)
          }
        }
      } else {
        if (skipPostsell) {
          await this.setNextFunnelNode(username, orderToken, YES, node)
        } else {
          if (form === FUNNEL_FORMS.link) {
            await this.redirectPostsell(username, orderToken, { funnelNodeId: id })
            const redirect = processFunnelRedirection(redirectionUrl, orderToken, username)

            this.redirect = redirect
          } else {
            if (form === FUNNEL_FORMS.page) {
              this.toggleHeaderAndFooter(hideHeader, hideFooter)
            }
            this.currentFunnelNode = node
            this.setLoading(false)
          }
        }
      }
    } else {
      this.currentFunnelNode = null
      this.setLoading(false)
    }
  }

  @action setNextFunnelNode = async (
    username: string,
    orderToken: string,
    nextDirection: string = YES,
    node?: Partial<FunnelNode>
  ) => {
    this.setLoading(true)
    const { childs = [] } = node || this.currentFunnelNode || {}

    const nextNode = childs.find(({ direction }) => direction === nextDirection)
    const nextNodeId = get(nextNode, 'id')

    const { data: { skipped = false } = {} } = nextNodeId
      ? await this.skipPostsell(username, orderToken, { funnelNodeId: nextNodeId })
      : {}

    await this.setCurrentFunnelNode(username, orderToken, nextNode, skipped)
  }

  @action fetchFunnel = async (username: string, orderToken: string) => {
    this.setLoading(true)
    const resp = await this.childApi.fetchFunnel(username, orderToken)
    if (resp.success) {
      const funnelNodeId = get(resp, 'data.funnelNode.id', '')
      this.setFunnel(resp.data.funnelNode)
      const { data: { skipped = false } = {} } = funnelNodeId
        ? await this.skipPostsell(username, orderToken, { funnelNodeId })
        : {}
      await this.setCurrentFunnelNode(username, orderToken, resp.data.funnelNode, skipped)
    }

    this.setLoading(false)

    return resp
  }

  @action skipPostsell = (username: string, orderToken: string, data: { funnelNodeId: number }) =>
    this.childApi.skipPostsell(username, orderToken, data)

  @action redirectPostsell = (username: string, orderToken: string, data) =>
    this.childApi.redirectPostsell(username, orderToken, data)

  @action acceptPostsell = async (
    username: string,
    orderToken: string,
    data: AcceptFunnelReq,
    skipFunnelRedirect = false
  ) => {
    this.setLoading(true)
    const resp = await this.childApi.acceptPostsell(username, orderToken, data)
    const { redirectLink, clientSecret } = resp.data || {}
    if (!skipFunnelRedirect || !clientSecret) {
      if (redirectLink) {
        window.location.href = redirectLink
      } else {
        await this.setNextFunnelNode(username, orderToken, YES)
      }
    }
    return resp
  }

  @action rejectPostsell = async (username: string, orderToken: string, data: { funnelNodeId?: number }) => {
    this.setLoading(true)
    const resp = await this.childApi.rejectPostsell(username, orderToken, data)
    await this.setNextFunnelNode(username, orderToken, NO)
    return resp
  }

  @action fetchFunnelPreview = async (username: string, token: string) => {
    this.setLoading(true)
    const resp = await this.childApi.fetchFunnelPreview(username, token)
    this.setFunnel(resp?.data)
    this.setLoading(false)
    return resp
  }

  @override
  hydrate(key, data) {
    if (key === 'funnel') {
      this.funnel = data
    } else if (key === 'currentFunnelNode') {
      this.currentFunnelNode = data
    } else if (key === 'loading') {
      this.setLoading(data)
    }
  }
}
