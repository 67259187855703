export const COURSE_FORMS = {
  default: 'default',
  custom: 'custom',
}

export const COURSES_TABS = [
  {
    key: 'overview',
    link: '/cabinet/courses/overview',
    label: I18n.t('react.cabinet.courses.overview'),
  },
  {
    key: 'sessions',
    link: '/cabinet/courses/sessions',
    label: I18n.t('react.cabinet.courses.sessions'),
  },
  {
    key: 'lesson_statuses',
    link: '/cabinet/courses/lesson_statuses',
    label: I18n.t('react.cabinet.courses.lesson_statuses'),
  },
  {
    key: 'quiz_attempts',
    link: '/cabinet/courses/quiz_attempts',
    label: I18n.t('react.cabinet.courses.quiz_attempts'),
  },
]

export const COURSE_SESSIONS_FILTER_LIST = ['payerId', 'productId', 'createdAt', 'courseAccessState', 'access']

export const LESSON_STATUSES_FILTER_LIST = [
  'payerId',
  'productId',
  'lessonId',
  'publishState',
  'notifyState',
  'notifyDate',
  'createdAt',
]

export const QUIZ_ATTEMPTS_FILTER_LIST = ['payerId', 'productId', 'lessonId', 'status', 'createdAt']

export const QUIZ_ATTEMPT_STATUSES = {
  waiting: 'waiting',
  failed: 'failed',
  completed: 'completed',
}

export const QUIZ_QUESTION_TYPES = {
  text: 'text_field',
  single: 'single_choice',
  multiple: 'multiple_choice',
}

export const MEMBERSHIP_SESSIONS_PUBLISH_STATES = {
  publishPaused: 'publish_paused',
  publishing: 'publishing',
  publishCanceled: 'publish_canceled',
}

export const COURSE_SESSION_PUBLISH_STATES = {
  ...MEMBERSHIP_SESSIONS_PUBLISH_STATES,
  published: 'published',
  publishPending: 'publish_pending',
}
export const COURSE_SESSION_PUBLISH_STATES_VALUES = Object.values(COURSE_SESSION_PUBLISH_STATES)

export const COURSE_SESSION_PAYMENT_STATES = {
  paymentRefunded: 'payment_refunded',
  paymentPaused: 'payment_paused',
  paymentCanceled: 'payment_canceled',
}

export const LESSON_STATUSES_PUBLISH_STATES = {
  finished: 'finished',
  published: 'published',
  pending: 'pending',
  viewed: 'viewed',
  notForPublish: 'not_for_publish',
}
export const LESSON_STATUSES_PUBLISH_STATES_VALUES = Object.values(LESSON_STATUSES_PUBLISH_STATES)

export const LESSON_STATUSES_NOTIFY_STATE_COLORS = {
  outdate: 'orange',
}

const LESSON_STATUSES_PUBLISH_STATES_COLORS = {
  [LESSON_STATUSES_PUBLISH_STATES.finished]: 'green',
  [LESSON_STATUSES_PUBLISH_STATES.notForPublish]: 'red',
  [LESSON_STATUSES_PUBLISH_STATES.pending]: 'orange',
  [LESSON_STATUSES_PUBLISH_STATES.published]: 'blue',
  [LESSON_STATUSES_PUBLISH_STATES.viewed]: 'grey',
}

const COURSE_SESSION_PUBLISH_STATES_COLORS = {
  [COURSE_SESSION_PUBLISH_STATES.publishCanceled]: 'orange',
  [COURSE_SESSION_PUBLISH_STATES.publishing]: 'green',
  [COURSE_SESSION_PUBLISH_STATES.publishPaused]: 'red',
}

export const PUBLISH_STATES_COLORS = {
  ...LESSON_STATUSES_PUBLISH_STATES_COLORS,
  ...COURSE_SESSION_PUBLISH_STATES_COLORS,
}

export const PUBLISH_STATES_LABELS = {
  publish_canceled: I18n.t('react.shared.publish_state.publish_canceled'),
  published: I18n.t('react.shared.publish_state.published'),
  publishing: I18n.t('react.shared.publish_state.publishing'),
  publish_paused: I18n.t('react.shared.publish_state.publish_paused'),
  publish_pending: I18n.t('react.shared.publish_state.publish_pending'),
}

export const LIFETIME_ACCESS = 'Lifetime'

export const EMPTY_TEXT_EDITOR = '<br>'

export const ACCESS_LIMITED_MODAL_MODE = {
  anotherPersonViewing: 'anotherPersonViewing',
  tryToRejoin: 'tryToRejoin',
}

export const CHECK_ACCESS_DELAY = 5000
export const COURSE_ACCESS_MODAL_STEPS = {
  access: 'access',
  extend: 'extend',
  selectCustomers: 'selectCustomers',
  customers: 'customers',
  confirmation: 'confirmation',
}

export const COURSE_ACCESS_TYPES = {
  extendDuration: 'extendDuration',
  grantFullAccess: 'grantFullAccess',
}

export const COURSE_ACCESS_TYPES_LIST = Object.values(COURSE_ACCESS_TYPES)

export const EXTEND_COURSE_DURATION_TYPES = {
  duration: 'duration',
  endDate: 'endDate',
}

export const LIFETIME_DURATION = 300

export const MAX_FREE_LESSON_COUNT = 5

export const PUBLISH_STATE_LABEL = {
  not_for_publish: I18n.t('react.cabinet.lesson_status.publish_state.not_for_publish'),
  pending: I18n.t('react.cabinet.lesson_status.publish_state.pending'),
  published: I18n.t('react.cabinet.lesson_status.publish_state.published'),
  finished: I18n.t('react.cabinet.lesson_status.publish_state.finished'),
  viewed: I18n.t('react.cabinet.lesson_status.publish_state.viewed'),
}
