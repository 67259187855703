import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const defaultProps = {
  viewBox: '0 0 220 249',
  width: 220,
  height: 249,
}

export const BumpTwoColumnThird = (props) => {
  const { height, viewBox, width } = props
  /* eslint-disable */
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <rect fill='#F3F5F8' fillRule='nonzero' x='0' y='0' width='220' height='259' rx='8' />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(109.500000, 165.000000) scale(1, -1) rotate(-180.000000) translate(-109.500000, -165.000000) '
            x='19'
            y='79'
            width='181'
            height='172'
            rx='4'
          />
          <path
            d='M95.9948231,121 C97.1035316,121 98,121.895431 98,123 C98,124.11227 97.1022517,125 95.9948231,125 L95.9948231,125 L33.0051769,125 C31.8964684,125 31,124.104569 31,123 L31,123 L31.0054999,122.849781 C31.0820467,121.808861 31.948086,121 33.0051769,121 L33.0051769,121 Z M95.9948231,111 C97.1035316,111 98,111.895431 98,113 C98,114.11227 97.1022517,115 95.9948231,115 L95.9948231,115 L33.0051769,115 C31.8964684,115 31,114.104569 31,113 L31,113 L31.0054999,112.849781 C31.0820467,111.808861 31.948086,111 33.0051769,111 L33.0051769,111 Z M95.9948231,101 C97.1035316,101 98,101.895431 98,103 C98,104.11227 97.1022517,105 95.9948231,105 L95.9948231,105 L33.0051769,105 C31.8964684,105 31,104.104569 31,103 L31,103 L31.0054999,102.849781 C31.0820467,101.808861 31.948086,101 33.0051769,101 L33.0051769,101 Z M71.9980004,91 C73.1018839,91 74,91.8954305 74,93 C74,94.1122704 73.1036743,95 71.9980004,95 L71.9980004,95 L33.0019996,95 C31.8981161,95 31,94.1045695 31,93 L31,93 L31.0054912,92.8497808 C31.0819167,91.8088611 31.9465836,91 33.0019996,91 L33.0019996,91 Z'
            fill='#E5E3E5'
          />
          <line
            x1='109.641844'
            y1='91.2138009'
            x2='109.5'
            y2='238.5'
            id='Line'
            stroke='#EAEAEA'
            strokeLinecap='square'
          />
          <rect fill='#2BFF99' fillRule='nonzero' x='120' y='223' width='67' height='15.821267' rx='7.91063348' />
          <g transform='translate(120.000000, 161.000000)' fillRule='nonzero'>
            <rect
              fill='#B8B9BC'
              transform='translate(33.500000, 26.000000) scale(1, -1) rotate(-180.000000) translate(-33.500000, -26.000000) '
              x='0'
              y='0'
              width='67'
              height='52'
              rx='4'
            />
            <path
              d='M43.9632842,35.8316835 C45.3323813,35.8316835 46.4422446,34.7218201 46.4422446,33.3527231 L46.4422446,18.4789604 C46.4422446,17.1098633 45.3323813,16 43.9632842,16 L22.4789604,16 C21.1098633,16 20,17.1098633 20,18.4789604 L20,33.3527231 C20,34.7218201 21.1098633,35.8316835 22.4789604,35.8316835 L43.9632842,35.8316835 Z M25.784241,24.6763615 C24.1869779,24.6763615 22.8921205,23.3815041 22.8921205,21.784241 C22.8921205,20.1869779 24.1869779,18.8921205 25.784241,18.8921205 C27.3815041,18.8921205 28.6763615,20.1869779 28.6763615,21.784241 C28.6763615,23.3815041 27.3815041,24.6763615 25.784241,24.6763615 Z M43.1369641,32.5264029 L23.3052806,32.5264029 L23.3052806,30.0474425 L26.9986048,26.3540678 C27.2406908,26.1120323 27.6330718,26.1120323 27.8751074,26.3540678 L30.7421619,29.2211223 L37.7407667,22.2224671 C37.9828527,21.9804315 38.3752337,21.9804315 38.6172693,22.2224671 L43.1369641,26.7421619 L43.1369641,32.5264029 Z'
              fill='#FFFFFF'
              opacity='0.5'
            />
          </g>
          <path
            d='M184.994823,151 C186.103532,151 187,151.895431 187,153 C187,154.11227 186.102252,155 184.994823,155 L184.994823,155 L122.005177,155 C120.896468,155 120,154.104569 120,153 L120,153 L120.0055,152.849781 C120.082047,151.808861 120.948086,151 122.005177,151 L122.005177,151 Z M184.994823,141 C186.103532,141 187,141.895431 187,143 C187,144.11227 186.102252,145 184.994823,145 L184.994823,145 L122.005177,145 C120.896468,145 120,144.104569 120,143 L120,143 L120.0055,142.849781 C120.082047,141.808861 120.948086,141 122.005177,141 L122.005177,141 Z M184.994823,131 C186.103532,131 187,131.895431 187,133 C187,134.11227 186.102252,135 184.994823,135 L184.994823,135 L122.005177,135 C120.896468,135 120,134.104569 120,133 L120,133 L120.0055,132.849781 C120.082047,131.808861 120.948086,131 122.005177,131 L122.005177,131 Z M184.994823,121 C186.103532,121 187,121.895431 187,123 C187,124.11227 186.102252,125 184.994823,125 L184.994823,125 L122.005177,125 C120.896468,125 120,124.104569 120,123 L120,123 L120.0055,122.849781 C120.082047,121.808861 120.948086,121 122.005177,121 L122.005177,121 Z M184.994823,111 C186.103532,111 187,111.895431 187,113 C187,114.11227 186.102252,115 184.994823,115 L184.994823,115 L122.005177,115 C120.896468,115 120,114.104569 120,113 L120,113 L120.0055,112.849781 C120.082047,111.808861 120.948086,111 122.005177,111 L122.005177,111 Z M184.994823,101 C186.103532,101 187,101.895431 187,103 C187,104.11227 186.102252,105 184.994823,105 L184.994823,105 L122.005177,105 C120.896468,105 120,104.104569 120,103 L120,103 L120.0055,102.849781 C120.082047,101.808861 120.948086,101 122.005177,101 L122.005177,101 Z M160.998,91 C162.101884,91 163,91.8954305 163,93 C163,94.1122704 162.103674,95 160.998,95 L160.998,95 L122.002,95 C120.898116,95 120,94.1045695 120,93 L120,93 L120.005491,92.8497808 C120.081917,91.8088611 120.946584,91 122.002,91 L122.002,91 Z'
            fill='#E5E3E5'
          />
          <rect
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(109.500000, 47.000000) scale(1, -1) rotate(-180.000000) translate(-109.500000, -47.000000) '
            x='19'
            y='23'
            width='181'
            height='48'
            rx='4'
          />
          <path
            d='M102.940549,55 C104.052088,55 104.938534,55.8954305 104.938534,57 C104.938534,58.1122704 104.044006,59 102.940549,59 L102.940549,59 L67.997985,59 C66.8864466,59 66,58.1045695 66,57 L66,57 L66.0054802,56.8497808 C66.0817524,55.8088611 66.9446855,55 67.997985,55 L67.997985,55 Z M186.009328,45 C187.105285,45 188,45.8954305 188,47 C188,48.1122704 187.108746,49 186.009328,49 L186.009328,49 L67.9906724,49 C66.8947148,49 66,48.1045695 66,47 L66,47 L66.0054602,46.8497808 C66.0814532,45.8088611 66.9412279,45 67.9906724,45 L67.9906724,45 Z M186.009328,35 C187.105285,35 188,35.8954305 188,37 C188,38.1122704 187.108746,39 186.009328,39 L186.009328,39 L67.9906724,39 C66.8947148,39 66,38.1045695 66,37 L66,37 L66.0054602,36.8497808 C66.0814532,35.8088611 66.9412279,35 67.9906724,35 L67.9906724,35 Z'
            fill='#E5E3E5'
          />
          <rect id='Rectangle-Copy-8' fill='#E5E3E5' x='31' y='35' width='24' height='23.9835004' rx='4' />
          <path
            d='M47.875,52 C48.4963226,52 49,51.4403585 49,50.75 L49,43.25 C49,42.5596415 48.4963226,42 47.875,42 L38.125,42 C37.5036774,42 37,42.5596415 37,43.25 L37,50.75 C37,51.4403585 37.5036774,52 38.125,52 L47.875,52 Z M39.5,46 C38.6715785,46 38,45.3284215 38,44.5 C38,43.6715785 38.6715785,43 39.5,43 C40.3284215,43 41,43.6715785 41,44.5 C41,45.3284215 40.3284215,46 39.5,46 Z M48,50 L39,50 L39,48.817906 L40.6761017,47.0567178 C40.785965,46.9413029 40.964035,46.9413029 41.0738754,47.0567178 L42.375,48.4238747 L45.5511017,45.0865611 C45.660965,44.9711463 45.839035,44.9711463 45.9488754,45.0865611 L48,47.2417807 L48,50 Z'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
          <g>
            <path
              d='M8,0 L212,0 C216.418278,-8.11624501e-16 220,3.581722 220,8 L220,16 L220,16 L0,16 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z'
              fill='#D7DADF'
            />
            <path
              d='M9,4.9754902 C10.6568542,4.9754902 12,6.31863595 12,7.9754902 C12,9.63234445 10.6568542,10.9754902 9,10.9754902 C7.34314575,10.9754902 6,9.63234445 6,7.9754902 C6,6.31863595 7.34314575,4.9754902 9,4.9754902 Z M18.1636364,4.9754902 C19.8204906,4.9754902 21.1636364,6.31863595 21.1636364,7.9754902 C21.1636364,9.63234445 19.8204906,10.9754902 18.1636364,10.9754902 C16.5067821,10.9754902 15.1636364,9.63234445 15.1636364,7.9754902 C15.1636364,6.31863595 16.5067821,4.9754902 18.1636364,4.9754902 Z M26.8909091,4.9754902 C28.5477633,4.9754902 29.8909091,6.31863595 29.8909091,7.9754902 C29.8909091,9.63234445 28.5477633,10.9754902 26.8909091,10.9754902 C25.2340548,10.9754902 23.8909091,9.63234445 23.8909091,7.9754902 C23.8909091,6.31863595 25.2340548,4.9754902 26.8909091,4.9754902 Z'
              fill='#FFFFFF'
            />
            <rect fill='#FFFFFF' x='47' y='4.9754902' width='156' height='6' rx='3' />
          </g>
        </g>
      </g>
    </svg>
  )
  /* eslint-enable */
}

BumpTwoColumnThird.displayName = 'BumpTwoColumnThird'
BumpTwoColumnThird.propTypes = propTypes
BumpTwoColumnThird.defaultProps = defaultProps
