import { getCookies, setCookies } from 'libs/common/cookies'

class Profile {
  profileType: string
  tmSelectedSeller: string
  tmSelectedTeamId: string

  setProfileType(type: string) {
    this.profileType = type
    setCookies('cabinet_profile_type', type)
  }

  setTmSelectedSeller(seller: string) {
    this.tmSelectedSeller = seller
  }

  setTmSelectedTeamId(id: string) {
    this.tmSelectedTeamId = id
  }

  get isInAdminMode() {
    const adminToken = getCookies('admin_access_token') as string

    return adminToken && adminToken.length > 0
  }
}

export const profile = new Profile()
