import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { TrackingEventAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type TrackingEvent = FromSwagger<TrackingEventAttributes>

const BASE_URL = (username: string, trackingCodeId: number) =>
  `/shop/${username}/tracking_codes/${trackingCodeId}/tracking_events`

export const createTrackingEventApi = ({ GET }: ApiClientV2) => ({
  fetchEvents: (username: string, trackingCodeId: number) => GET<TrackingEvent[]>(BASE_URL(username, trackingCodeId)),
})

export type TrackingEventApi = ReturnType<typeof createTrackingEventApi>
