import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloClipboardIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C11.8502 3.31465 11.9262 3.40223 11.9957 3.4942C11.9984 3.4979 12.0011 3.50156 12.0039 3.50519C12.3241 3.93442 12.5 4.45814 12.5 5H7.5C7.5 4.45814 7.67591 3.93442 7.99611 3.50519C7.99889 3.50156 8.00164 3.4979 8.00435 3.49421C8.07375 3.40223 8.14982 3.31465 8.23223 3.23223ZM7.20491 2.5C7.25118 2.44826 7.29901 2.39769 7.34835 2.34835C8.05161 1.64509 9.00544 1.25 10 1.25C10.9946 1.25 11.9484 1.64509 12.6517 2.34835C12.701 2.39769 12.7488 2.44826 12.7951 2.5H15.625C15.9565 2.5 16.2745 2.6317 16.5089 2.86612C16.7433 3.10054 16.875 3.41848 16.875 3.75V16.875C16.875 17.2065 16.7433 17.5245 16.5089 17.7589C16.2745 17.9933 15.9565 18.125 15.625 18.125H4.375C4.04348 18.125 3.72554 17.9933 3.49112 17.7589C3.2567 17.5245 3.125 17.2065 3.125 16.875V3.75C3.125 3.41848 3.2567 3.10054 3.49112 2.86612C3.72554 2.6317 4.04348 2.5 4.375 2.5H7.20491ZM6.46446 3.75H4.375L4.375 16.875H15.625V3.75H13.5355C13.6762 4.14775 13.75 4.57007 13.75 5V5.625C13.75 5.97018 13.4702 6.25 13.125 6.25H6.875C6.52982 6.25 6.25 5.97018 6.25 5.625V5C6.25 4.57007 6.32383 4.14775 6.46446 3.75Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
