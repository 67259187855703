import type { PaginatedResponse } from 'types/responses'
import type { Nullable } from 'types/helpers'
import type { ApiClient } from 'utils/requests.utils'
import type { PricingPlan } from 'shop/types/orders'
import type { AddonTheme, Upsell } from 'shop/types/upsell'

interface ProductUpsellReq {
  username: string
}

interface ProductUpsell {
  addonTheme: AddonTheme
  addonThemeId: number
  createdAt: string
  id: number
  position: number
  prefs: {
    ownPlans: boolean
  }
  pricingPlans: PricingPlan[]
  productId: number
  ticketDateId: Nullable<number>
  ticketId: Nullable<number>
  ticketsCount: Nullable<number>
  updatedAt: string
  upsell: Upsell
  upsellId: number
}

// export default fetchList

const BASE_URL = (username) => `/shop/${username}/product_upsells`

export const createProductUpsellsApi = ({ GET }: ApiClient) => ({
  fetchList: (data: ProductUpsellReq): Promise<PaginatedResponse<ProductUpsell[]>> =>
    GET(BASE_URL(data.username), data),
})

export type ProductUpsellsApi = ReturnType<typeof createProductUpsellsApi>
