import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMailFillIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M18.6667 6.84V13.625C18.6667 15.0692 17.5363 16.2493 16.112 16.3291L15.9583 16.3333H4.70833C3.26414 16.3333 2.08396 15.2029 2.00428 13.7787L2 13.625V6.84L10.0433 11.0537C10.225 11.1488 10.4417 11.1488 10.6233 11.0537L18.6667 6.84ZM4.70833 3H15.9583C17.3622 3 18.5168 4.06826 18.6532 5.43629L10.3333 9.79442L2.01349 5.43629C2.14488 4.11893 3.22027 3.07954 4.55341 3.00436L4.70833 3Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
