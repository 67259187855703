import { makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { OrderBumpsThemesApi, createOrderBumpsThemesApi } from 'shop/api/orderBumpsThemes.api'

export class AddonThemesStore extends SharedStore<any> {
  storeName = 'AddonThemesStore'
  declare childApi: OrderBumpsThemesApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createOrderBumpsThemesApi(root?.apiClient ?? apiClient)
    makeObservable(this)
  }

  @override async fetchItem(username: string, id: string | number) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem(username, id)
    this.setItem(resp)
    this.toggleLoading(false)
  }
}
