import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { createSellerApi } from 'shop/api/seller.api'

export class SellablesStore extends SharedStore {
  storeName = 'SellablesStore'
  childApi

  constructor(root) {
    super(root)
    this.childApi = createSellerApi(root?.apiClientV2)
    makeObservable(this)
  }
}
