import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloBulletsIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path d='M6.875 5H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M6.875 10H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path d='M6.875 15H16.875' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    <path
      d='M3.4375 5.9375C3.95527 5.9375 4.375 5.51777 4.375 5C4.375 4.48223 3.95527 4.0625 3.4375 4.0625C2.91973 4.0625 2.5 4.48223 2.5 5C2.5 5.51777 2.91973 5.9375 3.4375 5.9375Z'
      fill='currentColor'
    />
    <path
      d='M3.4375 10.9375C3.95527 10.9375 4.375 10.5178 4.375 10C4.375 9.48223 3.95527 9.0625 3.4375 9.0625C2.91973 9.0625 2.5 9.48223 2.5 10C2.5 10.5178 2.91973 10.9375 3.4375 10.9375Z'
      fill='currentColor'
    />
    <path
      d='M3.4375 15.9375C3.95527 15.9375 4.375 15.5178 4.375 15C4.375 14.4822 3.95527 14.0625 3.4375 14.0625C2.91973 14.0625 2.5 14.4822 2.5 15C2.5 15.5178 2.91973 15.9375 3.4375 15.9375Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
