import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

const BASE_URL = (username: string) => `/shop/${username}/ticket/availability`

export const createCheckTicketsAvailabilityApi = ({ POST }: ApiClient) => ({
  check: (username, data): Promise<Response<any>> => POST(`${BASE_URL(username)}`, data),
})

export type CheckTicketsAvailabilityApi = ReturnType<typeof createCheckTicketsAvailabilityApi>
