import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloZoomOut: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M11.8766 8.75164C11.8766 8.9174 11.8108 9.07637 11.6936 9.19358C11.5764 9.31079 11.4174 9.37664 11.2516 9.37664H6.25164C6.08588 9.37664 5.92691 9.31079 5.8097 9.19358C5.69249 9.07637 5.62664 8.9174 5.62664 8.75164C5.62664 8.58588 5.69249 8.42691 5.8097 8.3097C5.92691 8.19249 6.08588 8.12664 6.25164 8.12664H11.2516C11.4174 8.12664 11.5764 8.19249 11.6936 8.3097C11.8108 8.42691 11.8766 8.58588 11.8766 8.75164ZM17.9438 17.9438C17.8858 18.0019 17.8169 18.048 17.741 18.0795C17.6651 18.1109 17.5838 18.1271 17.5016 18.1271C17.4195 18.1271 17.3382 18.1109 17.2623 18.0795C17.1864 18.048 17.1175 18.0019 17.0595 17.9438L13.1485 14.0321C11.7874 15.1659 10.0415 15.7312 8.27409 15.6106C6.5067 15.4899 4.85387 14.6925 3.65943 13.3843C2.46499 12.076 1.8209 10.3576 1.86115 8.58657C1.9014 6.81553 2.62289 5.12818 3.87554 3.87554C5.12818 2.62289 6.81553 1.9014 8.58657 1.86115C10.3576 1.8209 12.076 2.46499 13.3843 3.65943C14.6925 4.85387 15.4899 6.5067 15.6106 8.27409C15.7312 10.0415 15.1659 11.7874 14.0321 13.1485L17.9438 17.0595C18.0019 17.1175 18.048 17.1864 18.0795 17.2623C18.1109 17.3382 18.1271 17.4195 18.1271 17.5016C18.1271 17.5838 18.1109 17.6651 18.0795 17.741C18.048 17.8169 18.0019 17.8858 17.9438 17.9438ZM8.75164 14.3766C9.86416 14.3766 10.9517 14.0467 11.8767 13.4287C12.8018 12.8106 13.5227 11.9321 13.9485 10.9042C14.3742 9.8764 14.4856 8.7454 14.2686 7.65426C14.0515 6.56312 13.5158 5.56084 12.7291 4.77417C11.9424 3.9875 10.9402 3.45177 9.84903 3.23473C8.75788 3.01768 7.62688 3.12908 6.59905 3.55482C5.57121 3.98056 4.69271 4.70153 4.07463 5.62656C3.45654 6.55159 3.12664 7.63912 3.12664 8.75164C3.1283 10.243 3.72146 11.6728 4.77599 12.7273C5.83053 13.7818 7.26031 14.375 8.75164 14.3766Z'
      fill='black'
    />
  </EloBaseIcon>
)
