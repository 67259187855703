import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { createTracifyApi, Tracify, TracifyApi } from 'shop/api/tracify.api'

export class TracifyStore extends SharedStore<Tracify> {
  storeName = 'TracifyStore'
  declare childApi: TracifyApi

  constructor(root: ShopRootStore) {
    super()
    makeObservable(this)
    this.childApi = createTracifyApi(root.apiClientV2)
  }

  fetchTracifyItem = async (username: string) => {
    const { data } = await this.childApi.fetchTracifyItem(username)

    return data
  }
}
