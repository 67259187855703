import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloTypeIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M5.78333 14.6896L3.09167 16.8528C2.99454 16.9301 2.87662 16.9793 2.75158 16.9948C2.62654 17.0102 2.49951 16.9913 2.38523 16.9401C2.27094 16.8889 2.17409 16.8077 2.10591 16.7057C2.03772 16.6038 2.001 16.4853 2 16.3641V3.64096C2 3.47097 2.07024 3.30794 2.19526 3.18773C2.32029 3.06753 2.48986 3 2.66667 3H17.3333C17.5101 3 17.6797 3.06753 17.8047 3.18773C17.9298 3.30794 18 3.47097 18 3.64096V13.8964C18 14.0664 17.9298 14.2294 17.8047 14.3496C17.6797 14.4698 17.5101 14.5374 17.3333 14.5374H6.20833C6.05225 14.5374 5.9014 14.5914 5.78333 14.6896V14.6896Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.1562 10.0625C10.7258 10.0625 11.1875 9.60079 11.1875 9.03125C11.1875 8.46171 10.7258 8 10.1562 8C9.58671 8 9.125 8.46171 9.125 9.03125C9.125 9.60079 9.58671 10.0625 10.1562 10.0625Z'
      fill='currentColor'
    />
    <path
      d='M6.03125 10.0625C6.60079 10.0625 7.0625 9.60079 7.0625 9.03125C7.0625 8.46171 6.60079 8 6.03125 8C5.46171 8 5 8.46171 5 9.03125C5 9.60079 5.46171 10.0625 6.03125 10.0625Z'
      fill='currentColor'
    />
    <path
      d='M14.2812 10.0625C14.8508 10.0625 15.3125 9.60079 15.3125 9.03125C15.3125 8.46171 14.8508 8 14.2812 8C13.7117 8 13.25 8.46171 13.25 9.03125C13.25 9.60079 13.7117 10.0625 14.2812 10.0625Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
