import { observable, action, makeObservable, computed, toJS } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'

import {
  ShopThemeApi,
  ShopPage,
  ShopTheme,
  ThemePagePrefs,
  PayerForms,
  createShopThemeApi,
} from 'shop/api/shopTheme.api'

export enum THEME_PAGE_TYPES {
  main = 'main_page',
  product = 'product_page',
  customProduct = 'custom_product_page',
  content = 'content_page',
  checkout = 'checkout_page',
  funnel = 'funnel_page',
  thankYou = 'thank_you_page',
  manage = 'manage_page',
}

export class ShopThemeStore {
  storeName = 'ShopThemeStore'
  declare childApi: ShopThemeApi
  root
  constructor(root: ShopRootStore) {
    this.root = root
    this.childApi = createShopThemeApi(root.apiClientV2)
    makeObservable(this)
  }

  @observable shopTheme = { prefs: {} } as ShopTheme
  @observable shopPages = [] as ShopPage[]
  @observable payerForms = {} as PayerForms
  @observable pageType = '' as THEME_PAGE_TYPES // actually it is just router state
  @observable pageSlug = ''

  fetchShopTheme = async (username: string, id: string | number) => {
    const { data } = await this.childApi.fetchShopTheme(username, id)

    if (data) {
      this.shopTheme = data
    }
  }

  fetchShopPages = async (username: string, themeId: number) => {
    const { data } = await this.childApi.fetchShopPages(username, themeId)
    this.shopPages = data || []
  }

  fetchPayerForms = async (username: string, themeId: number) => {
    const { data } = await this.childApi.fetchPayerForms(username, themeId)
    this.payerForms = data
    this.root.themeStore.setPpTemplate({ buyerInformation: data })
  }

  @computed get displayData(): Partial<ThemePagePrefs> {
    switch (this.pageType) {
      case THEME_PAGE_TYPES.main: {
        const shopPage = this.shopPages.find(({ form }) => form === 'main')
        return {
          headerHidden: this.shopTheme.prefs.headerHidden || shopPage?.prefs?.headerHidden,
          footerHidden: this.shopTheme.prefs.footerHidden || shopPage?.prefs?.footerHidden,
          header: { ...this.shopTheme.prefs.header, ...shopPage?.prefs?.header },
          footer: { ...this.shopTheme.prefs.footer, ...shopPage?.prefs?.footer },
        }
      }
      case THEME_PAGE_TYPES.product: {
        const shopPage = this.shopPages.find((item) => item.form === 'product' && item.default === true)
        return {
          headerHidden: this.shopTheme.prefs.headerHidden || shopPage?.prefs?.headerHidden,
          footerHidden: this.shopTheme.prefs.footerHidden || shopPage?.prefs?.footerHidden,
          header: { ...this.shopTheme.prefs.header, ...shopPage?.prefs?.header },
          footer: { ...this.shopTheme.prefs.footer, ...shopPage?.prefs?.footer },
        }
      }
      case THEME_PAGE_TYPES.customProduct: {
        const shopPage = this.shopPages.find(
          ({ prefs }) =>
            Array.isArray(toJS(prefs.products)) &&
            prefs.products.includes(String(this.root.contentPageStore.product?.id))
        )
        return {
          headerHidden: this.shopTheme.prefs.headerHidden || shopPage?.prefs?.headerHidden,
          footerHidden: this.shopTheme.prefs.footerHidden || shopPage?.prefs?.footerHidden,
          header: { ...this.shopTheme.prefs.header, ...shopPage?.prefs?.header },
          footer: { ...this.shopTheme.prefs.footer, ...shopPage?.prefs?.footer },
        }
      }
      case THEME_PAGE_TYPES.manage: {
        const shopPage = this.shopTheme.prefs.managePageTheme
        if (shopPage?.active) {
          return {
            headerHidden: shopPage?.headerGeneral ? this.shopTheme.prefs.headerHidden : shopPage?.headerHidden,
            header: shopPage?.headerGeneral ? this.shopTheme.prefs.header : shopPage?.header,
            footer: shopPage?.footerGeneral ? this.shopTheme.prefs.footer : shopPage?.footer,
          }
        } else {
          return this.shopTheme.prefs
        }
      }
      case THEME_PAGE_TYPES.thankYou: {
        const shopPage = this.shopTheme.prefs.thankYouPageTheme
        if (shopPage?.active) {
          return {
            headerHidden: shopPage?.headerGeneral ? this.shopTheme.prefs.headerHidden : shopPage?.headerHidden,
            header: shopPage?.headerGeneral ? this.shopTheme.prefs.header : shopPage?.header,
            footer: shopPage?.footerGeneral ? this.shopTheme.prefs.footer : shopPage?.footer,
          }
        } else {
          return this.shopTheme.prefs
        }
      }
      case THEME_PAGE_TYPES.funnel: {
        const shopPage = this.shopTheme.prefs.funnelPageTheme
        if (shopPage?.active) {
          return {
            headerHidden: shopPage?.headerGeneral ? this.shopTheme.prefs.headerHidden : shopPage?.headerHidden,
            header: shopPage?.headerGeneral ? this.shopTheme.prefs.header : shopPage?.header,
            footer: shopPage?.footerGeneral ? this.shopTheme.prefs.footer : shopPage?.footer,
          }
        } else {
          return this.shopTheme.prefs
        }
      }
      case THEME_PAGE_TYPES.checkout: {
        const shopPage = this.shopTheme.prefs.paymentPageTheme
        if (shopPage?.active) {
          return {
            headerHidden: shopPage?.headerGeneral ? this.shopTheme.prefs.headerHidden : shopPage?.headerHidden,
            header: shopPage?.headerGeneral ? this.shopTheme.prefs.header : shopPage?.header,
            footer: shopPage?.footerGeneral ? this.shopTheme.prefs.footer : shopPage?.footer,
          }
        } else {
          return this.shopTheme.prefs
        }
      }
      case THEME_PAGE_TYPES.content: {
        const shopPage = this.shopPages.find(({ slug }) => slug === this.pageSlug)
        return {
          headerHidden: this.shopTheme.prefs.headerHidden || shopPage?.prefs?.headerHidden,
          footerHidden: this.shopTheme.prefs.footerHidden || shopPage?.prefs?.footerHidden,
          header: { ...this.shopTheme.prefs.header, ...shopPage?.prefs?.header },
          footer: { ...this.shopTheme.prefs.footer, ...shopPage?.prefs?.footer },
        }
      }
      default:
        return this.shopTheme.prefs
    }
  }

  @computed get headerHidden() {
    return this.displayData.headerGeneral ? this.shopTheme.prefs.headerHidden : this.displayData.headerHidden
  }

  @action hydrate(...args: HydrationData) {
    const [key, data] = args
    if (key === 'shopTheme') {
      this.shopTheme = data
    } else if (key === 'shopPages') {
      this.shopPages = data || []
    } else if (key === 'payerForms') {
      this.payerForms = data
    } else if (key === 'pageType') {
      this.pageType = data
    } else if (key === 'pageSlug') {
      this.pageSlug = data
    }
  }
}

type HydrationData =
  | ['shopTheme', ShopTheme]
  | ['shopPages', ShopPage[]]
  | ['payerForms', PayerForms]
  | ['pageType', THEME_PAGE_TYPES]
