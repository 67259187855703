import type { ApiClientV2 } from 'utils/api-client/api-client.utils'
import { SellerSettingsTracifyAttributes } from 'types/__generated__/api'
import { FromSwagger } from 'types/helpers'

export type Tracify = FromSwagger<SellerSettingsTracifyAttributes>

const BASE_URL = (username: string) => `/shop/${username}/seller_settings/tracify`

export const createTracifyApi = ({ GET }: ApiClientV2) => ({
  fetchTracifyItem: (username: string) => GET<Tracify>(BASE_URL(username)),
})

export type TracifyApi = ReturnType<typeof createTracifyApi>
