import type { Nullable } from 'types/helpers'
import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface SharingItem {
  active: boolean
  archived: boolean
  createdAt: string
  deactivateAt: Nullable<string>
  description: string
  form: string
  id: number
  name: string
  productId: Nullable<number>
  reshareEnabled: boolean
  sellerId: number
  sharableId: Nullable<number>
  sharableType: Nullable<string>
  sharingItems: {
    id: number
    sharable: {
      contentPageId: number
      copyFromId: Nullable<number>
      createdAt: string
      default: boolean
      id: number
      name: string
      prefs: Record<string, unknown>
      sellerId: number
      sharingId: Nullable<number>
      sharingItemId: Nullable<number>
      template: boolean
      token: string
      updatedAt: string
    }
    sharableId: number
    sharableType: string
    sharingId: number
  }[]
  token: string
  updatedAt: string
}

const BASE_URL = (username, token) => `/shop/${username}/sharings/${token}`

export const createSharingsApi = ({ GET }: ApiClient) => ({
  fetchItem: ({ username, token }: { username: string; token: string }, data): Promise<Response<SharingItem>> =>
    GET(BASE_URL(username, token), { ...data, expand: ['sharing_items.sharable'] }),
})

export type SharingsApi = ReturnType<typeof createSharingsApi>
