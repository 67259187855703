import { getCookies, setCookies } from 'libs/common/cookies'
import {
  AFFILIATE_PROGRAM_FORMS,
  MAX_PERCENTAGE,
  MIN_PERCENTAGE,
  STAGE_TYPE,
  STAGES_NUMBER,
  STATISTIC_OVERVIEW_TABLE_TYPE,
  COMMISION_DURATION_TYPES,
} from 'constants/affiliatePrograms.constants'
import { isObject } from 'utils/validators.utils'
import { getSearchParams } from 'utils/queryString.utils'

import { createItem as createElopageAffiliateClick } from '../containers/app/api/elopageAffiliateClicks.api'

export const isCommisionAndBonusValid = (commission, bonus) => {
  const getSum = (total, number) => total + Number(number)
  const total = commission.reduce(getSum, 0) + bonus
  return total <= MAX_PERCENTAGE && total >= MIN_PERCENTAGE
}

export const makeFullCommisionArray = (commission = []) => {
  const elementNumber = STAGES_NUMBER - commission.length
  for (let i = 0; i < elementNumber; i += 1) {
    commission.push(0)
  }
  return commission
}

export const getCommissionRange = (allProductForm, commissionShort = [], stagesType) => {
  let minValue = (allProductForm ? commissionShort[0] : ((commissionShort[0] || {}).commission || {})[0]) || 0
  let maxValue = 0

  const getMinMaxValues = (commission) => {
    const value = parseFloat(commission)
    if (value < minValue && value != 0) {
      minValue = value
    }
    if (value > maxValue) {
      maxValue = value
    }
  }

  if (!allProductForm) {
    commissionShort.forEach((product) => {
      if (stagesType === STAGE_TYPE.one) {
        getMinMaxValues(product.commission[0])
      } else {
        const fullCommission = makeFullCommisionArray([...product.commission])
        fullCommission.forEach(getMinMaxValues)
      }
    })
  } else {
    const fullCommission = makeFullCommisionArray([...commissionShort])
    fullCommission.forEach(getMinMaxValues)
  }

  return parseFloat(minValue) === parseFloat(maxValue) || (stagesType === STAGE_TYPE.one && allProductForm)
    ? `${parseFloat(maxValue)}%`
    : `${parseFloat(minValue)}% - ${parseFloat(maxValue)}%`
}

export const getMinAndMaxForCommission = (commision = 0, initialMinValue = 0, initialMaxValue = 0) => {
  let minValue = initialMinValue
  let maxValue = initialMaxValue

  const getMinMaxValues = (commission = 0) => {
    const value = parseFloat(commission)
    if (value < minValue && value !== 0) {
      minValue = value
    }
    if (value > maxValue) {
      maxValue = value
    }
  }

  commision.forEach(getMinMaxValues)

  return {
    minValue,
    maxValue,
  }
}

export const getCommissionRangeWithTiers = (commissions = []) => {
  const tier_locales = {
    1: I18n.t('react.shared.affiliate_program.tiers.tier_1'),
    2: I18n.t('react.shared.affiliate_program.tiers.tier_2'),
    3: I18n.t('react.shared.affiliate_program.tiers.tier_3'),
    4: I18n.t('react.shared.affiliate_program.tiers.tier_4'),
    5: I18n.t('react.shared.affiliate_program.tiers.tier_5'),
  }

  if (!isObject(commissions[0])) {
    return commissions
      .map((item, index) =>
        I18n.t('react.shared.affiliate_program.commission_tier', {
          amount: `${item}%`,
          tier: tier_locales[index + 1],
        })
      )
      .join(', ')
  }

  const makeCommissionObject = (commissions) => {
    const stagesObject = {}
    for (let i = 0; i < STAGES_NUMBER; i += 1) {
      stagesObject[i + 1] = []
    }

    commissions.forEach((item) =>
      makeFullCommisionArray(item.commission).forEach((commission, index) => stagesObject[index + 1].push(commission))
    )

    return stagesObject
  }

  return Object.values(makeCommissionObject(commissions))
    .map((item, index) => {
      const { minValue, maxValue } = getMinAndMaxForCommission(item, item[0]) || {}
      return parseFloat(minValue) === parseFloat(maxValue)
        ? `${parseFloat(maxValue)}%`
        : I18n.t('react.shared.affiliate_program.commission_tier', {
            amount: `${parseFloat(minValue)}% - ${parseFloat(maxValue)}%`,
            tier: tier_locales[index + 1],
          })
    })
    .join(', ')
}

export const reduceCommissions = (item, type = STATISTIC_OVERVIEW_TABLE_TYPE.overview) => {
  let totalCommisions = 0
  for (let i = 1; i < 6; i += 1) {
    const fieldName = type === STATISTIC_OVERVIEW_TABLE_TYPE.overview ? `commission${i}Tier` : `tier${i}Amount`

    totalCommisions += Number(item[fieldName])
  }
  return totalCommisions
}

export const getCurrentPublisherCommission = (item) => {
  let commision = 0
  for (let i = 1; i < 6; i += 1) {
    if (item.currentPublisherId == item[`tier${i}Id`]) {
      commision = item[`tier${i}Amount`]
    }
  }
  return commision
}

export const getStagesType = (item = {}) => {
  let stageType = ''
  const { affiliateProgramCommissions = [], commission = [], form } = item || {}
  const someProductsIsMultiple = affiliateProgramCommissions.some((item) => item.commission?.length > 1)
  if (form === AFFILIATE_PROGRAM_FORMS.all) {
    stageType = commission.length > 1 ? STAGE_TYPE.multiple : STAGE_TYPE.one
  } else {
    stageType = someProductsIsMultiple ? STAGE_TYPE.multiple : STAGE_TYPE.one
  }

  return stageType
}

export const setAffiliateCookies = () => {
  const urlParams = getSearchParams()
  if (urlParams.epid) {
    setCookies('epid', urlParams.epid)
    createElopageAffiliateClick({ eloPublisherId: urlParams.epid })
  }
}

export const getAffiliateCookies = () => {
  const epid = getCookies('epid')
  return epid ? { epid } : {}
}

export const formatLifetimeValue = (lifetime, duration) => {
  if (lifetime) {
    if (duration) {
      return COMMISION_DURATION_TYPES.fixed
    }
    return COMMISION_DURATION_TYPES.lifetime
  }
  return COMMISION_DURATION_TYPES.none
}

export const getLifetimeRequestParams = (lifetime, lifetimeDuration) => {
  switch (lifetime) {
    case COMMISION_DURATION_TYPES.none:
      return {
        lifetime: false,
        lifetimeDuration: 0,
      }
    case COMMISION_DURATION_TYPES.lifetime:
      return {
        lifetime: true,
        lifetimeDuration: 0,
      }
    default:
      return {
        lifetime: true,
        lifetimeDuration,
      }
  }
}

export const getLiftimeValue = (lifetime, duration) => {
  const formattedValue = formatLifetimeValue(lifetime, duration)

  switch (formattedValue) {
    case COMMISION_DURATION_TYPES.lifetime:
      return I18n.t('react.shared.affiliateProgram.lifetime')
    case COMMISION_DURATION_TYPES.fixed:
      return `${duration} ${duration === 1 ? I18n.t('react.shared.common.day') : I18n.t('react.shared.common.days')}`
    default:
      return I18n.t('react.shared.affiliateProgram.commission_duration_none')
  }
}
