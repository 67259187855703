import type { Response } from 'types/responses'
import type { ApiClient } from 'utils/requests.utils'

interface ClickReq {
  pid: string
  token: string
  productSlug: string
  clickerRef: string | undefined
}

interface Click {
  clickerRef: string
}

const BASE_URL = (username) => `/shop/${username}/affiliate_clicks`

export const createAffiliateClicksApi = ({ POST }: ApiClient) => ({
  createClick: (username: string, data: ClickReq) => POST<Response<Click>>(BASE_URL(username), data),
})

export type AffiliateClicksApi = ReturnType<typeof createAffiliateClicksApi>
