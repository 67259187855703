import { observable, action, computed, makeObservable } from 'mobx'

import I18n from 'i18n-js'
import { DEFAULT_FALLBACK_LOCALE, getSupportedLocale, LANGUAGES_OPTIONS_KEYS } from 'constants/locale.constants'
import { getCookies } from 'libs/common/cookies'
import { merge } from 'utils/lodash.utils'

const loadTranslations = (locale, appName) =>
  Promise.all([
    import(`locales/${locale}/shared/common.yml`),
    import(`locales/${locale}/shared/other.yml`),
    import(`locales/${locale}/shared/payment_period.yml`),
    import(`locales/${locale}/shared/payment_form.yml`),
    import(`locales/${locale}/shared/payment_state.yml`),
    import(`locales/${locale}/shared/product_form.yml`),
    import(`locales/${locale}/shared/invoice_form.yml`),
    import(`locales/${locale}/react/shared.yml`),
    import(`locales/${locale}/react/common.yml`),
    import(`locales/${locale}/react/${appName}.yml`),
  ])

const loadTranslationsNew = (locale, appName) => {
  const supportedLocale = getSupportedLocale(locale)

  return Promise.all([
    import(`locales-gen/${supportedLocale}/${appName}.locales-gen.json`),
    import(`locales-gen/${supportedLocale}/shared.locales-gen.json`),
  ])
}

async function handleTranslationsNew(locale, appName) {
  const [appTranslations, sharedTranslation] = await loadTranslationsNew(locale, appName)

  I18n.translations[locale] = merge(sharedTranslation, appTranslations)
}

async function handleTranslations(locale, appName) {
  const [
    sharedCommon,
    sharedOther,
    sharedPaymentPeriod,
    sharedPaymentForm,
    sharedPaymentState,
    sharedProductForm,
    sharedInvoiceForm,
    sharedApp,
    commonApp,
    appTranslations,
  ] = await loadTranslations(locale, appName)

  const sharedTranslations = {
    common: sharedCommon,
    other: sharedOther,
    payment_period: sharedPaymentPeriod,
    payment_form: sharedPaymentForm,
    payment_state: sharedPaymentState,
    product_form: sharedProductForm,
    invoice_form: sharedInvoiceForm,
  }

  I18n.translations[locale] = {
    react: {
      shared: sharedApp,
      common: commonApp,
      [appName]: appTranslations,
    },
    shared: sharedTranslations,
  }
}

export class I18nStore {
  appName = null
  @observable isTranslationsLoading = true

  constructor() {
    makeObservable(this)
  }

  @computed
  get isDefaultLocale() {
    return I18n.locale === I18n.defaultLocale
  }

  @action
  setTranslationsLoading = (value) => (this.isTranslationsLoading = value)

  // TODO: rm after finalize FLOW-9268
  @action
  fetchTranslations = async () => {
    if (!this.isDefaultLocale) {
      await handleTranslations(I18n.locale, this.appName)
    }
    this.setTranslationsLoading(false)
  }

  @action
  fetchTranslationsNew = async () => {
    if (!this.isDefaultLocale) {
      await handleTranslationsNew(I18n.locale, this.appName)
    }
    this.setTranslationsLoading(false)
  }

  // TODO: SSR - where is source of true for initializing locale
  @action
  init = ({ router, appName, locale }) => {
    this.appName = appName

    I18n.fallbacks = true
    I18n.defaultLocale = DEFAULT_FALLBACK_LOCALE
    I18n.locale = DEFAULT_FALLBACK_LOCALE
    I18n.missingTranslationPrefix = 'MT: '
    I18n.missingBehaviour = 'guess'
    I18n.availableLanguages = LANGUAGES_OPTIONS_KEYS

    // TODO: zero deps for now, possible use of common utils in future
    const isServer = typeof window === 'undefined'
    // TODO: zero deps for now, possible use of common utils in future
    const localeParam = isServer ? router?.query?.locale : new URL(window.location.href)?.searchParams?.get('locale') // zero dependencies
    const browserLanguages = isServer
      ? []
      : [...new Set(window.navigator.language.split(/,|-|;/).map((item) => item.toLowerCase()))]
    const getBrowserLocale = browserLanguages.filter((language) => I18n.availableLanguages.indexOf(language) !== -1)[0]

    /**
     * TODO: this needs to be improved as soon as cookies will be working for next.js
     */
    // if (localeParam && localeParam.length && I18n.availableLanguages.includes(localeParam) && localeParam !== getCookies('locale')) {
    //   updateItem({locale: localeParam})

    //   setCookies('locale', localeParam)
    // }

    I18n.locale =
      locale ||
      (I18n.availableLanguages.includes(localeParam) && localeParam) ||
      getCookies('locale') ||
      getBrowserLocale ||
      I18n.defaultLocale
  }

  @action hydrate = ({ locale, translations }) => {
    if (translations) {
      I18n.translations[locale] = translations
    }

    this.setTranslationsLoading(false)
  }
}

export default new I18nStore()
