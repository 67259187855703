import { makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { AffiliateLanding, AffiliateLandingsApi, createAffiliateLandingsApi } from 'shop/api/affiliateLandings.api'

export class AffiliateLandingsStore extends SharedStore<AffiliateLanding> {
  storeName = 'AffiliateLandingsStore'
  root: ShopRootStore
  declare childApi: AffiliateLandingsApi

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    makeObservable(this)

    this.childApi = createAffiliateLandingsApi(root.apiClientV2)
  }

  @override
  async fetchItem(id: string, data: { username: string }) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem(id, data)

    this.item = resp.data
    this.toggleLoading(false)

    return resp
  }
}
