import { NO, YES } from '@elo-kit/constants/general.constants'

export const FULL_NAME_MIN_LENGTH = 2
export const FULL_NAME_MAX_LENGTH = 40
export const MAX_LENGTH_VAT_REGISTRATIONS = 5

export const BUSINESS_TYPES = {
  natural: 'natural',
  entity: 'entity',
}

export const PROFILE_TABS = {
  business: 'business',
  personal: 'personal',
  details: 'details',
  representative: 'representative',
  tax: 'tax',
  documents: 'documents',
  summary: 'summary',
}

export const getProfileSteps = (isNewUser) => ({
  [BUSINESS_TYPES.natural]: [
    {
      title: I18n.t('react.cabinet.profile_modal.steps.business'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-circle',
      step: PROFILE_TABS.business,
    },
    {
      title: I18n.t('react.cabinet.profile_modal.steps.personal'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-exclamation-circle',
      step: PROFILE_TABS.personal,
    },
    {
      title: I18n.t('react.cabinet.profile_modal.steps.tax'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-exclamation-circle',
      step: PROFILE_TABS.tax,
    },
    ...(isNewUser
      ? [
          {
            title: I18n.t('react.cabinet.profile_modal.steps.documents'),
            defaultIcon: 'far fa-circle',
            completeIcon: 'fas fa-check-circle',
            errorIcon: 'far fa-exclamation-circle',
            step: PROFILE_TABS.documents,
          },
          {
            title: I18n.t('react.cabinet.profile_modal.steps.summary'),
            defaultIcon: 'far fa-circle',
            completeIcon: 'fas fa-check-circle',
            errorIcon: 'far fa-exclamation-circle',
            step: PROFILE_TABS.summary,
          },
        ]
      : []),
  ],
  [BUSINESS_TYPES.entity]: [
    {
      title: I18n.t('react.cabinet.profile_modal.steps.business'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-circle',
      step: PROFILE_TABS.business,
    },
    {
      title: I18n.t('react.cabinet.profile_modal.steps.representative'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-exclamation-circle',
      step: PROFILE_TABS.representative,
    },
    {
      title: I18n.t('react.cabinet.profile_modal.steps.details'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-exclamation-circle',
      step: PROFILE_TABS.details,
    },
    {
      title: I18n.t('react.cabinet.profile_modal.steps.tax'),
      defaultIcon: 'far fa-circle',
      completeIcon: 'fas fa-check-circle',
      errorIcon: 'far fa-exclamation-circle',
      step: PROFILE_TABS.tax,
    },
    ...(isNewUser
      ? [
          {
            title: I18n.t('react.cabinet.profile_modal.steps.documents'),
            defaultIcon: 'far fa-circle',
            completeIcon: 'fas fa-check-circle',
            errorIcon: 'far fa-exclamation-circle',
            step: PROFILE_TABS.documents,
          },
          {
            title: I18n.t('react.cabinet.profile_modal.steps.summary'),
            defaultIcon: 'far fa-circle',
            completeIcon: 'fas fa-check-circle',
            errorIcon: 'far fa-exclamation-circle',
            step: PROFILE_TABS.summary,
          },
        ]
      : []),
  ],
})

export const getVatPayerOptions = () => [
  {
    label: I18n.t('react.cabinet.common.vat_liable'),
    value: YES,
  },
  {
    label: I18n.t('react.cabinet.common.vat_exempt'),
    value: NO,
  },
]

export const VAT_REASON_KEYS = {
  smallBusiness: 'small_business',
  educational: 'educational',
  regulatedProfession: 'regulated_profession',
  other: 'other',
}

export const getVatReasonOptions = () => [
  {
    label: I18n.t('react.cabinet.profile_modal.tax_step.reason_small_business'),
    value: VAT_REASON_KEYS.smallBusiness,
  },
  {
    label: I18n.t('react.cabinet.profile_modal.tax_step.reason_educational_institution'),
    value: VAT_REASON_KEYS.educational,
  },
  {
    label: I18n.t('react.cabinet.profile_modal.tax_step.reason_regulated_profession'),
    value: VAT_REASON_KEYS.regulatedProfession,
  },
  {
    label: I18n.t('react.cabinet.profile_modal.tax_step.reason_other'),
    value: VAT_REASON_KEYS.other,
  },
]

export const getVatReasonLocales = () => ({
  small_business: I18n.t('react.cabinet.profile_modal.tax_step.small_business_message'),
  educational: I18n.t('react.cabinet.profile_modal.tax_step.educational_institution_message'),
  regulated_profession: I18n.t('react.cabinet.profile_modal.tax_step.regulated_profession_message'),
  other: I18n.t('react.cabinet.profile_modal.tax_step.other_message'),
})

export const isNameValid = (name, minLength, maxLength) => name?.length >= minLength && name?.length <= maxLength

export const TAX_SWITCHER_KEYS = {
  taxNumber: 'number',
  taxIdentificationNumber: 'identification_number',
}
