import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloPhoneIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M8.27084 1.70874L9.13517 1.8733C9.94509 2.02749 10.4889 2.78427 10.4061 3.6417L10.2412 5.34803C10.1693 6.09159 9.64125 6.72512 8.9355 6.91429L6.97165 7.44073C6.65212 8.21991 6.50607 9.07866 6.5335 10.0171C6.56094 10.9554 6.75761 11.8282 7.12352 12.6356L8.96184 12.9617C9.65867 13.0852 10.1785 13.6602 10.2517 14.3884L10.4223 16.0848C10.5073 16.9313 9.96875 17.7429 9.16217 17.9836L8.29781 18.2414C7.43746 18.4981 6.53203 18.269 5.92091 17.6401C4.47817 16.1552 3.75455 13.6796 3.75002 10.2132C3.74558 6.74173 4.46479 4.18903 5.9077 2.55507C6.51485 1.86753 7.41382 1.54557 8.27084 1.70874ZM13.9136 5.95598L13.9837 6.0165L17.3143 9.34791C17.5359 9.56966 17.5562 9.91658 17.3749 10.1612L17.3145 10.2313L13.984 13.5675C13.7402 13.8118 13.3444 13.8122 13.1002 13.5682C12.8781 13.3466 12.8576 12.9994 13.0389 12.7546L13.0994 12.6844L15.3588 10.4201L10.0005 10.4208C9.68409 10.4208 9.42259 10.1857 9.38117 9.88066L9.3755 9.79583C9.3755 9.47941 9.61059 9.21791 9.91567 9.17658L10.0005 9.17083L15.368 9.17008L13.0998 6.90027C12.8778 6.67835 12.8578 6.33113 13.0393 6.08648L13.0998 6.01638C13.2971 5.81918 13.5933 5.78136 13.8286 5.90293L13.9136 5.95598Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
