export const DEFAULT_PAST_DUE = 14

export const PRICING_INTERVALS = [
  '1d',
  '2d',
  '3d',
  '4d',
  '5d',
  '6d',
  '1w',
  '8d',
  '9d',
  '10d',
  '11d',
  '12d',
  '13d',
  '2w',
  '15d',
  '16d',
  '17d',
  '18d',
  '19d',
  '20d',
  '3w',
  '22d',
  '23d',
  '24d',
  '25d',
  '26d',
  '27d',
  '4w',
  '1m',
  '2m',
  '3m',
  '4m',
  '5m',
  '6m',
  '7m',
  '8m',
  '9m',
  '10m',
  '11m',
  '1y',
  '2y',
]
export const getPricingIntervalsLabels = () => ({
  '1d': I18n.t('react.shared.pricing_plan.interval.1d'),
  '2d': I18n.t('react.shared.pricing_plan.interval.2d'),
  '3d': I18n.t('react.shared.pricing_plan.interval.3d'),
  '4d': I18n.t('react.shared.pricing_plan.interval.4d'),
  '5d': I18n.t('react.shared.pricing_plan.interval.5d'),
  '6d': I18n.t('react.shared.pricing_plan.interval.6d'),
  '1w': I18n.t('react.shared.pricing_plan.interval.1w'),
  '8d': I18n.t('react.shared.pricing_plan.interval.8d'),
  '9d': I18n.t('react.shared.pricing_plan.interval.9d'),
  '10d': I18n.t('react.shared.pricing_plan.interval.10d'),
  '11d': I18n.t('react.shared.pricing_plan.interval.11d'),
  '12d': I18n.t('react.shared.pricing_plan.interval.12d'),
  '13d': I18n.t('react.shared.pricing_plan.interval.13d'),
  '2w': I18n.t('react.shared.pricing_plan.interval.2w'),
  '15d': I18n.t('react.shared.pricing_plan.interval.15d'),
  '16d': I18n.t('react.shared.pricing_plan.interval.16d'),
  '17d': I18n.t('react.shared.pricing_plan.interval.17d'),
  '18d': I18n.t('react.shared.pricing_plan.interval.18d'),
  '19d': I18n.t('react.shared.pricing_plan.interval.19d'),
  '20d': I18n.t('react.shared.pricing_plan.interval.20d'),
  '3w': I18n.t('react.shared.pricing_plan.interval.3w'),
  '22d': I18n.t('react.shared.pricing_plan.interval.22d'),
  '23d': I18n.t('react.shared.pricing_plan.interval.23d'),
  '24d': I18n.t('react.shared.pricing_plan.interval.24d'),
  '25d': I18n.t('react.shared.pricing_plan.interval.25d'),
  '26d': I18n.t('react.shared.pricing_plan.interval.26d'),
  '27d': I18n.t('react.shared.pricing_plan.interval.27d'),
  '4w': I18n.t('react.shared.pricing_plan.interval.4w'),
  '1m': I18n.t('react.shared.pricing_plan.interval.1m'),
  '2m': I18n.t('react.shared.pricing_plan.interval.2m'),
  '3m': I18n.t('react.shared.pricing_plan.interval.3m'),
  '4m': I18n.t('react.shared.pricing_plan.interval.4m'),
  '5m': I18n.t('react.shared.pricing_plan.interval.5m'),
  '6m': I18n.t('react.shared.pricing_plan.interval.6m'),
  '7m': I18n.t('react.shared.pricing_plan.interval.7m'),
  '8m': I18n.t('react.shared.pricing_plan.interval.8m'),
  '9m': I18n.t('react.shared.pricing_plan.interval.9m'),
  '10m': I18n.t('react.shared.pricing_plan.interval.10m'),
  '11m': I18n.t('react.shared.pricing_plan.interval.11m'),
  '1y': I18n.t('react.shared.pricing_plan.interval.1y'),
  '2y': I18n.t('react.shared.pricing_plan.interval.2y'),
})
