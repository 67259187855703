import { capitalize } from 'utils/nameStyle.utils'

export const MAX_PRODUCT_NAME_LENGTH = 300
export const MAX_PRODUCT_INTERNAL_NAME_LENGTH = 300

export const PRODUCT_TYPE_IDS = {
  course: 'course',
  digital: 'service',
  download: 'download',
  membership: 'membership',
  eventTickets: 'event',
  certificate: 'certificate',
  licenseKey: 'license_key',
  bundle: 'bundle',
}

export const SHOP_ITEM_PRODUCT_EXPANDS = [
  'covers',
  'additional_fee',
  'goods.digital',
  'opt_in.opt_in_questions',
  'tickets.ticket_dates',
  'pricing_plans',
]

export const SHOP_ITEM_PRODUCT_WITH_UPSELLS_EXPANDS = ['product_addons']
export const SHOP_PRODUCTS_EXPANDS = ['covers']
export const SOCIAL_PROOF_BUBBLE_EXPANDS = ['social_proof_bubble']

export const UPSELLS_TYPES = {
  bundle: 'bundle',
  bump: 'bump',
}

export const ONE_LINE_CHECKOUT_PAGE_PRODUCT_NAME = 45

export const MAX_LICENSE_CODES_COUNT = 75

export const BUBBLE_TYPE = {
  totalViews: 'total_views',
  totalPurchases: 'total_purchases',
  recentPurchase: 'recent_purchase',
}

export const TICKET_VIEW = {
  dropDown: 'drop_down',
  list: 'list',
}

export const CAN_NOT_SELL_TEXT = {
  isNotValid: 'not_valid',
  noActivePayMethod: 'no_active_pay_method',
  noPricingPlans: 'no_pricing_plans',
  limitReached: 'limit_reached',
  inReview: 'on_review',
  rejected: 'rejected',
  notForReview: 'not_reviewed',
  notActive: 'not_active',
  noActiveEvents: 'no_active_events',
  noDigitals: 'no_digitals',
  finishRegistration: 'finish_registration',
  noProducts: 'no_products',
  archived: 'is_archived',
  hasAccessPassword: 'has_access_password',
}

export const PRODUCT_TYPE_APP_NAMES = {
  membership: 'memberships',
  certificate: 'certificates',
  license_key: 'license_keys',
}

export const PRODUCT_TYPE_REQUIRED_APPS = {
  download: false,
  event: false,
  course: false,
  service: false,
  membership: true,
  certificate: true,
  license_key: true,
}

export const getShopProductTypeOptions = () => [
  {
    value: 'download',
    label: I18n.t('shared.product_form.download'),
  },
  {
    value: 'event',
    label: I18n.t('shared.product_form.event'),
  },
  {
    value: 'course',
    label: I18n.t('shared.product_form.course'),
  },
  {
    value: 'service',
    label: I18n.t('shared.product_form.service'),
  },
  {
    value: 'membership',
    label: I18n.t('shared.product_form.membership'),
  },
  {
    value: 'bundle',
    label: I18n.t('shared.product_form.bundle'),
  },
  {
    value: 'license_key',
    label: I18n.t('shared.product_form.license_key'),
  },
  {
    value: 'certificate',
    label: I18n.t('shared.product_form.certificate'),
  },
]

export const PRODUCT_SELECTION_TYPES = {
  product: 'product',
  ticket: 'ticket',
  ticketDates: 'ticketDates',
}

export const TABLE_TYPES = {
  checkboxes: 'checkboxes',
  radios: 'radios',
}

export const PRODUCT_TAXONOMOY_CATEGORIES = {
  eBook: 'e_book',
  musicFiles: 'music_files',
  coaching: 'coaching',
  membership: 'membership',
  book: 'book',
  onlineCourse: 'online_course',
  onlineCourseLive: 'online_course_live',
  event: 'event',
  certificate: 'certificate',
  licenseCode: 'license_code',
}

export const PRODUCT_TAXONOMOY_CATEGORIES_OPTIONS = Object.values(PRODUCT_TAXONOMOY_CATEGORIES).map((category) => ({
  value: category,
  label: capitalize(category.split('_').join(' ')),
}))

export const PRODUCT_TYPE_OPTIONS = [
  {
    value: 'download',
    label: I18n.t('shared.product_form.download'),
  },
  {
    value: 'event',
    label: I18n.t('shared.product_form.event_2'),
  },
  {
    value: 'course',
    label: I18n.t('shared.product_form.course'),
  },
  {
    value: 'service',
    label: I18n.t('shared.product_form.service'),
  },
  {
    value: 'membership',
    label: I18n.t('shared.product_form.membership'),
  },
  {
    value: 'bundle',
    label: I18n.t('shared.product_form.bundle'),
  },
  {
    value: 'license_key',
    label: I18n.t('react.cabinet.common.license_key'),
  },
  {
    value: 'certificate',
    label: I18n.t('react.cabinet.common.certificate'),
  },
]

export const REVIEW_STATES = Object.freeze({
  reviewed: 'reviewed',
  inReview: 'in_review',
  rejected: 'rejected',
  notForReview: 'not_for_review',
  free: 'free_without_fee',
})

export const PRODUCT_FILTERS_LIST = [
  'productType',
  'isPublished',
  'isArchived',
  'isPrivate',
  'isFree',
  'createdAt',
  'multiProductCategory',
  'multiProductIntName',
]

export const EMAIL_FORMS = {
  standardEmail: 'standard_email',
  customEmail: 'custom_email',
  noEmail: 'no_email',
}

export const EMAIL_FORMS_ATTENDEE_VALUES = [EMAIL_FORMS.standardEmail, EMAIL_FORMS.customEmail]

export const EMAIL_FORMS_VALUES = Object.values(EMAIL_FORMS)

export const TRACKING_FORMS = {
  noneTrackingCodes: 'none_tracking_codes',
  allTrackingCodes: 'all_tracking_codes',
  selectedTrackingCodes: 'selected_tracking_codes',
}

export const WEBHOOK_ENDPOINT_FORMS = {
  noWebhookEndpoints: 'no_webhook_endpoints',
  allWebhookEndpoints: 'all_webhook_endpoints',
  selectedWebhookEndpoints: 'selected_webhook_endpoints',
}
