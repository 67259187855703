import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import { EventAttendeesApi, createEventAttendeesApi, EventAttendee } from 'shop/api/eventAttendees.api'

export class EventAttendeesStore extends SharedStore<EventAttendee> {
  storeName = 'EventAttendeesStore'
  declare childApi: EventAttendeesApi

  constructor(root?: ShopRootStore) {
    super()
    this.childApi = createEventAttendeesApi(root?.apiClient ?? apiClient)
  }
}

export default new EventAttendeesStore()
